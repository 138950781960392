import instance from "../../../../../API/MainAPI";

export default function CouponModificationAPI(id: number, body: any) {
  return instance.patch(`/v1/admins/coupons/managements/${id}`, body, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
