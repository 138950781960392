import { useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import SupporterSetting from "../Data/SupporterSetting";

interface OwnProps {
  onClose(): void;
}

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 130px);
  left: calc(50vw - 280px);
  background-color: white;
  border-radius: 10px;
  width: 576px;
  height: 344px;
`;
const BigTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: bold;
`;
const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const InputBox = styled.div`
  margin-left: 10px;
  border: 1px solid black;
  border-radius: 9px;
`;
const PerInput = styled.input`
  width: 200px;
  border-width: 0;
  height: 30px;
  outline: none;
  text-align: right;
`;
const Percent = styled.span`
  margin-right: 10px;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 70px;
`;

const OkBtn = styled.button`
  width: 180px;
  height: 60px;
  border: none;
  background-color: #ffc200;
  color: black;
  border-radius: 13px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
`;

const CancelBtn = styled.button`
  width: 180px;
  height: 60px;
  border: none;
  background-color: #cecece;
  color: black;

  border-radius: 13px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 30px;
  margin-right: -10px;
`;
const SettingModal: React.FC<OwnProps> = ({ onClose }) => {
  const [obtValue, setObtValue] = useState<number | undefined>();
  const [cbtValue, setCbtValue] = useState<number | undefined>();

  const { data: resdata, refetch: refetchAll } = useQuery(
    ["supporterSettings", obtValue, cbtValue],
    async () => {
      const response = await Promise.all([
        SupporterSetting(obtValue, "RELEASE"),
        SupporterSetting(cbtValue, "CBT"),
      ]);
      console.log(response);
      return response;
    },
    {
      enabled: false,
      onSuccess: (res) => {
        console.log(res);
      },
    }
  );
  // const { data: response2, refetch: refetch2 } = useQuery(
  //   ["supporterSettingCbt", cbtValue],
  //   async () => {
  //     return await SupporterSetting(cbtValue, "CBT");
  //   },
  //   {
  //     enabled: false,
  //     onSuccess: (res) => {
  //       console.log(res);
  //     },
  //   }
  // );
  const onSet = async () => {
    refetchAll();
    onClose();
  };
  const onObtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setObtValue(value);
    } else {
      alert("숫자를 입력해주세요!");
      setObtValue(0);
    }
  };
  const onCbtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setCbtValue(value);
    } else {
      alert("숫자를 입력해주세요!");
      setCbtValue(0);
    }
  };

  return (
    <ModalBg>
      <ModalBox>
        <BigTxt>서포터즈 설정</BigTxt>
        <ContentsTxt>
          OBT:
          <InputBox>
            <PerInput onChange={onObtChange} value={obtValue} />
            <Percent>%</Percent>
          </InputBox>
        </ContentsTxt>

        <ContentsTxt>
          CBT:
          <InputBox>
            <PerInput onChange={onCbtChange} value={cbtValue} />
            <Percent>%</Percent>
          </InputBox>
        </ContentsTxt>

        <DivBtn>
          <OkBtn onClick={onSet}>저장</OkBtn>
          <CancelBtn onClick={onClose}>취소</CancelBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
};

export default SettingModal;
