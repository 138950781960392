import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import AppStatusCreationAPI from "./Data/AppStatusCreation";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function AppStatsAdd() {
  const { register, getValues } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const formData = new FormData();

  async function CallAPI() {
    const versionValue = getValues("version");
    const messageValue = getValues("message");
    const appStoreLinkValue = getValues("appStoreLink");
    const playStoreValue = getValues("playStoreLink");
    const statusValue = getValues("status");
    const compulsionValue = getValues("compulsion");
    formData.append("version", versionValue);

    formData.append("message", messageValue);
    formData.append("appStoreLink", appStoreLinkValue);
    formData.append("playStoreLink", playStoreValue);
    formData.append("status", statusValue);
    formData.append("compulsion", compulsionValue);
    await adsAddMutation.mutateAsync(formData);
  }

  const adsAddMutation = useMutation(AppStatusCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("앱 버전 등록이 완료되었습니다.");
      queryClient.invalidateQueries("notices");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const versionValue = getValues("version");
    const messageValue = getValues("message");
    const appStoreLinkValue = getValues("appStoreLink");
    const playStoreValue = getValues("playStoreLink");
    const statusValue = getValues("status");
    const compulsionValue = getValues("compulsion");
    if (
      versionValue &&
      messageValue &&
      appStoreLinkValue &&
      playStoreValue &&
      statusValue &&
      compulsionValue
    ) {
      setModal(true);
    } else {
      alert("필수사항을 확인하세요");
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <Inner>
        <Header>
          <Title>앱 버전 정보 추가</Title>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>
              앱 버전
              <ItemSpan>
                "x.x.x" 형식의 문자열로, x 자리에는 정수만 허용합니다.
              </ItemSpan>
            </ItemTitle>
          </Item>
          <div>
            <TitleInput type="text" {...register("version")} />
          </div>
          <Item>
            <ItemTitle>메시지</ItemTitle>
            <div>
              <TextArea rows={2} {...register("message")} />
            </div>
            <Item>
              <ItemTitle>앱스토어 앱 다운로드 링크</ItemTitle>
              <LinkInput type="text" {...register("appStoreLink")} />
            </Item>
          </Item>
          <Item>
            <ItemTitle>플레이스토어 앱 다운로드 링크</ItemTitle>
            <LinkInput type="text" {...register("playStoreLink")} />
          </Item>
          <Item>
            <ItemTitle>접속 가능 유무</ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("status")} />
              접속 가능
            </Label>
            <Label>
              <input type="radio" value="false" {...register("status")} />
              접속 차단
            </Label>
          </Item>
          <Item>
            <ItemTitle>
              강제 업데이트 유무
              <ItemSpan>
                설정 시 업데이트 하지 않은 기기는 접속이 제한됩니다.
              </ItemSpan>
            </ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("compulsion")} />
              업데이트 설정
            </Label>
            <Label>
              <input type="radio" value="false" {...register("compulsion")} />
              업데이트 해제
            </Label>
          </Item>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={onAdd}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
