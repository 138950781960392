import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 250px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 580px;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const ReasonTxt = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ReasonInput = styled.input`
  width: 70%;
  height: 1rem;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-left: 1rem;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 50px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
`;

const CancelBtn = styled.button`
  width: 50px;
  padding: 5px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

function RejectModal(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const arr = props.selectArr;

  const { register, getValues } = useForm();

  function onClick() {
    setIsLoading(true);
    props.callAPI(arr, arr.length, getValues("reason")).then(() => {
      alert("거절을 완료했습니다.");
      //props.setIsLoading();
      setIsLoading(false);
    });
  }

  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>인증을 거절하시겠습니까?</ContentsTxt>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "2rem",
            marginLeft: "1rem",
          }}
        >
          {" "}
          <ReasonTxt>거절 사유</ReasonTxt>
          <ReasonInput {...register("reason")} />
        </div>

        <DivBtn>
          <CancelBtn className="cancelBtn" onClick={() => props.onClose()}>
            취소
          </CancelBtn>
          <OkBtn disabled={isLoading} onClick={onClick}>
            확인
          </OkBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
}

export default RejectModal;
