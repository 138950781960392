import instance from "API/MainAPI";

export default function approveBountyHunter(bountyHunterIds: number[]) {
  return instance.patch<{
    success: boolean;
  }>("/v3/admins/bountyHunters/managements/approval", {
    bountyHunterIds,
    count: bountyHunterIds.length,
  });
}
