import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import AnnouncementDeleteAPI from "./Data/AnnouncementDeleteAPI";
import AnnouncementDetailAPI from "./Data/AnnouncementDetailAPI";
import AnnouncementModificationAPI from "./Data/AnnouncementModificationAPI";

import DeleteModal from "./Modal/DeleteModal";
import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const PrevDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 30%;
  height: 100%;
`;

const ShowImg = styled.img`
  width: 40%;
  height: 10%;
  margin-right: 10px;
  padding: 10px;
`;

const ClickedImg = styled.img`
  width: 40%;
  height: 10%;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`;

const ImgForm = styled.div`
  width: 40%;
  height: 200px;
  border: dotted;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CircleInput = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-size: 40px;
  border: 2px solid;
  padding: 2px;
  cursor: pointer;
  align-items: center;
`;

const ImgInput = styled.input`
  font-size: 16px;
  display: none;
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
  font-size: 18px;
`;
const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
  font-size: 18px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;
const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function AnnouncementEdit() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;

  const [images, setImages] = useState<File[]>([]);
  const [originImages, setOriginImages] = useState<string[]>([]);

  const [showImages, setShowImages] = useState<string[]>([]);
  const formData = new FormData();
  const showLink = watch("category");

  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };

  const { data: announcementDetail } = useQuery(
    ["announcementDetail", id],
    () => AnnouncementDetailAPI(id)
  );

  //modal
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  function onDelete() {
    setModalDelete(true);
  }
  function closeDelete() {
    setModalDelete(false);
  }
  function onEdit() {
    const redirectLinkValue = getValues("redirectLink");
    if (showLink === "EXTERNALS") {
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else {
        setModalEdit(true);
      }
    } else {
      setValue("redirectLink", "");
      setModalEdit(true);
    }
  }
  function closeEdit() {
    setModalEdit(false);
  }

  const { mutate: deleteAnnouncement, isLoading: deleteLoading } = useMutation(
    async () => await AnnouncementDeleteAPI(id),
    {
      onMutate: async () => {
        // Remove the query from cache before making the delete request
        queryClient.removeQueries(["announcementDetail", id]);
      },
      onSuccess: () => {
        closeDelete();
      },
      onError: () => {
        alert("실패했습니다. 다시 시도하세요.");
        closeDelete();
      },
    }
  );

  const handleDelete = async () => {
    await deleteAnnouncement();
    await alert("공지글가 삭제되었습니다.");
    await navigate(-1); // Move navigation here
  };

  async function CallEditAPI() {
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const contentValue = getValues("content");
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");

    const modifiedData = {
      title:
        titleValue !== undefined ? titleValue : announcementDetail?.data.title,
      subtitle:
        subtitleValue !== undefined
          ? subtitleValue
          : announcementDetail?.data.subtitle,
      content:
        contentValue !== undefined
          ? contentValue
          : announcementDetail?.data.cotent,
      redirectLink:
        redirectLinkValue !== undefined
          ? redirectLinkValue
          : announcementDetail?.data.redirectLink,
      category:
        categoryValue !== undefined
          ? categoryValue
          : announcementDetail?.data.category,
    };

    const dataBlob = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("data", dataBlob);

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    await gameNewsEdit.mutateAsync(formData);
  }

  const gameNewsEdit = useMutation(
    (formData: FormData) => AnnouncementModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("공지글 수정이 완료되었습니다.");
        queryClient.invalidateQueries("announcementDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await AnnouncementDetailAPI(id);

    if (response) {
      setValue("title", response.data.title);
      setValue("content", response.data.content);
      setValue("redirectLink", response.data.redirectLink);
      setValue("category", response.data.category);
      setOriginImages(response.data.imageUrls);
    }
  };

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images!.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));
        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          CallAPI={handleDelete}
          isLoding={deleteLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>공지글 #{id}</Title>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>기존 이미지</ItemTitle>
          </Item>
          <Item>
            <PrevDiv>
              {originImages &&
                originImages.map((url, idx) => (
                  <ShowImg key={idx} src={url} alt="" />
                ))}
            </PrevDiv>
          </Item>
          <Item>
            <ItemTitle>
              공지글 이미지 파일
              <ItemSpan>*수정 시 사진 재등록 필수(최대 5개까지)</ItemSpan>
            </ItemTitle>
          </Item>

          <Item>
            <ImgForm>
              <div>
                <CircleInput onClick={handleClick}>
                  <div style={{ marginBottom: "0.5rem" }}>+</div>
                </CircleInput>
                <ImgInput
                  ref={fileRef}
                  type="file"
                  multiple
                  accept=".jpg, .png"
                  onChange={Image}
                />
              </div>
              <ItemSpan>이미지 선택 시 삭제</ItemSpan>
            </ImgForm>
          </Item>
          <Item>
            <PrevDiv>
              {showImages &&
                showImages.map((url, idx) => (
                  <ClickedImg
                    key={idx}
                    src={url}
                    alt=""
                    onClick={() => imageDelete(idx, url)}
                  />
                ))}
            </PrevDiv>
          </Item>

          <Item>
            <ItemTitle>공지글 제목</ItemTitle>
            <div>
              <TitleInput
                type="text"
                defaultValue={announcementDetail?.data.title}
                {...register("title")}
              />
            </div>
          </Item>

          <Item>
            <ItemTitle>
              공지글 내용
              <ItemSpan>*최대 2000자</ItemSpan>
            </ItemTitle>
            <div>
              <TextArea
                rows={10}
                maxLength={2000}
                defaultValue={announcementDetail?.data.content}
                {...register("content")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>카테고리</ItemTitle>
            <div className="w-25 mt-1">
              <Select {...register("category")}>
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </Select>
              {showLink === "EXTERNALS" && (
                <Item>
                  <ItemTitle>연결 링크</ItemTitle>
                  <LinkInput
                    type="text"
                    defaultValue={announcementDetail?.data.redirectLink}
                    {...register("redirectLink")}
                  />
                </Item>
              )}
            </div>
          </Item>
        </Contents>
        <ButtonDiv>
          <DeleteButton onClick={onDelete}>삭제하기</DeleteButton>
          <EditButton onClick={onEdit}>수정하기</EditButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
