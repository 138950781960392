import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faX } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { ModalType } from "components/common/Modal/ModalsContext";
import { BountyStatus } from "../../types";
import useModal from "components/common/Modal/useModal";
import {
  labelOfBountyStatusPhoto,
  labelOfBountyStatusTime,
} from "../../constants";

const AlertTest = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  color: #454545;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 5px;
`;

const Contents = styled.div`
  margin: 1rem;
  color: #454545;
`;

const Cursor = styled.div`
  display: flex;
  margin-right: 15px;
`;

const Txt = styled.div`
  font-weight: 700;
  margin-right: 0.5rem;
`;

const CloseBtn = styled.div`
  background: none;
  border: none;
  text-transform: none;
  cursor: pointer;
  display: flex;
  margin-top: -0.2rem;
`;

const Content = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  border-radius: 5px;
  padding: 0.25rem !important;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
`;

interface StatusModalProps {
  close: () => void;
  setStatus: (status: BountyStatus | undefined) => unknown;
  type: "photo" | "time" | "all";
}

const selectable: {
  [key in StatusModalProps["type"]]: ("ALL" | BountyStatus)[];
} = {
  photo: ["ALL", "SUBMITTED", "APPROVED", "REJECTED"],
  time: ["ALL", "SUBMITTED", "APPROVED"],
  all: ["ALL"],
};

function StatusModal({ close, setStatus, type }: StatusModalProps) {
  const labelMap =
    type === "photo" ? labelOfBountyStatusPhoto : labelOfBountyStatusTime;
  return (
    <AlertTest>
      <Header>
        <Cursor>
          <Txt>인증상태</Txt>
          <FontAwesomeIcon icon={faCaretDown} />
        </Cursor>
        <CloseBtn onClick={close}>
          <FontAwesomeIcon icon={faX} />
        </CloseBtn>
      </Header>

      <Contents>
        <div>
          {selectable[type].map((status) => (
            <Content
              key={status}
              onClick={() => {
                setStatus(status === "ALL" ? undefined : status);
                close();
              }}
            >
              <Title>{status === "ALL" ? "전체" : labelMap[status]}</Title>
            </Content>
          ))}
        </div>
      </Contents>
    </AlertTest>
  );
}

export default StatusModal;
