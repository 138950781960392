import instance from "../../../../../API/MainAPI";

export default function DonationCreationAPI(bodyFormData: any) {
  return instance.post(
    "/v3/admins/gifticons/managements/donations",
    bodyFormData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=Paw",
        Accept: "application/json",
      },
    }
  );
}
