import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import useDayMissionEditForm, {
  HandleSubmitFunc,
} from "./useDayMissionEditForm";
import { Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Form from "../shared/Form";
import { useNavigate, useParams } from "react-router-dom";
import GetDayMissionGameDetailAPI from "../DayMissionGameDetail/Data/GetDayMissionGameDetailAPI";
import { editDayMissions } from "../api";

const rewardOptions = [
  { value: "POINT", label: "포인트" },
  { value: "PLISTAR", label: "플리스타" },
  { value: "NONE", label: "없음" },
];

const orientationOptions = [
  { label: "가로", value: true },
  { label: "세로", value: false },
];

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px 40px 20px 20px;
  align-items: center;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const FormInner = styled.div`
  display: grid;
  grid-template-columns: 438px 1fr;
  gap: 60px 30px;
`;

interface DaySelectorProps {
  dayNumber: number;
  onChange: (dayNumber: number) => unknown;
  maxDayNumber: number;
  append: Function;
}

const DayContainer = styled.div`
  grid-column: span 2;
  display: flex;
  gap: 12px;
`;
const Button = styled.button`
  margin-left: auto;
  width: fit-content;
  grid-column: 2;
  padding: 13px 34px;
  color: white;
  background: #2282e9;
  outline: 0;
  border: 0;
  border-radius: 13px;
  font-size: 24px;
  margin-top: 80px;
`;

const Day = styled.div<{ $isActive?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  background-color: #e3e3e3;
  color: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.$isActive &&
    `
  background-color: #2282E9;
  color: white;
  `}
  border: 0;
  cursor: pointer;
`;

const DaySelector = ({
  dayNumber,
  onChange,
  maxDayNumber,
  append,
}: DaySelectorProps) => {
  const dayList = useMemo(
    () => Array.from({ length: maxDayNumber }, (_, idx) => idx + 1),
    [maxDayNumber]
  );

  return (
    <DayContainer>
      {dayList.map((day) => (
        <Day
          onClick={() => onChange(day)}
          $isActive={day == dayNumber}
          key={day}
        >
          {day}
        </Day>
      ))}
      <Day onClick={(e) => append()}>+</Day>
    </DayContainer>
  );
};

export default function DayMissionEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const daymissionGameId = +(params.daymissionId || "1") || 1;

  const { data: daymissionGameData } = useQuery(
    ["daymissiongame", daymissionGameId],
    () => GetDayMissionGameDetailAPI(daymissionGameId),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );
  const queryClient = useQueryClient();
  const { mutate } = useMutation(editDayMissions, {
    onSuccess() {
      window.alert("데이미션이 수정되었습니다.");
      queryClient.invalidateQueries({
        queryKey: ["daymissiongame", daymissionGameId],
      });
      navigate("/retension/daymissions/" + daymissionGameId);
    },
    onError(err) {
      if ((err as any).response.data.message)
        window.alert((err as any).response.data.message);
    },
  });
  const _handleSubmit: HandleSubmitFunc = (data) => {
    for (const daymission of data.dayMissions) {
      console.log(daymission.id, daymission.missionImage);
      if (
        !daymission.id &&
        (!daymission.missionImage || daymission.missionImage.length < 1)
      ) {
        window.alert("채우지 않은 필수 항목이 있습니다.");
        setDayNumber(daymission.dayNumber);
        return;
      }
    }

    mutate({
      data,
      gameId: daymissionGameId,
    });
  };

  const { handleSubmit, control, reset, register } =
    useDayMissionEditForm(_handleSubmit);

  const { fields, append, update } = useFieldArray({
    control,
    name: "dayMissions",
  });
  const appendDayMission = () => {
    const last = fields[fields.length - 1];
    console.log(last);
    append({
      dayNumber: last.dayNumber + 1,
      exp: last.exp,
      rewards: last.rewards,
      rewardCategory: last.rewardCategory,
      title: "",
      missionImageHorizontal: last.missionImageHorizontal,
    });
    setDayNumber(last.dayNumber + 1);
  };
  const [dayNumber, setDayNumber] = useState(1);

  useEffect(() => {
    if (daymissionGameData?.data) {
      const { dayMissions } = daymissionGameData?.data;
      reset({
        dayMissions,
      });
    }
  }, [daymissionGameData]);

  return (
    <Wrapper>
      <Inner>
        <Header>
          {" "}
          <Title>데이미션 게임 수정</Title>
        </Header>
        <Contents>
          <form onSubmit={handleSubmit}>
            {fields.map((field, index) => (
              <FormInner
                key={field.id}
                style={{
                  display: dayNumber == field.dayNumber ? "grid" : "none",
                }}
              >
                <Form.ImageField
                  label="미션 인증 예시 사진"
                  {...register(`dayMissions.${index}.missionImage`)}
                  previewSrc={
                    daymissionGameData?.data.dayMissions[index]?.missionImageUrl
                  }
                />
                <Form.FormGroup>
                  <DaySelector
                    dayNumber={dayNumber}
                    onChange={setDayNumber}
                    maxDayNumber={fields.length}
                    append={appendDayMission}
                  />
                  <Form.TextArea
                    label="플레이 미션 조건"
                    placeholder="플레이 미션 조건을 상세히 작성해주세요."
                    {...register(`dayMissions.${index}.title`)}
                  />

                  <Controller
                    control={control}
                    name={`dayMissions.${index}.missionImageHorizontal`}
                    render={({ field: { value, onChange, ref } }) => (
                      <Form.RadioGroup
                        label="예시 이미지 수평 방향"
                        options={orientationOptions}
                        onValueChange={onChange}
                        value={value}
                        ref={ref}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`dayMissions.${index}.rewardCategory`}
                    render={({ field: { value, onChange, ref } }) => (
                      <Form.Select
                        label="지급 재화"
                        placeholder="재화를 선택하세요."
                        options={rewardOptions}
                        onChange={(option) => onChange(option.value)}
                        ref={ref}
                        value={value}
                      />
                    )}
                  />
                  <Form.TextField
                    containerStyle={{
                      marginTop: "auto",
                    }}
                    placeholder="숫자만 입력 가능합니다."
                    type="number"
                    suffix="포인트"
                    {...register(`dayMissions.${index}.rewards`)}
                  />
                  <Form.TextField
                    label="경험치"
                    containerStyle={{ gridColumn: "span 2" }}
                    placeholder="숫자만 입력 가능합니다."
                    type="number"
                    {...register(`dayMissions.${index}.exp`)}
                  />
                  <input
                    type="hidden"
                    {...register(`dayMissions.${index}.dayNumber`)}
                  />
                </Form.FormGroup>
              </FormInner>
            ))}
            <Form.FormGroup containerStyle={{ gridColumn: 2 }}>
              <Button type="submit">수정</Button>
            </Form.FormGroup>
          </form>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
