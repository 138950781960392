import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BountyHunt, BountyHuntFilter, BountyStatus } from "../types";
import { useQuery } from "react-query";
import BountyHunterPhotoList from "./Data/BountyHunterPhotoListAPI";

const ITEMS_PER_PAGE = 10;

const useBountyHunterPhotoDetails = (bountyId: number) => {
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState<BountyHuntFilter>({});
  // 현재 페이지 불러오기(예외 시 1)
  const currentPage = useMemo(() => {
    const _page = Number(searchParams.get("page") || "1");
    return Number.isNaN(_page) ? 1 : _page;
  }, [location.search]);

  const [checkedItems, setCheckedItems] = useState<Set<string | number>>(
    new Set()
  );

  // 아이템 목록 불러오기

  const { data: hunterData } = useQuery(
    ["bounty", "photo", bountyId, currentPage, filter.user, filter.status],
    () =>
      BountyHunterPhotoList({
        bountyId,
        pageNumber: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        userId: filter.user,
        status: filter.status,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );
  const items = hunterData?.data.page.content;

  // 아이템 선택/해제
  const toggleItem = useCallback(
    (item: BountyHunt, checked: boolean) => {
      const id = item.bountyHunterId;
      if (!checked) checkedItems.delete(id);
      else if (!checkedItems.has(id)) checkedItems.add(id);
      setCheckedItems(new Set(checkedItems));
    },
    [checkedItems]
  );

  // 모든 아이템 선택/해제
  const toggleItems = useCallback(
    (itemFilter: (item: BountyHunt) => boolean, checked: boolean) => {
      if (!items) return;
      if (checked)
        setCheckedItems(
          new Set(items.filter(itemFilter).map((item) => item.bountyHunterId))
        );
      else setCheckedItems(new Set());
    },
    [checkedItems, items]
  );

  return {
    currentPage,
    items,
    checkedItems,
    toggleItem,
    toggleItems,
    setFilter,
  };
};

export default useBountyHunterPhotoDetails;
