import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import date from "../../../common/function/date";
import CandidateModificationAPI from "./Data/CandidateModificationAPI";
import ElectionDetailAPI from "./Data/ElectionDetailAPI";
import ElectionModificationAPI from "./Data/ElectionModificationAPI";

import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ShowThumb = styled.img`
  width: 400px;
  margin-bottom: 10px;
`;

const ShowImg = styled.img`
  width: 200px;
  margin: 1rem 0;
`;

const ThumbnailInput = styled.input`
  margin-top: 0.5rem;
  font-size: 16px;
`;

const ImageInput = styled.input`
  margin-left: 1rem;
  font-size: 16px;
`;

const CandidateForm = styled.div`
  width: 70%;
  border: 2px solid;
  border-radius: 10px;
  align-items: center;
  margin: 1rem;
  margin-left: 0;
  padding: 1rem;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const AppendButton = styled.button`
  margin-left: 3rem;
  background-color: gray;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;

  width: 60px;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const TxtInput = styled.input`
  width: 25%;
  font-size: 1rem;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const NumInput = styled.input`
  width: 30vw;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function ElectionEdit() {
  const { register, setValue, getValues, watch, control } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "candidate",
  });

  const formData = new FormData();

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [index, setIndex] = useState<number>(0);
  const [candidateId, setCandidateId] = useState<any>(0);
  const [image, setImage] = useState<File | undefined>();
  const [showImg, setShowImg] = useState<string[]>([]);
  const { data: electionDetail } = useQuery(["electionDetail", id], () =>
    ElectionDetailAPI(id)
  );

  //modal

  const [modalEdit, setModalEdit] = useState(false);

  const [candidateEdit, setCandidateEdit] = useState(false);

  function onEdit() {
    const titleValue = getValues("title");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");

    const allFieldsFilled =
      titleValue && rewardsValue && startedAtValue && expiredAtValue;

    if (expiredAtValue < startedAtValue) {
      alert("날짜를 확인하세요.");
    } else if (allFieldsFilled) {
      setValue("redirectLink", "");
      setModalEdit(true);
    } else {
      alert("이미지, 카테고리, 연결링크, 어플 표시 여부는 필수입니다.");
    }
  }

  function closeEdit() {
    setModalEdit(false);
  }

  function onCandidateEdit() {
    setCandidateEdit(true);
  }

  function closeCandidateEdit() {
    setCandidateEdit(false);
  }

  async function CallEditAPI() {
    const rewardsValue = getValues("rewards");
    const titleValue = getValues("title");
    const allowedCandidatesValue = getValues("allowedCandidates");
    const visibleValue = getValues("visible");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    const modifiedData = {
      rewards:
        rewardsValue !== undefined
          ? rewardsValue
          : electionDetail?.data.rewards,

      title: titleValue !== undefined ? titleValue : electionDetail?.data.title,
      allowedCandidates:
        allowedCandidatesValue !== undefined
          ? allowedCandidatesValue
          : electionDetail?.data.allowedCandidates,
      visible:
        visibleValue !== undefined
          ? visibleValue
          : electionDetail?.data.visible,
      startedAt:
        startedAtValue !== undefined
          ? startedAtValue
          : electionDetail?.data.startedAt,

      expiredAt:
        expiredAtValue !== undefined
          ? expiredAtValue
          : electionDetail?.data.expiredAt,
    };

    const blob = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("electionInfo", blob);

    await electionEdit.mutateAsync(formData);
  }

  const electionEdit = useMutation(
    (formData: FormData) => ElectionModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("투표 수정이 완료되었습니다.");
        queryClient.invalidateQueries("electionDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  async function CallCandidatesEditAPI() {
    const idx = index;
    const titleValue = getValues(`candidate.${idx}.title`);
    const bodyValue = getValues(`candidate.${idx}.body`);

    const blob = new Blob(
      [
        JSON.stringify({
          title: titleValue,
          body: bodyValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("candidateInfo", blob);
    if (image) {
      formData.append("image", image);
    }
    await candidatesEdit.mutateAsync(formData);
  }

  const candidatesEdit = useMutation(
    (formData: FormData) => CandidateModificationAPI(candidateId, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("후보 수정이 완료되었습니다.");
        queryClient.invalidateQueries("electionDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await ElectionDetailAPI(id);
      if (response) {
        setValue("title", response.data.title);
        setValue("allowedCandidates", response.data.allowedCandidates);
        setValue("rewards", response.data.rewards);
        setValue("visible", response.data.visible);
        setValue("totVotes", response.data.totVotes);
        setValue("startedAt", response.data.startedAt);
        setValue("expiredAt", response.data.expiredAt);
        setValue("createdAt", response.data.createdAt);
        setValue("visible", response.data.visible);
        setValue("candidate", response.data.candidates);
      }
    };

    fetchData();
  }, []);

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeThumbToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      encodeImgToBase64(selectedImage);
    }
  };

  function encodeImgToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(file);
      showImg[index] = URL.createObjectURL(file);
    };
  }

  const visibleValue = watch("visible");

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  return (
    <Wrapper>
      {modalEdit && (
        <EditModal close={closeEdit} CallAPI={CallEditAPI} text={"투표"} />
      )}
      {candidateEdit && (
        <EditModal
          close={closeCandidateEdit}
          CallAPI={CallCandidatesEditAPI}
          text={"후보"}
        />
      )}

      <Inner>
        <Header>
          <Title>
            투표 #{id} 총 투표수 : {electionDetail?.data.totVotes} 생성 일자 :{" "}
            {date(electionDetail?.data.createdAt)}
          </Title>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>투표 썸네일 파일</ItemTitle>
            {thumbnail ? (
              <ShowThumb src={URL.createObjectURL(thumbnail)} alt="" />
            ) : (
              <ShowThumb src={electionDetail?.data.thumbnailUrl} alt="" />
            )}
            <Item>
              <ThumbnailInput
                type="file"
                accept=".jpg, .png"
                onChange={Thumbnail}
              />
            </Item>
          </Item>

          <Item>
            <ItemTitle>
              투표 제목 <ItemSpan>*20자 이내</ItemSpan>
            </ItemTitle>
            <div>
              <TitleInput
                type="text"
                maxLength={20}
                defaultValue={electionDetail?.data.title}
                {...register("title")}
              />
            </div>
          </Item>

          <Item>
            <ItemTitle>
              다중 투표 허용 갯수
              <ItemSpan>*숫자만 입력 가능</ItemSpan>
            </ItemTitle>
            <NumInput
              type="number"
              min="0"
              {...register("allowedCandidates")}
            />
          </Item>
          <Item>
            <ItemTitle>
              투표 보상
              <ItemSpan>*숫자만 입력 가능</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("rewards")} />
          </Item>
          <Item>
            <ItemTitle>시작일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("startedAt")}
            />
          </Item>
          <Item>
            <ItemTitle>종료일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("expiredAt")}
            />
          </Item>
          <Item>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input
                type="radio"
                value="true"
                checked={visibleValue === true}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              보이기
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                checked={visibleValue === false}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              숨기기
            </Label>
          </Item>
          <Item style={{ display: "flex", alignItems: "center" }}>
            <ItemTitle>후보</ItemTitle>
            {/* <AppendButton
                type="button"
                onClick={() => {
                  append({});
                }}
              >
                등록
              </AppendButton> */}
          </Item>

          <CandidateForm>
            {" "}
            <div>
              {" "}
              {fields.map((item, index) => {
                const category = getValues(`candidate.${index}.category`);
                const totVotes = getValues(`candidate.${index}.totVotes`);
                const showImage = getValues(`candidate.${index}.bannerUrl`);
                return (
                  <div key={item.id} style={{ marginBottom: "1rem" }}>
                    {category === "RED_STATE"
                      ? "후보 1 "
                      : category === "BLUE_STATE"
                      ? "후보 2 "
                      : category === "ORANGE_STATE"
                      ? "후보 3 "
                      : category === "YELLOW_STATE"
                      ? "후보 4 "
                      : category === "GREEN_STATE"
                      ? "후보 5 "
                      : category === "INDIGO_STATE"
                      ? "후보 6 "
                      : category === "VIOLET_STATE"
                      ? "후보 7 "
                      : category === "BLACK_STATE"
                      ? "후보 8 "
                      : "추가 후보 등록 "}
                    {category ? `총 득표수: ${totVotes}` : ""}

                    <Item style={{ marginTop: "0.5rem" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {showImg[index] ? (
                          <ShowImg src={showImg[index]} alt="" />
                        ) : (
                          <ShowImg src={showImage} alt="" />
                        )}

                        <ImageInput
                          type="file"
                          accept=".jpg, .png"
                          onChange={Image}
                        />
                      </div>
                      제목
                      <TxtInput
                        style={{ margin: "0 1rem" }}
                        {...register(`candidate.${index}.title`, {
                          required: true,
                        })}
                      />
                      내용
                      <TxtInput
                        style={{ margin: "0 1rem" }}
                        {...register(`candidate.${index}.body`, {
                          required: true,
                        })}
                      />
                      {!category && (
                        <DeleteButton onClick={() => remove(index)}>
                          Delete
                        </DeleteButton>
                      )}
                      <EditButton
                        onClick={() => {
                          onCandidateEdit();
                          setIndex(index);
                          setCandidateId(getValues(`candidate.${index}.id`));
                          console.log(candidateId);
                        }}
                      >
                        후보 수정
                      </EditButton>
                    </Item>
                  </div>
                );
              })}
            </div>
          </CandidateForm>
        </Contents>
        <ButtonDiv>
          <EditButton onClick={onEdit}>수정하기</EditButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
