import instance from "API/MainAPI";
import { PageableData } from "API/type";
import { Bounty, CreateBountyDto, CreateBountyFileDto } from "../../types";
import { keys } from "utils";

interface CreateBountyParams {
  data: CreateBountyDto;
  fileData: CreateBountyFileDto;
}

export default function CreateBountyAPI({
  data,
  fileData,
}: CreateBountyParams) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );
  keys(fileData).forEach((key) => {
    const file = fileData[key];
    if (file.length > 0) formData.append(key, file[0]);
  });
  return instance.post<Bounty>("/v3/admins/bounties/managements", formData, {
    headers: {
      "Content-Type": "multipart/form-data; boundary=Paw",
      Accept: "application/json",
    },
  });
}
