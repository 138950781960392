import { useState } from "react";
import styled from "styled-components";
import SupporterRegistration from "../Data/SupporterRegistration";
import { useQuery } from "react-query";

interface OwnProps {
  id: number;
  name: string;
  onClose(): void;
}

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 130px);
  left: calc(50vw - 280px);
  background-color: white;
  border-radius: 10px;
  width: 576px;
  height: 344px;
`;
const BigTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: bold;
`;
const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 70px;
`;

const OkBtn = styled.button`
  width: 180px;
  height: 60px;
  border: none;
  background-color: #ffc200;
  color: black;
  border-radius: 13px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
`;

const CancelBtn = styled.button`
  width: 180px;
  height: 60px;
  border: none;
  background-color: #cecece;
  color: black;

  border-radius: 13px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 30px;
  margin-right: -10px;
`;

function AddModal({ id, name, onClose }: OwnProps) {
  const { data, refetch } = useQuery(
    ["addSupporter", id],
    async () => {
      return await SupporterRegistration(id);
    },
    {
      enabled: false,
      onSuccess: (res) => {
        console.log(res);
        if (res) {
          onClose();
          window.location.reload();
        }
      },
    }
  );
  const onClick = () => {
    refetch();
  };
  return (
    <ModalBg>
      <ModalBox>
        <BigTxt>서포터즈 지정</BigTxt>
        <ContentsTxt>{name}님을 서포터즈로 지정하시겠습니까?</ContentsTxt>

        <DivBtn>
          <OkBtn onClick={onClick}>네, 지정합니다</OkBtn>
          <CancelBtn onClick={onClose}>아니오</CancelBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
}

export default AddModal;
