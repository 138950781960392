import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import GifticonCreationAPI from "./Data/GifticonCreation";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const ClickedTitle = styled.h2`
  font-size: 30px;
  font-weight: 700;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 28px;
  color: gray;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: center;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TokenInput = styled.input`
  width: 30%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
  font-size: 16px;
`;
const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
  font-size: 16px;
`;

const Label = styled.label`
  margin-right: 3rem;
  font-size: 16px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const NumInput = styled.input`
  width: 30vw;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function GifticonAdd() {
  const { register, getValues } = useForm();
  const navigate = useNavigate();

  const formData = new FormData();
  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  async function CallAPI() {
    const templateTokenValue = getValues("templateToken");
    const descriptionValue = getValues("description");
    const categoryValue = getValues("category");
    const expValue = getValues("exp");
    const visibleValue = getValues("visible");

    formData.append("templateToken", templateTokenValue);
    formData.append("description", descriptionValue);
    formData.append("category", categoryValue);
    formData.append("exp", expValue);
    formData.append("visible", visibleValue);

    await gifticonAddMutation.mutateAsync(formData);
  }

  const gifticonAddMutation = useMutation(GifticonCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("기프티콘 등록이 완료되었습니다.");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const templateTokenValue = getValues("templateToken");
    const descriptionValue = getValues("description");
    const categoryValue = getValues("category");
    const expValue = getValues("exp");
    const visibleValue = getValues("visible");



    if (expValue < 0 || expValue > 100000) {
      alert("포인트는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
    } else if (
      templateTokenValue &&
      descriptionValue &&
      categoryValue &&
      visibleValue
    ) {
      setModal(true);
    } else {
      alert("모든 항목을 입력하세요");
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <Inner>
        <Header>
          <Title>기프티콘 추가/</Title>
          <Link to="/gifticons/addDonate" style={{ textDecoration: "none" }}>
            <ClickedTitle>후원 상품 추가</ClickedTitle>
          </Link>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>템플릿 토큰</ItemTitle>
            <TokenInput {...register("templateToken")} />
          </Item>

          <Item>
            <ItemTitle>
              상세 설명
              <ItemSpan>최대 500자</ItemSpan>
            </ItemTitle>
            <div>
              <TextArea rows={8} maxLength={500} {...register("description")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>분류</ItemTitle>

            <Select {...register("category")}>
              <option value="GAME_GIFT_CARD">게임 기프트 카드</option>
              <option value="CAFE_BAKERY">카페|베이커리</option>
              <option value="CONVENIENCE_STORE">편의점</option>
              <option value="CHICKEN_PIZZA_BURGER">치킨|피자|버거</option>
              <option value="EAT_OUT">외식</option>
            </Select>
          </Item>
          <Item>
            <ItemTitle>
              경험치
              <ItemSpan>*숫자만 입력 가능 / 필수 아님</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("exp")} />
          </Item>

          <Item>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("visible")} />
              보이기
            </Label>
            <Label>
              <input type="radio" value="false" {...register("visible")} />
              숨기기
            </Label>
          </Item>
          <ButtonDiv>
            <SubmitButton onClick={onAdd}> 등록하기 </SubmitButton>
          </ButtonDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
