import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 380px;
`;

const HeaderName = styled.h3`
  display: flex;
  font-size: 14px;
  margin: 0.5rem 0 0.5rem 1rem;
  font-weight: bold;
`;

const Line = styled.hr`
  margin: 0;
  opacity: 0.25;
`;

const ContentsTxt = styled.div`
  font-weight: 700;
  margin-left: 1.5rem;

  display: flex;
  font-size: 16px;
  box-sizing: border-box;
`;

const ModalBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 120px;
  height: 40px;
  border: none;
  padding: 5px;
  background-color: lightgrey;
  color: black;
  border-radius: 5px;
  font-size: 13px;
`;

function ModalLevel(props: any) {
  const [isLoading, setIsLoading] = useState(false);

  function onClick() {
    setIsLoading(true);
    props.CallAPI().then(() => {
      props.setIsLoading();
      setIsLoading(false);
    });
  }

  return (
    <ModalBg>
      <ModalBox>
        <HeaderName>경험치 변경</HeaderName>
        <Line />
        <ContentsTxt style={{ marginTop: "1.5rem" }}>
          정말 변경하시겠습니까?
        </ContentsTxt>
        <ContentsTxt style={{ marginTop: "0.5rem" }}>
          변경 후에는 삭제되지 않습니다.
        </ContentsTxt>

        <ModalBtn>
          <Button style={{ marginRight: "1rem" }} onClick={onClick}>
            네
          </Button>
          <Button onClick={() => props.close()}>아니오</Button>
        </ModalBtn>
      </ModalBox>
    </ModalBg>
  );
}

export default ModalLevel;
