import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMagnifyingGlass,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";

import GamePageAPI from "../../../GameTest/TestList/Data/GamePageAPI";
import { useQuery } from "react-query";
import styled from "styled-components";
import { clearTestGame } from "../../../../common/clearStorage";
import { useForm } from "react-hook-form";
import GameSearchAPI from "../Data/GameSearchAPI";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
`;

const AlertTest = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;
  right: 14%;
  top: 18%;
  width: 250px;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 5px;
`;

const SearchBar = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

const Contents = styled.div`
  padding-bottom: 20px;
`;

const Cursor = styled.div`
  cursor: pointer;
  display: flex;
`;

const Txt = styled.div`
  font-weight: 700;
  margin-right: 0.5rem;
`;

const Btn = styled.div`
  padding-left: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  text-transform: none;
`;

const SearchInput = styled.input`
  width: 100%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  color: #000;
  margin: 0;
`;
const CloseBtn = styled.div`
  background: none;
  border: none;
  text-transform: none;
  cursor: pointer;
  display: flex;
  margin-top: -0.2rem;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  overflow: visible;
  box-sizing: content-box;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  height: 1em;
`;

const Content = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
`;

const Img = styled.img`
  margin-left: 1rem;
  height: 50px;
  width: 50px;
  vertical-align: middle;
  overflow-clip-margin: content-box;
  overflow: clip;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 700;
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
  font-size: 14px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

function TestPopup(props: any) {
  const pageNum = sessionStorage.getItem("testGamePage") || "1";

  const gamePageNum = () => {
    const page = sessionStorage.getItem("testerGamePage");
    if (page) return page;
    else return "1";
  };

  const [state, setState] = useState({
    testLists: [],
    page: pageNum,
    total: 0,
    pageSize: 0,
    gameTests: [],
    gamePage: gamePageNum(),
    gameTotal: 0,
    search: "",
    basicShow: true,
  });

  const { register, getValues } = useForm({});

  const { data: gameSearchLists, refetch: refetchGameSearch } = useQuery(
    ["testerGamePage", state.search],
    async () => {
      const response = await GameSearchAPI(state.search);
      return response?.data;
    }
  );

  const { data: testLists, refetch } = useQuery(
    ["testGamePage", pageNum],
    async () => {
      const response = await GamePageAPI(Number(pageNum), 7);
      return response?.data?.page;
    }
  );

  const onSubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const searchValue = getValues("searchTest");

      setState((prevState) => ({
        ...prevState,
        search: searchValue,
        basicShow: searchValue === "" ? true : false,
      }));
    }
  };

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("testGamePage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    if (state.search !== "") {
      refetchGameSearch();
    }
  }, [state.search, refetchGameSearch]);
  return (
    <div>
      <Wrapper>
        <AlertTest>
          <Header>
            <Cursor>
              <Txt>게임 타이틀</Txt>

              <FontAwesomeIcon icon={faCaretDown} />
            </Cursor>
            <CloseBtn onClick={props.onClose}>
              <FontAwesomeIcon icon={faX} />
            </CloseBtn>
          </Header>

          <SearchBar>
            <SearchInput
              type="text"
              onKeyPress={onSubmitSearch}
              {...register("searchTest")}
            />
            <SearchIcon
              icon={faMagnifyingGlass}
              onClick={() => {
                setState({ ...state, basicShow: false });
              }}
            />
          </SearchBar>

          <Contents>
            <div>
              {state.basicShow &&
                testLists?.content.map((test: any) => {
                  return (
                    <Content
                      key={test.id}
                      onClick={() => {
                        props.setSearch(test.id);
                        props.onClose();
                      }}
                    >
                      <Img src={test.bannerUrl} alt="" />
                      <Title>{test.title}</Title>
                    </Content>
                  );
                })}
              {!state.basicShow &&
                gameSearchLists?.contents.map((game: any) => {
                  return (
                    <Content
                      key={game.id}
                      onClick={() => {
                        props.setSearch(game.id);
                        props.onClose();
                      }}
                    >
                      <Img src={game.thumbnailUrl} alt="" />
                      <Title>{game.title}</Title>
                    </Content>
                  );
                })}
            </div>

            <PaginationWrapper>
              {state.basicShow && (
                <Pagination
                  activePage={Number(pageNum)}
                  itemsCountPerPage={7}
                  totalItemsCount={testLists?.pageable.totalElements || 1}
                  pageRangeDisplayed={3}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                />
              )}
            </PaginationWrapper>
          </Contents>
        </AlertTest>
      </Wrapper>
    </div>
  );
}

export default TestPopup;
