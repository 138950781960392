import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import AddModal from "../../../common/Modal/AddModal";
import NoticeCreationAPI from "./Data/noticeCreation";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ErrorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: red;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const ErrorSpan = styled.span`
  margin-left: 0.5rem;
  color: #f05650;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const SubjectInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  font-size: 18px;
  padding: 5px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  font-size: 18px;
  padding: 5px;
`;

const BlockItem = styled.div`
  display: block;
`;
const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function NoticeAdd() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  const [img, setImg] = useState<File | undefined>();
  const formData = new FormData();

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallAPI(data: any) {
    const blob = new Blob(
      [
        JSON.stringify({
          subject: data.subject,
          redirectLink: data.redirectLink,
          visible: data.visible,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);
    if (img) {
      formData.append("photo", img);
    }

    await noticeAddMutation.mutateAsync(formData);
  }

  const noticeAddMutation = useMutation(NoticeCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("팝업 등록이 완료되었습니다.");
      queryClient.invalidateQueries("notices");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  const onAdd = () => {
    if (img) {
      setModal(true);
    } else {
      alert("이미지는 필수입니다.");
    }
  };

  function closeAdd() {
    setModal(false);
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && (
        <AddModal
          CallAPI={handleSubmit(CallAPI)}
          close={closeAdd}
          name={"팝업"}
        />
      )}
      <Inner>
        <Header>
          <Title>팝업 등록</Title>
        </Header>
        <Contents>
          <div>{img && <Img src={URL.createObjectURL(img)} alt="" />}</div>
          <div>
            <ImgInput type="file" accept=".jpg, .png" onChange={Image} />
          </div>
          <Item>
            <ItemTitle>
              주제
              <ItemSpan>*필수 아님</ItemSpan>
            </ItemTitle>
            <div>
              <SubjectInput type="text" {...register("subject")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              연결 링크
              <ItemSpan>*필수 아님</ItemSpan>
            </ItemTitle>
            <LinkInput type="text" {...register("redirectLink")} />
          </Item>
          <BlockItem>
            {!errors.visible ? (
              <ItemTitle>어플 표시</ItemTitle>
            ) : (
              <ErrorTitle>
                어플 표시 <ErrorSpan>* 어플 표시는 필수 사항입니다</ErrorSpan>
              </ErrorTitle>
            )}

            <Label>
              <input
                type="radio"
                value="true"
                {...register("visible", {
                  required: true,
                })}
              />
              보이기
            </Label>

            <Label>
              <input
                type="radio"
                value="false"
                {...register("visible", {
                  required: true,
                })}
              />
              숨기기
            </Label>
          </BlockItem>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={handleSubmit(onAdd)}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
