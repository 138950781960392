import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value"> {
  checked?: boolean;
  value?: boolean;
  indeterminate?: boolean;
  stopPropagation?: boolean;
}
const Container = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  color: #2282e9;
  font-size: 12px;
  & button {
    margin-left: 4px;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  & mark {
    margin-left: 4px;
    color: #d1095d;
    background-color: inherit;
  }
  & input {
    color: inherit;
  }
  & input:has(+ *) {
    margin-right: 10px;
  }
`;

const Input = styled.input`
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  width: 21px;
  height: 21px;
  border: 2px solid #2282e9;
  border-radius: 2px;
  background: white;
  position: relative;
  &:disabled {
    cursor: not-allowed;
    border: 1px solid #e7e7e7;
  }
  &:checked {
    accent-color: #2282e9;
    appearance: checkbox;
  }
  &:indeterminate {
    accent-color: #2282e9;
    appearance: checkbox;
  }
  &:not(:disabled):hover {
    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    transform: scale(0);
    opacity: 0;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, opacity;
    bottom: -13px;
    left: -13px;
    right: -13px;
    top: -13px;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: -1;
    background-color: rgba(120, 128, 134, 0.3);
  }
  &:has(+ span) {
    margin-right: 10px;
  }
`;
const Label = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 12px;
  color: white;
`;

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ stopPropagation, children, value, indeterminate, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => inputRef.current
    );
    const handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
    };
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate || false;
      }
    }, [indeterminate]);
    return (
      <Container onClick={handleClick}>
        <Input {...props} type="checkbox" ref={inputRef}></Input>
        {children && <Label>{children}</Label>}
      </Container>
    );
  }
);
Checkbox.displayName = "Checkbox";

export default Checkbox;
