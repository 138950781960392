import {
  faAngleDown,
  faAngleUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { clearAll, clearTestGame } from "../../../common/clearStorage";

import GameDetailAPI from "./Data/GameDetailAPI";
import GameModification from "./Data/GameModificationAPI";
import Modal from "./Modal/Modal";
import MultipleSelectChip from "./MultipleSelectChip";
import SurveyPopup from "./Popup/SurveyPopup";
import BounceRateSurveyPopup from "../TestAdd/Popup/BounceRateSurveyPopup";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const CountItem = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  margin-right: 5px;
  justify-content: normal;
`;

const BoxTitle = styled.div`
  border: 2px solid #a9a9a9;
  width: 80%;
  display: flex;

  align-items: center;
  padding: 0 1rem;
  margin-top: 2rem;
  justify-content: space-between;
`;

const Box = styled.div`
  border: 2px solid #a9a9a9;
  border-top: 0;
  width: 80%;

  align-items: center;
  padding: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
`;

const SectionTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  display: flex;
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
`;

const Img = styled.img`
  width: 400px;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ImgsInput = styled.input`
  font-size: 16px;
  display: none;
`;

const PrevDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 30%;
  height: 100%;
`;

const ShowImg = styled.img`
  width: 60%;
  height: 20%;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ImgForm = styled.div`
  width: 40%;
  height: 200px;
  border: dotted;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CircleInput = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-size: 40px;
  border: 2px solid;
  padding: 2px;
  cursor: pointer;
  align-items: center;
`;

const FlexItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
  width: 300px;
`;

const ItemSubtitle = styled.h4`
  color: #333740;
  display: flex;
  font-size: 15px;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const FlexTxtInput = styled.input`
  font-size: 16px;
  width: 100%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const FlexNumInput = styled.input`
  font-size: 16px;
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  margin-right: 5px;
  padding: 5px;
`;

const FlexSelect = styled.select`
  font-size: 16px;
  width: 410px;
  height: 1.5rem;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
  color: gray;
`;

const FlexLinkInput = styled.input`
  font-size: 16px;
  width: 70%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  margin-right: 400px;
`;

const TxtInput = styled.input`
  font-size: 16px;
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;

const Select = styled.select`
  font-size: 16px;
  width: 200px;
  height: 100px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 18px;
  text-transform: none;
  color: gray;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 100%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const LinkInput = styled.input`
  font-size: 16px;
  width: 90%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

interface SurveyId {
  id: number;
  content: string;
}

export default function TestDetail() {
  const navigate = useNavigate();

  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { id } = useParams();
  const formData = new FormData();

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [background, setBackground] = useState<File | undefined>();
  const [originImages, setOriginImages] = useState<string[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [showBounceRateSurvey, setShowBounceRateSurvey] = useState(false);
  const [phaseOne, setPhaseOne] = useState<File | undefined>();

  const [checkGenres, setCheckGenres] = useState<string[]>([]);

  const [questionId, setQuestionId] = useState<SurveyId[]>([]);
  const [bounceRateQuestionId, setBounceRateQuestionId] = useState<SurveyId[]>(
    []
  );

  const handleCheckGenresChange = (newGenres: string[]) => {
    setCheckGenres(newGenres);
  };

  const deleteSurvey = () => {
    setShowSurvey(false);
  };

  const deleteBounceRateSurvey = () => {
    setShowBounceRateSurvey(false);
  };

  const handleDelete = (idToDelete: any) => {
    // idToDelete를 가진 항목을 questionId에서 필터링
    const updatedQuestionId = questionId.filter(
      (item: any) => item.id !== idToDelete
    );
    setQuestionId(updatedQuestionId);
  };

  const handleBounceRateDelete = (idToDelete: any) => {
    const updatedQuestionId = bounceRateQuestionId.filter(
      (item) => item.id !== idToDelete
    );
    setBounceRateQuestionId(updatedQuestionId);
  };

  ////IMAGE PART
  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("thumbnail", selectedImage);
      encodeThumbToBase64(selectedImage);
    }
  };

  const Background = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("bakground", selectedImage);
      encodebackToBase64(selectedImage);
    }
  };

  const PhaseOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("phaseOne", selectedImage);
      encodephaseOneToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  function encodebackToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBackground(file);
    };
  }

  function encodephaseOneToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPhaseOne(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };
  const fileRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileRef?.current?.click();
  };

  const [modal, setModal] = useState(false);

  function onEdit() {
    setModal(true);
  }
  function closeEdit() {
    setModal(false);
  }

  const { data: testDetail } = useQuery(["testDetail", id], async () => {
    if (id !== undefined) {
      return await GameDetailAPI(Number(id));
    }
  });

  let questionDetails = getValues("questionDetails");

  const visibleValue = watch("visible");
  const watchCBT = watch("category");
  const watchHorizontal = watch("phaseOneExampleHorizontal");
  const cbtSurveyExists = watch("cbtSurveyExists");

  const handleVisibleChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  const handleHorizontalChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("phaseOneExampleHorizontal", newValue);
  };

  async function CallAPI() {
    const packageIdValue = getValues("packageId");
    const titleValue = getValues("title");
    const descriptionValue = getValues("description");
    const companyValue = getValues("company");
    const categoryValue = getValues("category");
    const genresValue = checkGenres;
    const phaseOneDescriptionValue = getValues("phaseOneDescription");
    const phaseOneExampleHorizontalValue = getValues(
      "phaseOneExampleHorizontal"
    );
    const youtubeLinkValue = getValues("youtubeLink");
    const rewardsValue = getValues("rewards");
    const cheeringPlistarValue = getValues("cheeringPlistar");
    const reviewPlistarValue = getValues("reviewPlistar");
    const expValue = getValues("exp");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    const appStoreLinkValue = getValues("appStoreLink");
    const playStoreLinkValue = getValues("playStoreLink");
    const allowedPlayTimeValue = getValues("allowedPlayTime");
    const visibleValue = getValues("visible");

    const cbtDescriptionValue = getValues("cbtDescription");
    const kakaoOpenLinkValue = getValues("kakaoOpenLink");
    const allowedTotTestersValue = getValues("allowedTotTesters");
    const allowedUserLevelValue = getValues("allowedUserLevel");

    const recruitStartedAtValue = getValues("recruitStartedAt");
    const recruitExpiredAtValue = getValues("recruitExpiredAt");
    const cbtSurveyExists = getValues("cbtSurveyExists");
    if (cbtSurveyExists === "false") {
      setValue("cbtSurveyRewardCategory", "NONE");
      setValue("cbtSurveyRewards", 0);
    }

    const cbtSurveyRewardCategory = getValues("cbtSurveyRewardCategory");
    const cbtSurveyRewards = getValues("cbtSurveyRewards");

    const cbtInfoValue = {
      cbtDescription: cbtDescriptionValue,
      kakaoOpenLink: kakaoOpenLinkValue,
      allowedTotTesters: allowedTotTestersValue,
      allowedUserLevel: allowedUserLevelValue,
      recruitStartedAt: recruitStartedAtValue,
      recruitExpiredAt: recruitExpiredAtValue,
      cbtSurveyExists: cbtSurveyExists,
      cbtSurveyRewardCategory: cbtSurveyRewardCategory,
      cbtSurveyRewards: cbtSurveyRewards,
      questionIds: bounceRateQuestionId.map((item) => item.id),
    };

    const inputs = {
      packageId: watchCBT === "CBT" ? "" : packageIdValue,
      title: titleValue,
      description: descriptionValue,
      company: companyValue,
      category: categoryValue,
      genres: genresValue,
      phaseOneDescription: phaseOneDescriptionValue,
      phaseOneExampleHorizontal: phaseOneExampleHorizontalValue,
      youtubeLink: youtubeLinkValue ? youtubeLinkValue : "",
      rewards: rewardsValue,
      cheeringPlistar: cheeringPlistarValue,
      reviewPlistar: reviewPlistarValue,
      exp: expValue,
      startedAt: startedAtValue,
      expiredAt: expiredAtValue,
      appStoreLink: appStoreLinkValue !== undefined ? appStoreLinkValue : "",
      playStoreLink: playStoreLinkValue !== undefined ? playStoreLinkValue : "",
      allowedPlayTime: allowedPlayTimeValue,
      questionIds: questionId.map((item) => item.id),
      visible: visibleValue,
      cbtInfo: watchCBT !== "CBT" ? undefined : cbtInfoValue,
    };

    const blob = new Blob([JSON.stringify(inputs)], {
      type: "application/json",
    });

    formData.append("data", blob);

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    if (background) {
      formData.append("background", background);
    }

    if (phaseOne) {
      formData.append("phaseOneExample", phaseOne);
    }

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    await testEditMutation.mutateAsync(formData);
  }

  const testEditMutation = useMutation(
    (formData: FormData) => GameModification(Number(id), formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notices");
        closeEdit();
        setIsLoading(false);
        alert("수정이 완료되었습니다.");
        navigate(-1);
      },
      onError: (error) => {
        setIsLoading(false);
        alert("Error: " + error);
        closeEdit();
      },
    }
  );

  function checkBody() {
    const titleValue = getValues("title");
    const descriptionValue = getValues("description");
    const companyValue = getValues("company");
    const categoryValue = getValues("category");
    const genresValue = checkGenres;
    const phaseOneDescriptionValue = getValues("phaseOneDescription");
    const youtubeLinkValue = getValues("youtubeLink");
    const rewardsValue = getValues("rewards");
    const cheeringPlistarValue = getValues("cheeringPlistar");
    const reviewPlistarValue = getValues("reviewPlistar");
    const expValue = getValues("exp");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");

    if (
      !titleValue ||
      !descriptionValue ||
      !companyValue ||
      !categoryValue ||
      !genresValue ||
      !phaseOneDescriptionValue ||
      !startedAtValue ||
      !expiredAtValue
    ) {
      alert("모든 항목을 입력하세요");
    } else if (
      rewardsValue < 0 ||
      rewardsValue > 100000 ||
      cheeringPlistarValue < 0 ||
      cheeringPlistarValue > 100000 ||
      reviewPlistarValue < 0 ||
      reviewPlistarValue > 100000 ||
      expValue < 0 ||
      expValue > 100000
    ) {
      alert("지급 재화는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
    } else if (expiredAtValue < startedAtValue) {
      alert("날짜를 확인하세요.");
    } else {
      onEdit();
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  function setLoading() {
    setIsLoading(false);
  }

  const fetchData = async () => {
    const response = await GameDetailAPI(Number(id));

    if (response) {
      setValue("packageId", response.data.packageId);
      setValue("title", response.data.title);
      setValue("description", response.data.description);
      setValue("company", response.data.company);
      setCheckGenres(response.data.genres);
      setValue("category", response.data.category);
      setValue("phaseOneDescription", response.data.phaseOneDescription);

      setValue("phaseOneExampleUrl", response.data.phaseOneExampleUrl);
      setValue(
        "phaseOneExampleHorizontal",
        response.data.phaseOneExampleHorizontal
      );
      setValue("youtubeLink", response.data.youtubeLink);
      setValue("rewards", response.data.rewards);
      setValue("cheeringPlistar", response.data.cheeringPlistar);
      setValue("reviewPlistar", response.data.reviewPlistar);
      setValue("exp", response.data.exp);
      setValue("startedAt", response.data.startedAt);
      setValue("expiredAt", response.data.expiredAt);

      setValue("appStoreLink", response.data.appStoreLink);
      setValue("playStoreLink", response.data.playStoreLink);

      setValue("totTesters", response.data.totTesters);
      setValue("totCompletions", response.data.totCompletions);
      setValue("allowedPlayTime", response.data.allowedPlayTime);
      setValue("visible", response.data.visible);
      setValue("cbtDescription", response.data.cbtDescription);
      setValue("kakaoOpenLink", response.data.kakaoOpenLink);
      setValue("allowedTotTesters", response.data.allowedTotTesters);
      setValue("allowedUserLevel", response.data.allowedUserLevel);
      setValue("recruitStartedAt", response.data.recruitStartedAt);
      setValue("recruitExpiredAt", response.data.recruitExpiredAt);
      // setValue("questionDetails", response.data.questionDetails);
      setValue("cbtSurveyExists", response.data.cbtSurveyExists);
      setValue(
        "cbtSurveyRewardCategory",
        response.data.cbtSurveyRewardCategory
      );
      setValue("cbtSurveyRewards", response.data.cbtSurveyRewards);
      setOriginImages(response.data.imageUrls);
      setQuestionId(response.data.questionDetails);
      setBounceRateQuestionId(response.data.cbtQuestionDetails);
    }
  };

  useEffect(() => {
    clearTestGame();
    fetchData();
  }, []);

  const [sections, setSections] = useState({
    imageSection: false,
    gameDataSection: false,
    phaseOneSection: false,
    cbtSection: false,
    rewardSection: false,
  });

  const {
    imageSection,
    gameDataSection,
    phaseOneSection,
    cbtSection,
    rewardSection,
  } = sections;

  function onClick(e: any) {
    const clickedId = e.currentTarget.id as keyof typeof sections;
    const now = sections[clickedId];
    setSections({
      ...sections,
      [clickedId]: !now,
    });
  }

  return (
    <Wrapper>
      {modal && (
        <Modal
          close={closeEdit}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      {showSurvey && (
        <SurveyPopup
          close={deleteSurvey}
          setQuestionId={setQuestionId}
          questionId={questionId}
        />
      )}
      {showBounceRateSurvey && (
        <BounceRateSurveyPopup
          close={deleteBounceRateSurvey}
          setQuestionId={setBounceRateQuestionId}
          questionId={bounceRateQuestionId}
        />
      )}

      <Inner>
        <Header>
          <Title>{getValues("title")} 수정</Title>
          <Item
            style={{
              background: " #FFDC01",
              padding: "5px",
              border: " 1px solid",
              marginLeft: "30px",
            }}
          >
            <CountItem
              style={{
                marginBottom: "10px",
              }}
            >
              게임 테스터 수: {getValues("totTesters")}명
            </CountItem>
            <CountItem>
              테스트 완료자 수: {getValues("totCompletions")}명
            </CountItem>
          </Item>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>카테고리</ItemTitle>
            <div>
              <Select {...register("category")} className="input">
                <option value="CBT">CBT 게임</option>
                <option value="RELEASE">출시 게임</option>
              </Select>
            </div>
          </Item>
          <BoxTitle onClick={onClick} id="imageSection">
            <SectionTitle>이미지</SectionTitle>
            {imageSection ? (
              <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
            )}
          </BoxTitle>
          {imageSection && (
            <Box>
              {" "}
              <FlexItem>
                <Item>
                  <ItemTitle>썸네일</ItemTitle>
                  {thumbnail ? (
                    <Img src={URL.createObjectURL(thumbnail)} alt="" />
                  ) : (
                    <Img src={testDetail?.data.bannerUrl} alt="" />
                  )}

                  <div>
                    <ImgInput
                      type="file"
                      accept=".jpg, .png"
                      className="mt-3"
                      onChange={Thumbnail}
                    />
                  </div>
                </Item>
                <Item style={{ marginLeft: "10%" }}>
                  <ItemTitle>배경</ItemTitle>
                  <div>
                    {background ? (
                      <Img src={URL.createObjectURL(background)} alt="" />
                    ) : (
                      <Img src={testDetail?.data.backgroundUrl} alt="" />
                    )}
                  </div>
                  <div>
                    <ImgInput
                      type="file"
                      accept=".jpg, .png"
                      className="mt-3"
                      onChange={Background}
                    />
                  </div>
                </Item>
              </FlexItem>
              <Item>
                <ItemTitle>기존 이미지</ItemTitle>
              </Item>
              <Item>
                <PrevDiv>
                  {originImages &&
                    originImages.map((url, idx) => (
                      <ShowImg
                        style={{ cursor: "default" }}
                        key={idx}
                        src={url}
                        alt=""
                      />
                    ))}
                </PrevDiv>
              </Item>
              <Item>
                <ItemTitle>
                  게임테스트 이미지 파일
                  <ItemSpan>*필수 아님(최대 5개까지 선택 가능)</ItemSpan>
                </ItemTitle>
              </Item>
              <Item>
                <ImgForm>
                  <CircleInput onClick={handleClick}>
                    <div style={{ marginBottom: "0.5rem" }}>+</div>
                  </CircleInput>
                  <ImgsInput
                    ref={fileRef}
                    type="file"
                    multiple
                    accept=".jpg, .png"
                    onChange={Image}
                  />

                  <ItemSpan>이미지 선택 시 삭제</ItemSpan>
                </ImgForm>
              </Item>
              <Item>
                <PrevDiv>
                  {showImages?.map((url, idx) => (
                    <ShowImg
                      key={url}
                      src={url}
                      onClick={() => imageDelete(idx, url)}
                    />
                  ))}
                </PrevDiv>
              </Item>
            </Box>
          )}
          <BoxTitle onClick={onClick} id="gameDataSection">
            <SectionTitle>게임데이터</SectionTitle>
            {gameDataSection ? (
              <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
            )}
          </BoxTitle>
          {gameDataSection && (
            <Box>
              {" "}
              <FlexItem>
                <Item>
                  <ItemTitle>게임 이름</ItemTitle>
                  <div>
                    <FlexTxtInput
                      type="text"
                      max="255"
                      {...register("title")}
                    />
                  </div>
                </Item>

                <Item style={{ marginLeft: "10%" }}>
                  <ItemTitle>게임 회사</ItemTitle>
                  <div>
                    <FlexTxtInput
                      type="text"
                      max="255"
                      {...register("company")}
                    />
                  </div>
                </Item>
              </FlexItem>
              <Item>
                <ItemTitle>
                  게임 설명
                  <ItemSpan>최대 2000자</ItemSpan>
                </ItemTitle>
                <div>
                  <TextArea
                    rows={8}
                    maxLength={2000}
                    className="input"
                    {...register("description")}
                  />
                </div>
              </Item>
              <FlexItem style={{ width: "30%" }}>
                <Item>
                  <ItemTitle>장르</ItemTitle>
                  <div>
                    {
                      <MultipleSelectChip
                        checkOptions={checkGenres}
                        onCheckOptionsChange={handleCheckGenresChange}
                      />
                    }
                  </div>
                </Item>
              </FlexItem>
              <FlexItem>
                <Item>
                  <ItemTitle>유튜브링크</ItemTitle>
                  <FlexLinkInput type="text" {...register("youtubeLink")} />
                </Item>
                <Item>
                  <FlexItemTitle>
                    테스트 인증 최소 시간
                    <ItemSpan>*초 단위로 입력해주세요</ItemSpan>
                  </FlexItemTitle>
                  <FlexNumInput
                    type="number"
                    min={0}
                    {...register("allowedPlayTime")}
                  />
                  초
                </Item>
              </FlexItem>
              <Item>
                <ItemSubtitle>ios</ItemSubtitle>
                <LinkInput type="text" {...register("appStoreLink")} />
                <ItemSubtitle>android</ItemSubtitle>
                <LinkInput type="text" {...register("playStoreLink")} />
              </Item>
              <FlexItem style={{ width: "100%" }}>
                <Item>
                  <ItemTitle>시작일</ItemTitle>
                  <DateInput
                    type="datetime-local"
                    required
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                    {...register("startedAt")}
                  />
                </Item>
                <Item style={{ marginLeft: "10%" }}>
                  <ItemTitle>종료일</ItemTitle>
                  <DateInput
                    type="datetime-local"
                    className={
                      getValues("expiredAt") < getValues("startedAt")
                        ? "box-under-red w-25"
                        : "box-under w-25"
                    }
                    min={getValues("startedAt")}
                    required
                    pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                    {...register("expiredAt")}
                  />
                </Item>
              </FlexItem>
              {watchCBT !== "CBT" && (
                <Item>
                  <ItemTitle>게임 패키지 ID</ItemTitle>
                  <div>
                    <TxtInput
                      type="text"
                      max="255"
                      {...register("packageId")}
                    />
                  </div>
                </Item>
              )}
              <Item>
                <ItemTitle>어플 표시</ItemTitle>
                <Label>
                  <input
                    type="radio"
                    value="true"
                    checked={visibleValue === true}
                    {...register("visible")}
                    onChange={handleVisibleChange}
                  />
                  보이기
                </Label>
                <Label>
                  <input
                    type="radio"
                    value="false"
                    checked={visibleValue === false}
                    {...register("visible")}
                    onChange={handleVisibleChange}
                  />
                  숨기기
                </Label>
              </Item>
            </Box>
          )}
          {watchCBT === "CBT" && (
            <>
              {" "}
              <BoxTitle onClick={onClick} id="cbtSection">
                <SectionTitle>CBT</SectionTitle>
                {cbtSection ? (
                  <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
                )}
              </BoxTitle>
              {cbtSection && (
                <Box>
                  {" "}
                  <ItemTitle>CBT Info</ItemTitle>
                  <ItemTitle>
                    CBT 참여 방법 설명
                    <ItemSpan>최대 2000자</ItemSpan>
                  </ItemTitle>
                  <div>
                    <TextArea
                      rows={8}
                      maxLength={2000}
                      className="input"
                      {...register("cbtDescription")}
                    />
                  </div>
                  <Item>
                    <ItemTitle>카카오톡 오픈 채팅방 링크</ItemTitle>
                    <LinkInput type="text" {...register("kakaoOpenLink")} />
                  </Item>
                  <FlexItem>
                    {" "}
                    <Item>
                      <ItemTitle>CBT 참여 제한 인원수</ItemTitle>
                      <FlexNumInput
                        type="number"
                        {...register("allowedTotTesters")}
                      />
                      명
                    </Item>{" "}
                    <Item>
                      <ItemTitle>CBT 참여 제한 레벨</ItemTitle>
                      <FlexTxtInput
                        type="text"
                        {...register("allowedUserLevel")}
                      />
                    </Item>
                  </FlexItem>
                  <FlexItem style={{ width: "100%" }}>
                    <Item>
                      <ItemTitle>CBT 모집 시작 일시</ItemTitle>
                      <DateInput
                        type="datetime-local"
                        required
                        pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                        {...register("recruitStartedAt")}
                      />
                    </Item>
                    <Item style={{ marginLeft: "10%" }}>
                      <ItemTitle>CBT 모집 종료 일시</ItemTitle>
                      <DateInput
                        type="datetime-local"
                        className={
                          getValues("recruitExpiredAt") <
                          getValues("recruitStartedAt")
                            ? "box-under-red w-25"
                            : "box-under w-25"
                        }
                        min={getValues("recruitStartedAt")}
                        required
                        pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                        {...register("recruitExpiredAt")}
                      />
                    </Item>
                  </FlexItem>
                </Box>
              )}
            </>
          )}
          <BoxTitle onClick={onClick} id="phaseOneSection">
            <SectionTitle>1단계 데이터</SectionTitle>
            {phaseOneSection ? (
              <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
            )}
          </BoxTitle>
          {phaseOneSection && (
            <Box>
              {" "}
              <Item>
                <ItemTitle>
                  1단계 설명
                  <ItemSpan>최대 2000자</ItemSpan>
                </ItemTitle>
                <div>
                  <TextArea
                    rows={8}
                    maxLength={2000}
                    className="input"
                    {...register("phaseOneDescription")}
                  />
                </div>
              </Item>
              <FlexItem>
                <Item>
                  <ItemTitle>1단계 예시 이미지 파일</ItemTitle>
                  {phaseOne ? (
                    <Img src={URL.createObjectURL(phaseOne)} alt="" />
                  ) : (
                    <Img src={testDetail?.data.phaseOneExampleUrl} alt="" />
                  )}

                  <div>
                    <ImgInput
                      type="file"
                      accept=".jpg, .png"
                      className="mt-3"
                      onChange={PhaseOne}
                    />
                  </div>
                </Item>

                <Item style={{ marginLeft: "2rem" }}>
                  <ItemTitle>예시 이미지 수평 방향</ItemTitle>
                  <Label>
                    <input
                      type="radio"
                      value="true"
                      className="me-1"
                      checked={watchHorizontal === true}
                      {...register("phaseOneExampleHorizontal")}
                      onChange={handleHorizontalChange}
                    />
                    수평
                  </Label>
                  <Label>
                    <input
                      type="radio"
                      value="false"
                      className="me-1"
                      checked={watchHorizontal === false}
                      {...register("phaseOneExampleHorizontal")}
                      onChange={handleHorizontalChange}
                    />
                    수직
                  </Label>
                </Item>
              </FlexItem>
            </Box>
          )}
          <BoxTitle onClick={onClick} id="rewardSection">
            <SectionTitle>지급 재화</SectionTitle>
            {rewardSection ? (
              <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
            )}
          </BoxTitle>
          {rewardSection && (
            <Box>
              {" "}
              <FlexItem>
                <Item>
                  <FlexItemTitle>
                    지급포인트
                    <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                  </FlexItemTitle>
                  <FlexNumInput
                    type="number"
                    min={0}
                    {...register("rewards")}
                  />
                </Item>

                <Item style={{ marginLeft: "10%" }}>
                  <FlexItemTitle>
                    경험치
                    <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                  </FlexItemTitle>
                  <FlexNumInput type="number" min={0} {...register("exp")} />
                </Item>
              </FlexItem>
              <FlexItem>
                <Item>
                  <FlexItemTitle>
                    응원하기 지급 플리스타
                    <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                  </FlexItemTitle>
                  <FlexNumInput
                    type="number"
                    min={0}
                    {...register("cheeringPlistar")}
                  />
                </Item>
                <Item style={{ marginLeft: "10%" }}>
                  <FlexItemTitle>
                    리뷰글 지급 플리스타
                    <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                  </FlexItemTitle>

                  <FlexNumInput
                    type="number"
                    min={0}
                    {...register("reviewPlistar")}
                  />
                </Item>
              </FlexItem>
              {watchCBT === "CBT" && (
                <>
                  <FlexItem>
                    <Item>
                      <FlexItemTitle>이탈 설문 여부</FlexItemTitle>
                      <FlexSelect
                        {...register("cbtSurveyExists")}
                        className="input"
                        placeholder="이탈 설문 여부를 선택하세요."
                      >
                        <option value="false">N</option>
                        <option value="true">Y</option>
                      </FlexSelect>
                    </Item>
                  </FlexItem>
                  <FlexItem>
                    <Item>
                      <FlexItemTitle>이탈 설문 지급 보상</FlexItemTitle>
                      <FlexSelect
                        {...register("cbtSurveyRewardCategory")}
                        className="input"
                        placeholder="지급 보상 형태를 선택하세요."
                      >
                        <option value="POINT">포인트</option>
                        <option value="PLISTAR">플리스타</option>
                      </FlexSelect>
                    </Item>
                    <Item>
                      <FlexNumInput
                        {...register("cbtSurveyRewards")}
                        style={{ marginTop: "52px", width: "300px" }}
                        type="number"
                        min={0}
                        placeholder="*숫자만 입력 가능합니다."
                      />
                    </Item>
                  </FlexItem>
                </>
              )}
            </Box>
          )}

          <Item>
            {" "}
            <FlexItemTitle style={{ width: "200px" }}>
              📌 질문 목록
              <div style={{ width: "30px" }} />
              <FontAwesomeIcon
                icon={faCaretDown}
                onClick={() => setShowSurvey(true)}
              />
            </FlexItemTitle>
            {questionId?.map((id: any) => {
              return (
                <Chip
                  key={id.id}
                  label={id.content}
                  onClick={() => handleDelete(id.id)}
                />
              );
            })}
          </Item>
          {watchCBT === "CBT" && (
            <>
              <Item
                style={{
                  display: "flex ",
                  alignItems: "baseline",
                  marginBottom: "1rem",
                }}
              >
                <FlexItemTitle style={{ width: "100px" }}>
                  📌 이탈율 질문
                </FlexItemTitle>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  onClick={() => setShowBounceRateSurvey(true)}
                />
              </Item>
              {bounceRateQuestionId.map((id) => {
                return (
                  <Chip
                    key={id.id}
                    label={id.content}
                    onClick={() => handleBounceRateDelete(id.id)}
                  />
                );
              })}
            </>
          )}
        </Contents>
        <ButtonDiv>
          <EditButton onClick={checkBody}> 수정하기 </EditButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
