export default function Tier(tier: any) {
  if (tier === "TIER_ROOKIE") return "ROOKIE";
  else if (tier === "TIER_BRONZE") return "BRONZE";
  else if (tier === "TIER_SILVER") return "SILVER";
  else if (tier === "TIER_GOLD") return "GOLD";
  else if (tier === "TIER_PLATINUM") return "PLATINUM";
  else if (tier === "TIER_DIAMOND") return "DIAMOND";
  else if (tier === "TIER_MASTER") return "MASTER";
  else if (tier === "TIER_CHALLENGER") return "CHALLENGER";
  else return "-";
}
