import instance from "../../../../../API/MainAPI";

export default function AnnouncementModificationAPI(id: any, formData: any) {
  return instance.post(`/v3/admins/announcements/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data; boundary=Paw",
      Accept: "application/json",
    },
  });
}
