import instance from "../../../../../API/MainAPI";

export default function SurveyListAPI(
  surveyCategory: string,
  page: number,
  pageSize: number,
  category?: string
) {
  const params: {
    "survey-category": string;
    pageSize: number;
    pageNumber: number;
    category?: string;
  } = {
    "survey-category": surveyCategory,
    pageSize: pageSize,
    pageNumber: page - 1,
  };
  if (category !== undefined) {
    params["category"] = category;
  }
  return instance.get("/v3/admins/surveys/managements/questions/pages", {
    params: params,
  });
}
