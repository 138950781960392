import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const ModalBg = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 6;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 250px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 580px;
  min-height: 400px;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const ReasonTxt = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ReasonInput = styled.input`
  width: 70%;
  height: 1rem;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-left: 1rem;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 50px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
`;

const CancelBtn = styled.button`
  width: 50px;
  padding: 5px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

const ReasonList = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReasonItem = styled.div`
  margin-bottom: 3px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function RejectModal(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const arr = props.selectArr;
  const [rejectionReason, setRejectionReason] = useState("");
  const { register, getValues, setValue } = useForm();
  // let rejectreasonstr = "";

  useEffect(() => {
    const savedRejectionReason = localStorage.getItem("rejectionReason");
    if (savedRejectionReason) setRejectionReason(savedRejectionReason);
  }, []);

  // useEffect(() => {
  //   rejectreasonstr = rejectreasonstr.concat(rejectionReason);
  // }, [rejectionReason]);
  function onClick() {
    setIsLoading(true);
    props.callAPI(arr, arr.length, getValues("reason")).then(() => {
      alert("거절을 완료했습니다.");
      // props.setIsLoading();
      setIsLoading(false);

      let newRejectionReason = "";
      if (rejectionReason) {
        newRejectionReason = `${getValues("reason")}, ${rejectionReason}`;
      } else {
        newRejectionReason = `${getValues("reason")}`;
      }
      setRejectionReason(newRejectionReason);
      //배열 내 중복 사유들 Set객체를 활용해 제거
      const newarr = newRejectionReason.split(",").map((item) => item.trim());
      const newset = new Set(newarr);
      localStorage.setItem("rejectionReason", Array.from(newset).toString());
    });
  }

  const reasons = rejectionReason.split(",").map((item) => item.trim());
  //거절 사유 화면에 최대 10개 표출
  const limitedReasons = reasons.slice(0, 10);

  const handleReasonOnForm = (item: string) => {
    setValue("reason", item);
  };
  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>인증을 거절하시겠습니까?</ContentsTxt>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.8rem",
            marginLeft: "1rem",
          }}
        >
          {" "}
          <ReasonTxt>거절 사유</ReasonTxt>
          <ReasonInput {...register("reason")} />
        </div>
        <hr />
        <ReasonList>
          {limitedReasons.map((item) => (
            <ReasonItem onClick={() => handleReasonOnForm(item)}>
              {item}
            </ReasonItem>
          ))}
        </ReasonList>

        <DivBtn>
          <CancelBtn className="cancelBtn" onClick={() => props.onClose()}>
            취소
          </CancelBtn>
          <OkBtn disabled={isLoading} onClick={onClick}>
            확인
          </OkBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
}

export default RejectModal;
