import instance from "API/MainAPI";

export default function SupporterExcelAPI(grade: string) {
  return instance.get("/v3/admins/surveys/managements/download", {
    responseType: "arraybuffer",
    params: {
      grade: grade,
    },
  });
}
