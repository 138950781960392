import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../../../common/function/formatDate";
import styled from "styled-components";

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  isWide?: boolean;
}

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  overflow: scroll;
`;

const AlertImage = styled.div<ImgProps>`
  padding: 30px;
  top: 2%;
  width: ${(props) => (props.isWide ? "1000px" : "800px")};
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;
`;

const Txt = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GrayH4 = styled.h4`
  color: #333742;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;

  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

const ImageFrame = styled.div`
  text-align: center;
  margin-top: 0.5rem;
`;

const Cursor = styled.div`
  cursor: pointer;
`;

const Img = styled.img<ImgProps>`
  width: ${(props) => (props.isWide ? "100%" : "15vw")};
  vertical-align: middle;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

function ImagePopup(props: any) {
  //   const state = {
  //     rejectModal: false,
  //     approveModal: false,
  //     isLoading: props.isLoading,
  //   };

  //   function reject() {
  //     setState({ rejectModal: true });
  //   }
  //   function approve() {
  //     setState({ approveModal: true });
  //   }

  //   function onCloseRe() {
  //     setState({ rejectModal: false });
  //   }
  //   function onCloseAp() {
  //     setState({ approveModal: false });
  //   }

  //   function callRejectAPI() {
  //     props.onRejection(state.data.testerId, state.data.id);
  //   }

  //   function callApproveAPI() {
  //     props.onApproval(state.data.testerId, state.data.id);
  //   }

  // let {rejectModal, approveModal} = state
  const [isWide, setIsWide] = useState(false);

  function checkSize(width: number, height: number) {
    const isImageWide = width > height;
    setIsWide(isImageWide);
  }

  useEffect(() => {
    const image = new Image();
    image.src = props.phaseCheeringSnapshotUrl;
    image.onload = () => {
      checkSize(image.width, image.height);
    };
  }, [props.phaseCheeringSnapshotUrl]);

  return (
    <div className="dimmed_layer_wrapper">
      {/*{rejectModal && <RejectModal onClose={() => onCloseRe()}*/}
      {/*                             callAPI={() => callRejectAPI()}*/}
      {/*                             isLoading={state.isLoading}/>}*/}
      {/*{approveModal && <ApproveModal onClose={() => onCloseAp()}*/}
      {/*                               callAPI={() => callApproveAPI()}*/}
      {/*                               isLoading={state.isLoading}/>}*/}
      <Wrapper>
        <AlertImage>
          <Txt>
            <div>
              <GrayH4>
                인증 요청 일시 :{" "}
                {props.phaseCheeringCompletedAt &&
                  new Date(props.phaseCheeringCompletedAt).toLocaleString()}
              </GrayH4>
            </div>
            <Cursor>
              <FontAwesomeIcon icon={faX} onClick={props.onClose} />
            </Cursor>
          </Txt>
          <ImageFrame>
            <Img src={props.phaseCheeringSnapshotUrl} alt="" isWide={isWide} />
          </ImageFrame>
          <div className="mb-3"></div>
          {/*<div className="d-flex justify-content-center mt-4">*/}
          {/*	<button type="button" onClick={() => reject()}*/}
          {/*					className="cancelBtn-m">거절</button>*/}
          {/*	<button type="button" onClick={() => approve()}*/}
          {/*	        className="okBtn-m ms-3">승인</button>*/}
          {/*</div>*/}
        </AlertImage>
      </Wrapper>
    </div>
  );
}

export default ImagePopup;
