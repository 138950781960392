export function Category(category: string) {
  if (category === "NOTICES") {
    return "공지사항";
  } else if (category === "ADVERTISEMENTS") {
    return "광고";
  } else if (category === "TESTS") {
    return "게임 테스트";
  } else if (category === "ACCUMULATIONS") {
    return "포인트 적립";
  } else if (category === "EXCHANGES") {
    return "기프티콘 교환";
  } else if (category === "INVOICE") {
    return "기프티콘 지급 완료";
  } else if (category === "COUPONS") {
    return "쿠폰 포인트 적립";
  } else if (category === "VOTES") {
    return "투표";
  } else if (category === "PLISTAR_REWARDS") {
    return "추가 플리스타 적립";
  } else if (category === "PLISTAR_PHASE") {
    return "플리스타 보상";
  } else if (category === "TOPIC_NEW") {
    return "뉴스";
  } else if (category === "GAME_TEST_SUBMITTED") {
    return "1,2단계 테스트 제출";
  } else if (category === "GAME_CHEERING_SUBMITTED") {
    return "응원하기 제출";
  } else if (category === "GAME_REVIEW_SUBMITTED") {
    return "리뷰글 남기기 제출";
  } else if (category === "GAME_TEST_APPROVAL") {
    return "1,2단계 테스트 승인";
  } else if (category === "GAME_CHEERING_APPROVAL") {
    return "응원하기 승인";
  } else if (category === "GAME_REVIEW_APPROVAL") {
    return "리뷰글 남기기 승인";
  } else if (category === "GAME_TEST_REJECTION") {
    return "1,2단계 테스트 거절/반려";
  } else if (category === "GAME_CHEERING_REJECTION") {
    return "응원하기 거절/반려";
  } else if (category === "GAME_REVIEW_REJECTION") {
    return "리뷰글 남기기 거절/반려";
  } else if (category === "DAILY_QUEST") {
    return "데일리 퀘스트 완료";
  } else if (category === "PLI_QUEST") {
    return "플리 퀘스트 완료";
  } else {
    return "알림";
  }
}
