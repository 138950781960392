import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import AddModal from "../../../common/Modal/AddModal";
import { ErrorMessage } from "@hookform/error-message";
import AnnoucementCreationAPI from "./Data/AnnoucementCreation";
import AddAnnounceModal from "./modal/AddAnnounceModal";
import AnnouncementNotiModal from "./modal/AnnouncementNotiModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ImgForm = styled.div`
  width: 40%;
  height: 200px;
  border: dotted;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CircleInput = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-size: 40px;
  border: 2px solid;
  padding: 2px;
  cursor: pointer;
  align-items: center;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
  display: none;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const ErrorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: red;
  display: flex;
`;
const ErrorSpan = styled.span`
  margin-left: 0.5rem;
  color: #f05650;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
  font-size: 18px;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;

const PrevDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 30%;
  height: 100%;
`;

const ShowImg = styled.img`
  width: 40%;
  height: 10%;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
  font-size: 18px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function AnnouncementAdd() {
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [pushModal, setNotiModal] = useState(false);
  const [push, setPush] = useState(false);
  const [modal, setModal] = useState(false);

  function closeNotiAdd() {
    setNotiModal(false);
  }

  function onPushAdd() {
    setPush(true);
  }

  function closePushAdd() {
    setPush(false);
  }

  function onModalAdd() {
    setModal(true);
  }

  function closeModalAdd() {
    setModal(false);
  }

  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);

  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };

  const formData = new FormData();

  const showLink = watch("category");

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  async function CallAPI(data: any) {
    const blob = new Blob(
      [
        JSON.stringify({
          title: data.title,
          content: data.content,
          redirectLink: data.redirectLink,
          category: data.category,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    await announcementAddMutation.mutateAsync(formData);
  }

  const announcementAddMutation = useMutation(AnnoucementCreationAPI, {
    onSuccess: () => {
      setNotiModal(false);

      alert("공지글 등록이 완료되었습니다.");
      queryClient.invalidateQueries("announcement");
      navigate(-1);
    },
    onError: () => {
      setNotiModal(false);
    },
  });

  const onAdd = (data: any) => {
    const allFieldsFilled = data.content && data.category && data.title;

    if (showLink === "EXTERNALS") {
      // 외부 링크이며 링크가 없는 경우
      if (!data.redirectLink) {
        alert("외부링크를 입력해주세요.");
      } else if (allFieldsFilled) {
        setNotiModal(true);
      }
    } else if (allFieldsFilled) {
      setValue("redirectLink", "");
      setNotiModal(true);
    } else {
      alert("이미지, 카테고리, 연결링크, 어플 표시 여부는 필수입니다.");
    }
  };

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {push && (
        <AddAnnounceModal
          close={closePushAdd}
          CallAPI={handleSubmit(CallAPI)}
          name={"푸시"}
          title={getValues("title")}
          message={getValues("content")}
        />
      )}
      {pushModal && (
        <AnnouncementNotiModal
          close={closeNotiAdd}
          // cancel={}
          pushModal={onPushAdd}
          CallAPI={handleSubmit(CallAPI)}
          title={getValues("title")}
          message={getValues("content")}
        />
      )}
      {modal && (
        <AddModal
          close={closeModalAdd}
          // cancel={}
          pushModal={onPushAdd}
          CallAPI={handleSubmit(CallAPI)}
          title={getValues("title")}
          message={getValues("content")}
        />
      )}
      <Inner>
        <Contents>
          <Header>
            <Title>공지글 추가</Title>
          </Header>
          <Item>
            <ItemTitle>공지 이미지</ItemTitle>
            <ImgForm>
              <div>
                <CircleInput onClick={handleClick}>
                  <div style={{ marginBottom: "0.5rem" }}>+</div>
                </CircleInput>
                <ImgInput
                  ref={fileRef}
                  type="file"
                  multiple
                  accept=".jpg, .png"
                  onChange={Image}
                />
              </div>
              <ItemSpan>이미지 선택 시 삭제</ItemSpan>
            </ImgForm>
          </Item>
          <Item>
            <PrevDiv>
              {showImages?.map((url, idx) => (
                <ShowImg
                  key={url}
                  src={url}
                  onClick={() => imageDelete(idx, url)}
                />
              ))}
            </PrevDiv>
          </Item>
          <Item>
            {!errors.title ? (
              <ItemTitle>제목</ItemTitle>
            ) : (
              <ErrorTitle>
                제목 <ErrorSpan>* 제목은 필수 사항입니다</ErrorSpan>
              </ErrorTitle>
            )}
            <div>
              <TitleInput
                type="text"
                {...register("title", {
                  required: true,
                })}
              />
            </div>
          </Item>
          <Item>
            {!errors.content ? (
              <ItemTitle>
                공지글 내용
                <ItemSpan>*최대 2000자</ItemSpan>
              </ItemTitle>
            ) : errors.content.type === "required" ? (
              <ErrorTitle>
                공지글 내용 <ErrorSpan>* 내용은 필수 사항입니다</ErrorSpan>
              </ErrorTitle>
            ) : (
              <ErrorTitle>
                공지글 내용{" "}
                <ErrorSpan>* 2000자를 초과해서 입력하실 수 없습니다</ErrorSpan>
              </ErrorTitle>
            )}
            <div>
              <TextArea
                rows={8}
                {...register("content", {
                  required: true,
                  maxLength: 2000,
                })}
              />
            </div>

            <Item>
              {!errors.category ? (
                <ItemTitle>카테고리</ItemTitle>
              ) : (
                <ErrorTitle>
                  카테고리 <ErrorSpan>* 카테고리는 필수 사항입니다</ErrorSpan>
                </ErrorTitle>
              )}

              <Select
                {...register("category", {
                  required: true,
                })}
                name="category"
                id="category"
              >
                <option value="">=== 선택 ===</option>
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </Select>
            </Item>
          </Item>

          {showLink === "EXTERNALS" && (
            <Item>
              <ItemTitle>
                연결 링크
                <ItemSpan>*필수 아님</ItemSpan>
              </ItemTitle>
              <LinkInput type="text" {...register("redirectLink")} />
            </Item>
          )}
        </Contents>
        <ButtonDiv>
          <AddButton onClick={handleSubmit(onAdd)}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
