import styled from "styled-components";
import ImageField from "./ImageField";
import Select from "./Select";
import RadioGroup from "./RadioGroup";
import { forwardRef } from "react";
import MultiSelect from "./MultiSelect";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  suffix?: string;
  containerStyle?: React.CSSProperties;
}

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  containerStyle?: React.CSSProperties;
  controlStyle?: React.CSSProperties;
}
interface DayBoxProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  containerStyle?: React.CSSProperties;
  controlStyle?: React.CSSProperties;
}

interface FormGroupProps {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
}
const FormGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 53px;
  height: fit-content;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-width: 320px;
  /* width: calc(calc(100% - 53px) / 2); */
`;
const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  min-width: 320px;
  margin-top: 3rem;
`;
const TextLabel = styled.label`
  font-weight: 500;
  font-size: 22px;
`;
const TextControl = styled.div<{ disabled?: boolean }>`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  gap: 6px;
  border: 2px solid ${(props) => (props.disabled ? `#8E8E8E` : `#2282e9`)};
  border-radius: 6px;

  &:not(:has(textarea)) {
    padding-right: 17px;
  }
  input {
    outline: 0;
    border: 0;
    font-size: 18px;
    padding: 18px 0 17px 20px;
    flex: 1;
    background-color: transparent;
    box-sizing: border-box;
  }
  textarea {
    outline: 0;
    border: 0;
    font-size: 18px;
    padding: 17px 8px 16px 20px;
    width: 100%;
    background-color: transparent;
    box-sizing: border-box;
    resize: none;
    min-height: 154px;
  }
`;
const TextFieldSuffix = styled.div`
  font-size: 18px;
  color: #262626;
  margin-top: 5px;
`;
const DayButtton = styled.button`
  width: 51px;
  height: 51px;
  border-radius: 10px;
  border: none;
  background-color: #e3e3e3;
  color: #bdbdbd;
  font-weight: 400;
  font-size: 24px;
`;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, id, suffix, containerStyle, disabled, ...props }, ref) => {
    return (
      <TextContainer style={containerStyle}>
        <TextLabel htmlFor={id}>{label}</TextLabel>
        <TextControl disabled={disabled}>
          <input type="text" {...props} id={id} ref={ref} disabled={disabled} />
          {suffix && <TextFieldSuffix>{suffix}</TextFieldSuffix>}
        </TextControl>
      </TextContainer>
    );
  }
);

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, id, disabled, containerStyle, controlStyle, ...props }, ref) => {
    return (
      <TextContainer style={containerStyle}>
        <TextLabel htmlFor={id}>{label}</TextLabel>
        <TextControl disabled={disabled}>
          <textarea
            {...props}
            id={id}
            ref={ref}
            disabled={disabled}
            style={controlStyle}
          />
        </TextControl>
      </TextContainer>
    );
  }
);

const DayBox: React.FC<DayBoxProps> = ({ containerStyle, ...props }) => {
  return (
    <BoxContainer style={containerStyle}>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
      <DayButtton>1</DayButtton>
      <DayButtton>2</DayButtton>
    </BoxContainer>
  );
};

const FormGroup: React.FC<FormGroupProps> = ({ containerStyle, children }) => {
  return (
    <FormGroupContainer style={containerStyle}>{children}</FormGroupContainer>
  );
};

const Form = Object.assign(
  {},
  {
    TextField,
    FormGroup,
    ImageField,
    TextArea,
    Select,
    RadioGroup,
    DayBox,
    MultiSelect,
  }
);

export default Form;
