import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import EventCreationAPI from "./Data/EventCreationAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ShowThumb = styled.img`
  width: 400px;
  margin-bottom: 10px;
`;

const ThumbnailInput = styled.input`
  margin-top: 0.5rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TxtInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;
const NumInput = styled.input`
  width: 30vw;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;
const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function EventAdd() {
  const { register, setValue, getValues, watch } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);
  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [image, setImage] = useState<File | undefined>();

  function closeAdd() {
    setModal(false);
  }

  const formData = new FormData();

  const showLink = watch("category");

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeThumbToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeImgToBase64(selectedImage);
    }
  };

  function encodeImgToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(file);
    };
  }

  async function CallAPI() {
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const contentValue = getValues("content");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");

    const blob = new Blob(
      [
        JSON.stringify({
          title: titleValue,
          subtitle: subtitleValue,
          content: contentValue,
          rewards: rewardsValue,
          startedAt: startedAtValue,
          expiredAt: expiredAtValue,
          redirectLink: redirectLinkValue,
          category: categoryValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    if (image) {
      formData.append("image", image);
    }

    await eventAddMutation.mutateAsync(formData);
  }

  const eventAddMutation = useMutation(EventCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("이벤트 등록이 완료되었습니다.");
      queryClient.invalidateQueries("notices");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const contentValue = getValues("content");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");

    const allFieldsFilled =
      thumbnail &&
      titleValue &&
      subtitleValue &&
      contentValue &&
      rewardsValue &&
      startedAtValue &&
      expiredAtValue &&
      categoryValue;

    if (expiredAtValue < startedAtValue) {
      alert("날짜를 확인하세요.");
    } else if (showLink === "EXTERNALS") {
      // 외부 링크이며 링크가 없는 경우
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else if (allFieldsFilled) {
        setModal(true);
      }
    } else if (allFieldsFilled) {
      setValue("redirectLink", "");
      setModal(true);
    } else {
      alert("이미지, 카테고리, 연결링크, 어플 표시 여부는 필수입니다.");
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <Inner>
        <Header>
          <Title>이벤트 추가</Title>
        </Header>
        <Contents>
          <FlexItem>
            <Item>
              <ItemTitle>이벤트 썸네일 파일</ItemTitle>
              {thumbnail && (
                <ShowThumb src={URL.createObjectURL(thumbnail)} alt="" />
              )}
              <Item>
                <ThumbnailInput
                  type="file"
                  accept=".jpg, .png"
                  onChange={Thumbnail}
                />
              </Item>
            </Item>
            <Item style={{ marginLeft: "10%" }}>
              <Item>
                <ItemTitle>이벤트 이미지 파일</ItemTitle>
                {image && (
                  <div>
                    <ShowThumb src={URL.createObjectURL(image)} alt="" />
                  </div>
                )}
              </Item>
              <Item>
                <ThumbnailInput
                  type="file"
                  accept=".jpg, .png"
                  onChange={Image}
                />
              </Item>
            </Item>
          </FlexItem>
          <Item>
            <ItemTitle>이벤트 제목</ItemTitle>
            <div>
              <TxtInput type="text" {...register("title")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>이벤트 부제목</ItemTitle>
            <div>
              <TxtInput type="text" {...register("subtitle")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>이벤트 내용</ItemTitle>
            <div>
              <TextArea rows={8} maxLength={2000} {...register("content")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              이벤트 보상
              <ItemSpan>*숫자만 입력 가능</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("rewards")} />
          </Item>
          <Item>
            <ItemTitle>시작일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("startedAt")}
            />
          </Item>
          <Item>
            <ItemTitle>종료일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("expiredAt")}
            />
          </Item>
          <Item>
            <ItemTitle>연결 링크 카테고리</ItemTitle>
            <Select {...register("category")}>
              <option value="ANNOUNCEMENT">공지글</option>
              <option value="GAME_NEWS">게임뉴스</option>
              <option value="TESTS">테스트 게임</option>
              <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
              <option value="RELEASE">출시게임</option>
              <option value="QUESTS">퀘스트</option>
              <option value="CONTENTS">콘텐츠</option>
              <option value="MY_PAGES">마이페이지</option>
              <option value="SETTINGS">설정</option>
              <option value="CALENDAR">캘린더</option>
              <option value="EXTERNALS">외부링크</option>
              <option value="NONE">리다이렉션 없음</option>
            </Select>
          </Item>
          {showLink === "EXTERNALS" && (
            <Item>
              <ItemTitle>
                연결 링크
                <ItemSpan>*필수 아님</ItemSpan>
              </ItemTitle>
              <LinkInput type="text" {...register("redirectLink")} />
            </Item>
          )}
        </Contents>
        <ButtonDiv>
          <AddButton onClick={onAdd}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
