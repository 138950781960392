import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import DeleteModal from "../../../common/Modal/DeleteModal";
import EditModal from "../../../common/Modal/EditModal";
import NoticeDelete from "./Data/NoticeDeleteAPI";
import NoticeDetailAPI from "./Data/NoticeDetailAPI";
import NoticeEditAPI from "./Data/NoticeEditAPI";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;
const ErrorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: red;
  display: flex;
`;
const ErrorSpan = styled.span`
  margin-left: 0.5rem;
  color: #f05650;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const SubjectInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;

const BlockItem = styled.div`
  display: block;
`;
const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;
const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function NoticeDetail() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const [image, setImage] = useState<File | undefined>();
  const formData = new FormData();

  const { data: noticeDetail, isSuccess } = useQuery(["noticeDetail", id], () =>
    NoticeDetailAPI(id)
  );

  //modal
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  function onDelete() {
    setModalDelete(true);
  }
  function closeDelete() {
    setModalDelete(false);
  }
  function onEdit() {
    setModalEdit(true);
  }
  function closeEdit() {
    setModalEdit(false);
  }

  const { mutate: deleteNotice, isLoading: deleteLoading } = useMutation(
    async () => await NoticeDelete(id),
    {
      onMutate: async () => {
        queryClient.removeQueries(["noticeDetail", id]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["noticeDetail", id]);
        closeDelete();
      },
      onError: () => {
        alert("실패했습니다. 다시 시도하세요.");
        closeDelete();
      },
    }
  );

  const handleDelete = async () => {
    await deleteNotice();
    await navigate(-1);
    await alert("팝업이 삭제되었습니다.");
  };

  async function CallEditAPI() {
    if (!isSuccess) {
      return;
    }
    const subjectValue = getValues("subject");
    const redirectLinkValue = getValues("redirectLink");
    const visibleValue = getValues("visible");

    const modifiedData = {
      subject: subjectValue,
      redirectLink: redirectLinkValue,
      visible: visibleValue,
    };

    const blob = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("data", blob);
    if (image) {
      formData.append("photo", image);
    }

    await noticeEdit.mutateAsync(formData);
  }

  const noticeEdit = useMutation(
    (formData: FormData) => NoticeEditAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("팝업 등록이 완료되었습니다.");
        queryClient.invalidateQueries("notices");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);
        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await NoticeDetailAPI(id);
      if (response) {
        setValue("subject", response.data.subject);
        setValue("redirectLink", response.data.redirectLink);
        setValue("visible", response.data.visible);
      }
    };

    fetchData();
  }, []);

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(file);
    };
  }
  const visibleValue = watch("visible");

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  return (
    <Wrapper>
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          onDelete={handleDelete}
          isLoading={deleteLoading}
          name={"팝업"}
        />
      )}
      {modalEdit && (
        <EditModal close={closeEdit} CallAPI={CallEditAPI} name={"팝업"} />
      )}
      <Inner>
        <Header>
          <Title>팝업 상세</Title>
        </Header>
        <Contents>
          <Item>
            <div>
              {image ? (
                <Img src={URL.createObjectURL(image)} alt="" />
              ) : (
                <Img src={noticeDetail?.data.bannerUrl} alt="" />
              )}
            </div>
            <div>
              <ImgInput
                type="file"
                accept=".jpg, .png"
                name="chooseFile"
                onChange={Image}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              주제
              <ItemSpan>*필수 아님</ItemSpan>
            </ItemTitle>
            <div>
              <SubjectInput
                type="text"
                defaultValue={noticeDetail?.data.subject}
                {...register("subject")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              연결 링크
              <ItemSpan>*필수 아님</ItemSpan>
            </ItemTitle>
            <LinkInput
              type="text"
              defaultValue={noticeDetail?.data.redirectLink}
              {...register("redirectLink")}
            />
          </Item>
          <BlockItem>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input
                type="radio"
                value="true"
                checked={visibleValue === true}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              보이기
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                checked={visibleValue === false}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              숨기기
            </Label>
          </BlockItem>
        </Contents>
        <ButtonDiv>
          <DeleteButton onClick={onDelete}>삭제하기</DeleteButton>
          <EditButton onClick={onEdit}>수정하기</EditButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
