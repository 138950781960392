import { PageableData } from "API/type";
import instance from "../../../../../API/MainAPI";
import {
  BountyHuntPhoto,
  BountyStatus,
  DayMission,
  DayMissionActivity,
} from "../../types";

interface BountyHunterPhotoListParams {
  dayMissionGameId: number;
  userId?: number;
  status?: BountyStatus;
  pageNumber: number;
  pageSize: number;
  dayNumber?: number;
}

export default function DayMissionList({
  dayMissionGameId,
  userId,
  dayNumber,
  status,
  pageNumber,
  pageSize,
}: BountyHunterPhotoListParams) {
  return instance.get<PageableData<DayMissionActivity>>(
    "/v3/admins/dayMissions/activities/managements",
    {
      params: {
        pageNumber,
        pageSize,
        status,
        dayNumber,
        dayMissionGame: dayMissionGameId,
        user: userId,
      },
    }
  );
}
