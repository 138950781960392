import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./components/sidebar/SideBar";
import { ModalsProvider } from "components/common/Modal/ModalsContext";

const Layout = styled.div`
  display: flex;
`;

const Arrow = styled.div`
  display: flex;
  width: 20px;
  height: 100px;

  background-color: #5c5c5c;
  border-radius: 0 10px 10px 0;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 40%;
  z-index: 1;
`;

function App() {
  const navigate = useNavigate();

  const [hideBar, setHideBar] = useState(false);

  const clickHide = () => {
    setHideBar(!hideBar);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <ModalsProvider>
      <Layout>
        <div style={{ display: "flex" }}>{!hideBar && <Sidebar />}</div>{" "}
        <Arrow onClick={clickHide}>
          <FontAwesomeIcon
            icon={!hideBar ? faAngleLeft : faAngleRight}
            style={{ color: "white" }}
          ></FontAwesomeIcon>
        </Arrow>
        <Outlet />
      </Layout>
    </ModalsProvider>
  );
}

export default App;
