import { Outlet, createBrowserRouter } from "react-router-dom";
import App from "./App";
import Login from "./components/pages/Auth/Login";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import NoticeList from "./components/pages/Notice/noticeList/NoticeList";
import NoticeAdd from "./components/pages/Notice/noticeAdd/NoticeAdd";
import NotificationList from "./components/pages/Notification/NotificationList/NotificationList";
import UserStatsDetail from "./components/pages/UserStatDetail/UserStatDetail";
import Notice from "./components/pages/Notice/Notice";
import User from "./components/pages/User/User";
import UserList from "./components/pages/User/UserList/UserList";
import NoticeDetail from "./components/pages/Notice/noticeDetail/NoticeDetail";

import Sidebar from "./components/sidebar/SideBar";
import Notification from "./components/pages/Notification/Notification";
import Coupon from "./components/pages/Coupon/Coupon";
import CouponList from "./components/pages/Coupon/CouponList/CouponList";

import Gifticon from "./components/pages/GifticonShop/Gifticon";

import Order from "./components/pages/Order/Order";
import OrderList from "./components/pages/Order/OrderList/OrderList";
import CouponUsage from "./components/pages/CouponUsage/CouponUsage";
import UsageList from "./components/pages/CouponUsage/UsageList/UsageList";
import Supporter from "./components/pages/Suppoter/Supporter";
import SupporterList from "./components/pages/Suppoter/SuppoterList/SupporterList";

import UserDetail from "./components/pages/User/UserDetail/UserDetail";

import GifticonList from "./components/pages/GifticonShop/GifticonList/GifticonList";
import GifticonAdd from "./components/pages/GifticonShop/GifticonAdd/GifticonAdd";
import GifticonDetail from "./components/pages/GifticonShop/GifticonDetail/GifticonDetail";
import CouponAdd from "./components/pages/Coupon/CouponAdd/CouponAdd";
import CouponDetail from "./components/pages/Coupon/CouponDetail/CouponDetail";
import OrderDetail from "./components/pages/Order/OrderDetail/OrderDetail";
import PointSend from "./components/pages/Point/PointSend/PointSend";
import Point from "./components/pages/Point/Point";
import Push from "./components/pages/Push/Push";
import PushSend from "./components/pages/Push/PushSend/PushSend";
import GameTest from "./components/pages/GameTest/GameTest";
import TestList from "./components/pages/GameTest/TestList/TestList";
import TestAdd from "./components/pages/GameTest/TestAdd/TestAdd";
import TestDetail from "./components/pages/GameTest/TestDetail/TestDetail";

import AppStat from "./components/pages/AppStatsDetail/AppStatDetail";
import AppStatsList from "./components/pages/AppStatsDetail/AppStatsList/AppStatsList";
import AppStatsAdd from "./components/pages/AppStatsDetail/AppStatsAdd/AppStatsAdd";
import GameNews from "./components/pages/GameNews/GameNews";
import GameNewsList from "./components/pages/GameNews/GameNewsList/GameNewsList";
import GameNewsAdd from "./components/pages/GameNews/GameNewsAdd/GameNewsAdd";

import GameNewsEdit from "./components/pages/GameNews/GameNewsDetail/GameNewsDetail";
import InvitStatsDetail from "./components/pages/InvitStatsDetail/InvitStatsDetail";
import DonationAdd from "./components/pages/GifticonShop/DonationAdd/DonationAdd";
import DonationDetail from "./components/pages/GifticonShop/DonationDetail/DonationDetail";
import Plistar from "./components/pages/Plistar/Plistar";

import PlistarSend from "./components/pages/Plistar/PlistarSend/PlistarSend";

import SurveyList from "./components/pages/Survey/SurveyList/SurveyList";
import Survey from "./components/pages/Survey/Survey";
import SurveyAdd from "./components/pages/Survey/SurveyAdd/SurveyAdd";

import GameDownload from "./components/common/Modal/DownloadList/GameDownload";
import PlistarOrder from "./components/pages/PlistarOrder/PlistarOrder";

import PlistarOrderDetail from "./components/pages/PlistarOrder/OrderDetail/PlistarOrderDetail";
import TestManagePhaseOneList from "./components/pages/TestManage/TestManagePhaseOneList/TestManagePhaseOneList";
import UserRegisterPage from "./components/pages/TestManage/UserRegister/UserRegisterList";
import TestManage from "./components/pages/TestManage/TestManage";
import TesterActivity from "./components/pages/TesterActivity/TesterActivity";
import CheeringTester from "./components/pages/TesterActivity/CheeringTester/CheeringTester";
import ReviewTester from "./components/pages/TesterActivity/ReviewTester/ReviewTester";
import PlistarOrderList from "./components/pages/PlistarOrder/OrderList/PlistarOrderList";

import Announcement from "./components/pages/Announcement/Announcement";
import AnnouncementList from "./components/pages/Announcement/AnnouncementList/AnnouncementList";
import AnnouncementAdd from "./components/pages/Announcement/AnnouncementAdd/AnnouncementAdd";
import AnnouncementDetailAPI from "./components/pages/Announcement/AnnouncementDetail/Data/AnnouncementDetailAPI";
import AnnouncementEdit from "./components/pages/Announcement/AnnouncementDetail/AnnouncementDetail";

import ReviewAwards from "./components/pages/ReviewAwards/ReviewAwards";
import ReviewAwardsPage from "./components/pages/ReviewAwards/ReviewAwardsList/Data/ReviewAwardsListAPI";
import ReviewAwardsList from "./components/pages/ReviewAwards/ReviewAwardsList/ReviewAwardsList";
import ReviewAwardAdd from "./components/pages/ReviewAwards/ReviewAwardsAdd/ReviewAwardsAdd";
import ReviewAwardDetailAPI from "./components/pages/ReviewAwards/ReviewAwardsDetail/Data/ReviewAwardDetailAPI";
import ReviewAwardEdit from "./components/pages/ReviewAwards/ReviewAwardsDetail/ReviewAwardDetail";
import Election from "./components/pages/Election/Election";
import ElectionList from "./components/pages/Election/ElectionList/ElectionList";
import ElectionAdd from "./components/pages/Election/ElectionAdd/ElectionAdd";
import SurveyEdit from "./components/pages/Survey/SurveyDetail/SurveyDetail";
import Event from "./components/pages/EventManagement/Event";
import EventPage from "./components/pages/EventManagement/EventPage/EventPage";
import EventAdd from "./components/pages/EventManagement/EventAdd/EventAdd";
import EventEdit from "./components/pages/EventManagement/EventDetail/EventDetail";
import EventRegisterPageAPI from "./components/pages/EventManagement/EventRegisterPage/Data/EventRegisterPageAPI";
import EventRegisterPage from "./components/pages/EventManagement/EventRegisterPage/EventRegisterPage";
import Banner from "./components/pages/Banner/Banner";
import BannerList from "./components/pages/Banner/BannerList/BannerList";
import BannerEdit from "./components/pages/Banner/BannerDetail/BannerDetail";
import BannerAdd from "./components/pages/Banner/BannerAdd/BannerAdd";
import WordList from "./components/pages/Attendance/WordList/WordList";
import WordAdd from "./components/pages/Attendance/WordAdd/WordCreation";
import Attendance from "./components/pages/Attendance/Attendance";
import WordEdit from "./components/pages/Attendance/WordDetail/WordEdit";
import UserAttendancePage from "./components/pages/User/Attendance/Data/UserAttendancePageAPI";
import UserAttendance from "./components/pages/User/Attendance/UserAttendance";
import WordStatement from "./components/pages/Attendance/WordStatement/WordStatement";
import ElectionDetailAPI from "./components/pages/Election/ElectionEdit/Data/ElectionDetailAPI";
import ElectionEdit from "./components/pages/Election/ElectionEdit/ElectionEdit";
import SurveyDetail from "./components/pages/Survey/SurveyDetail/SurveyDetail";
import InAppGame from "./components/pages/InAppGame/InAppGame";
import InAppGamePage from "./components/pages/InAppGame/InAppGamePage/InAppGamePage";
import DailyQuest from "./components/pages/DailyQuest/DailyQuest";
import DailyQuestList from "./components/pages/DailyQuest/DailyQuestList/DailyQuestList";
import DailyQuestAdd from "./components/pages/DailyQuest/DailyQuestAdd/DailyQuestAdd";
import TestManagePhaseTwoList from "./components/pages/TestManage/TestManagePhaseTwoList/TestManagePhaseTwoList";
import DailyQuestDetail from "./components/pages/DailyQuest/DailyQuestDetail/DailyQuestDetail";
import TestSurvey from "./components/pages/TestManage/TestSurvey/TestSurveyDetail/TestSurveyDetail";
import TestSurveyDetail from "./components/pages/TestManage/TestSurvey/TestSurveyDetail/TestSurveyDetail";
import UserNotSup from "./components/pages/Suppoter/Supporter";
import BountyList from "components/pages/Retensions/BountyList/BountyList";
import BountyRegister from "components/pages/Retensions/BountyRegister/BountyRegister";
import BountyDetail from "components/pages/Retensions/BountyDetail/BountyDetail";
import BountyHunterPhoto from "components/pages/Retensions/BountyHunterPhoto/BountyHunterPhoto";
import BountyHunterTime from "components/pages/Retensions/BountyHunterTime/BountyHunterTime";
import DayMissionGameList from "components/pages/Retensions/DayMissionGameList/DayMissionGameList";
import DayMissionGameRegister from "components/pages/Retensions/DayMissionGameRegister/DayMissionGameRegister";
import DayMissionGameDetail from "components/pages/Retensions/DayMissionGameDetail/DayMissionGameDetail";
import DayMissionCertificate from "components/pages/Retensions/DayMissionGameCertificate/DayMissionGameCertificate";
import DayMissionGameEdit from "components/pages/Retensions/DayMissionGameEdit/DayMissionGameEdit";
import DayMissionEdit from "components/pages/Retensions/DayMissionEdit/DayMissionEdit";
import BounceRateSurveyList from "components/pages/Survey/BounceRateSurvey/BounceRateSurveyList/BounceRateSurveyList";
import BounceRateSurveyAdd from "components/pages/Survey/BounceRateSurvey/BounceRateSurveyAdd/BounceRateSurveyAdd";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },

      { path: "userStats", element: <UserStatsDetail /> },
      {
        path: "appStats",
        element: <AppStat />,
        children: [
          { path: "", element: <AppStatsList /> },
          { path: "add", element: <AppStatsAdd /> },
        ],
      },
      { path: "invitStats", element: <InvitStatsDetail /> },
      {
        path: "notifications",
        element: <Notification />,
        children: [{ path: "", element: <NotificationList /> }],
      },
      {
        path: "announcements",
        element: <Announcement />,
        children: [
          { path: "", element: <AnnouncementList /> },
          { path: "add", element: <AnnouncementAdd /> },
          { path: ":announceId", element: <AnnouncementEdit /> },
        ],
      },
      {
        path: "notices",
        element: <Notice />,
        children: [
          { path: "", element: <NoticeList /> },
          {
            path: "add",
            element: <NoticeAdd />,
          },
          { path: ":notiId", element: <NoticeDetail /> },
        ],
      },
      {
        path: "users",
        element: <User />,
        children: [
          { path: "", element: <UserList /> },
          { path: ":usersId", element: <UserDetail /> },
          { path: "attendance/:userId", element: <UserAttendance /> },
        ],
      },
      {
        path: "attendance",
        element: <Attendance />,
        children: [
          { path: "", element: <WordList /> },
          { path: "add", element: <WordAdd /> },
          { path: ":wordId", element: <WordEdit /> },
          { path: "users/:wordId", element: <WordStatement /> },
        ],
      },
      {
        path: "ads",
        element: <Banner />,
        children: [
          { path: "", element: <BannerList /> },
          { path: ":adsId", element: <BannerEdit /> },
          { path: "add", element: <BannerAdd /> },
        ],
      },
      {
        path: "reviewAwards",
        element: <ReviewAwards />,
        children: [
          { path: "", element: <ReviewAwardsList /> },
          { path: "add", element: <ReviewAwardAdd /> },
          { path: ":reviewAwardsId", element: <ReviewAwardEdit /> },
        ],
      },
      { path: "gameAPI/:gameId", element: <GameDownload /> },

      {
        path: "gameNews",
        element: <GameNews />,
        children: [
          { path: "", element: <GameNewsList /> },
          { path: "add", element: <GameNewsAdd /> },
          { path: ":gameNewsId", element: <GameNewsEdit /> },
        ],
      },
      {
        path: "inAppGames",
        element: <InAppGame />,
        children: [
          { path: "", element: <InAppGamePage /> },
          { path: "add", element: <GameNewsAdd /> },
          { path: ":gameNewsId", element: <GameNewsEdit /> },
        ],
      },
      {
        path: "retension",
        element: <Outlet />,
        children: [
          {
            path: "daymissions",
            element: <DayMissionGameList />,
          },
          {
            path: "daymissions/register",
            element: <DayMissionGameRegister />,
          },
          {
            path: "daymissions/:daymissionId",
            element: <DayMissionGameDetail />,
          },
          {
            path: "daymissions/:daymissionId/certification",
            element: <DayMissionCertificate />,
          },
          {
            path: "daymissions/:daymissionId/edit",
            element: <DayMissionGameEdit />,
          },
          {
            path: "daymissions/:daymissionId/edit-daymission",
            element: <DayMissionEdit />,
          },
          {
            path: "bounties",
            element: <BountyList />,
          },
          {
            path: "bounties/register",
            element: <BountyRegister />,
          },
          {
            path: "bounties/:bountyId",
            element: <BountyDetail />,
          },
          {
            path: "bounties/:bountyId/photo",
            element: <BountyHunterPhoto />,
          },
          {
            path: "bounties/:bountyId/time",
            element: <BountyHunterTime />,
          },
        ],
      },
      {
        path: "coupons",
        element: <Coupon />,
        children: [
          { path: "", element: <CouponList /> },
          { path: "add", element: <CouponAdd /> },
          { path: ":counponsId", element: <CouponDetail /> },
        ],
      },
      {
        path: "elections",
        element: <Election />,
        children: [
          { path: "", element: <ElectionList /> },
          { path: "add", element: <ElectionAdd /> },
          { path: ":electionId", element: <ElectionEdit /> },
        ],
      },
      {
        path: "gifticons",
        element: <Gifticon />,
        children: [
          {
            path: "",
            element: <GifticonList />,
          },
          { path: "add", element: <GifticonAdd /> },
          { path: "gifticon/:gifticonId", element: <GifticonDetail /> },
          { path: "addDonate", element: <DonationAdd /> },
          { path: "donation/:gifticon", element: <DonationDetail /> },
        ],
      },
      {
        path: "events",
        element: <Event />,
        children: [
          { path: "", element: <EventPage /> },
          { path: "add", element: <EventAdd /> },
          { path: ":eventsId", element: <EventEdit /> },
          { path: "register/:eventId", element: <EventRegisterPage /> },
        ],
      },
      {
        path: "orders",
        element: <Order />,
        children: [
          { path: "", element: <OrderList /> },
          { path: ":orderId", element: <OrderDetail /> },
        ],
      },
      {
        path: "usages",
        element: <CouponUsage />,
        children: [{ path: "", element: <UsageList /> }],
      },
      {
        path: "supporters",
        element: <Supporter />,
        children: [{ path: "", element: <SupporterList /> }],
      },

      {
        path: "rewards",
        element: <Point />,
        children: [{ path: "", element: <PointSend /> }],
      },
      {
        path: "plistars",
        element: <Plistar />,
        children: [{ path: "", element: <PlistarSend /> }],
      },
      {
        path: "plistarOrders",
        element: <PlistarOrder />,
        children: [
          { path: "", element: <PlistarOrderList /> },
          { path: ":orderId", element: <PlistarOrderDetail /> },
        ],
      },
      {
        path: "pushes",
        element: <Push />,
        children: [{ path: "", element: <PushSend /> }],
      },
      {
        path: "tests",
        element: <GameTest />,
        children: [
          {
            path: "",
            element: <TestList />,
          },
          { path: "add", element: <TestAdd /> },
          { path: ":id", element: <TestDetail /> },
        ],
      },
      {
        path: "test",
        element: <TestManage />,
        children: [
          { path: "phaseOne", element: <TestManagePhaseOneList /> },
          {
            path: "phaseTwo",
            element: <TestManagePhaseTwoList />,
          },
        ],
      },
      {
        path: "testSurvey",
        element: <TestSurvey />,
        children: [{ path: ":activityId", element: <TestSurveyDetail /> }],
      },
      { path: ":registerId", element: <UserRegisterPage /> },
      {
        path: "testerActivity",
        element: <TesterActivity />,
        children: [
          { path: "cheering", element: <CheeringTester /> },
          { path: "reviews", element: <ReviewTester /> },
        ],
      },

      {
        path: "surveys",
        element: <Survey />,
        children: [
          { path: "", element: <SurveyList /> },
          { path: "add", element: <SurveyAdd /> },
          { path: ":surveyId", element: <SurveyDetail /> },
          { path: "bounceRate", element: <BounceRateSurveyList /> },
          { path: "bounceRate/add", element: <BounceRateSurveyAdd /> },
        ],
      },
      {
        path: "quests",
        element: <DailyQuest />,
        children: [
          { path: "", element: <DailyQuestList /> },
          { path: "add", element: <DailyQuestAdd /> },
          { path: ":questId", element: <DailyQuestDetail /> },
        ],
      },
    ],
  },
  { path: "/login", element: <Login /> },
]);
export default router;
