import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { clearCoupon } from "../../../common/clearStorage";
import index from "../../../common/function";
import formatDate from "../../../common/function/formatDate";
import CouponPageAPI from "./Data/couponAPI";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const Td = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function CouponList() {
  const pageNum = sessionStorage.getItem("couponPage") || "1";

  const { data: couponData, refetch } = useQuery(
    ["couponPage", pageNum],
    () => CouponPageAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("couponPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearCoupon();
  }, []);

  let count = 0;

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>쿠폰 목록</Title>

          <Link to="/coupons/add">
            <AddBtn className="addBtn-min">등록</AddBtn>
          </Link>
        </Header>
        <Contents>
          <Table>
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>코드</Th>
                <Th>보상</Th>
                <Th>시작일시</Th>
                <Th>만료일시</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <tbody>
              {couponData?.data?.page?.content &&
                couponData?.data?.page?.content.map((coupon: any) => {
                  count = count + 1;
                  return (
                    <Tr key={coupon.id}>
                      <Td>
                        {index(
                          couponData?.data?.page?.pageable.totalElements,
                          couponData?.data?.page?.pageable.pageNumber + 1,
                          couponData?.data?.page?.pageable.pageSize,
                          count
                        )}
                      </Td>
                      <Td>{coupon.code}</Td>
                      <Td>{coupon.rewards}</Td>
                      <Td>{formatDate(coupon.startedAt)}</Td>
                      <Td>{formatDate(coupon.expiredAt)}</Td>
                      <Td>
                        <Link
                          to={"/coupons/" + coupon.id}
                          state={{ id: coupon.id }}
                        >
                          <DetailButton>자세히</DetailButton>
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                couponData?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
