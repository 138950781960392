import instance from "../../../../../API/MainAPI";
interface Filter {
  userId: number | null;
  gameId: number | null;
  status: string | null;
}
export function ReviewTesterActivityAPI(page: number, filter: Filter) {
  return instance.get("/v3/admins/testers/managements/activities/reviews", {
    params: {
      game: filter.gameId,
      user: filter.userId,
      status: filter.status,
      pageNumber: page - 1,
    },
  });
}
