import React, { useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import formatDate from "../../../common/function/formatDate";
import index from "../../../common/function";
import { useQuery } from "react-query";
import GamePageAPI from "./Data/GamePageAPI";

import styled from "styled-components";
import { clearTestOne } from "../../../common/clearStorage";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border: 2px solid #333742;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Tr = styled.tr`
  width: 100%;
`;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const OverflowDiv = styled.div`
  font-size: 15px;
  text-align: center;
  overflow: hidden;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  width: autopx;
  table-layout: fixed;
  word-break: break-all;
  height: auto;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function TestList() {
  const pageNum = sessionStorage.getItem("testPage") || "1";

  const { data: tests, refetch } = useQuery(
    ["test", pageNum],
    async () => await GamePageAPI(Number(pageNum), 10)
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("testPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearTestOne();
  }, []);
  let count = 0;

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>게임 테스트 목록</Title>
          <Link to="add">
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>

        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th>No.</Th>
                <Th>게임명</Th>
                <Th>회사</Th>
                <Th>사진</Th>
                <Th>카테고리</Th>
                <Th>참여인원</Th>
                <Th>시작일</Th>
                <Th>종료일</Th>
                <Th>인증 최소 시간</Th>
                <Th />
              </Tr>
            </thead>
            <tbody>
              {tests?.data.page.content.map((game: any) => {
                count = count + 1;
                return (
                  <Tr key={game.id}>
                    <Td style={{ width: "35px" }}>
                      {index(
                        tests?.data?.page?.pageable.totalElements,
                        tests?.data?.page?.pageable.pageNumber + 1,
                        tests?.data?.page?.pageable.pageSize,
                        count
                      )}
                    </Td>
                    <Td style={{ width: "140px", wordBreak: "keep-all" }}>
                      {game.title}
                    </Td>
                    <Td style={{ width: "50px" }}>{game.company}</Td>
                    <Td>
                      {" "}
                      <img src={game.bannerUrl} width={"100px"} alt="" />
                    </Td>
                    <Td style={{ width: "80px" }}>{game.category}</Td>
                    <Td>{game.totTesters}</Td>
                    <Td style={{ width: "130px", wordBreak: "normal" }}>
                      {formatDate(game.startedAt)}
                    </Td>
                    <Td style={{ width: "130px", wordBreak: "normal" }}>
                      {formatDate(game.expiredAt)}
                    </Td>
                    <Td>{Math.floor(game.allowedPlayTime / 60)}분</Td>
                    <Td>
                      <Link
                        to={"/tests/" + game.id}
                        state={{
                          id: game.id,
                        }}
                      >
                        <DetailButton>자세히</DetailButton>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={tests?.data?.page?.pageable.totalElements || 1}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
