import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";

import ReviewAwardCreationAPI from "./Data/ReviewAwardCreation";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const SubjectInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  padding: 5px;
  width: 30%;
  height: 100px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function ReviewAwardAdd() {
  const { register, handleSubmit, setValue, getValues, watch } = useForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  const [img, setImg] = useState<File | undefined>();
  const formData = new FormData();
  const showLink = watch("redirectionCategory");
  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallAPI() {
    const titleValue = getValues("title");
    const contentValue = getValues("content");
    const redirectLinkValue = getValues("redirectLink");
    const redirectionCategoryValue = getValues("redirectionCategory");

    const blob = new Blob(
      [
        JSON.stringify({
          title: titleValue,
          content: contentValue,
          redirectLink: showLink === "EXTERNALS" ? redirectLinkValue : "",
          redirectionCategory: redirectionCategoryValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);
    if (img) {
      formData.append("image", img);
    }

    await reviewAwardMutation.mutateAsync(formData);
  }

  const reviewAwardMutation = useMutation(ReviewAwardCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("리뷰어워드 등록이 완료되었습니다.");
      queryClient.invalidateQueries("reviewAwards");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const titleValue = getValues("title");
    const contentValue = getValues("content");
    const redirectLinkValue = getValues("redirectLink");
    const redirectionCategoryValue = getValues("redirectionCategory");

    if (titleValue && contentValue && redirectionCategoryValue) {
      setModal(true);
    } else {
      alert("제목, 내용, 카테고리는 필수입니다.");
    }
  }

  function closeAdd() {
    setModal(false);
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal CallAPI={CallAPI} close={closeAdd} />}
      <Inner>
        <Header>
          <Title>리뷰어워드 등록</Title>
        </Header>
        <Contents>
          <div>{img && <Img src={URL.createObjectURL(img)} alt="" />}</div>
          <div>
            <ImgInput type="file" accept=".jpg, .png" onChange={Image} />
          </div>
          <Item>
            <ItemTitle>제목</ItemTitle>
            <div>
              <SubjectInput type="text" {...register("title")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>내용</ItemTitle>
            <TextArea {...register("content")} />
          </Item>

          <Item>
            <ItemTitle>링크 카테고리</ItemTitle>
            <Select
              // value={adsDetail?.data.category}
              {...register("redirectionCategory")}
            >
              <option value="ANNOUNCEMENT">공지글</option>
              <option value="GAME_NEWS">게임뉴스</option>
              <option value="TESTS">테스트 게임</option>
              <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
              <option value="RELEASE">출시게임</option>
              <option value="QUESTS">퀘스트</option>
              <option value="CONTENTS">콘텐츠</option>
              <option value="MY_PAGES">마이페이지</option>
              <option value="SETTINGS">설정</option>
              <option value="CALENDAR">캘린더</option>
              <option value="EXTERNALS">외부링크</option>
              <option value="NONE">리다이렉션 없음</option>
            </Select>
          </Item>
          {showLink === "EXTERNALS" && (
            <Item>
              <ItemTitle>
                연결 링크
                <ItemSpan>*필수 아님</ItemSpan>
              </ItemTitle>
              <LinkInput type="text" {...register("redirectLink")} />
            </Item>
          )}
        </Contents>
        <ButtonDiv>
          <AddButton onClick={handleSubmit(onAdd)}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
