import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { CreateBountyDto, CreateBountyFileDto } from "../types";

export interface BountyRegisterForm
  extends CreateBountyDto,
    CreateBountyFileDto {}

export type HandleSubmitFunc = (
  data: BountyRegisterForm
) => Promise<unknown> | unknown;

const useBountyRegisterForm = (
  handleSubmitProp: HandleSubmitFunc = () => {}
) => {
  const {
    register,
    handleSubmit: onSubmit,
    ...props
  } = useForm<BountyRegisterForm>();

  const _handleSubmit: SubmitHandler<BountyRegisterForm> = async (data) => {
    await handleSubmitProp(data);
  };
  const _handleError: SubmitErrorHandler<BountyRegisterForm> = (data) => {
    window.alert("채우지 않은 필수 항목이 있습니다.");
  };
  const handleSubmit = onSubmit(_handleSubmit, _handleError);
  return {
    handleSubmit,
    inputs: {
      packageId: register("packageId"),
      gameTitle: register("gameTitle", { required: true }),
      gameSubtitle: register("gameSubtitle", { required: true, maxLength: 20 }),
      gameImage: register("gameImage", { required: true }),
      appStoreLink: register("appStoreLink"),
      playStoreLink: register("playStoreLink"),
      startedAt: register("startedAt", { required: true }),
      expiredAt: register("expiredAt", { required: true }),
      rewardCategory: register("rewardCategory", { required: true }),
      rewards: register("rewards", { required: true }),
      certificationMethod: register("certificationMethod", { required: true }),
      allowedPlayTime: register("allowedPlayTime", { required: true }),
      photoMission: register("photoMission"),
      missionExample: register("missionExample"),
      visible: register("visible"),
    },
    ...props,
  };
};

export default useBountyRegisterForm;
