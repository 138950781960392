import instance from "API/MainAPI";
import { PageableData } from "API/type";
import { BOUNTY_FILTER_STATUS, BountyFilterStatus } from "../../constants";
import { SimpleBounty } from "../../types";

export default function BountyListAPI(
  page: number,
  pageSize: number,
  filter: BOUNTY_FILTER_STATUS
) {
  return instance.get<PageableData<SimpleBounty>>(
    "/v3/admins/bounties/managements",
    {
      params: {
        pageSize: pageSize,
        pageNumber: page - 1,
        ongoing:
          filter === BountyFilterStatus.OnGoing
            ? true
            : filter === BountyFilterStatus.Ended
            ? false
            : null,
      },
    }
  );
}
