import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import CouponDetailAPI from "./Data/CouponDetailAPI";
import CouponModificationAPI from "./Data/CouponModificationAPI";
import Modal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TxtInput = styled.input`
  width: 25%;
  font-size: 1rem;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function CouponDetail() {
  const location = useLocation();
  const id = location.state.id;
  const navigate = useNavigate();
  const { register, setValue, getValues } = useForm();
  const formData = new FormData();

  const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
  const { data: couponDetail } = useQuery(["couponDetail", id], () =>
    CouponDetailAPI(id)
  );

  useEffect(() => {
    clearAll();
    const fetchData = async () => {
      const response = await CouponDetailAPI(id);
      if (response) {
        setValue("code", response.data.code);
        setValue("rewards", response.data.rewards);
        setValue("startedAt", response.data.startedAt);
        setValue("expiredAt", response.data.expiredAt);
      }
    };
    fetchData();
  }, []);

  const [editModal, setEditModal] = useState(false);
  function closeEdit() {
    setEditModal(false);
  }
  function onEditModal() {
    setEditModal(true);
  }

  async function CallEditAPI() {
    const codeValue = getValues("code");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    formData.append("code", codeValue);

    formData.append("rewards", rewardsValue);

    formData.append("startedAt", startedAtValue);

    formData.append("expiredAt", expiredAtValue);
    await couponEditMutation.mutateAsync(formData);
  }

  const couponEditMutation = useMutation(
    (formData: FormData) => CouponModificationAPI(id, formData),
    {
      onSuccess: () => {
        setEditModal(false);
        setIsLoading(false);
        alert("수정이 완료되었습니다");
        navigate(-1);
      },
      onError: (error: any) => {
        setIsLoading(false);
        let message = error.response.data.message;
        alert(message);
        setEditModal(false);
      },
    }
  );

  function onEdit() {
    const codeValue = getValues("code");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");

    if (codeValue && rewardsValue && startedAtValue && expiredAtValue) {
      if (codeValue.length < 4 || codeValue.length > 12) {
        alert("코드 길이를 4~12자로 설정해주세요.");
      } else if (!regex.test(codeValue)) {
        alert("한글, 영어, 숫자만 입력 가능합니다.");
      } else if (rewardsValue < 0 || rewardsValue > 100000) {
        alert("포인트는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
      } else {
        onEditModal();
      }
    } else {
      alert("모든 항목을 입력하세요");
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  function setLoading() {
    setIsLoading(false);
  }

  return (
    <Wrapper>
      {editModal && (
        <Modal
          close={closeEdit}
          CallAPI={CallEditAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>쿠폰 상세</Title>
        </Header>
        <Contents>
          <Item>
            <Item>
              <ItemTitle>
                코드
                <ItemSpan>
                  *한글, 영어, 숫자만 가능 / 띄어쓰기 불가 / 중복코드 발급 불가
                </ItemSpan>
              </ItemTitle>
              <div>
                <TxtInput
                  type="text"
                  min="4"
                  max="12"
                  defaultValue={couponDetail?.data.code}
                  {...register("code")}
                />
              </div>
            </Item>

            <Item>
              <ItemTitle>
                포인트
                <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
              </ItemTitle>
              <TxtInput
                type="number"
                min="0"
                defaultValue={couponDetail?.data.rewards}
                {...register("rewards")}
              />
            </Item>

            <Item>
              <ItemTitle>시작일</ItemTitle>
              <DateInput
                type="datetime-local"
                className="box-under w-25"
                defaultValue={couponDetail?.data.startedAt}
                required
                pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                {...register("startedAt")}
              />
            </Item>

            <Item>
              <ItemTitle>종료일</ItemTitle>
              <DateInput
                type="datetime-local"
                className="box-under w-25"
                defaultValue={couponDetail?.data.expiredAt}
                required
                pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                {...register("expiredAt")}
              />
            </Item>

            <ButtonDiv>
              <EditButton onClick={onEdit}>수정하기</EditButton>
            </ButtonDiv>
          </Item>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
