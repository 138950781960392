import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { QueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import CouponCreationAPI from "./Data/CouponCreationAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TxtInput = styled.input`
  width: 25%;
  font-size: 1rem;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;
const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;
export default function CouponAdd() {
  const navigate = useNavigate();
  const formData = new FormData();
  const now = new Date(new Date().toString().split("GMT")[0] + " UTC")
    .toISOString()
    .slice(0, 19);

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  useEffect(() => {
    clearAll();
  }, []);

  const { register, getValues } = useForm();

  async function CallAddAPI() {
    const codeValue = getValues("code");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    formData.append("code", codeValue);

    formData.append("rewards", rewardsValue);

    formData.append("startedAt", startedAtValue);

    formData.append("expiredAt", expiredAtValue);
    await couponAddMutation.mutateAsync(formData);
  }

  const couponAddMutation = useMutation(CouponCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("쿠폰 등록이 완료되었습니다.");
      navigate(-1);
    },
    onError: (error: any) => {
      setModal(false);
      alert(error.message);
    },
  });

  const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;

  function onClick() {
    const codeValue = getValues("code");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");

    if (codeValue && rewardsValue && startedAtValue && expiredAtValue) {
      if (codeValue.length < 4 || codeValue.length > 12) {
        alert("코드 길이를 4~12자로 설정해주세요.");
      } else if (rewardsValue < 0 || rewardsValue > 100000) {
        alert("포인트는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
      } else if (expiredAtValue < startedAtValue) {
        alert("날짜를 확인하세요.");
      } else {
        setModal(true);
      }
    } else {
      alert("모든 항목을 입력하세요");
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  function setLoading() {
    setIsLoading(false);
  }

  return (
    <Wrapper>
      {modal && (
        <Modal
          close={closeAdd}
          CallAPI={CallAddAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>쿠폰 등록</Title>
        </Header>

        <Contents>
          <Item>
            <ItemTitle>
              코드
              <ItemSpan>
                * 4~12자 / 한글, 영어, 숫자만 가능 / 띄어쓰기 불가 / 중복코드
                발급 불가
              </ItemSpan>
            </ItemTitle>
            <div>
              <TxtInput
                type="text"
                className="input w-25"
                {...register("code", {
                  required: true,
                  pattern: regex,
                })}
              />
            </div>
          </Item>

          <Item>
            <ItemTitle>
              포인트
              <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
            </ItemTitle>
            <TxtInput type="number" min="0" {...register("rewards")} />
          </Item>

          <Item>
            <ItemTitle>시작일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              defaultValue={now}
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("startedAt")}
            />
          </Item>

          <Item>
            <ItemTitle>종료일</ItemTitle>
            <DateInput
              type="datetime-local"
              className={
                getValues("expiredAt") < getValues("startedAt")
                  ? "box-under-red w-25"
                  : "box-under w-25"
              }
              min={getValues("startedAt")}
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("expiredAt")}
            />
          </Item>

          <ButtonDiv>
            <AddButton onClick={onClick}>등록하기</AddButton>
          </ButtonDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
