import styled from "styled-components";
import Checkbox from "../../Checkbox";
import { Column, DayMissionColumn } from "../types";
import useModal from "components/common/Modal/useModal";
import UserModal from "../../modals/UserModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import {
  BountyHuntFilter,
  DayMissionGame,
  DayMissionGameDetail,
  SetBountyHuntFilter,
  SetDayMissionFilter,
} from "../../../types";
import StatusModal from "../../modals/StatusModal";
import usePopup from "../../usePopup";
import Popup from "../../Popup";
import DayNumberModal from "../../modals/DayNumberModal";
import { useMemo } from "react";

type Props = {
  dmg?: DayMissionGameDetail;
  checked: boolean;
  indeterminate: boolean;
  toggleAllItems: () => void;
  columns: DayMissionColumn[];
  setFilter: SetDayMissionFilter;
};

const TableRow = styled.tr`
  background: #333742;
  & th {
    font-size: 20px;
    font-weight: 700;
    color: white;
    padding: 15px 12px 17px;
  }
`;
const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
`;

const TableHeader = ({
  dmg,
  columns,
  checked,
  indeterminate,
  toggleAllItems,
  setFilter,
}: Props) => {
  const {
    visible: userModalVisible,
    open: openUserModal,
    close: closeUserModal,
  } = usePopup();
  // const {
  //   visible: statusModalVisible,
  //   open: openStatusModal,
  //   close: closeStatusModal,
  // } = usePopup();
  const {
    visible: dayModalVisible,
    open: openDayModal,
    close: closeDayModal,
  } = usePopup();
  const days = useMemo(() => {
    if (dmg) {
      return dmg.dayMissions.map((dayMission) => dayMission.dayNumber);
    } else return [1];
  }, [dmg]);
  const renderHeader = (column: DayMissionColumn) => {
    switch (column.accessor) {
      case "checked":
        return (
          <Checkbox
            aria-label="Select all projects"
            checked={checked}
            indeterminate={indeterminate}
            onChange={toggleAllItems}
          >
            전체선택
          </Checkbox>
        );
      case "nickname":
        return (
          <ToggleButton onClick={openUserModal}>
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} />
            <Popup visible={userModalVisible}>
              <UserModal
                close={closeUserModal}
                setUser={(user) => setFilter((filter) => ({ ...filter, user }))}
              />
            </Popup>
          </ToggleButton>
        );
      case "dayNumber":
        return (
          <ToggleButton onClick={openDayModal}>
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} />
            <Popup visible={dayModalVisible}>
              <DayNumberModal
                days={days}
                close={closeDayModal}
                setStatus={(dayNumber) =>
                  setFilter((filter) => ({ ...filter, dayNumber }))
                }
              />
            </Popup>
          </ToggleButton>
        );
      // case "status":
      //   return (
      //     <ToggleButton onClick={openStatusModal}>
      //       {column.header}
      //       <FontAwesomeIcon icon={faCaretDown} />
      //       <Popup visible={statusModalVisible}>
      //         <StatusModal
      //           type={type}
      //           close={closeStatusModal}
      //           setStatus={(status) =>
      //             setFilter((filter) => ({ ...filter, status }))
      //           }
      //         />
      //       </Popup>
      //     </ToggleButton>
      //   );
      default:
        return column.header;
    }
  };
  return (
    <TableRow>
      {columns.map((column) => (
        <th key={column.accessor}>{renderHeader(column)}</th>
      ))}
    </TableRow>
  );
};

export default TableHeader;
