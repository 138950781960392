import React, { useEffect, useMemo, useState } from "react";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";
import ApproveModal from "./Modal/ApproveModal";
import RejectModal from "./Modal/RejectModal";

import TestApprovalAPI from "./Data/TestApprovalAPI";
import TestRejectAPI from "./Data/TestRejectAPI";
import { useQuery } from "react-query";
import styled from "styled-components";
import formatDate from "../../../common/function/formatDate";
import ImagePopup from "./Popup/ImagePopup";
import TestPopup from "./Popup/TestPopup";

import { AxiosResponse } from "axios";
import UserPopup from "./Popup/UserPopup";
import { TesterActivityAPI } from "./Data/TesterActivityPageAPI";
import UserRegisterPopup from "./Popup/UserRegisterPopup";
import { Link } from "react-router-dom";
import ConfirmedPopup from "./Popup/StatusPopup";
import GameDownload from "../../../common/Modal/DownloadList/GameDownload";
import { PhaseOneTesterSearchAPI } from "../Data/PhaseOneTesterSearchAPI";
import TestManageOneAllCheck from "./TestManageOneAllCheck";
import TestManageOneOnlyCheck from "./TestManageOneOnlyCheck";
import { once } from "events";

interface Item {
  activityId: number;
  testerId: number;
  nickname: string;
  game: string;
  snapshotUrl: string;
  playTime: number;
  submittedAt: string;
  category: string;
  status: string;
  confirmed: boolean;
}

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Button = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const Table = styled.table`
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  caption-side: bottom;
  text-indent: initial;
  border-spacing: 2px;
`;

const Tr = styled.tr`
  background-color: #fff;
  border: 1px solid #fff;
  color: #333742;
  text-align: center;
`;

const Th = styled.th`
  display: table-cell;
  font-weight: bold;
`;

const ThTxt = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
`;

const ThP = styled.p`
  margin-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const ThButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.5rem;
  border-radius: 0;
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col = styled.div`
  flex: 1 0;
`;

const Tbody = styled.tbody`
  text-align: center;
  border-spacing: 2px;
`;

const TrYellow = styled.tr`
  background-color: #ffc200;
  border: 10px solid #fff;
  height: 60px;
`;
const TrGray = styled.tr`
  background-color: #dddddd;
  border: 10px solid #fff;
  height: 60px;
`;
const SnapshotButton = styled.button`
  cursor: pointer;
  background: none;
  width: 50px;
  padding: 0.25rem;
  border-radius: 5px;
`;

const ButtonDiv = styled.div`
  flex: 1 0;
  display: flex;
  margin-top: 1.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
`;

const ConfirmButton = styled.button`
  background-color: #ffc200;
  border: none;
  width: 5vw;
  height: 5vh;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  color: #333742;
`;

const NoneData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

interface Filter {
  userId: number | null;
  gameId: number | null;
  status: String | null;
}

export default function TestManagePhaseOneList() {
  const [pageNum, setPageNum] = useState(1);
  const [testList, setTestList] = useState<any>([]); //api호출로 가져오는 값

  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = { userId: null, gameId: null, status: null };
    return savedFilter;
  });
  const [popupState, setPopupState] = useState({
    isOpenPopupTest: false,
    isOpenPopupUser: false,
    isOpenPopupImage: false,
    isOpenPopupConfirmed: false,
    isOpenApprovePopup: false,
    isOpenRejectPopup: false,
    isOpenDownloadPopup: false,
    isOpenBounceRateDownloadPopup: false,
    isOpenUserRegisterPopup: false,
  });

  let length: number = 0;
  ///api호출
  const { data, refetch } = useQuery(
    ["testPage1", pageNum],
    async () => {
      return await TesterActivityAPI(pageNum, filter);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        length = res?.data.page.content.length;
        // console.log(res);
        const checkbox_state_arr = new Array(length).fill(false);
        setOneCheckBoxChecked(checkbox_state_arr);
      }, //
      // enabled: false,
    }
  );

  useEffect(() => {
    if (data) {
      setTestList(data);
    }
    console.log(data);
  }, [data]);

  useEffect(() => {
    setPageNum(1); //필터링할 때 마다 페이지 1로 초기화
  }, [filter]);

  useEffect(() => {
    refetch();
  }, [filter, pageNum]);

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  //체크박스 상태
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState<boolean>(false);
  const [oneCheckBoxChecked, setOneCheckBoxChecked] = useState<boolean[]>([]);

  //전체 선택 체크박스 핸들러
  const handleAllCheckBox = () => {
    const newAllCheckBoxChecked = !allCheckBoxChecked;
    setAllCheckBoxChecked(newAllCheckBoxChecked);
    // console.log(allCheckBoxChecked);
    const checkHandlerArray = new Array(oneCheckBoxChecked.length).fill(
      newAllCheckBoxChecked
    );
    setOneCheckBoxChecked(checkHandlerArray);
  };

  //각 항목 체크박스 핸들러
  const handleOneCheckBox = (index: number) => {
    oneCheckBoxChecked[index] = !oneCheckBoxChecked[index];
    setOneCheckBoxChecked([...oneCheckBoxChecked]);
  };

  //체크박스 선택된 데이터
  const selectedArray = useMemo(() => {
    return (testList?.data?.page.content as Item[] | undefined)?.filter(
      (item, idx) => oneCheckBoxChecked[idx]
    );
  }, [oneCheckBoxChecked, testList]);
  const activityIdinArray = selectedArray?.map((item) => item.activityId);

  //첨부사진 관련 state와 handler
  const [currentIdx, setCurrentIdx] = useState<number>();
  const [snapShot, setSnapShot] = useState<String>("");
  const [userId, setUserId] = useState<String>();
  const openImagePopUp = (index: number) => {
    console.log(index);
    console.log(testList?.data?.page?.content[index]);
    if (testList && index >= 0) {
      setSnapShot(testList?.data?.page?.content[index].snapshotUrl);
      setUserId(testList?.data?.page?.content[index].activityId.toString());
      setPopupState({
        ...popupState,
        isOpenPopupImage: true,
      });
    }
  };

  useEffect(() => {
    if (currentIdx !== undefined && currentIdx !== null) {
      openImagePopUp(currentIdx);
    }
  }, [currentIdx]);

  // 다음 팝업으로 넘기기
  const shiftRight = () => {
    setCurrentIdx((prevIndex) => {
      if (prevIndex === undefined || prevIndex === null) {
        return 0;
      }
      return (prevIndex + 1) % (testList?.data?.page?.content.length || 1);
    });
  };

  // 이전 팝업으로 넘기기
  const shiftLeft = () => {
    setCurrentIdx((prevIndex) => {
      if (prevIndex === undefined || prevIndex === null) {
        return 0;
      }
      return prevIndex === 0
        ? (testList?.data?.page?.content.length || 1) - 1
        : prevIndex - 1;
    });
  };

  //승인&거절하기
  const onApproval = async (activityIds: number, count: number) => {
    const body = {
      activityIds: activityIds,
      count: count,
    };
    await TestApprovalAPI(body)
      .then(() => {
        setPopupState({
          ...popupState,
          isOpenApprovePopup: false,
        });
        refetch();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  const onRejection = async (
    activityIds: number,
    count: number,
    reason: string
  ) => {
    const body = {
      activityIds: activityIds,
      count: count,
      reason: reason,
    };
    await TestRejectAPI(body)
      .then(() => {
        setPopupState({
          ...popupState,
          isOpenRejectPopup: false,
        });
        refetch();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  return (
    <Wrapper>
      {popupState.isOpenPopupTest && (
        <TestPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupTest: false,
            })
          }
          setGame={(newGame: number) =>
            setFilter((prevState) => ({
              ...prevState,
              gameId: newGame,
            }))
          }
        />
      )}
      {popupState.isOpenPopupUser && (
        <UserPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupUser: false,
            })
          }
          setUser={(newUser: number) =>
            setFilter((prevState) => ({
              ...prevState,
              userId: newUser,
            }))
          }
        />
      )}
      {popupState.isOpenPopupImage && (
        <ImagePopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupImage: false,
            })
          }
          snapshotUrl={snapShot}
          id={userId}
          shiftLeft={shiftLeft}
          shiftRight={shiftRight}
        />
      )}
      {popupState.isOpenPopupConfirmed && (
        <ConfirmedPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupConfirmed: false,
            })
          }
          setValue={(newStatus: String) =>
            setFilter((prevState) => ({
              ...prevState,
              status: newStatus,
            }))
          }
        />
      )}
      {popupState.isOpenApprovePopup && (
        <ApproveModal
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenApprovePopup: false,
            })
          }
          callAPI={onApproval}
          selectArr={activityIdinArray}
        />
      )}
      {popupState.isOpenRejectPopup && (
        <RejectModal
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenRejectPopup: false,
            })
          }
          callAPI={onRejection}
          selectArr={activityIdinArray}
        />
      )}
      {popupState.isOpenDownloadPopup && (
        <GameDownload
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenDownloadPopup: false,
            })
          }
          search={filter.gameId}
          type={"game"}
          surveyCategory={"GAME_TEST"}
        />
      )}
      {popupState.isOpenBounceRateDownloadPopup && (
        <GameDownload
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenBounceRateDownloadPopup: false,
            })
          }
          search={filter.gameId}
          type={"game"}
          surveyCategory={"CBT_BREAKAWAY"}
        />
      )}
      {popupState.isOpenUserRegisterPopup && (
        <UserRegisterPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenUserRegisterPopup: false,
            })
          }
        />
      )}

      <Inner>
        <Header>
          <Title>게임 테스트 1단계 인증 목록</Title>
          {/* '전체 목록 보기' 버튼은 항상 뜨도록 */}
          <Button
            onClick={() => {
              setFilter({
                userId: null,
                gameId: null,
                status: null,
              });
            }}
          >
            전체 목록 보기
          </Button>
          {filter.gameId == null ? (
            <Button
              onClick={() => {
                setPopupState({
                  ...popupState,
                  isOpenUserRegisterPopup: true,
                });
              }}
            >
              게임 등록 명단
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  setPopupState({
                    ...popupState,
                    isOpenDownloadPopup: true,
                  });
                }}
              >
                설문 다운로드
              </Button>
              <Button
                onClick={() => {
                  setPopupState({
                    ...popupState,
                    isOpenBounceRateDownloadPopup: true,
                  });
                }}
              >
                이탈률 설문 다운로드
              </Button>
            </>
          )}
        </Header>

        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th>
                  <ThTxt>
                    {/*전체 선택 체크박스 컴포넌트 */}
                    <TestManageOneAllCheck
                      checked={allCheckBoxChecked}
                      onChange={handleAllCheckBox}
                    />
                    <ThP>전체 선택</ThP>
                  </ThTxt>
                </Th>
                <Th>제출 일시</Th>
                <Th>
                  <ThTxt
                    onClick={() => {
                      setPopupState({
                        ...popupState,
                        isOpenPopupTest: true,
                      });
                    }}
                  >
                    <div>테스트 게임</div>
                    <ThButton>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </ThButton>
                  </ThTxt>
                </Th>
                <Th>
                  <ThTxt
                    onClick={() => {
                      setPopupState({
                        ...popupState,
                        isOpenPopupUser: true,
                      });
                    }}
                  >
                    <div>유저</div>
                    <ThButton>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </ThButton>
                  </ThTxt>
                </Th>

                <Th>첨부사진</Th>

                <Th>제출단계</Th>
                <ThTxt
                  onClick={() => {
                    setPopupState({
                      ...popupState,
                      isOpenPopupConfirmed: true,
                    });
                  }}
                >
                  제출상태
                  <ThButton>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </ThButton>
                </ThTxt>
                <Th>인증상태</Th>
                <Th>반려 사유</Th>
                <Th />
              </Tr>
            </thead>
            <Tbody>
              {testList &&
                testList?.data?.page?.content.map(
                  (tester: any, index: number) => {
                    //상태가 승인일 때 밝은 회색으로
                    return tester.confirmed === false ? (
                      <TrYellow key={tester.activityId}>
                        <td>
                          <TestManageOneOnlyCheck
                            index={index}
                            checked={oneCheckBoxChecked}
                            onChange={() => handleOneCheckBox(index)}
                            disabled={tester.confirmed}
                          />
                        </td>
                        <td>
                          {tester.submittedAt &&
                            new Date(tester.submittedAt).toLocaleString()}
                        </td>
                        <td>{tester.game}</td>

                        <td>{tester.nickname}</td>
                        {tester.playTime === 0 ? (
                          <td>
                            <SnapshotButton
                              onClick={() => {
                                setCurrentIdx(index);
                              }}
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                            </SnapshotButton>
                          </td>
                        ) : (
                          <td>버닝비버</td>
                        )}

                        <td>
                          {tester.category === "PHASE_NONE"
                            ? "테스터 등록 가능"
                            : tester.category === "PHASE_0"
                            ? "테스터 등록"
                            : tester.category === "PHASE_1"
                            ? "1단계 완료"
                            : tester.category === "PHASE_2_TEMPORARY"
                            ? "2단계 진행중"
                            : tester.category === "PHASE_2"
                            ? "2단계 완료"
                            : tester.category === "PHASE_CHEERING"
                            ? "응원하기"
                            : tester.category === "PHASE_REVIEW"
                            ? "리뷰글"
                            : tester.category === "PHASE_COMPLETION"
                            ? "모든 테스트 완료"
                            : "코드 오류"}
                        </td>
                        <td>
                          {tester.status === "PENDING"
                            ? "대기"
                            : tester.status === "REJECTED"
                            ? "거절"
                            : tester.status === "APPROVED"
                            ? "승인"
                            : "심사중"}
                        </td>
                        <td>{tester.confirmed ? "완료" : "대기"}</td>
                        <td>{tester.rejectionMessage}</td>
                        <td />
                      </TrYellow>
                    ) : (
                      <TrGray key={tester.activityId}>
                        <td>
                          <TestManageOneOnlyCheck
                            index={index}
                            checked={oneCheckBoxChecked}
                            onChange={() => handleOneCheckBox(index)}
                            disabled={tester.confirmed}
                          />
                        </td>
                        <td>
                          {tester.submittedAt &&
                            new Date(tester.submittedAt).toLocaleString()}
                        </td>
                        <td>{tester.game}</td>

                        <td>{tester.nickname}</td>
                        {tester.playTime === 0 ? (
                          <td>
                            <SnapshotButton
                              onClick={() => {
                                setCurrentIdx(index);
                              }}
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                            </SnapshotButton>
                          </td>
                        ) : (
                          <td>버닝비버</td>
                        )}

                        <td>
                          {tester.category === "PHASE_NONE"
                            ? "테스터 등록 가능"
                            : tester.category === "PHASE_0"
                            ? "테스터 등록"
                            : tester.category === "PHASE_1"
                            ? "1단계 완료"
                            : tester.category === "PHASE_2_TEMPORARY"
                            ? "2단계 진행중"
                            : tester.category === "PHASE_2"
                            ? "2단계 완료"
                            : tester.category === "PHASE_CHEERING"
                            ? "응원하기"
                            : tester.category === "PHASE_REVIEW"
                            ? "리뷰글"
                            : tester.category === "PHASE_COMPLETION"
                            ? "모든 테스트 완료"
                            : "코드 오류"}
                        </td>
                        <td>
                          {tester.status === "PENDING"
                            ? "대기"
                            : tester.status === "REJECTED"
                            ? "거절"
                            : tester.status === "APPROVED"
                            ? "승인"
                            : "심사중"}
                        </td>
                        <td>{tester.confirmed ? "완료" : "대기"}</td>
                        <td>{tester.rejectionMessage}</td>
                        <td />
                      </TrGray>
                    );
                  }
                )}
            </Tbody>
          </Table>
          <BottomDiv>
            <Col />

            <Col>
              <PaginationWrapper>
                <Pagination
                  activePage={pageNum}
                  itemsCountPerPage={10}
                  totalItemsCount={
                    testList?.data?.page?.pageable.totalElements || 1
                  }
                  pageRangeDisplayed={5}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                />
              </PaginationWrapper>
              {testList?.data?.page?.count == 0 && (
                <NoneData>검색 결과 없음</NoneData>
              )}
            </Col>

            <Col>
              <ButtonDiv>
                <ConfirmButton
                  type="button"
                  className="okBtn-s"
                  onClick={() => {
                    setPopupState({
                      ...popupState,
                      isOpenApprovePopup: true,
                    });
                  }}
                >
                  인증하기
                </ConfirmButton>
                <ConfirmButton
                  style={{ marginLeft: "1rem" }}
                  type="button"
                  className="okBtn-s ms-3"
                  onClick={() => {
                    setPopupState({
                      ...popupState,
                      isOpenRejectPopup: true,
                    });
                  }}
                >
                  거절하기
                </ConfirmButton>
              </ButtonDiv>
            </Col>
          </BottomDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
