import styled from "styled-components";
import { Link } from "react-router-dom";
import { DayMissionGame } from "../types";

interface DayMissionGameGroupProps {
  items: DayMissionGame[];
}
interface DayMissionGameItemProps {
  item: DayMissionGame;
}

const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px 13px;
  flex-wrap: wrap;
`;

const ItemContainer = styled.div`
  width: 316px;
  border-radius: 15px;
  border: 1px solid #e3e3e3;
`;
const ItemImage = styled.div`
  width: 100%;
  height: 191px;
  & img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
  position: relative;
`;
const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 14px;
`;
const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemTitle = styled.div`
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
`;
const ItemSubTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #a8a8a8;
`;
const ItemButton = styled(Link)<{ disabled?: boolean }>`
  outline: 0;
  border: 0;
  border-radius: 34px;
  background-color: #2282e9;
  padding: 10px 23px 9px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  ${(props) =>
    props.disabled &&
    `
    background: #8E8E8E;
    user-select: none;
  `}
`;
const ItemBanner = styled.div<{ disabled?: boolean }>`
  padding: 11px 16px 12px;
  background-color: ${(props) => (props.disabled ? "#262626" : "#2282E9")};
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  & span {
    display: block;
    width: 50%;
  }
`;

const isValidDate = (startDate: Date, endDate: Date) => {
  const now = new Date();
  return now >= startDate && now <= endDate;
};

const DayMissionGameItem: React.FC<DayMissionGameItemProps> = ({ item }) => {
  return (
    <ItemContainer>
      <ItemImage>
        <img src={item.bannerUrl} alt={item.title + " 썸네일"} />
        <ItemBanner disabled={!item.ongoing}>
          <span>참여자 수 : {item.totRegistrations}명</span>
          <span>완료자 수 : {item.totCompletions}명</span>
        </ItemBanner>
      </ItemImage>
      <ItemContent>
        <ItemTitleContainer>
          <ItemTitle>{item.title}</ItemTitle>
          <ItemSubTitle>{item.subtitle}</ItemSubTitle>
        </ItemTitleContainer>
        <ItemButton disabled={!item.ongoing} to={`./${item.id}`}>
          관리
        </ItemButton>
      </ItemContent>
    </ItemContainer>
  );
};

const DayMissionGameGroup: React.FC<DayMissionGameGroupProps> = ({ items }) => {
  items.sort((a, b) => +b.ongoing - +a.ongoing);
  return (
    <GroupContainer>
      {items.map((item) => (
        <DayMissionGameItem key={item.id} item={item} />
      ))}
    </GroupContainer>
  );
};

export default DayMissionGameGroup;
