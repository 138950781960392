import instance from "../../../../../API/MainAPI";

export default function GamePageAPI(page: number, pageSize: number) {
  return instance.get("/v3/admins/games/managements", {
    params: {
      pageSize: pageSize,
      pageNumber: page - 1,
    },
  });
}
