export default function questCategory(quest: any) {
  if (quest === "ATTENDANCE") return "출석 룰렛 돌리기";
  else if (quest === "VOTES") return "투표하기";
  else if (quest === "IN_APP_GAME") return "인앱게임 점수 넘기기";
  else if (quest === "OBT") return "OBT 참여하기";
  else if (quest === "CBT") return "CBT 참여하기";
  else if (quest === "CHEERING") return "게임 응원글 작성하기";
  else if (quest === "REVIEW") return "게임 리뷰글 작성하기";
  else if (quest === "POINT_SHOP") return "포인트샵 구매하기";
  else if (quest === "INVITATION") return "친구 초대하기";
  else return "-";
}
