import instance from "API/MainAPI";

export default function DayMissionGameExcelAPI(dayMissionGameId: number) {
  return instance.get(
    `/v3/admins/dayMissions/activities/managements/download/${dayMissionGameId}`,
    {
      responseType: "arraybuffer",
    }
  );
}
