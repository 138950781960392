import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../../../common/function/formatDate";
import styled from "styled-components";
import ApproveModal from "../Modal/ApproveModal";
import RejectModal from "../Modal/RejectModal";
import TestApprovalAPI from "../Data/TestApprovalAPI";
import TestRejectAPI from "../Data/TestRejectAPI";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
  overflow: scroll;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  margin-bottom: 15px;
  font-size: 23px;
`;
const Survey = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;

  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;

  min-height: 500px;
  top: 14%;
  width: 500px;
`;
const Block = styled.div`
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CloseBtn = styled.div`
  background: none;
  border: none;
  text-transform: none;
  cursor: pointer;
  display: flex;
  margin-top: -0.2rem;
`;

const Txt = styled.span`
  display: inline;
`;
const BigTxt = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
const GrayH4 = styled.h4`
  color: #333742;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;

  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

const Contents = styled.div`
  padding-bottom: 20px;
  padding-left: 20px;
`;

function SurveyPopup(props: any) {
  console.log(props);
  return (
    <div>
      <Wrapper>
        <Survey>
          <Header>
            <Txt>설문 자세히 보기</Txt>
            <CloseBtn onClick={props.onClose}>X</CloseBtn>
          </Header>
          <hr />
          <Contents>
            <Block>
              <BigTxt>객관식</BigTxt>

              {props.multiple !== undefined &&
                props.multiple.map((item: any, index: number) => {
                  const filteredChoices = item.choices.filter(
                    (itemin: any) => itemin.archive === true
                  );
                  return (
                    <div key={index}>
                      <span>Q: </span>
                      <Txt>{item.content}</Txt>
                      <br />
                      <span>A: </span>
                      {filteredChoices.length > 0 ? (
                        filteredChoices.map(
                          (trueItems: any, trueindex: number) => (
                            <Txt key={trueindex}>{trueItems.content}</Txt>
                          )
                        )
                      ) : (
                        <Txt>답변 없음</Txt>
                      )}
                    </div>
                  );
                })}
            </Block>
            <Block>
              <BigTxt>주관식</BigTxt>
              {props.subjective !== undefined &&
                props.subjective.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <span>Q: </span>
                      <Txt>{item.content}</Txt>
                      <br />
                      <span>A: </span>
                      {!item.archiveResponse ? (
                        <Txt>답변없음</Txt>
                      ) : (
                        <Txt>{item.archiveResponse}</Txt>
                      )}
                    </div>
                  );
                })}
            </Block>
          </Contents>
        </Survey>
      </Wrapper>
    </div>
  );
}

export default SurveyPopup;
