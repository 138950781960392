import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import PushAllAPI from "../../../Push/PushSend/Data/PushAllAPI";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 200px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 400px;
`;

const Section = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  width: 65%;
`;

const SectionTitle = styled.div`
  font-weight: 700;
  margin-left: 0;
  display: flex;
  font-size: 18px;
  width: 90%;
`;

const SectionSpan = styled.span`
  font-weight: 700;
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
  color: gray;
  font-size: 6px;
`;

const ContentsHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 18px;
  font-weight: bold;
`;

const DescArea = styled.textarea`
  width: 120%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 10px;
  font-size: 15px;
`;

const Item = styled.div`
  margin: 20px 0;
`;

const ItemTitle = styled.h3`
  margin-right: 1.5rem;
  display: flex;
  font-size: 15px;
  color: #333742;
  font-weight: 600;
`;

const TitleInput = styled.input`
  width: 120%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 10px;
  font-size: 15px;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 100px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
`;

const CancelBtn = styled.button`
  width: 100px;
  padding: 3px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;
function AnnouncementNotiModal(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const { register, setValue } = useForm();

  useEffect(() => {
    setValue("title", props.title);
    setValue("message", props.message);
  }, []);

  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>
          게임뉴스 공지를 등록하시겠습니까?{" "}
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => props.close()}
            style={{ marginLeft: "1rem", cursor: "pointer" }}
          />
        </ContentsTxt>

        <Section>
          <ContentsHeader>
            <SectionTitle> 알림 메시지 입력</SectionTitle>
          </ContentsHeader>

          <Item>
            <ItemTitle>제목</ItemTitle>
            <div>
              <TitleInput type="text" {...register("title")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>내용</ItemTitle>
            <div>
              <DescArea rows={2} maxLength={250} {...register("message")} />
            </div>
          </Item>
        </Section>
        {!isLoading && (
          <DivBtn>
            <CancelBtn
              onClick={() => {
                props.close();
                props.CallAPI();
              }}
            >
              공지글 작성
            </CancelBtn>
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} />
            ) : (
              <OkBtn
                disabled={isLoading}
                onClick={() => {
                  props.close();
                  props.pushModal();
                }}
              >
                푸시 전송
              </OkBtn>
            )}
          </DivBtn>
        )}
      </ModalBox>
    </ModalBg>
  );
}

export default AnnouncementNotiModal;
