import { useContext } from "react";
import { ModalType, ModalsContext } from "./ModalsContext";

const useModal = () => {
  const { modalList, setModals } = useContext(ModalsContext);

  const checkModal = (
    // check if modal duplicate
    component: ModalType["component"],
    onlyLastCheck = false
  ) => {
    const modals = modalList.current;
    if (onlyLastCheck) {
      return modals.length > 0 ? modals[modals.length - 1].component : false;
    }
    return modals.some((m) => m.component === component);
  };

  const openModal = (
    component: ModalType["component"],
    duplicateCheck = false
  ) => {
    const modal: ModalType = {
      id: -1,
      component,
    };

    let duplicate = checkModal(modal.component, true);
    if (duplicateCheck) duplicate = checkModal(modal.component);
    if (duplicate) return;

    const modals = modalList.current;

    modal.id = (modals[modals.length - 1]?.id ?? -1) + 1;

    setModals([...modals, modal]);

    return modal.id;
  };

  const closeModal = (id: number) => {
    const modals = modalList.current;
    const newModalList = modals.filter((m) => m.id !== id);

    setModals(newModalList);
  };

  const resetModal = () => {
    setModals([]);
  };

  return {
    modals: modalList,
    modal: openModal,
    openModal,
    closeModal,
    checkModal,
    resetModal,
  };
};

export default useModal;
