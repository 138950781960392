import instance from "../../../../../API/MainAPI";

export default function UserAttendancePage(
  page: number,
  pageSize: number,
  id: number
) {
  return instance.get(`/v3/admins/attendances/${id}`, {
    params: {
      pageNumber: Number(Number(page) - 1),
      pageSize: pageSize,
    },
  });
}
