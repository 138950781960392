import { responsiveFontSizes } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import WordDetailAPI from "./Data/WordDetailAPI";
import WordModificationAPI from "./Data/WordModificationAPI";
import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TxtInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const NumInput = styled.input`
  width: 30vw;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const BlockItem = styled.div`
  display: block;
`;
const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function WordEdit() {
  const { register, handleSubmit, setValue, getValues, watch } = useForm();

  const [modalEdit, setModalEdit] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;

  const formData = new FormData();

  const { data: word } = useQuery(["word", id], () => WordDetailAPI(id));

  const now = new Date(new Date().toString().split("GMT")[0] + " UTC")
    .toISOString()
    .slice(0, 19);

  function onEdit() {
    if (true) {
      setModalEdit(true);
    } else {
    }
  }
  function closeEdit() {
    setModalEdit(false);
  }

  async function CallAPI() {
    const contentValue = getValues("content");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");

    const expiredAtValue = getValues("expiredAt");

    const visibleValue = getValues("visible");
    const fixedValue = getValues("fixed");

    formData.append("content", contentValue.toUpperCase());
    formData.append("rewards", rewardsValue);
    formData.append("startedAt", startedAtValue);
    formData.append("expiredAt", expiredAtValue);
    formData.append("visible", visibleValue);
    formData.append("fixed", fixedValue);

    await wordEditMutation.mutateAsync(formData);
  }

  const wordEditMutation = useMutation(
    (formData: FormData) => WordModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("단어 수정이 완료되었습니다.");

        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  function onAdd() {
    const contentValue = getValues("content");
    const rewardsValue = getValues("rewards");
    const startedAtValue = getValues("startedAt");

    const expiredAtValue = getValues("expiredAt");

    const visibleValue = getValues("visible");
    const fixedValue = getValues("fixed");
    if (
      contentValue &&
      rewardsValue &&
      startedAtValue &&
      expiredAtValue &&
      visibleValue.toString() &&
      fixedValue.toString()
    ) {
      onEdit();
    } else {
      alert("이미지, 어플 표시 여부는 필수입니다.");
    }
  }

  useEffect(() => {
    clearAll();
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await WordDetailAPI(id);
    if (response) {
      setValue("content", response?.data.content);
      setValue("rewards", response?.data.rewards);
      setValue("totCompletions", response?.data.totCompletions);
      setValue("startedAt", response?.data.startedAt);
      setValue("expiredAt", response?.data.expiredAt);
      setValue("visible", response?.data.visible);
      setValue("fixed", response?.data.fixed);
    }
  };

  const visibleValue = watch("visible");
  const fixedValue = watch("fixed");

  const handleVisibleChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  const handleFixedChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("fixed", newValue);
  };
  return (
    <Wrapper>
      {modalEdit && <EditModal CallAPI={CallAPI} close={closeEdit} />}
      <Inner>
        <Header>
          <Title>
            단어 수정
            <ItemSpan>
              수정 시 시작일, 종료일, 고정 여부는 초기화됩니다
            </ItemSpan>
          </Title>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>
              단어 내용<ItemSpan>대문자 영어단어로 바뀝니다.</ItemSpan>
            </ItemTitle>
            <div>
              <TxtInput type="text" {...register("content")} />
            </div>
          </Item>

          <Item>
            <ItemTitle>리워드</ItemTitle>
            <div>
              <NumInput type="number" {...register("rewards")} />
            </div>
          </Item>

          <Item>
            <ItemTitle>시작일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("startedAt")}
            />
          </Item>

          <Item>
            <ItemTitle>종료일</ItemTitle>
            <DateInput
              type="datetime-local"
              className={
                getValues("expiredAt") < getValues("startedAt")
                  ? "box-under-red w-25"
                  : "box-under w-25"
              }
              min={getValues("startedAt")}
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("expiredAt")}
            />
          </Item>

          <BlockItem>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input
                type="radio"
                value="true"
                checked={visibleValue === true}
                {...register("visible")}
                onChange={handleVisibleChange}
              />
              보이기
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                checked={visibleValue === false}
                {...register("visible")}
                onChange={handleVisibleChange}
              />
              숨기기
            </Label>
          </BlockItem>
          <BlockItem>
            <ItemTitle>고정 여부</ItemTitle>
            <Label>
              <input
                type="radio"
                value="true"
                checked={fixedValue === true}
                {...register("fixed")}
                onChange={handleFixedChange}
              />
              고정
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                checked={fixedValue === false}
                {...register("fixed")}
                onChange={handleFixedChange}
              />
              고정 해제
            </Label>
          </BlockItem>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={handleSubmit(onAdd)}>수정하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
