import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

interface ImageFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  previewSrc?: string[];
}

const ImageFieldContainer = styled.div`
  margin-top: 41px;
  display: flex;
  flex-direction: column;
  gap: 19px;
`;
const ImageFieldLabel = styled.label`
  font-weight: 600;
  font-size: 22px;
  span {
    display: block;
    margin-top: 6px;
    font-size: 16px;
    font-weight: 400;
    color: #898989;
  }
`;
const ImageFieldControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    display: none;
  }
`;
const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
`;
const Comment = styled.div``;
const Preview = styled.img`
  width: calc(calc(100% - 28px) / 3);
`;
const UploadButton = styled.div`
  border: 1px solid #dee2e6;
  padding: 6px 8px 4px;
  color: #5f5f5f;
  background-color: #f0f0f0;
  font-size: 20px;
  font-weight: 500;
  border-radius: 7px;
  cursor: pointer;
  height: fit-content;
`;
const PictureIconImg = styled.img``;

const MultiImageField = forwardRef<HTMLInputElement, ImageFieldProps>(
  ({ label, onChange, disabled, previewSrc, placeholder, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => inputRef.current
    );

    const [image, setImage] = useState<File[]>();
    const [preview, setPreview] = useState<string[]>();

    const handleClick = () => {
      inputRef.current?.click();
    };

    useEffect(() => {
      setPreview(previewSrc);
    }, [previewSrc]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (!inputRef.current?.files || !inputRef.current.files[0]) {
        setPreview(undefined);
        setImage(undefined);
        return;
      }
      if (inputRef.current.files.length > 5) {
        alert("게임 이미지는 최대 5장까지 추가 가능합니다.");
        return;
      }

      const files = Array.from(inputRef.current.files);

      const newPreview: string[] = [];

      const readFile = (idx: number) => {
        if (idx >= files.length) return;
        const reader = new FileReader();
        reader.onloadend = () => {
          newPreview.push(reader.result as string);
          setPreview([...newPreview]);
          readFile(idx + 1);
        };
        reader.readAsDataURL(files[idx]);
      };
      readFile(0);

      setImage(files);

      onChange?.(e);
    };
    return (
      <ImageFieldContainer>
        <ImageFieldControl aria-disabled={disabled}>
          <ImageFieldLabel>
            {label}
            <span>{placeholder}</span>
          </ImageFieldLabel>
          <UploadButton onClick={handleClick}>파일 선택</UploadButton>
          <input
            type="file"
            ref={inputRef}
            multiple
            accept="image/*"
            onChange={handleChange}
            disabled={disabled}
            {...props}
          />
        </ImageFieldControl>
        {!image && <Comment>*선택된 파일 없음</Comment>}
        <PreviewContainer>
          {preview?.map((preview, idx) => (
            <Preview key={idx} src={preview} />
          ))}
        </PreviewContainer>
      </ImageFieldContainer>
    );
  }
);

export default MultiImageField;
