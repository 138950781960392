import {
  Control,
  Controller,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { DayMissionGameRegisterForm } from "./useDayMissionGameRegisterForm";
import Form from "../shared/Form";
import styled from "styled-components";
import { useMemo, useState } from "react";

interface DayMissionRegister {
  control: Control<DayMissionGameRegisterForm>;
  register: UseFormRegister<DayMissionGameRegisterForm>;
}

const rewardOptions = [
  { value: "POINT", label: "포인트" },
  { value: "PLISTAR", label: "플리스타" },
  { value: "NONE", label: "없음" },
];

const orientationOptions = [
  { label: "가로", value: true },
  { label: "세로", value: false },
];

const FormInner = styled.div`
  display: grid;
  grid-template-columns: 438px 1fr;
  gap: 60px 30px;
`;

interface DaySelectorProps {
  dayNumber: number;
  onChange: (dayNumber: number) => unknown;
  maxDayNumber: number;
  append: Function;
}

const DayContainer = styled.div`
  grid-column: span 2;
  display: flex;
  gap: 12px;
`;

const Day = styled.div<{ $isActive?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  background-color: #e3e3e3;
  color: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.$isActive &&
    `
  background-color: #2282E9;
  color: white;
  `}
  border: 0;
  cursor: pointer;
`;

const DaySelector = ({
  dayNumber,
  onChange,
  maxDayNumber,
  append,
}: DaySelectorProps) => {
  const dayList = useMemo(
    () => Array.from({ length: maxDayNumber }, (_, idx) => idx + 1),
    [maxDayNumber]
  );

  return (
    <DayContainer>
      {dayList.map((day) => (
        <Day
          onClick={() => onChange(day)}
          $isActive={day == dayNumber}
          key={day}
        >
          {day}
        </Day>
      ))}
      <Day onClick={(e) => append()}>+</Day>
    </DayContainer>
  );
};

const DayMissionRegister = ({ control, register }: DayMissionRegister) => {
  const { fields, append, update } = useFieldArray({
    control,
    name: "dayMissionRecords",
  });
  const appendDayMission = () => {
    const last = fields[fields.length - 1];
    console.log(last);
    append({
      dayNumber: last.dayNumber + 1,
      exp: last.exp,
      rewards: last.rewards,
      rewardCategory: last.rewardCategory,
      title: "",
      missionImageHorizontal: last.missionImageHorizontal,
    });
    setDayNumber(last.dayNumber + 1);
  };
  const [dayNumber, setDayNumber] = useState(1);
  return (
    <>
      {fields.map((field, index) => (
        <FormInner
          key={field.id}
          style={{
            display: dayNumber == field.dayNumber ? "grid" : "none",
          }}
        >
          <Form.ImageField
            label="미션 인증 예시 사진"
            {...register(`dayMissionRecords.${index}.missionImage`)}
          />
          <Form.FormGroup>
            <DaySelector
              dayNumber={dayNumber}
              onChange={setDayNumber}
              maxDayNumber={fields.length}
              append={appendDayMission}
            />
            <Form.TextArea
              label="플레이 미션 조건"
              placeholder="플레이 미션 조건을 상세히 작성해주세요."
              {...register(`dayMissionRecords.${index}.title`)}
            />
            <Form.RadioGroup
              label="예시 이미지 수평 방향"
              options={orientationOptions}
              {...register(`dayMissionRecords.${index}.missionImageHorizontal`)}
            />

            <Controller
              control={control}
              name={`dayMissionRecords.${index}.rewardCategory`}
              render={({ field: { value, onChange, ref } }) => (
                <Form.Select
                  label="지급 재화"
                  placeholder="재화를 선택하세요."
                  options={rewardOptions}
                  onChange={(option) => onChange(option.value)}
                  ref={ref}
                  value={value}
                />
              )}
            />
            <Form.TextField
              containerStyle={{
                marginTop: "auto",
              }}
              placeholder="숫자만 입력 가능합니다."
              type="number"
              suffix="포인트"
              {...register(`dayMissionRecords.${index}.rewards`)}
            />
            <Form.TextField
              label="경험치"
              containerStyle={{ gridColumn: "span 2" }}
              placeholder="숫자만 입력 가능합니다."
              type="number"
              {...register(`dayMissionRecords.${index}.exp`)}
            />
            <input
              type="hidden"
              {...register(`dayMissionRecords.${index}.dayNumber`)}
            />
          </Form.FormGroup>
        </FormInner>
      ))}
    </>
  );
};

export default DayMissionRegister;
