import instance from "../../../../../API/MainAPI";

export default function SupporterListAPI(
  grade: string,
  pageNumber: number,
  pageSize: number
) {
  if (grade === "ALL") {
    return instance.get("/v3/admins/users/supporters", {
      params: {
        pageNumber: pageNumber - 1,
        pageSize: pageSize,
      },
    });
  } else {
    return instance.get("/v3/admins/users/supporters", {
      params: {
        grade: grade,
        pageNumber: pageNumber - 1,
        pageSize: pageSize,
      },
    });
  }
}
