import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { clearUserRegister } from "../../../common/clearStorage";
import { useQuery } from "react-query";
import styled from "styled-components";

import UserRegisterPageAPI from "./Data/UserRegisterPageAPI";
import { Link, useLocation } from "react-router-dom";
import UserPopup from "./Popup/UserPopup";
const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Button = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const Table = styled.table`
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  caption-side: bottom;
  text-indent: initial;
  border-spacing: 2px;
`;

const Tr = styled.tr`
  background-color: #fff;
  border: 1px solid #fff;
  color: #333742;
  text-align: center;
`;

const Th = styled.th`
  display: table-cell;
  font-weight: bold;
`;

const ThTxt = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col = styled.div`
  flex: 1 0;
`;

const Tbody = styled.tbody`
  text-align: center;
  border-spacing: 2px;
`;

const TrYellow = styled.tr`
  background-color: #ffc200;
  border: 10px solid #fff;
  height: 60px;
`;

const NoneData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function UserRegisterPage() {
  const pageNum = sessionStorage.getItem("userRegisterPage") || 1;
  const location = useLocation();
  const id = location.state.id;
  const category = location.state.category;

  const [state, setState] = useState({
    page: pageNum,
    testers: [],
    total: 0,
    id: 0,
    isLoading: true,
    popup: false,
  });

  const onPopup = () => {
    setState({ ...state, popup: true });
  };

  const closePopup = () => {
    setState({ ...state, popup: false });
  };

  const callAPI = async () => {
    const {
      data: {
        page: {
          content,
          pageable: { pageNumber, totalElements },
        },
      },
    } = await UserRegisterPageAPI(id, Number(pageNum));

    setState((prevState) => ({
      ...prevState,
      testers: content,
      page: pageNumber + 1,
      total: totalElements,
    }));

    sessionStorage.setItem("userRegisterPage", pageNumber + 1);
  };

  const { data: userList, refetch } = useQuery(
    ["userRegisterPage", pageNum],
    () => UserRegisterPageAPI(id, Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("userRegisterPage", pageNumber.toString());
    refetch();
  };

  let count =
    userList?.data?.page.pageable.totalElements - 10 * (Number(pageNum) - 1);

  const checkEmpty = userList?.data?.page.pageable.totalElements;

  useEffect(() => {
    clearUserRegister();
  }, []);

  return (
    <Wrapper>
      {state.popup && <UserPopup onClose={closePopup} gameId={id} />}
      <Inner>
        <Header>
          <Title>등록 유저 목록</Title>
          <Link to="/test/phaseOne">
            {" "}
            <Button
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  sGame: "",
                  sUser: "",
                  showSearch: false,
                  page: 1,
                }));
              }}
            >
              게임 목록 보기
            </Button>
          </Link>

          {category === "CBT" && <Button onClick={onPopup}>유저 등록</Button>}
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th />
                <Th>No.</Th>
                <Th>
                  <ThTxt>
                    <div>테스트 게임</div>
                  </ThTxt>
                </Th>
                <Th>
                  <ThTxt>유저</ThTxt>
                </Th>
                <Th>핸드폰</Th>
                <Th />
              </Tr>
            </thead>
            <Tbody>
              {userList &&
                userList?.data?.page.content.map((tester: any) => {
                  count = count - 1;
                  return (
                    <TrYellow key={tester.activityId}>
                      {" "}
                      <td />
                      <td>{count}</td>
                      <td>{tester.game}</td>
                      <td>{tester.nickname}</td>
                      <td>{tester.phone}</td>
                      <td />
                    </TrYellow>
                  );
                })}
            </Tbody>
          </Table>
          <BottomDiv>
            <Col>
              {checkEmpty == 0 ? (
                <NoneData>검색 결과 없음</NoneData>
              ) : (
                <PaginationWrapper>
                  <Pagination
                    activePage={Number(pageNum)}
                    itemsCountPerPage={10}
                    totalItemsCount={
                      userList?.data?.page?.pageable.totalElements || 1
                    }
                    pageRangeDisplayed={5}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                  />
                </PaginationWrapper>
              )}
            </Col>
          </BottomDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
