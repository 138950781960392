import instance from "../../../../../API/MainAPI";

export default function NotificationPage(page: any) {
  return instance.get("/v1/admins/notifications/managements", {
    params: {
      pageSize: 10,
      pageNumber: page - 1,
    },
  });
}
