import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { clearSupporter } from "../../../common/clearStorage";
import SupporterListAPI from "./Data/SupporterListAPI";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import index from "../../../common/function";
import AddModal from "../SupporterAdd/Modal/AddModal";
import RemoveModal from "../SupporterRemove/Modal/RemoveModal";
import SupporterSearch from "./Data/SupporterSearch";
import SettingModal from "../Modal/SettingModal";
import DownloadModal from "../Modal/DownloadModal";

interface SupporterRes {
  count: number;
  page: Page;
}
interface Page {
  content: Content[];
  pageable: pageable;
}
interface Content {
  id: number;
  nickname: string;
  phone: number;
  points: number;
  plistar: number;
  exp: number;
  signedUpAt: string;
  allowedNotifications: boolean;
  releaseApprovedCount: number;
  cbtApprovedCount: number;
  promotedAt: string | null;
  demotedAt: string | null;
}
interface pageable {
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  first: boolean;
  last: boolean;
}
interface ModalState {
  isAddModalOpen: boolean;
  isRemoveModalOpen: boolean;
  isSettingModalOpen: boolean;
  isDownloadModalOpen: boolean;
}
const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const TopButton = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  margin-right: -3rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const TopBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Search = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SearchStyle = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: end;
  font-size: large;
`;

const SearchInput = styled.input`
  margin: 0 1rem;
  border-radius: 10px;
  border: 1px solid;
  width: 300px; /* 크기 조절 */
  height: 40px; /* 높이 추가 */
  font-size: large;
`;

const SearchIcon = styled.div`
  height: auto;
`;

const SearchButton = styled.button`
  display: flex;
  justify-content: start;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: large;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const SelectOpt = styled.option`
  min-height: 23px;
  min-width: 86px;
  text-align: center;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
`;
const SelectBox = styled.select`
  font-size: 16px;
  min-width: 123px;
  min-height: 41px;
  border-radius: 11px;
  font-weight: bold;
`;
const Td = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  background-color: #ffc20029;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;
const Tdgray = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  background-color: #f3f3f3;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;
const TdYellow = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  background-color: #ffc200;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;
const NoneData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const UpgrageBtn = styled.button`
  background-color: #ffc200;
  border: none;
  width: 5vw;
  height: 5vh;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  color: #000000;
`;

const DowngradeBtn = styled.button`
  background-color: #cecece;
  border: none;
  width: 5vw;
  height: 5vh;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  color: #000000;
`;

const SupporterList: React.FC = () => {
  const pageNum = sessionStorage.getItem("supporterpage") || "1";
  const { handleSubmit, register, setValue, getValues } = useForm();
  const [searchResults, setSearchResults] = useState<Content[] | null>();
  const [option, setOption] = useState<string>("ALL");
  const [totalData, setTotalData] = useState<SupporterRes>();

  const [modalState, setModalState] = useState<ModalState>({
    isAddModalOpen: false,
    isRemoveModalOpen: false,
    isSettingModalOpen: false,
    isDownloadModalOpen: false,
  });
  const { data, isLoading, refetch } = useQuery(
    ["supporters", pageNum],
    async () => {
      return await SupporterListAPI(option, Number(pageNum), 10);
    },
    {
      onSuccess: (res) => {
        console.log(res);
        setTotalData(res?.data);
      },
    }
  );

  const onSubmit = async (data: any) => {
    if (getValues("search") === "") {
      setSearchResults(null);
      refetch();
    } else {
      try {
        console.log(data.search);
        const response = await SupporterSearch(data.search);
        console.log(response);
        const newSearchResults = response.data.contents;
        setValue("search", "");

        setSearchResults(newSearchResults);
      } catch (e) {
        alert("다시 시도하세요");
        window.location.reload();
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("supporterpage", pageNumber.toString());
    refetch();
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Member") {
      setOption("MEMBER");
    } else if (e.target.value === "Supporters") {
      setOption("SUPPORTERS");
    } else if (e.target.value === "ALL") {
      setOption("ALL");
    }
  };
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedName, setSelectedName] = useState<string>("");
  useEffect(() => {
    clearSupporter();
  }, []);

  useEffect(() => {
    refetch();
  }, [option]);
  let count = 0;
  return (
    <Wrapper>
      {modalState.isAddModalOpen && (
        <AddModal
          id={selectedId}
          name={selectedName}
          onClose={() => {
            setModalState({
              ...modalState,
              isAddModalOpen: false,
            });
          }}
        />
      )}
      {modalState.isRemoveModalOpen && (
        <RemoveModal
          id={selectedId}
          name={selectedName}
          onClose={() => {
            setModalState({
              ...modalState,
              isRemoveModalOpen: false,
            });
          }}
        />
      )}
      {modalState.isSettingModalOpen && (
        <SettingModal
          onClose={() => {
            setModalState({
              ...modalState,
              isSettingModalOpen: false,
            });
          }}
        />
      )}
      {modalState.isDownloadModalOpen && (
        <DownloadModal
          onClose={() => {
            setModalState({
              ...modalState,
              isDownloadModalOpen: false,
            });
          }}
          grade={option}
        />
      )}
      <Inner>
        <Header>
          <Title>서포터즈 목록</Title>
          <TopButton
            onClick={() => {
              setModalState({ ...modalState, isSettingModalOpen: true });
            }}
          >
            서포터즈 설정
          </TopButton>
          <TopButton
            onClick={() => {
              setModalState({ ...modalState, isDownloadModalOpen: true });
            }}
          >
            엑셀 다운로드
          </TopButton>
        </Header>

        <Contents>
          <TopBox>
            <Search>
              <SearchStyle>검색</SearchStyle>
              <SearchInput
                type="text"
                placeholder="닉네임이나 전화번호를 입력하세요"
                {...register("search")}
                onKeyDown={handleKeyDown}
              />
              <SearchIcon>
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} className="col fa-spin" />
                ) : (
                  <SearchButton onClick={handleSubmit(onSubmit)}>
                    <FontAwesomeIcon icon={faSearch} />
                  </SearchButton>
                )}
              </SearchIcon>
            </Search>
            <SelectBox onChange={handleSelectChange}>
              <SelectOpt selected={option === "ALL"} value={"ALL"}>
                전체
              </SelectOpt>
              <SelectOpt selected={option === "MEMBER"} value={"Member"}>
                일반 유저
              </SelectOpt>
              <SelectOpt
                selected={option === "SUPPORTERS"}
                value={"Supporters"}
              >
                서포터즈
              </SelectOpt>
            </SelectBox>
          </TopBox>
          <Table>
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>닉네임</Th>
                <Th>전화번호</Th>
                <Th>보유 포인트</Th>
                <Th>테스트 참여 수(OBT)</Th>
                <Th>테스트 참여 수(CBT)</Th>
                <Th>서포터즈 지정 날짜</Th>
                <Th>서포터즈 해제 날짜</Th>
                <Th>자격 조건</Th>
                <Th>지정</Th>
              </Tr>
            </Thead>
            <tbody>
              {totalData &&
                (searchResults || totalData?.page?.content)?.map(
                  (member: any, idx: number) => {
                    count = count + 1;
                    if (member.grade === "MEMBER") {
                      return member.releaseApprovedCount >= 10 ||
                        member.cbtApprovedCount >= 3 ? (
                        <Tr key={member.id}>
                          <Td>
                            {index(
                              totalData?.page?.pageable.totalElements as number,
                              (totalData?.page?.pageable.pageNumber as number) +
                                1,
                              totalData?.page?.pageable.pageSize as number,
                              count
                            )}
                          </Td>
                          <Td>{member.nickname}</Td>
                          <Td>{member.phone}</Td>
                          <Td>{member.points}</Td>
                          <Td>{member.releaseApprovedCount}</Td>
                          <Td>{member.cbtApprovedCount}</Td>
                          {member.promotedAt !== null ? (
                            <Td>
                              {new Date(member.promotedAt).toLocaleString()}
                            </Td>
                          ) : (
                            <Td>-</Td>
                          )}
                          {member.demotedAt !== null ? (
                            <Td>
                              {new Date(member.demotedAt).toLocaleString()}
                            </Td>
                          ) : (
                            <Td>-</Td>
                          )}
                          <Td>자격 기준 충족</Td>
                          <Td>
                            <UpgrageBtn
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  isAddModalOpen: true,
                                });
                                setSelectedId(member.id);
                                setSelectedName(member.nickname);
                              }}
                            >
                              지정
                            </UpgrageBtn>
                          </Td>
                        </Tr>
                      ) : (
                        <Tr>
                          <Tdgray>
                            {index(
                              totalData?.page?.pageable.totalElements as number,
                              (totalData?.page?.pageable.pageNumber as number) +
                                1,
                              totalData?.page?.pageable.pageSize as number,

                              count
                            )}
                          </Tdgray>
                          <Tdgray>{member.nickname}</Tdgray>
                          <Tdgray>{member.phone}</Tdgray>
                          <Tdgray>{member.points}</Tdgray>
                          <Tdgray>{member.releaseApprovedCount}</Tdgray>
                          <Tdgray>{member.cbtApprovedCount}</Tdgray>
                          {member.promotedAt !== null ? (
                            <Tdgray>
                              {new Date(member.promotedAt).toLocaleString()}
                            </Tdgray>
                          ) : (
                            <Tdgray>-</Tdgray>
                          )}
                          {member.demotedAt !== null ? (
                            <Tdgray>
                              {new Date(member.demotedAt).toLocaleString()}
                            </Tdgray>
                          ) : (
                            <Tdgray>-</Tdgray>
                          )}
                          <Tdgray>자격 기준 미달</Tdgray>
                          <Tdgray>
                            <UpgrageBtn
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  isAddModalOpen: true,
                                });
                                setSelectedId(member.id);
                                setSelectedName(member.nickname);
                              }}
                            >
                              지정
                            </UpgrageBtn>
                          </Tdgray>
                        </Tr>
                      );
                    } else if (member.grade === "SUPPORTERS") {
                      return (
                        <Tr>
                          <TdYellow>
                            {index(
                              totalData?.page?.pageable.totalElements as number,
                              (totalData?.page?.pageable.pageNumber as number) +
                                1,
                              totalData?.page?.pageable.pageSize as number,
                              count
                            )}
                          </TdYellow>
                          <TdYellow>{member.nickname}</TdYellow>
                          <TdYellow>{member.phone}</TdYellow>
                          <TdYellow>{member.points}</TdYellow>
                          <TdYellow>{member.releaseApprovedCount}</TdYellow>
                          <TdYellow>{member.cbtApprovedCount}</TdYellow>
                          {member.promotedAt !== null ? (
                            <TdYellow>
                              {new Date(member.promotedAt).toLocaleString()}
                            </TdYellow>
                          ) : (
                            <TdYellow>-</TdYellow>
                          )}
                          {member.demotedAt !== null ? (
                            <TdYellow>
                              {new Date(member.demotedAt).toLocaleString()}
                            </TdYellow>
                          ) : (
                            <TdYellow>-</TdYellow>
                          )}
                          {member.releaseApprovedCount >= 10 ||
                          member.cbtApprovedCount >= 3 ? (
                            <TdYellow>자격 기준 충족</TdYellow>
                          ) : (
                            <TdYellow>자격 기준 미달</TdYellow>
                          )}
                          <TdYellow>
                            <DowngradeBtn
                              onClick={() => {
                                setModalState({
                                  ...modalState,
                                  isRemoveModalOpen: true,
                                });
                                setSelectedId(member.id);
                                setSelectedName(member.nickname);
                              }}
                            >
                              강등
                            </DowngradeBtn>
                          </TdYellow>
                        </Tr>
                      );
                    }
                  }
                )}
            </tbody>
          </Table>
        </Contents>
        <PaginationWrapper>
          <Pagination
            activePage={Number(pageNum)}
            itemsCountPerPage={10}
            totalItemsCount={totalData?.page?.pageable?.totalElements || 1}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            onChange={handlePageChange}
          />
        </PaginationWrapper>
        {totalData?.page?.content.length == 0 && (
          <NoneData>검색 결과 없음</NoneData>
        )}
      </Inner>
    </Wrapper>
  );
};

export default SupporterList;
