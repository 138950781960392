import { ModalType } from "components/common/Modal/ModalsContext";
import useModal from "components/common/Modal/useModal";
import ModalCard from "components/common/Modal/ModalCard";
import styled from "styled-components";

type Props = {
  modal: ModalType;
  handleApprove: () => void;
  isLoading: boolean;
};

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 380px;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 50px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
  cursor: pointer;
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
`;

const CancelBtn = styled.button`
  width: 50px;
  padding: 5px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

const ApproveModal = ({ modal, handleApprove, isLoading }: Props) => {
  const { closeModal } = useModal();
  const close = () => closeModal(modal.id);
  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>인증을 승인하시겠습니까?</ContentsTxt>
        <div></div>
        <DivBtn>
          <CancelBtn className="cancelBtn" onClick={close}>
            취소
          </CancelBtn>
          <OkBtn
            className={isLoading ? "ok-disable ms-2" : "okBtn ms-2"}
            disabled={isLoading}
            onClick={handleApprove}
          >
            확인
          </OkBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
};

export default ApproveModal;
