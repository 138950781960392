import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import UserSearch from "../../User/UserList/Data/userSearchAPI";

import styled from "styled-components";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import PlistarSendAPI from "./Data/PlistarSendAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const Section = styled.div`
  margin-top: 1rem;
  width: 65%;
`;

const ContentsHeader = styled.div`
  display: flex;
`;

const SectionTitle = styled.div`
  font-weight: 700;
  margin-left: 0;
  display: flex;
  font-size: 20px;
`;

const SectionInfo = styled.div`
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
  font-size: 20px;
`;

const SectionSpan = styled.span`
  font-weight: 700;
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
  color: gray;
  font-size: 10px;
`;

const Item = styled.div`
  margin: 20px 0;
`;

const ItemTitle = styled.h3`
  margin-right: 1.5rem;
  display: flex;
  font-size: 15px;
  color: #333742;
  font-weight: 600;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  align-items: center;
  display: flex;
  color: gray;
  font-size: 10px;
`;

const SearchHeader = styled.div`
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 70%;
`;

const SearchInput = styled.input`
  width: 30%;
  flex: 1 0;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 10px;
  font-size: 15px;
`;

const SearchIcon = styled.div`
  display: flex;
  flex: 1 0;
`;

const SearchButton = styled.div`
  cursor: pointer;
  padding-top: 0.25rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  background: none;
  border: none;
  padding: 0;
`;

const UserResults = styled.div`
  display: flex;
  overflow: scroll;
  margin: 10px 0;
`;

const User = styled.div`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin: 10px 10px 10px 0;
  padding: 5px;
  width: max-content;
  cursor: pointer;
`;

const TitleInput = styled.input`
  width: 100%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 10px;
  font-size: 15px;
`;

const DescArea = styled.textarea`
  width: 100%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 10px;
  font-size: 15px;
`;

const PlistarInput = styled.input`
  width: 60%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 10px;
  font-size: 15px;
`;

const ButtonDiv = styled.div`
  margin-top: 0.5rem;
  justify-content: flex-end;
  display: flex;
`;

const NotificationBtn = styled.button`
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 100px;
`;
export default function PlistarSend() {
  type IdsList = { id: number };
  const { register, getValues, setValue, handleSubmit } = useForm();

  const [ids, setIds] = useState([]);

  const [userLists, setUserList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<IdsList[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [modal, setModal] = useState(false);
  function close() {
    setModal(false);
  }

  function onClick() {
    if (
      ids &&
      getValues("title") &&
      getValues("message") &&
      getValues("rewards")
    ) {
      if (getValues("rewards") < -100000) {
        alert("플리스타 포인트는 -100000보다 작은 수를 입력할 수 없습니다.");
      } else {
        setModal(true);
      }
    } else {
      alert("모든 항목을 입력해주세요.");
    }
  }

  function callAll() {
    const input = "";
    UserSearch(input)
      .then((r) => {
        setSelectedList(r.data.contents);
      })
      .catch(() => {
        alert("다시 시도하세요");
        window.location.reload();
      });
  }

  useEffect(() => {
    callAll();
  }, []);

  const onSubmitSearch = async (data: any) => {
    try {
      const response = await UserSearch(data.search);

      const newSearchResults = response.data.contents;
      setValue("search", "");

      setUserList(newSearchResults);
    } catch (e) {
      alert("다시 시도하세요");
      window.location.reload();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmitSearch)();
    }
  };

  function clickUser(id: any) {
    let set = new Set(selectedUsers);

    if (set.has(id as never)) {
      set.delete(id as never);
      let temp = Array.from(set);
      setSelectedUsers(temp);
    } else {
      if (selectedUsers.length < 101) {
        let temp = selectedUsers.concat(id);
        setSelectedUsers(temp);
      } else {
        alert("100명을 초과할 수 없습니다!");
      }
    }
  }

  async function CallAPI() {
    const idList = selectedUsers.map((item) => item.id);
    const requestData = {
      ids: idList,
      title: getValues("title"),
      message: getValues("message"),
      rewards: getValues("rewards"),
    };
    await PlistarSendMutation.mutateAsync(requestData);
  }

  const PlistarSendMutation = useMutation(
    (requestData: any) => PlistarSendAPI(requestData),
    {
      onSuccess: () => {
        setIsLoading(false);
        alert("플리스타 포인트를 적립했습니다.");
        setModal(false);
        window.location.reload();
      },
      onError: () => {
        setIsLoading(false);
        alert("플리스타 포인트를 지급하지 못했습니다. 다시 시도하세요.");
        setModal(false);
      },
    }
  );

  return (
    <Wrapper>
      {modal && <Modal close={close} CallAPI={CallAPI} isLoading={isLoading} />}
      <Inner>
        <Header>
          <Title>플리스타 지급</Title>
        </Header>
        <Contents>
          <Section>
            <SectionTitle>유저 검색</SectionTitle>
            <SearchHeader>
              <SearchInput
                type="text"
                placeholder="닉네임이나 전화번호를 입력하세요"
                onKeyDown={handleKeyDown}
                {...register("search")}
              />

              {
                <SearchButton onClick={handleSubmit(onSubmitSearch)}>
                  <SearchIcon>
                    <FontAwesomeIcon icon={faSearch} />
                  </SearchIcon>
                </SearchButton>
              }
            </SearchHeader>
            <UserResults>
              {userLists &&
                userLists.map((user: any) => {
                  let set = new Set(selectedUsers);

                  return (
                    <div key={user.id} onClick={() => clickUser(user)}>
                      {!set.has(user as never) && <User>{user.nickname}</User>}
                    </div>
                  );
                })}
            </UserResults>
          </Section>

          <Section>
            <ContentsHeader>
              <SectionTitle>선택한 유저</SectionTitle>
              <SectionInfo>{selectedUsers.length + "/100"}</SectionInfo>
            </ContentsHeader>

            <UserResults>
              {selectedUsers.length !== 0 &&
                selectedUsers.map((user: any) => {
                  return (
                    <div key={user.nickname} onClick={() => clickUser(user)}>
                      <User>{user.nickname}</User>
                    </div>
                  );
                })}{" "}
            </UserResults>
          </Section>

          <Section>
            <ContentsHeader>
              <SectionTitle> 알림 메시지 입력</SectionTitle>

              <SectionSpan>
                *플리스타 지급 시 보낼 알림 내용을 입력해주세요
              </SectionSpan>
            </ContentsHeader>

            <Item>
              <ItemTitle>제목</ItemTitle>
              <div>
                <TitleInput type="text" {...register("title")} />
              </div>
            </Item>
            <Item>
              <ItemTitle>내용</ItemTitle>
              <div>
                <DescArea rows={2} maxLength={250} {...register("message")} />
              </div>
            </Item>

            <Item>
              <ContentsHeader>
                <ItemTitle style={{ marginRight: "0" }}>플리스타</ItemTitle>
              </ContentsHeader>

              <PlistarInput type="number" min="-100" {...register("rewards")} />
            </Item>
          </Section>

          <ButtonDiv>
            <NotificationBtn onClick={onClick}>플리스타 지급</NotificationBtn>
          </ButtonDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
