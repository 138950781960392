import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Red = styled.div`
  color: #ff0202;
`;
const Blue = styled.div`
  color: #0222ff;
`;
const DataContainer = styled.div`
  margin-right: 20px;
`;

export default function Variation(num: string) {
  const res = parseFloat(num);
  if (res > 0) {
    return (
      <Red>
        <DataContainer>
          (<FontAwesomeIcon icon={faArrowUp} /> {num})
        </DataContainer>
      </Red>
    );
  } else if (res === 0) {
    return <DataContainer>( - )</DataContainer>;
  } else if (res < 0) {
    return (
      <Blue>
        <DataContainer>
          (<FontAwesomeIcon icon={faArrowDown} />
          {Math.abs(res)})
        </DataContainer>
      </Blue>
    );
  }
}
