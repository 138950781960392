import { useMutation } from "react-query";
import { BountyStatus } from "../types";
import { useState } from "react";
import approveDayMission from "./Data/DayMissionApproval";
import rejectDayMission from "./Data/DayMissioinRejection";

const useBountyHunterMutation = () => {
  const { mutate: approve, isLoading: approveLoading } = useMutation(
    approveDayMission,
    {
      onError: (err) => {
        console.log(err);
        alert("다시 시도하세요.");
        window.location.reload();
      },
      onSuccess: () => {
        alert("승인을 완료했습니다.");
        window.location.reload();
      },
    }
  );
  const { mutate: reject, isLoading: rejectLoading } = useMutation(
    rejectDayMission,
    {
      onError: () => {
        alert("다시 시도하세요.");
        window.location.reload();
      },
      onSuccess: () => {
        alert("거절을 완료했습니다.");
        window.location.reload();
      },
    }
  );

  return { approve, approveLoading, reject, rejectLoading };
};

export default useBountyHunterMutation;
