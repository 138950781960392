import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

type Option = {
  value?: any;
  label: string;
};
interface RadioGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  options: Option[];
  onValueChange?: (value: any) => void;
  value?: any;
  containerStyle?: React.CSSProperties;
}

const RadioGroupContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-width: 320px;
  /* width: calc(calc(100% - 53px) / 2); */
`;

const OptionList = styled.div`
  display: flex;
  gap: 94px;
`;

const RadioGroupLabel = styled.label`
  font-weight: 500;
  font-size: 22px;
`;
const Option = styled.div`
  label {
    color: #575757;
  }
  input {
    accent-color: #2282e9;
  }
`;

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      label,
      name,
      options,
      onValueChange,
      onChange,
      value: v,
      containerStyle,
      children,
      ...props
    },
    ref
  ) => {
    const optionsMemo = useMemo(() => {
      return options.map((option) => ({
        ...option,
        id: name + option.label,
      }));
    }, [options]);

    return (
      <RadioGroupContainer style={containerStyle}>
        <RadioGroupLabel>{label}</RadioGroupLabel>
        <OptionList>
          {optionsMemo.map(({ id, value, label }) => (
            <Option key={id}>
              <input
                type="radio"
                name={name}
                value={value}
                id={id}
                ref={ref}
                {...props}
                {...(onValueChange && {
                  onChange: (e) => {
                    onChange?.(e);
                    onValueChange?.(value);
                  },
                  checked: v === value,
                })}
              />
              <label htmlFor={id}>{label}</label>
            </Option>
          ))}
        </OptionList>
        {children}
      </RadioGroupContainer>
    );
  }
);

export default RadioGroup;
