import { useNavigate } from "react-router-dom";
import { BountyHunt, BountyHuntPhoto, BountyHuntTime } from "../../types";
import Checkbox from "../Checkbox";
import {
  labelOfBountyStatusPhoto,
  labelOfBountyStatusTime,
} from "../../constants";
import { Column } from "./types";
import styled from "styled-components";
import date from "components/common/function/date";
import { zeropad } from "utils";
import { ReactComponent as PicturePreivewSvg } from "assets/images/PicturePreview.svg";

type Props = {
  item: BountyHunt;
  columns: Column[];
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  openPreviewModal?: () => void;
};

const Row = styled.tr<{ $isActive?: boolean }>`
  & td {
    padding: 16px 0 18px;
    font-size: 16px;
    font-weight: 400;
    background-color: ${(props) =>
      props.$isActive ? "#2282E91F" : "#BBBBBB1F"};
  }
`;

const PreviewButton = styled.button`
  outline: 0;
  border: 0;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;
  display: flex;
  cursor: pointer;
`;

const isBountyHuntPhoto = (hunt: BountyHunt): hunt is BountyHuntPhoto =>
  "missionUrl" in hunt;

const formatPlayTime = (time: number) => {
  const m = Math.floor(time / 60);
  const s = time % 60;
  return `${zeropad(m, 2)}:${zeropad(s, 2)}`;
};

const TableRow = ({
  item,
  columns,
  checked,
  onCheckedChange,
  openPreviewModal,
}: Props) => {
  const navigate = useNavigate();
  const disabled = item.bountyStatus !== "SUBMITTED";
  const renderItem = (accessor: Column["accessor"]) => {
    switch (accessor) {
      case "checked":
        return (
          <Checkbox
            stopPropagation
            aria-label="select project"
            key={accessor}
            checked={checked}
            disabled={disabled}
            onChange={(e) => onCheckedChange(e.target.checked)}
          />
        );
      case "missionUrl":
        return (
          <PreviewButton onClick={() => openPreviewModal?.()}>
            <PicturePreivewSvg />
          </PreviewButton>
        );
      case "rejectionReason":
        return (item as BountyHuntPhoto).rejectionReason ?? "-";
      case "playTime":
        return formatPlayTime((item as BountyHuntTime).playTime);
      case "registeredAt":
      case "confirmedAt":
        if (!item[accessor]) return null;
        return date(new Date(item[accessor]!));
      case "bountyStatus":
        if (isBountyHuntPhoto(item)) {
          return labelOfBountyStatusPhoto[item.bountyStatus];
        }
        return labelOfBountyStatusTime[item.bountyStatus];
      default:
        return item[accessor];
    }
  };
  return (
    <Row $isActive={!disabled}>
      {columns.map(({ accessor }) => (
        <td key={accessor}>{renderItem(accessor)}</td>
      ))}
    </Row>
  );
};

export default TableRow;
