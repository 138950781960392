import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DailyQuestCategories } from "../../../common/category/DailyQuestCategory";
import { RewardCategories } from "../../../common/category/RewardCategory";

import { clearAll } from "../../../common/clearStorage";
import AddModal from "../../../common/Modal/AddModal";

import DailyQuestCreationAPI from "./Data/DailyQuestCreationAPI";
import GamesPopup from "./Popup/GamesPopup";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
`;

const ShowImg = styled.img`
  width: 200px;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ErrorTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: red;
  display: flex;
`;
const ErrorSpan = styled.span`
  margin-left: 0.5rem;
  color: #f05650;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
  font-size: 18px;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
  font-size: 18px;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
  font-size: 18px;
  margin-right: 1rem;
`;

const DateInput = styled.input`
  font-size: 18px;
  width: 100%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-right: 3rem;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

interface GameId {
  id: number;
  title: string;
}

export default function DailyQuestAdd() {
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const now = new Date(new Date().toString().split("GMT")[0] + " UTC")
    .toISOString()
    .slice(0, 19);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [img, setImg] = useState<File | undefined>();
  const [showGames, setShowGames] = useState(false);
  const [game, setGame] = useState<GameId>();

  const [modal, setModal] = useState(false);

  function onModalAdd() {
    setModal(true);
  }

  function closeModalAdd() {
    setModal(false);
  }

  function closeGames() {
    setShowGames(false);
  }

  const formData = new FormData();

  const watchCategory = watch("category");

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("img", selectedImage);
      encodeImgToBase64(selectedImage);
    }
  };

  function encodeImgToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallAPI(data: any) {
    console.log(data);
    console.log(game?.id);
    const blob = new Blob(
      [
        JSON.stringify({
          category: data.category,
          rewardCategory: data.rewardCategory,
          rewards: Number(data.rewards),
          goal: data.category === "ATTENDANCE" ? 1 : Number(data.goal),
          targetId: data.category === "IN_APP_GAME" ? game?.id : null,
          startedAt: data.startedAt,
          expiredAt: data.expiredAt,
          visible: data.visible,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);

    if (img) {
      formData.append("image", img);
    }

    await dailyQuestAddMutation.mutateAsync(formData);
  }

  const dailyQuestAddMutation = useMutation(DailyQuestCreationAPI, {
    onSuccess: () => {
      closeModalAdd();

      alert("데일리 퀘스트 등록이 완료되었습니다.");
      queryClient.invalidateQueries("dailyQuest");
      navigate(-1);
    },
    onError: () => {
      closeModalAdd();
    },
  });

  const onAdd = (data: any) => {
    onModalAdd();
  };

  useEffect(() => {
    clearAll();
  }, []);

  useEffect(() => {
    setValue("targetId", game?.title);
  }, [game]);

  return (
    <Wrapper>
      {/* {push && (
        <AddAnnounceModal
          close={closePushAdd}
          CallAPI={handleSubmit(CallAPI)}
          name={"푸시"}
          title={getValues("title")}
          message={getValues("content")}
        />
      )}
      {pushModal && (
        <AnnouncementNotiModal
          close={closeNotiAdd}
          // cancel={}
          pushModal={onPushAdd}
          CallAPI={handleSubmit(CallAPI)}
          title={getValues("title")}
          message={getValues("content")}
        />
      )}
      {modal && (
        <AddModal
          close={closeModalAdd}
          // cancel={}
          pushModal={onPushAdd}
          CallAPI={handleSubmit(CallAPI)}
          title={getValues("title")}
          message={getValues("content")}
        />
      )} */}
      {modal && (
        <AddModal
          name={"데일리 퀘스트"}
          close={closeModalAdd}
          CallAPI={handleSubmit(CallAPI)}
        />
      )}
      {showGames && (
        <GamesPopup
          close={closeGames}
          setQuestionId={setGame}
          questionId={game}
        />
      )}
      <Inner>
        <Contents>
          <Header>
            <Title>일퀘 추가</Title>
          </Header>
          <Item>
            {!errors.category ? (
              <ItemTitle>카테고리</ItemTitle>
            ) : (
              <ErrorTitle>
                카테고리
                <ErrorSpan>
                  <>{errors.category?.message}</>
                </ErrorSpan>
              </ErrorTitle>
            )}
            <div>
              <Select
                {...register("category", {
                  required: {
                    value: true,
                    message: "* 카테고리는 필수 사항입니다",
                  },
                })}
              >
                {DailyQuestCategories.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </div>
          </Item>
          <Item>
            <ItemTitle>이미지</ItemTitle>
            {img && (
              <div>
                <ShowImg src={URL.createObjectURL(img)} alt="" />
              </div>
            )}

            <div>
              <ImgInput
                type="file"
                accept=".jpg, .png"
                className="mt-3"
                onChange={Image}
              />
            </div>
          </Item>
          <FlexItem>
            <Item style={{ marginRight: "3rem" }}>
              {!errors.rewardCategory ? (
                <ItemTitle>보상 카테고리</ItemTitle>
              ) : (
                <ErrorTitle>
                  보상 카테고리
                  <ErrorSpan>
                    <>{errors.rewardCategory?.message}</>
                  </ErrorSpan>
                </ErrorTitle>
              )}
              <div>
                <Select
                  {...register("rewardCategory", {
                    required: {
                      value: true,
                      message: "* 보상 카테고리 필수 사항입니다",
                    },
                  })}
                >
                  {RewardCategories.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </div>
            </Item>
            <Item>
              {!errors.rewards ? (
                <ItemTitle>리워드</ItemTitle>
              ) : (
                <ErrorTitle>
                  리워드
                  <ErrorSpan>
                    <>{errors.rewards?.message}</>
                  </ErrorSpan>
                </ErrorTitle>
              )}
              <div>
                <TitleInput
                  type="number"
                  {...register("rewards", {
                    required: {
                      value: true,
                      message: "* 리워드는 필수 사항입니다",
                    },
                    min: {
                      value: 0,
                      message: "*리워드는 0 또는 양의 정수만 허용합니다.",
                    },
                    max: {
                      value: 100000,
                      message: "*리워드는 최대 10만입니다.",
                    },
                  })}
                />
              </div>
            </Item>
          </FlexItem>
          {watchCategory === "IN_APP_GAME" && (
            <Item>
              <ItemTitle>대상 ID</ItemTitle>
              <LinkInput {...register("targetId")} disabled />
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{ fontSize: "18px" }}
                onClick={() => setShowGames(true)}
              />
            </Item>
          )}
          {watchCategory !== "ATTENDANCE" && (
            <Item>
              {!errors.goal ? (
                <ItemTitle>목표</ItemTitle>
              ) : (
                <ErrorTitle>
                  목표
                  <ErrorSpan>
                    <>{errors.goal?.message}</>
                  </ErrorSpan>
                </ErrorTitle>
              )}
              <div>
                <TitleInput
                  type="number"
                  style={{ width: "10rem" }}
                  {...register("goal", {
                    required: {
                      value: true,
                      message: "* 목표는 필수 사항입니다",
                    },
                    min: {
                      value: 1,
                      message: "* 목표는 양의 정수만 허용합니다.",
                    },
                    max: {
                      value: 100000,
                      message: "* 목표는 최대 10만입니다.",
                    },
                  })}
                />
              </div>
            </Item>
          )}

          <FlexItem>
            {" "}
            <Item style={{ marginRight: "3rem" }}>
              {!errors.startedAt ? (
                <ItemTitle>퀘스트 시작 일시</ItemTitle>
              ) : (
                <ErrorTitle>
                  퀘스트 시작 일시
                  <ErrorSpan>
                    <>{errors.startedAt?.message}</>
                  </ErrorSpan>
                </ErrorTitle>
              )}
              <DateInput
                type="datetime-local"
                pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                min={String(now)}
                {...register("startedAt", {
                  required: {
                    value: true,
                    message: "* 시작 일시는 필수 사항입니다.",
                  },
                  min: {
                    value: String(now),
                    message: "* 시작 일시는 현재 시간보다 빨라질 수 없습니다.",
                  },
                })}
              />
            </Item>
            <Item>
              {!errors.expiredAt ? (
                <ItemTitle>퀘스트 종료 일시</ItemTitle>
              ) : (
                <ErrorTitle>
                  퀘스트 종료 일시
                  <ErrorSpan>
                    <>{errors.expiredAt?.message}</>
                  </ErrorSpan>
                </ErrorTitle>
              )}
              <DateInput
                type="datetime-local"
                pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
                min={getValues("startedAt")}
                {...register("expiredAt", {
                  required: {
                    value: true,
                    message: "* 종료 일시는 필수 사항입니다.",
                  },
                  min: {
                    value: getValues("startedAt"),
                    message: "* 종료 일시는 시작 시간보다 빨라질 수 없습니다.",
                  },
                })}
              />
            </Item>
          </FlexItem>
          <Item>
            {!errors.visible ? (
              <ItemTitle>어플 표시</ItemTitle>
            ) : (
              <ErrorTitle>
                어플 표시
                <ErrorSpan>
                  <>{errors.visible?.message}</>
                </ErrorSpan>
              </ErrorTitle>
            )}
            <Label>
              <input
                type="radio"
                value="true"
                {...register("visible", {
                  required: {
                    value: true,
                    message: "*어플 표시는 필수 사항입니다",
                  },
                })}
              />
              보이기
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                {...register("visible", {
                  required: {
                    value: true,
                    message: "*어플 표시는 필수 사항입니다",
                  },
                })}
              />
              숨기기
            </Label>
          </Item>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={handleSubmit(onAdd)}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
