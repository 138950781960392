import { useEffect, useRef } from "react";
import classNames from "classnames";
import useModal from "../useModal";
import { ModalType } from "../ModalsContext";
import styled from "styled-components";

type ModalCardSize = "large" | "medium" | "small";

type Props = {
  children: React.ReactNode;
  hasCloseButton?: boolean;
  modal: ModalType;
  className?: string;
  size?: ModalCardSize;
};

type ChildProps = {
  children: React.ReactNode;
};

const Bg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const Button = styled.button`
  align-self: flex-end;
  display: flex;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

const ModalHeader = styled.header`
  padding: 20px 24px 18px 24px;
  border-bottom: 1px solid var(--color-650);
`;

const ModalBody = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-100);
  display: flex;
  flex-direction: column;
`;

const ModalFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--color-650);
  color: var(--color-400);
  padding: 30px 0;
  margin: 0 24px;
  font-size: 14px;
`;

const ModalCard = ({ modal, hasCloseButton = false, children }: Props) => {
  const { closeModal } = useModal();
  const ref = useRef<HTMLDivElement>(null);
  const close = () => {
    closeModal(modal.id);
  };
  const CloseButton = () => (
    <Button onClick={close}>
      <svg width="29" height="29" viewBox="0 0 29 29">
        <path
          d="M3 3.0365L26.8472 26.8437"
          stroke="currentColor"
          strokeWidth="4.20833"
          strokeLinecap="round"
        />
        <path
          d="M26.8472 2.21594L2.99994 26.0232"
          stroke="currentColor"
          strokeWidth="4.20833"
          strokeLinecap="round"
        />
      </svg>
    </Button>
  );
  const hideBackground = () => (document.body.style.overflowY = "hidden");
  const showBackground = () => (document.body.style.overflowY = "scroll");
  useEffect(() => {
    hideBackground();
    const handleClose = ({ target }: MouseEvent) => {
      if (ref.current === target) close();
    };
    document.body.addEventListener("click", handleClose);

    return () => {
      showBackground();
      document.body.removeEventListener("click", handleClose);
    };
  }, []);
  return (
    <Bg ref={ref}>
      <Container>
        {hasCloseButton && <CloseButton />}
        {children}
      </Container>
    </Bg>
  );
};

ModalCard.Header = ModalHeader;
ModalCard.Body = ModalBody;
ModalCard.Footer = ModalFooter;

export default ModalCard;
