import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import ElectionCreationAPI from "./Data/ElectionCreation";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const SubjectInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;
const TxtInput = styled.input`
  width: 25%;
  font-size: 1rem;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;
const ImgForm = styled.div`
  width: 40%;
  height: 200px;
  border: dotted;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const CircleInput = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-size: 40px;
  border: 2px solid;
  padding: 2px;
  cursor: pointer;
  align-items: center;
  position: relative;
`;
const PrevDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 30%;
  height: 100%;
`;

const ShowImg = styled.img`
  width: 30%;
  height: 5%;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`;

const AppendButton = styled.button`
  margin-left: 3rem;
  background-color: gray;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;

  width: 60px;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

const BlockItem = styled.div`
  display: block;
`;
const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function ElectionAdd() {
  const { register, handleSubmit, setValue, getValues, control } = useForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "candidate",
  });

  const [modal, setModal] = useState(false);

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);
  const [candidateArray, setCandidateArray] = useState<
    { title: string; body: string }[]
  >([]);
  const formData = new FormData();

  const now = new Date(new Date().toString().split("GMT")[0] + " UTC")
    .toISOString()
    .slice(0, 19);

  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 8) {
      if (e.target.files && e.target.files.length <= 8) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("8장 이상 넣으셨습니다. 8장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 8장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  async function CallAPI() {
    const rewardsValue = getValues("rewards");
    const titleValue = getValues("title");
    const allowedCandidatesValue = getValues("allowedCandidates");
    const visibleValue = getValues("visible");
    const startedAtValue = getValues("startedAt");
    const expiredAtValue = getValues("expiredAt");
    const candidateValue: Array<{ title: string; body: string }> =
      getValues("candidate");

    const blob = new Blob(
      [
        JSON.stringify({
          rewards: rewardsValue,
          title: titleValue,
          allowedCandidates: allowedCandidatesValue,
          visible: visibleValue,
          startedAt: startedAtValue,
          expiredAt: expiredAtValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("electionInfo", blob);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }
    const inputArray: { title: string; body: string }[] = candidateValue;

    setCandidateArray(inputArray);

    console.log(candidateArray);

    const candidateBlob = new Blob([JSON.stringify(candidateArray)], {
      type: "application/json",
    });

    formData.append("candidateInfoList", candidateBlob);

    await electionAddMutation.mutateAsync(formData);
  }

  const electionAddMutation = useMutation(ElectionCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("투표 등록이 완료되었습니다.");
      queryClient.invalidateQueries("elections");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const visibleValue = getValues("visible");
    const inputArray: { title: string; body: boolean }[] =
      getValues("candidate");
    const allowedCandidatesVal = getValues("allowedCandidates");
    if (inputArray.length != images.length) {
      alert("후보 이지미와 후보의 수는 동일 해야합니다.");
    } else if (allowedCandidatesVal >= inputArray.length) {
      alert("다중 투표 허용 갯수는 후보 수 보다 많거나 같을 수 없습니다.");
    } else if (thumbnail && visibleValue) {
      setModal(true);
    } else {
      alert("이미지, 어플 표시 여부는 필수입니다.");
    }
  }

  function closeAdd() {
    setModal(false);
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal CallAPI={CallAPI} close={closeAdd} />}
      <Inner>
        <Header>
          <Title>투표 등록</Title>
        </Header>
        <Contents>
          <ItemTitle>투표 썸네일</ItemTitle>
          <div>
            {thumbnail && <Img src={URL.createObjectURL(thumbnail)} alt="" />}
          </div>
          <div>
            <ImgInput type="file" accept=".jpg, .png" onChange={Thumbnail} />
          </div>
          <Item>
            <ItemTitle>
              투표 제목 <ItemSpan>*20자 이내</ItemSpan>
            </ItemTitle>
            <div>
              <SubjectInput type="text" maxLength={20} {...register("title")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              다중 투표 허용 갯수
              <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
            </ItemTitle>
            <TxtInput
              type="number"
              min="1"
              {...register("allowedCandidates")}
            />
          </Item>
          <Item>
            <ItemTitle>
              포인트
              <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
            </ItemTitle>
            <TxtInput type="number" min="0" {...register("rewards")} />
          </Item>

          <Item>
            <ItemTitle>시작일</ItemTitle>
            <DateInput
              type="datetime-local"
              className="box-under w-25"
              defaultValue={now}
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("startedAt")}
            />
          </Item>

          <Item>
            <ItemTitle>종료일</ItemTitle>
            <DateInput
              type="datetime-local"
              className={
                getValues("expiredAt") < getValues("startedAt")
                  ? "box-under-red w-25"
                  : "box-under w-25"
              }
              min={getValues("startedAt")}
              required
              pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}"
              {...register("expiredAt")}
            />
          </Item>
          <Item>
            <ItemTitle>
              후보 이미지
              <ItemSpan>*필수 아님(최대 8개까지 선택 가능)</ItemSpan>
            </ItemTitle>
          </Item>
          <Item>
            <ImgForm>
              <div>
                <CircleInput onClick={handleClick}>
                  <div style={{ marginBottom: "0.5rem" }}>+</div>
                </CircleInput>
                <ImgInput
                  ref={fileRef}
                  type="file"
                  multiple
                  accept=".jpg, .png"
                  style={{ display: "none" }}
                  onChange={Image}
                />
              </div>
              <ItemSpan>이미지 선택 시 삭제</ItemSpan>
            </ImgForm>
          </Item>
          <Item>
            <PrevDiv>
              {showImages?.map((url, idx) => (
                <ShowImg
                  key={url}
                  src={url}
                  onClick={() => imageDelete(idx, url)}
                />
              ))}
            </PrevDiv>
          </Item>

          <Item style={{ display: "flex", alignItems: "center" }}>
            <ItemTitle>후보</ItemTitle>
            <AppendButton
              type="button"
              onClick={() => {
                append({});
              }}
            >
              등록
            </AppendButton>
          </Item>

          <div>
            {" "}
            {fields.map((item, index) => {
              return (
                <div key={item.id} style={{ marginBottom: "1rem" }}>
                  선택지 #{`${index + 1}`}
                  <Item style={{ marginTop: "0.5rem" }}>
                    제목
                    <TxtInput
                      style={{ margin: "0 1rem" }}
                      {...register(`candidate.${index}.title`, {
                        required: true,
                      })}
                    />
                    내용
                    <TxtInput
                      style={{ margin: "0 1rem" }}
                      {...register(`candidate.${index}.body`, {
                        required: true,
                      })}
                    />
                    <DeleteButton type="button" onClick={() => remove(index)}>
                      Delete
                    </DeleteButton>
                  </Item>
                </div>
              );
            })}
          </div>

          <BlockItem>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("visible")} />
              보이기
            </Label>
            <Label>
              <input type="radio" value="false" {...register("visible")} />
              숨기기
            </Label>
          </BlockItem>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={handleSubmit(onAdd)}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
