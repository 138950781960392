export const DailyQuestCategories = [
  { value: "", label: "===데일리 퀘스트 카테고리===" },
  { value: "ATTENDANCE", label: "출석 룰렛 돌리기" },
  { value: "VOTES", label: "투표하기" },
  { value: "IN_APP_GAME", label: "인앱게임 점수 넘기기" },
  { value: "ACCUMULATIONS", label: "포인트 적립" },
  { value: "OBT", label: "OBT 참여하기" },
  { value: "CBT", label: "CBT 참여하기" },
  { value: "CHEERING", label: "게임 응원글 작성하기" },
  { value: "REVIEW", label: "게임 리뷰글 작성하기" },
  { value: "POINT_SHOP", label: "포인트샵 구매하기" },
  { value: "INVITATION", label: "친구 초대하기" },
];
