import instance from "../../../../../API/MainAPI";


export default function NoticeEditAPI(id: any, formData: any) {
  return instance.post(`/v1/admins/notices/managements/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data; boundary=Paw",
      Accept: "application/json",
    },
  });
}
