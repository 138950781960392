import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import GameExcelAPI from "./Data/GameExcelAPI";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 380px;
`;

const HeaderName = styled.h3`
  display: flex;
  font-size: 14px;
  margin: 0.5rem 0 0.5rem 1rem;
  font-weight: bold;
`;

const Line = styled.hr`
  margin: 0;
  opacity: 0.25;
`;

const ContentsTxt = styled.div`
  font-weight: 700;
  margin-left: 1.5rem;

  display: flex;
  font-size: 16px;
  box-sizing: border-box;
`;

const ModalBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 120px;
  height: 40px;
  border: none;
  padding: 5px;
  background-color: lightgrey;
  color: black;
  border-radius: 5px;
  font-size: 13px;
`;

export default function GameDownload(props: any) {
  // const location = useLocation();
  const type = props.type;
  const search = props.search;
  const surveyCategory = props.surveyCategory;
  // const navigate = useNavigate();
  const { data: res, isLoading } = useQuery(["data"], () =>
    GameExcelAPI(type, search, surveyCategory)
  );

  function download() {
    if (res) {
      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `games_survey.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  }

  function onClick() {
    {
      isLoading && alert("잠시만 기다려 주세요. 곧 다운로드가 시작됩니다!");
    }
    if (!isLoading) {
      download();
      props.onClose();
    }
  }
  // function onReject() {
  //   navigate(-1);
  // }

  // const downloadFile = (file) => {
  //   let blob = new Blob([file.data], {
  //     type: file.headers["content-type"],
  //   });
  //   let name = decodeURIComponent(file.headers["encoded-disposition"]); //파일명 디코딩
  //   fileName = getFileName(name);
  //   if (window.navigator.msSaveOrOpenBlob) {
  //     // IE 10+는 해당 함수 제공
  //     window.navigator.msSaveOrOpenBlob(blob, name);
  //   } else {
  //     let link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.target = "_self";
  //     if (name) link.download = name;
  //     link.click();
  //     window.URL.revokeObjectURL(link); //메모리 누수 방지
  //   }
  // };

  // export const getFileName = (contentDisposition) => {
  //   let fileName = contentDisposition
  //     .split(";")
  //     .filter((el) => el.indexOf("filename") > -1)
  //     .map((ele) => ele.replace(/"/g, "").split("=")[1]);
  //   return fileName[0];
  // };

  // function handleResponse() = (response) => {
  //   response.blob().then(blob => {
  //       const link = document.createElement('a');
  //       const url = URL.createObjectURL(blob);
  //       console.log(url);
  //       link.href = url;
  //       link.download = '111.txt';
  //       link.click();
  //   })
  // }

  return (
    <ModalBg>
      <ModalBox>
        <HeaderName>게임 설문 다운로드</HeaderName>
        <Line />
        <ContentsTxt style={{ marginTop: "1.5rem" }}>
          엑셀 파일을 다운로드 하시겠습니까?
        </ContentsTxt>

        <ModalBtn>
          <Button style={{ marginRight: "1rem" }} onClick={props.onClose}>
            아니오
          </Button>
          <Button style={{ marginRight: "1rem" }} onClick={onClick}>
            네
          </Button>
        </ModalBtn>
      </ModalBox>
    </ModalBg>
  );
}
