import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../../../common/function/formatDate";
import styled from "styled-components";
import ApproveModal from "../Modal/ApproveModal";
import RejectModal from "../Modal/RejectModal";
import TestApprovalAPI from "../Data/TestApprovalAPI";
import TestRejectAPI from "../Data/TestRejectAPI";

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  $isWide?: boolean;
}

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
  overflow: scroll;
`;

const AlertImage = styled.div<ImgProps>`
  padding: 30px;
  top: 14%;
  width: ${($props) => ($props.$isWide ? "1000px" : "800px")};
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;
`;

const Txt = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MiddleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const ImageFrame = styled.div`
  text-align: center;
  margin-top: 0.5rem;
`;

const Cursor = styled.div`
  cursor: pointer;
`;

const ButtonDiv = styled.div`
  flex: 1 0;
  display: flex;
  margin-top: 1.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
`;

const ConfirmButton = styled.button`
  background-color: #ffc200;
  border: none;
  width: 5vw;
  height: 5vh;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  color: #333742;
`;

const Img = styled.img<ImgProps>`
  width: ${(props) => (props.$isWide ? "100%" : "15vw")};
  vertical-align: middle;
  overflow-clip-margin: content-box;
  overflow: clip;
`;

function ImagePopup(props: any) {
  console.log(props.snapshotUrl);
  const [isWide, setIsWide] = useState(false);

  const [appModal, setAppModal] = useState(false);
  const [rejModal, setRejModal] = useState(false);
  const [idList, setIdList] = useState<number[]>([]);

  const onAppModal = () => {
    setIdList([props.id]);
    setAppModal(true);
  };

  const onRejModal = () => {
    setIdList([props.id]);
    setRejModal(true);
  };

  const onCloseModal = () => {
    setAppModal(false);
    setRejModal(false);
  };

  function checkSize(width: number, height: number) {
    const isImageWide = width > height;
    setIsWide(isImageWide);
  }

  useEffect(() => {
    const image = new Image();
    image.src = props.snapshotUrl;
    image.onload = () => {
      checkSize(image.width, image.height);
    };
  }, [props.snapshotUrl]);

  const onApproval = async (activityIds: number[], count: number) => {
    const body = {
      activityIds: activityIds,
      count: count === undefined ? 1 : count,
    };
    console.log(body);
    await TestApprovalAPI(body)
      .then(() => {
        props.onClose();
        window.location.reload();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  const onRejection = async (
    activityIds: number[],
    count: number,
    reason: string
  ) => {
    const body = {
      activityIds: activityIds,
      count: count === undefined ? 1 : count,
      reason: reason,
    };
    console.log(body);
    await TestRejectAPI(body)
      .then(() => {
        props.onClose();
        window.location.reload();
      })
      .catch(() => {
        // alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  return (
    <div className="dimmed_layer_wrapper">
      <Wrapper>
        {appModal && (
          <ApproveModal
            onClose={onCloseModal}
            callAPI={onApproval}
            selectArr={idList}
          />
        )}
        {rejModal && (
          <RejectModal
            onClose={onCloseModal}
            callAPI={onRejection}
            selectArr={idList}
          />
        )}
        <AlertImage>
          <Txt>
            <Cursor>
              <FontAwesomeIcon icon={faX} onClick={props.onClose} />
            </Cursor>
          </Txt>
          <MiddleBox>
            <Cursor>
              <FontAwesomeIcon icon={faChevronLeft} onClick={props.shiftLeft} />
            </Cursor>
            <ImageFrame>
              <Img src={props.snapshotUrl} alt="" $isWide={isWide} />
            </ImageFrame>
            <Cursor>
              <FontAwesomeIcon
                icon={faChevronRight}
                onClick={props.shiftRight}
              />
            </Cursor>
          </MiddleBox>
          {!props.showButton && (
            <ButtonDiv>
              <ConfirmButton
                style={{ marginRight: "2rem" }}
                onClick={onAppModal}
              >
                인증하기
              </ConfirmButton>
              <ConfirmButton onClick={onRejModal}>거절하기</ConfirmButton>
            </ButtonDiv>
          )}
        </AlertImage>
      </Wrapper>
    </div>
  );
}

export default ImagePopup;
