import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCaretDown,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import UserPage from "components/pages/User/UserList/Data/userPageAPI";
import UserSearch from "components/pages/User/UserList/Data/userSearchAPI";

const AlertTest = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  width: 250px;
  color: #454545;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 5px;
`;

const SearchBar = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

const Contents = styled.div`
  padding-bottom: 20px;
`;

const Cursor = styled.div`
  cursor: pointer;
  display: flex;
`;

const Txt = styled.div`
  font-weight: 700;
  margin-right: 0.5rem;
`;

const CloseBtn = styled.div`
  background: none;
  border: none;
  text-transform: none;
  cursor: pointer;
  display: flex;
  margin-top: -0.2rem;
`;

const SearchInput = styled.input`
  width: 100%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  color: #454545;
  margin: 0;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  overflow: visible;
  box-sizing: content-box;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: absolute;
  cursor: pointer;
  height: 1em;
`;

const Content = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  align-items: center;
  justify-content: space-between;
  color: #454545;
`;

const User = styled.div`
  align-items: center;
  display: flex;
`;

const Nickname = styled.div`
  margin-left: 1rem;
  font-weight: 700;
  cursor: pointer;
  font-size: 14px;
`;

const UserDetail = styled.div`
  padding-left: 0.5rem;
  color: gray;
  font-size: 10px !important;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;
interface UserModalProps {
  setUser: (user: any) => void;
  close: () => void;
}

function UserModal({ setUser, close }: UserModalProps) {
  const [state, setState] = useState({
    search: "",
    userLists: [],
    page: 1,
    total: 0,
    searchLists: [],
    basicShow: true,
  });
  const [searchUser, setSearchUser] = useState("");

  const { data: userLists, refetch: refetchUser } = useQuery(
    ["testUserPage", state.page],
    async () => {
      return await UserPage(state.page, 7);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {},
    }
  );

  //위의 쿼리키와 동일하여 다른 쿼리키로 수정
  const { data: userSearch, refetch: refetchUserSearch } = useQuery(
    ["testUserPageSearch", state.search],
    async () => {
      return await UserSearch(state.search);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {},
    }
  );

  // const onKeySubmitSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     const searchValue = getValues("searchUser");
  //     console.log(searchValue);
  //     setState((prevState) => ({
  //       ...prevState,
  //       search: searchValue,
  //       basicShow: searchValue === "" ? true : false,
  //     }));
  //   }
  // };

  const onSubmitSearch = () => {
    setState((prevState) => ({
      ...prevState,
      search: searchUser,
      basicShow: searchUser === "" ? true : false,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setState((prevState) => ({
      ...prevState,
      page: pageNumber,
    }));
  };

  useEffect(() => {
    refetchUser();
  }, [state.page]);

  useEffect(() => {
    if (state.search !== "") {
      refetchUserSearch();
    }
  }, [state.search]);

  return (
    <AlertTest>
      <Header>
        <Cursor>
          <Txt>유저 목록</Txt>
          <FontAwesomeIcon icon={faCaretDown} />
        </Cursor>
        <CloseBtn onClick={close}>
          <FontAwesomeIcon icon={faX} />
        </CloseBtn>
      </Header>

      <SearchBar>
        <SearchInput
          type="text"
          onChange={(e) => setSearchUser(e.target.value)}
        />
        <SearchIcon icon={faMagnifyingGlass} onClick={onSubmitSearch} />
      </SearchBar>

      <Contents>
        <div>
          {state.basicShow &&
            userLists &&
            userLists?.data?.page?.content?.map((user: any) => {
              return (
                <Content
                  key={user.id}
                  onClick={() => {
                    setUser(user.id); //
                    close();
                  }}
                >
                  <User>
                    <Nickname>{user.nickname}</Nickname>
                    {/* <UserDetail>{Tier(user.tier)}</UserDetail> */}
                  </User>
                  {/* <UserDetail>회원정보</UserDetail> */}
                </Content>
              );
            })}

          {!state.basicShow &&
            userSearch?.data?.contents?.map((user: any) => {
              return (
                <Content
                  key={user.id}
                  onClick={() => {
                    setUser(user.id); //
                    close();
                  }}
                >
                  <User>
                    <Nickname>{user.nickname}</Nickname>
                    {/* <UserDetail>{Tier(user.tier)}</UserDetail> */}
                  </User>
                  {/* <UserDetail>회원정보</UserDetail> */}
                </Content>
              );
            })}
        </div>
        {state.basicShow && (
          <PaginationWrapper>
            <Pagination
              activePage={state.page}
              itemsCountPerPage={7}
              totalItemsCount={
                userLists?.data?.page?.pageable?.totalElements || 1
              }
              pageRangeDisplayed={3}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        )}
      </Contents>
    </AlertTest>
  );
}

export default UserModal;
