import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import BannerCreationAPI from "./Data/BannerCreationAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function BannerAdd() {
  const { register, setValue, getValues, watch } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const [img, setImg] = useState<File | undefined>();
  const formData = new FormData();

  const showLink = watch("redirectionCategory");

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallAPI() {
    const titleValue = getValues("title");
    const descriptionValue = "";
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");
    const visibleValue = getValues("visible");
    const redirectionCategoryValue = getValues("redirectionCategory");

    const blob = new Blob(
      [
        JSON.stringify({
          title: titleValue,
          description: descriptionValue,
          redirectLink: redirectLinkValue,
          category: categoryValue,
          visible: visibleValue,
          redirectionCategory: redirectionCategoryValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);
    if (img) {
      formData.append("photo", img);
    }

    await adsAddMutation.mutateAsync(formData);
  }

  const adsAddMutation = useMutation(BannerCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("배너 등록이 완료되었습니다.");
      queryClient.invalidateQueries("notices");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const visibleValue = getValues("visible");
    const categoryValue = getValues("category");
    const redirectionCategoryValue = getValues("redirectionCategory");
    const redirectLinkValue = getValues("redirectLink");

    const allFieldsFilled =
      img && categoryValue && visibleValue && redirectionCategoryValue;

    if (showLink === "EXTERNALS") {
      // 외부 링크이며 링크가 없는 경우
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else if (allFieldsFilled) {
        setModal(true);
      }
    } else if (allFieldsFilled) {
      setValue("redirectLink", "");
      setModal(true);
    } else {
      alert("이미지, 카테고리, 연결링크, 어플 표시 여부는 필수입니다.");
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <Inner>
        <Header>
          <Title>배너 추가</Title>
        </Header>
        <Contents>
          <div>{img && <Img src={URL.createObjectURL(img)} alt="" />}</div>
          <div>
            <ImgInput type="file" accept=".jpg, .png" onChange={Image} />
          </div>
          <Item>
            <ItemTitle>
              배너 이름
              <ItemSpan>*필수 아님</ItemSpan>
            </ItemTitle>
            <div>
              <TitleInput type="text" {...register("title")} />
            </div>
          </Item>
          <Item>
            {/* <ItemTitle>
              배너 설명
              <ItemSpan>*필수 아님, 최대 500자</ItemSpan>
            </ItemTitle>
            <div>
              <TextArea rows={8} maxLength={500} {...register("description")} />
            </div> */}
            <Item>
              <ItemTitle>카테고리</ItemTitle>

              <Select {...register("category")}>
                <option value="HOME_BANNER">홈 배너</option>
                <option value="HOME_SUB_BANNER">홈 서브 배너</option>
                <option value="HOME_NAVIGATE_BANNER">홈 바로가기 배너</option>
                <option value="COMMUNITY_BANNER">커뮤니티 배너</option>
                <option value="CONTENTS_BANNER">콘텐츠</option>
                <option value="POINT_SHOP_BANNER">포인트샵</option>
                <option value="INVITATION_BANNER">친구초대</option>
                <option value="CALENDAR_BANNER">캘린더</option>
              </Select>
            </Item>
          </Item>

          <Item>
            <ItemTitle>연결 링크 카테고리</ItemTitle>
            <Select {...register("redirectionCategory")}>
              <option value="ANNOUNCEMENT">공지글</option>
              <option value="GAME_NEWS">게임뉴스</option>
              <option value="TESTS">테스트 게임</option>
              <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
              <option value="RELEASE">출시게임</option>
              <option value="QUESTS">퀘스트</option>
              <option value="CONTENTS">콘텐츠</option>
              <option value="MY_PAGES">마이페이지</option>
              <option value="SETTINGS">설정</option>
              <option value="CALENDAR">캘린더</option>
              <option value="DAY_MISSION">데이 미션</option>
              <option value="EXTERNALS">외부링크</option>
              <option value="NONE">리다이렉션 없음</option>
            </Select>
          </Item>
          {showLink === "EXTERNALS" && (
            <Item>
              <ItemTitle>
                연결 링크
                <ItemSpan>*필수 아님</ItemSpan>
              </ItemTitle>
              <LinkInput type="text" {...register("redirectLink")} />
            </Item>
          )}

          <Item>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("visible")} />
              보이기
            </Label>
            <Label>
              <input type="radio" value="false" {...register("visible")} />
              숨기기
            </Label>
          </Item>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={onAdd}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
