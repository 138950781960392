import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import kakao from "../../../assets/images/kakaoLogin.png";
import text from "../../../assets/images/plithusText.png";
import KakaoSDK from "../../../API/Auth/KakaoSDK";

const LoginStyle = styled.div`
  flex: 4;
  padding-top: 50px;
`;

const Center = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const KaKaoBtn = styled.div`
  padding-top: 50px;
  cursor: pointer;
`;

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <LoginStyle>
      <Center>
        <img src={text} alt="" />
      </Center>
      <Center>
        <KaKaoBtn>
          <img src={kakao} onClick={KakaoSDK} alt="" width="150" />
        </KaKaoBtn>
      </Center>
    </LoginStyle>
  );
}

export default Login;
