import { Link, useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import { clearUser } from "../../../common/clearStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import index from "../../../common/function";
import { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import tier from "../../../common/function/tier";
import styled from "styled-components";
import { TransformStreamDefaultController } from "node:stream/web";
import UserAttendancePage from "./Data/UserAttendancePageAPI";
import UserSearch from "../UserList/Data/userSearchAPI";
import date from "../../../common/function/date";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const DownloadButton = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SearchStyle = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: end;
  font-size: large;
`;

const SerachInput = styled.input`
  margin: 0 1rem;
  border-radius: 10px;
  border: 1px solid;
  width: 300px; /* 크기 조절 */
  height: 40px; /* 높이 추가 */
  font-size: large;
`;

const SerachIcon = styled.div`
  height: auto;
`;

const SearchButton = styled.button`
  display: flex;
  justify-content: start;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: large;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  height: 50px;
  text-align: center;
`;
const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .p-1 {
    width: 30%;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function UserAttendance() {
  const pageNum = sessionStorage.getItem("userAttendance") || "1";
  const location = useLocation();
  const id = location.state.id;
  const nickname = location.state.nickname;

  const { data: userAttendance, refetch } = useQuery(
    ["userAttendance", pageNum],
    () => UserAttendancePage(Number(pageNum), 10, id),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("userAttendance", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearUser();
  }, []);
  let count = 0;

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>{nickname} 출석 목록</Title>
        </Header>
        <Contents>
          <Table>
            <Thead>
              <Tr className="tableHeader">
                <Th>출석 완료 보상 타입</Th>
                <Th>출석 완료 보상</Th>
                <Th>출석 완료 일시</Th>
              </Tr>
            </Thead>
            <tbody>
              {userAttendance?.data?.page?.content?.map((user: any) => {
                count = count + 1;
                return (
                  <Tr key={user.id}>
                    <Td>{user.type}</Td>
                    <Td>{user.rewards}</Td>
                    <Td>{date(user.stamp)}</Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Contents>{" "}
        {
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                userAttendance?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        }
      </Inner>
    </Wrapper>
  );
}
