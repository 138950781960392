import { createContext, useEffect, useMemo, useRef, useState } from "react";

export interface ModalType<R = unknown> {
  id: number;
  component: (modal: ModalType<R>) => React.ReactNode;
}

type ContextProps = {
  modalList: React.MutableRefObject<ModalType[]>;
  setModals: (modals: ModalType[]) => void;
};

export const ModalsContext = createContext<ContextProps>({
  modalList: { current: [] },
  setModals: () => {},
});

type Props = {
  children?: React.ReactNode;
};
export const ModalsProvider = ({ children }: Props) => {
  const modalList = useRef<ModalType[]>([]);
  const [modals, setModals] = useState<ModalType[]>([]);

  const value = useMemo(
    () => ({
      modalList,
      setModals,
    }),
    []
  );

  useEffect(() => {
    modalList.current = modals;
  }, [modals]);

  return (
    <ModalsContext.Provider value={value}>
      {modals.map((modal) => (
        <div key={modal.id}>{modal.component(modal)}</div>
      ))}
      {children}
    </ModalsContext.Provider>
  );
};
