import instance from "API/MainAPI";

export default function rejectBountyHunter({
  bountyHunterIds,
  reason,
}: {
  bountyHunterIds: number[];
  reason: string;
}) {
  return instance.patch<{
    success: boolean;
  }>("/v3/admins/bountyHunters/managements/rejection", {
    bountyHunterIds,
    count: bountyHunterIds.length,
    reason,
  });
}
