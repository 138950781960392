import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";

type Option = {
  value?: any;
  label: string;
};
interface SelectProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label: string;
  options: Option[];
  onChange?: (option: Option) => unknown;
  containerStyle?: React.CSSProperties;
}

const SelectContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-width: 320px;
  /* width: calc(calc(100% - 53px) / 2); */
  position: relative;

  input {
    display: none;
  }
`;
const SelectLabel = styled.label`
  font-weight: 500;
  font-size: 22px;
`;

const SelectButton = styled.button`
  align-items: center;
  display: flex;
  gap: 6px;
  cursor: pointer;
  min-height: 60px;
  border: 2px solid #2282e9;
  /* border-top-left-radius: 6px;
  border-top-right-radius: 6px; */
  border-radius: 6px;
  background-color: transparent;
  font-size: 18px;
  padding: 16px 20px;
`;
const Popup = styled.div`
  z-index: 999;
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 1;
  background-color: rgb(255, 255, 255);
  transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 60px 0px;
  transform: translateY(100%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
`;
const SelectItemList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 8px 0;
`;

const SelectItem = styled.li`
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  padding: 16px 20px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &[aria-selected="true"] {
    background: #2282e91f;
  }
`;

const SelectItemText = styled.span``;

const IconContainer = styled.div<{ $focused?: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  ${(props) => props.$focused && `transform: scaleY(-100%);`}
  svg {
    color: #2282e9;
  }
`;

const Select = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      value,
      onChange,
      children,
      label,
      id,
      options,
      placeholder,
      containerStyle,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.preventDefault();
      setFocused((focused) => !focused);
    };
    const handleItemClick = (option: Option) => {
      onChange?.(option);
      setFocused(false);
    };
    const containerRef = useRef<HTMLDivElement>(null);

    const outsideClick = useCallback(
      (e: MouseEvent) => {
        const { target } = e;
        if (!target) return;
        if (focused && !containerRef.current?.contains(target as Node))
          setFocused(false);
      },
      [focused]
    );
    useEffect(() => {
      document.addEventListener("click", outsideClick);
      return () => document.removeEventListener("click", outsideClick);
    }, [outsideClick]);

    return (
      <SelectContainer ref={containerRef} style={containerStyle}>
        <SelectLabel htmlFor={id}>{label}</SelectLabel>
        <SelectButton onClick={handleClick}>
          {options.find((option) => option.value === value)?.label ||
            placeholder}
          <IconContainer $focused={focused}>
            <ArrowDown />
          </IconContainer>
        </SelectButton>
        <input {...props} id={id} aria-hidden={true} ref={ref} />
        {focused && (
          <Popup>
            <SelectItemList>
              {options.map((option) => (
                <SelectItem
                  key={option.value}
                  aria-selected={option.value === value}
                  onClick={() => handleItemClick(option)}
                >
                  <SelectItemText>{option.label}</SelectItemText>
                </SelectItem>
              ))}
            </SelectItemList>
          </Popup>
        )}
      </SelectContainer>
    );
  }
);

export default Select;
