import React from "react";

interface checkProps {
  checked: boolean;
  onChange(): void;
}
const ReviewTesterAllCheck: React.FC<checkProps> = ({ checked, onChange }) => {
  return <input type="checkbox" checked={checked} onChange={onChange} />;
};

export default ReviewTesterAllCheck;
