import axios from "axios";
import IsLogin from "./IsLogin";

export default function Immigration(accessToken: any) {
  const url =
    process.env.REACT_APP_TESTAPI + "/v1/admins/immigrations/authentications";

  axios
    .post(url, {
      provider: "KAKAO",
      accessToken: "Bearer " + accessToken,
    })
    .then((res) => {
      const roles = res.data.roles;
      const admin = IsLogin(roles);
      if (admin) {
        const resToken = res.headers.authorization;
        const token = resToken.split(" ");
        sessionStorage.setItem("token", token[1]);
      }
    })
    .then(() => {
      window.location.reload();
    });
}
