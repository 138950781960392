import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BounceRateSurveyListAPI from "./Data/BounceRateSurveyListAPI";
import { clearBounceRateSurvey } from "components/common/clearStorage";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;
const SelectBox = styled.select`
  font-size: 17px;
  min-width: 100px;
  min-height: 20px;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .p-1 {
    width: 30%;
  }
  .w-25 {
    width: 25%;
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function BounceRateSurveyList() {
  const pageNum = sessionStorage.getItem("bounceRateSuveyPage") || "1";

  const [category, setCategory] = useState<string>("MULTIPLE_CHOICE");
  const { data: surveys, refetch } = useQuery(
    ["surveys", pageNum],
    async () => {
      return await BounceRateSurveyListAPI(
        "CBT_BREAKAWAY",
        Number(pageNum),
        10,
        category
      );
    },
    {
      onSuccess: (res) => {
        console.log(res);
      },
    }
  );
  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("bounceRateSuveyPage", pageNumber.toString());
    refetch();
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Multiple") {
      setCategory("MULTIPLE_CHOICE");
    } else if (e.target.value === "Subjective") {
      setCategory("SUBJECTIVE");
    }
  };

  useEffect(() => {
    refetch();
  }, [category]);
  useEffect(() => {
    clearBounceRateSurvey();
  }, []);
  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>게임이탈률 설문</Title>
          <div style={{ display: "flex" }}>
            <Link to="add">
              <AddBtn>등록</AddBtn>
            </Link>
          </div>
        </Header>

        <SelectBox onChange={handleSelectChange}>
          <option value={"Multiple"}>객관식</option>
          <option value={"Subjective"}>주관식</option>
        </SelectBox>

        <Contents>
          <Table>
            <thead>
              <Tr className="tableHeader">
                <Th>No.</Th>
                <Th>질문내용</Th>
                <Th style={{ width: "100px" }}>카테고리</Th>
                <Th style={{ width: "100px" }}>공개여부</Th>
                <Th style={{ width: "100px" }}>선택지</Th>
              </Tr>
            </thead>
            <tbody>
              {surveys?.data?.page?.content?.map((survey: any) => {
                return (
                  <Tr className="tableH-50" key={survey.id}>
                    <Td>{survey.id}</Td>
                    <Td>{survey.content}</Td>
                    <Td>
                      {survey.category === "MULTIPLE_CHOICE"
                        ? "객관식 질문"
                        : survey.category === "SUBJECTIVE"
                        ? "주관식 질문"
                        : survey.category === "PHOTO_AUTHENTICATION"
                        ? "사진 인증 질문"
                        : "코드 오류"}
                    </Td>
                    <Td>{survey.visible === true ? "보임" : "숨김"}</Td>
                    <Td>
                      <Link
                        to={"/surveys/" + survey.id}
                        className="link"
                        state={{
                          id: survey.id,
                        }}
                      >
                        <DetailButton>자세히</DetailButton>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={surveys?.data?.page?.pageable.totalElements || 1}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
