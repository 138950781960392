import instance from "../../../../../API/MainAPI";

export default function GifticonModificationAPI(id: any, formData: any) {
  return instance.patch(`/v3/admins/gifticons/managements/${id}`, formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
