import styled from "styled-components";
import { Bounty } from "../types";
import { NavLink as _NavLink } from "react-router-dom";

interface BountyHeaderProps {
  item: Bounty;
}

const Header = styled.header`
  height: 122px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px 0 40px;
  margin-left: -20px;
  border-bottom: 1px solid #e6e6e6;
`;
const HeaderTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;
const NavContinaer = styled.nav`
  display: flex;
  column-gap: 90px;
  margin-left: 76px;
`;
const NavLink = styled(_NavLink)`
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #b2b2b2;
  text-decoration: none;
  &.active {
    color: #2282e9;
  }
`;
const BountyContainer = styled.div`
  margin-left: auto;
  margin-right: 67px;
  background: #2282e9;
  border-radius: 13px;
  padding: 9px 48px;
`;
const BountyInfo = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: white;
`;

const BountyHeader: React.FC<BountyHeaderProps> = ({ item }) => {
  return (
    <Header>
      <HeaderContainer>
        <HeaderTitle>{item.gameTitle}</HeaderTitle>
        <NavContinaer>
          <NavLink to={`/retension/bounties/${item.id}`} end>
            현상금 관리
          </NavLink>
          <NavLink to={`/retension/bounties/${item.id}/photo`}>
            사진 인증
          </NavLink>
          <NavLink to={`/retension/bounties/${item.id}/time`}>
            시간 인증
          </NavLink>
        </NavContinaer>
        <BountyContainer>
          <BountyInfo>참여인원 : {item.totRegistrations}명 </BountyInfo>
          <BountyInfo>완료인원 : {item.totCompletions}명 </BountyInfo>
        </BountyContainer>
      </HeaderContainer>
    </Header>
  );
};

export default BountyHeader;
