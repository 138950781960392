import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import useBountyRegisterForm, {
  HandleSubmitFunc,
} from "./useBountyRegisterForm";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import CreateBountyAPI from "./Data/CreateBountyAPI";
import Form from "../shared/Form";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px 40px 20px 20px;
  align-items: center;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const FormInner = styled.div`
  display: grid;
  grid-template-columns: 438px 1fr;
  gap: 60px 30px;
`;
const Button = styled.button`
  margin-left: auto;
  width: fit-content;
  grid-column: 2;
  padding: 13px 34px;
  color: white;
  background: #2282e9;
  outline: 0;
  border: 0;
  border-radius: 13px;
  font-size: 24px;
`;

const platformOptions = [
  { value: "android", label: "Android 다운 링크" },
  { value: "ios", label: "IOS 다운 링크" },
  { value: "all", label: "2개 플랫폼 모두" },
];
const certificationOptions = [
  { value: "PHOTO", label: "사진인증" },
  { value: "TIME", label: "시간인증" },
];
const rewardOptions = [
  { value: "POINT", label: "포인트" },
  { value: "PLISTAR", label: "플리스타" },
  { value: "NONE", label: "없음" },
];

const visibleOptions = [
  { label: "보이기", value: true },
  { label: "숨기기", value: false },
];
const orientationOptions = [
  { label: "가로", value: "LANDSCAPE" },
  { label: "세로", value: "PORTRAIT" },
];

export default function BountyRegister() {
  const navigate = useNavigate();
  const [platform, setPlatform] = useState("");
  const { mutate } = useMutation(CreateBountyAPI, {
    onSuccess() {
      window.alert("현상금이 등록되었습니다.");
      navigate("/retension/bounties");
    },
  });

  const _handleSubmit: HandleSubmitFunc = (forms) => {
    const { gameImage, missionExample, ...data } = forms;
    console.log(forms);
    mutate({
      data,
      fileData: {
        gameImage,
        missionExample,
      },
    });
  };

  const { handleSubmit, inputs, control, watch, resetField } =
    useBountyRegisterForm(_handleSubmit);

  const iosLink = watch("appStoreLink");
  const method = watch("certificationMethod");

  const photoMissionEnabled = useMemo(
    () => method === "PHOTO" || iosLink !== "",
    [method, iosLink]
  );

  useEffect(() => {
    resetField("packageId");
  }, [photoMissionEnabled]);

  return (
    <Wrapper>
      <Inner>
        <Header>
          {" "}
          <Title>현상금 게임 등록</Title>
        </Header>
        <Contents>
          <form onSubmit={handleSubmit}>
            <FormInner>
              <Form.ImageField label="썸네일" {...inputs.gameImage} />
              <Form.FormGroup>
                <Form.TextField label="게임 이름" {...inputs.gameTitle} />
                <Form.TextField
                  label="게임 서브 설명"
                  placeholder="띄어쓰기 포함 20자까지 작성 가능합니다."
                  {...inputs.gameSubtitle}
                />
                <Form.Select
                  containerStyle={{ gridColumn: "span 2" }}
                  label="다운로드 플랫폼 선택"
                  placeholder="플랫폼을 선택해주세요."
                  options={platformOptions}
                  value={platform}
                  onChange={({ value }) => setPlatform(value)}
                />
                <Form.TextField
                  label="Android 다운 링크"
                  {...inputs.playStoreLink}
                  disabled={platform === "ios"}
                />
                <Form.TextField
                  label="IOS 다운 링크"
                  {...inputs.appStoreLink}
                  disabled={platform === "android"}
                />
                <Form.TextField
                  label="시작일"
                  type="datetime-local"
                  {...inputs.startedAt}
                />
                <Form.TextField
                  label="종료일"
                  type="datetime-local"
                  {...inputs.expiredAt}
                />
                <Controller
                  control={control}
                  name="certificationMethod"
                  render={({ field: { value, onChange, ref } }) => (
                    <Form.Select
                      label="미션 인증 방법 선택"
                      placeholder="인증 방법을 선택하세요."
                      options={certificationOptions}
                      onChange={(option) => onChange(option.value)}
                      ref={ref}
                      value={value}
                    />
                  )}
                />
                <Form.TextField
                  label="플레이 미션 시간"
                  type="number"
                  suffix="초"
                  {...inputs.allowedPlayTime}
                />
                <Form.TextField
                  label="패키지 ID"
                  placeholder="시간체크 시 필요합니다."
                  containerStyle={{ gridColumn: "span 2" }}
                  {...inputs.packageId}
                />

                <Controller
                  control={control}
                  name="rewardCategory"
                  render={({ field: { value, onChange, ref } }) => (
                    <Form.Select
                      label="지급 재화"
                      placeholder="재화를 선택하세요."
                      options={rewardOptions}
                      onChange={(option) => onChange(option.value)}
                      ref={ref}
                      value={value}
                    />
                  )}
                />
                <Form.TextField
                  containerStyle={{
                    marginTop: "auto",
                  }}
                  placeholder="숫자만 입력 가능합니다."
                  type="number"
                  suffix="포인트"
                  {...inputs.rewards}
                />
              </Form.FormGroup>

              {photoMissionEnabled && (
                <>
                  <Form.ImageField
                    label="미션 인증 예시 사진"
                    disabled={!photoMissionEnabled}
                    {...inputs.missionExample}
                  />
                  <Form.FormGroup>
                    <Form.TextArea
                      label="플레이 미션 조건"
                      {...inputs.photoMission}
                    />
                    <Form.RadioGroup
                      label="어플표시"
                      options={visibleOptions}
                      {...inputs.visible}
                    />
                    <Form.RadioGroup
                      label="예시 이미지 수평 방향"
                      name="orientaion"
                      options={orientationOptions}
                    />
                  </Form.FormGroup>
                </>
              )}
              <Form.FormGroup containerStyle={{ gridColumn: 2 }}>
                <Button type="submit">등록</Button>
              </Form.FormGroup>
            </FormInner>
          </form>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
