import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import UserStatDetail from "./Data/userdetailAPI";
import Pagination from "react-js-pagination";
import date from "../../common/function/date";
import { clearUserStats } from "../../common/clearStorage";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  flex: 4;
  margin-inline: 20px;
`;
const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
`;
const Contents = styled.div`
  margin-top: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;



const Table = styled.table`
  border: 2px solid #333742;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function UserStatsDetail() {
  const pageNum = sessionStorage.getItem("userStatsPage") || "1";

  const { data: detail, refetch } = useQuery(
    ["userStats", pageNum],
    () => UserStatDetail(pageNum),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("userStatsPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearUserStats();
  }, []);

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>일간 신규가입 유저 통계</Title>
        </Header>
        <Contents>
          <Table>
            <Thead>
              <Tr>
                <th></th>
                <Th>날짜</Th>
                <Th>가입자 수</Th>
                <th></th>
              </Tr>
            </Thead>
            <tbody>
              {detail?.data?.page?.content.map((stat: any) => (
                <Tr key={stat.date}>
                  <Td></Td>
                  <Td>{date(stat.date)}</Td>
                  <Td>{stat.count}명</Td>
                  <Td></Td>
                </Tr>
              ))}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={detail?.data?.page?.pageable.totalElements || 0}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
