import instance from "../../../../../API/MainAPI";

export default function CandidateModificationAPI(id: any, formData: any) {
  return instance.post(
    `/v3/admins/elections/managements/candidates/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=Paw",
        Accept: "application/json",
      },
    }
  );
}
