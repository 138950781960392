import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { clearGameNews, clearInAppGame } from "../../../common/clearStorage";

import Pagination from "react-js-pagination";
import date from "../../../common/function/date";
import formatDate from "../../../common/function/formatDate";
import InAppGamePageAPI from "./Data/InAppGamePageAPI";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .p-1 {
    width: 30%;
  }
  .w-25 {
    width: 25%;
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function InAppGamePage() {
  const pageNum = sessionStorage.getItem("inAppGamePage") || "1";

  const {
    data: inAppGames,
    refetch,
    isLoading,
  } = useQuery(
    ["inAppGames", pageNum],
    async () => await InAppGamePageAPI(Number(pageNum), 10)
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("inAppGamePage", pageNumber.toString());
    refetch();
  };

  let count = inAppGames?.data.count + 1;

  useEffect(() => {
    clearInAppGame();
  }, []);

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>인앱게임</Title>
          <AddBtn>등록</AddBtn>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th>No.</Th>
                <Th>제목</Th>
                <Th>부제목</Th>
                <Th>호스트</Th>
                <Th>공개여부</Th>
                <Th>생성 일시</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {inAppGames?.data.page.content.map((inAppGame: any) => {
                count = count - 1;
                return (
                  <Tr className="tableH-50" key={inAppGame.id}>
                    <Td>{count}</Td>

                    <Td>{inAppGame.title}</Td>
                    <Td>{inAppGame.subtitle}</Td>
                    <Td>{inAppGame.host}</Td>
                    <Td>{inAppGame.visible ? "공개" : "숨김"}</Td>
                    <Td>{formatDate(inAppGame.createdAt)}</Td>
                    <Td>
                      {/* <Link
                        to={"/inAppGame/" + inAppGame.id}
                        className="link"
                        state={{
                          id: inAppGame.id,
                          visible: inAppGame.visible,
                        }}
                      > */}
                      <DetailButton className="detailBtn">자세히</DetailButton>
                      {/* </Link> */}
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>{" "}
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                inAppGames?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"<"}
              nextPageText={">"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
