import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PointStats from "./Data/pointAPI";
import TesterStats from "./Data/testerAPI";
import UserStats from "./Data/userAPI";
import Variation from "./Variation";
import { useQuery } from "react-query";
import {
  IInvitStats,
  IPointStats,
  ITesterStats,
  IUserStats,
} from "./Data/status";
import AppStatsAPI from "./Data/AppStatusAPI";
import callInvitation from "./Data/Invitation";
import UserInvitation from "./Data/Invitation";

const DashboardStyle = styled.div`
  flex: 4;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const ContentTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
`;

const DashCell = styled.div`
  background-color: #ececec;
  padding-top: 5px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 20px 20px 30px 0px;
`;

const Inner = styled.div`
  margin: 40px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColContainer = styled.div`
  display: flex;
  font-weight: bold;
`;

const Item = styled.div`
  margin-left: 30px;
  margin-right: 20px;
`;

const LinkButton = styled.button`
  width: 90px;
  border: none;
  padding: 5px;
  background-color: #373947;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
`;

export default function Dashboard() {
  const pageNum = "1";

  function callUser() {
    return UserStats().then((res) => {
      return res.data;
    });
  }

  function callTester() {
    return TesterStats().then((res) => {
      return res.data;
    });
  }

  function callPoint() {
    return PointStats().then((res) => {
      return res.data;
    });
  }

  function callAppStatus() {
    return AppStatsAPI(Number(pageNum), 1).then((res) => {
      return res.data;
    });
  }

  function callInvitStatus() {
    return UserInvitation().then((res) => {
      return res.data;
    });
  }

  const userQuery = useQuery<IUserStats>(["user"], callUser);

  const { data: user } = userQuery;

  const testerQuery = useQuery<ITesterStats>(["tester"], callTester);

  const { data: tester } = testerQuery;

  const pointQuery = useQuery<IPointStats>(["point"], callPoint);

  const { data: point } = pointQuery;

  const { data: status } = useQuery(["status"], callAppStatus);

  const { data: invitation } = useQuery<IInvitStats>(
    ["invitation"],
    callInvitStatus
  );

  return (
    <DashboardStyle>
      <Inner>
        <Title>대시보드</Title>
        <Contents>
          <DashCell>
            <FlexContainer>
              <ContentTitle>유저</ContentTitle>
              <Link to="/userStats">
                <LinkButton>더보기</LinkButton>
              </Link>
            </FlexContainer>

            <hr />
            <FlexContainer>
              <ColContainer>
                <div>일일가입자</div>
                <Item>{user?.daily + ""}명</Item>
                {Variation(user?.dailyVariation + "")}
              </ColContainer>
              <ColContainer>
                <div>주간가입자</div>
                <Item>{user?.weekly + ""}명</Item>
                {Variation(user?.weeklyVariation + "")}
              </ColContainer>
              <ColContainer>
                <div>월간가입자</div>
                <Item>{user?.monthly + ""}명</Item>
                {Variation(user?.monthlyVariation + "")}
              </ColContainer>
            </FlexContainer>
          </DashCell>

          <DashCell>
            <FlexContainer>
              <ContentTitle>테스트</ContentTitle>
            </FlexContainer>
            <hr />
            <FlexContainer>
              <ColContainer>
                <div>1단계</div>
                <Item>{tester?.phaseOneCompletions}명</Item>
              </ColContainer>
              <ColContainer>
                <div>2단계</div>
                <Item>{tester?.phaseTwoCompletions}명</Item>
              </ColContainer>
              <ColContainer>
                <div>3단계</div>
                <Item>{tester?.phaseThreeCompletions}명</Item>
              </ColContainer>
            </FlexContainer>
          </DashCell>

          <DashCell>
            <FlexContainer>
              <ContentTitle>포인트</ContentTitle>
            </FlexContainer>
            <hr />
            <FlexContainer>
              <ColContainer>
                <div>획득포인트</div>
                <Item>{point?.deposits}PT</Item>
              </ColContainer>
              <ColContainer>
                <Item>소진포인트</Item>
                <Item>{point?.withdrawals}PT</Item>
              </ColContainer>
              <ColContainer />
            </FlexContainer>
          </DashCell>

          <DashCell>
            <FlexContainer>
              <ContentTitle>앱 버전 관리</ContentTitle>
              <Link to="/appStats">
                <LinkButton>더보기</LinkButton>
              </Link>
            </FlexContainer>
            <hr />
            <FlexContainer>
              <ColContainer>
                <div>현재 버전</div>
                <Item>{status?.page?.content[0].version}</Item>
              </ColContainer>
            </FlexContainer>
          </DashCell>

          <DashCell>
            <FlexContainer>
              <ContentTitle>신규 초대 유저</ContentTitle>
              <Link to="/invitStats">
                <LinkButton>더보기</LinkButton>
              </Link>
            </FlexContainer>

            <hr />
            <FlexContainer>
              <ColContainer>
                <div>일일가입자</div>
                <Item>{invitation?.daily + ""}명</Item>
                {Variation(invitation?.dailyVariation + "")}
              </ColContainer>
              <ColContainer>
                <div>주간가입자</div>
                <Item>{invitation?.weekly + ""}명</Item>
                {Variation(invitation?.weeklyVariation + "")}
              </ColContainer>
              <ColContainer>
                <div>월간가입자</div>
                <Item>{invitation?.monthly + ""}명</Item>
                {Variation(invitation?.monthlyVariation + "")}
              </ColContainer>
            </FlexContainer>
          </DashCell>
        </Contents>
      </Inner>
    </DashboardStyle>
  );
}
