import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { CreateBountyDto, CreateBountyFileDto } from "../types";

export interface BountyEditForm extends CreateBountyDto, CreateBountyFileDto {}

export type HandleSubmitFunc = (
  data: BountyEditForm
) => Promise<unknown> | unknown;

const useBountyEditForm = (
  handleSubmitProp: HandleSubmitFunc = () => {},
  defaultValues: Partial<BountyEditForm> = {}
) => {
  const {
    register,
    handleSubmit: onSubmit,
    ...props
  } = useForm<BountyEditForm>({
    defaultValues,
  });

  const _handleSubmit: SubmitHandler<BountyEditForm> = async (data) => {
    await handleSubmitProp(data);
  };
  const _handleError: SubmitErrorHandler<BountyEditForm> = (data) => {
    window.alert("채우지 않은 필수 항목이 있습니다.");
  };
  const handleSubmit = onSubmit(_handleSubmit, _handleError);
  return {
    handleSubmit,
    inputs: {
      packageId: register("packageId"),
      gameTitle: register("gameTitle", { required: true }),
      gameSubtitle: register("gameSubtitle", {
        required: true,
        maxLength: 100,
      }),
      gameImage: register("gameImage"),
      appStoreLink: register("appStoreLink"),
      playStoreLink: register("playStoreLink"),
      startedAt: register("startedAt", { required: true }),
      expiredAt: register("expiredAt", { required: true }),
      rewardCategory: register("rewardCategory", { required: true }),
      rewards: register("rewards", { required: true }),
      certificationMethod: register("certificationMethod", { required: true }),
      allowedPlayTime: register("allowedPlayTime", { required: true }),
      photoMission: register("photoMission"),
      missionExample: register("missionExample"),
      visible: register("visible"),
      missionExampleHorizontal: register("missionExampleHorizontal"),
    },
    ...props,
  };
};

export default useBountyEditForm;
