import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import formatDate from "../../../common/function/formatDate";
import Tier from "../../../common/function/tier";
import UserNotList from "./UserNoticeList";
import UserDetailAPI from "./Data/userDetailAPI";
import UserRegradeAPI from "./Data/userRegradeAPI";
import UserNickAPI from "./Data/userNickAPI";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Modal from "./modal/ModalNick";
import ModalLevel from "./modal/ModalLevel";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ModalNick from "./modal/ModalNick";
import UserWarnAPI from "./Data/userWarnAPI";
import ModalWarn from "./modal/ModalWarn";
import UserAttendanceStatement from "./UserAttendanceStatement";
import Role from "../../../common/function/role";
import date from "../../../common/function/date";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 30px;
  align-items: center;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  width: 80%;
  padding: 15px;
  margin: 3rem 0 3rem 0;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const Space = styled.div`
  width: 150px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: center;
  display: flex;
`;

const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333742;
  display: flex;
  margin: 10px 0;
`;

const ItemSubTitle = styled.div`
  font-size: 12px;
  color: #333740;
  display: flex;
  margin: 10px 0;
`;

const UserNoticeListDiv = styled.div`
  width: 100%;
`;

const NameInfo = styled.div`
  font-size: 30px;
  margin-left: 10px;
  display: flex;
  font-weight: bold;
  margin-left: 2rem;
`;

const ItemInfo = styled.div`
  font-size: 18px;
  margin-left: 10px;
  display: flex;
  font-weight: 600;
  margin-left: 2rem;
`;

const ChangeBtn = styled.button`
  width: 60px;
  height: 35px;
  border: none;
  padding: 1px;
  background-color: #373947;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 3rem;
`;

const NickInput = styled.input`
  width: 30vw;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const ExpInput = styled.input`
  width: 30vw;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

export default function UserDetail() {
  const { register, getValues } = useForm();
  const queryClient = useQueryClient();
  const location = useLocation();

  const id = location.state.id;

  const [minus, setMinus] = useState(true);

  const { data: userDetail } = useQuery(["userDetail", id], () =>
    UserDetailAPI(id)
  );

  const [state, setState] = useState("");

  // modal
  const [nickModal, setNickModal] = useState(false);
  const [levelModal, setLevelModal] = useState(false);
  const [warnModal, setWarnModal] = useState(false);

  function onNick() {
    setState("nick");

    const chgNickValue = getValues("chgNick");

    if (chgNickValue === "") {
      alert("변경할 닉네임을 입력해주세요");
    } else {
      setNickModal(true);
    }
  }
  function closeNick() {
    setNickModal(false);
  }

  function onLevel() {
    const expValue = getValues("exp");
    const degradeValue = getValues("degradeLevel");

    if (expValue === "" || degradeValue === null) {
      alert("변경할 경험치를 입력해주세요");
    } else {
      setState("level");
      setLevelModal(true);
    }
  }
  function closeLevel() {
    setLevelModal(false);
  }

  function onWarn() {
    const warnValue = getValues("warn");
    const degradeWarnValue = getValues("degradeWarn");
    if (warnValue === "" || degradeWarnValue === null) {
      alert("경고 횟수를 입력해주세요");
    } else {
      setState("warn");
      setWarnModal(true);
    }
  }
  function closeWarn() {
    setWarnModal(false);
  }

  const [isLoading, setIsLoading] = useState(false);
  function setLoading() {
    setIsLoading(false);
  }

  async function CallAPI() {
    setIsLoading(true);

    const chgNick = getValues("chgNick");

    const chgLevel = {
      exp: getValues("exp"),
      degrade: getValues("degradeLevel"),
    };
    const warningCount = {
      warning: getValues("warn"),
      degrade: getValues("degradeWarn"),
    };

    if (state === "level") {
      await regradeMutation.mutateAsync(chgLevel);
    } else if (state === "nick") {
      await reNickMutation.mutateAsync(chgNick);
    } else {
      await warnMutation.mutateAsync(warningCount);
    }
  }

  const reNickMutation = useMutation((nickData) => UserNickAPI(id, nickData), {
    onSuccess: () => {
      setIsLoading(false);
      setNickModal(false);
      alert("변경이 완료되었습니다.");
      queryClient.invalidateQueries(["userDetail", id]);
    },
    onError: (error: any) => {
      setIsLoading(false);
      setNickModal(false);
      alert("Error: " + error.response.data.message);
    },
  });

  const regradeMutation = useMutation(
    (chgRegrade: any) => UserRegradeAPI(id, chgRegrade),
    {
      onSuccess: () => {
        setIsLoading(false);
        setLevelModal(false);
        alert("변경이 완료되었습니다.");
        queryClient.invalidateQueries(["userDetail", id]);
      },
      onError: (error: any) => {
        setIsLoading(false);
        setLevelModal(false);
        alert(error.response.data.message);
      },
    }
  );

  const warnMutation = useMutation(
    (warnUser: any) => UserWarnAPI(id, warnUser),
    {
      onSuccess: () => {
        setWarnModal(false);
        setIsLoading(false);
        alert("변경이 완료되었습니다.");
        queryClient.invalidateQueries(["userDetail", id]);
      },
      onError: (error: any) => {
        setIsLoading(false);
        setWarnModal(false);
        alert(error.response.data.message);
      },
    }
  );

  return (
    <Wrapper>
      {nickModal && (
        <ModalNick
          close={closeNick}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      {levelModal && (
        <ModalLevel
          close={closeLevel}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      {warnModal && (
        <ModalWarn
          close={closeWarn}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      <Inner>
        <Title>유저 상세 #{id}</Title>
        <Contents>
          <Block>
            <Item>
              <ItemTitle>이름</ItemTitle>
              <NameInfo>{userDetail?.data.nickname}</NameInfo>
            </Item>
            <Space />
            <div>
              <Item>
                <ItemSubTitle>권한</ItemSubTitle>
                <ItemInfo>{Role(userDetail?.data.roles)}</ItemInfo>
              </Item>
              <Item>
                <ItemSubTitle>로그인 방식</ItemSubTitle>
                <ItemInfo>{userDetail?.data.provider}</ItemInfo>
              </Item>
              <Item>
                <ItemSubTitle>가입일</ItemSubTitle>
                <ItemInfo>{formatDate(userDetail?.data.signedUpAt)}</ItemInfo>
              </Item>
              <Item>
                <ItemSubTitle>전화번호</ItemSubTitle>
                <ItemInfo>{userDetail?.data.phone}</ItemInfo>
              </Item>
            </div>
          </Block>

          <Block>
            <Item>
              <ItemSubTitle>티어</ItemSubTitle>
              <ItemInfo>{Tier(userDetail?.data.tier)}</ItemInfo>
            </Item>
            <Space />
            <Item>
              <ItemSubTitle>레벨</ItemSubTitle>
              <ItemInfo>{userDetail?.data.level}</ItemInfo>
            </Item>{" "}
            <Space />
          </Block>

          <Block>
            {" "}
            <Item>
              <ItemSubTitle>경고 누적</ItemSubTitle>
              <ItemInfo>{userDetail?.data.warning}</ItemInfo>
            </Item>{" "}
            <Space />
            <Item>
              <ItemSubTitle>테스트 제한 해제 일시</ItemSubTitle>
              <ItemInfo>{date(userDetail?.data.allowedAt)}</ItemInfo>
            </Item>
          </Block>

          <Block>
            <Item>
              <ItemSubTitle>포인트</ItemSubTitle>
              <ItemInfo>{userDetail?.data.points}</ItemInfo>
            </Item>

            <Space />
            <Item>
              <ItemSubTitle>경험치</ItemSubTitle>
              <ItemInfo>{userDetail?.data.exp}</ItemInfo>
            </Item>
            <Space />
            <Item>
              <ItemSubTitle>플리스타</ItemSubTitle>
              <ItemInfo>{userDetail?.data.plistar}</ItemInfo>
            </Item>
          </Block>

          <Block>
            <Item>
              <ItemSubTitle>게임 완료 수</ItemSubTitle>
              <ItemInfo>{userDetail?.data.completions}</ItemInfo>
            </Item>
            <Space />
            <Item>
              <ItemSubTitle>로그아웃 여부</ItemSubTitle>
              <ItemInfo>
                {userDetail?.data.signedOut ? "로그아웃" : "로그인"}
              </ItemInfo>
            </Item>
          </Block>

          <Block>
            <Item>
              <ItemSubTitle>광고 수신 여부</ItemSubTitle>
              <ItemInfo>
                {userDetail?.data.allowedAds ? "수신" : "수신 거부"}
              </ItemInfo>
            </Item>
            <Space />{" "}
            <Item>
              <ItemSubTitle>푸시 알림 수신 여부</ItemSubTitle>
              <ItemInfo>
                {userDetail?.data.allowedNotifications ? "수신" : "수신 거부"}
              </ItemInfo>
            </Item>{" "}
            <Space />
            <Item>
              <ItemSubTitle>회원 가입 일시</ItemSubTitle>
              <ItemInfo>{date(userDetail?.data.signedUpAt)}</ItemInfo>
            </Item>
          </Block>

          <Block>
            <UserNoticeListDiv>
              <ItemTitle>알림 히스토리</ItemTitle>
              <div>
                <UserNotList id={id} />
              </div>
            </UserNoticeListDiv>
          </Block>

          <Block>
            <UserNoticeListDiv>
              <ItemTitle>미션 단어 완성</ItemTitle>
              <div>
                <UserAttendanceStatement id={id} />
              </div>
            </UserNoticeListDiv>
          </Block>

          <Block>
            <div>
              <ItemTitle>닉네임 변경</ItemTitle>
              <Item>
                <NickInput type="text" {...register("chgNick")} />
                <ChangeBtn onClick={onNick}>변경</ChangeBtn>
              </Item>
            </div>
          </Block>

          <Block>
            <div>
              <ItemTitle>경험치 변경</ItemTitle>
              <Item>
                <ExpInput
                  type="number"
                  min="0"
                  className={minus ? "col input w-25" : "col input-red w-25"}
                  {...register("exp")}
                />
                <Item style={{ marginLeft: "1rem" }}>
                  <Label>
                    <input
                      type="radio"
                      value="true"
                      style={{ marginRight: "0.5rem" }}
                      {...register("degradeLevel")}
                    />
                    감소
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </Label>
                  <Label>
                    <input
                      type="radio"
                      value="false"
                      style={{ marginRight: "0.5rem" }}
                      {...register("degradeLevel")}
                    />
                    증가
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </Label>
                </Item>
                <ChangeBtn onClick={onLevel}>변경</ChangeBtn>
              </Item>
            </div>
          </Block>

          <Block>
            <div>
              <ItemTitle>경고</ItemTitle>
              <Item>
                <ExpInput
                  type="number"
                  min="0"
                  className={minus ? "col input w-25" : "col input-red w-25"}
                  {...register("warn")}
                />
                <Item style={{ marginLeft: "1rem" }}>
                  <Label>
                    <input
                      type="radio"
                      value="true"
                      style={{ marginRight: "0.5rem" }}
                      {...register("degradeWarn")}
                    />
                    감소
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </Label>
                  <Label>
                    <input
                      type="radio"
                      value="false"
                      style={{ marginRight: "0.5rem" }}
                      {...register("degradeWarn")}
                    />
                    증가
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </Label>
                </Item>
                <ChangeBtn onClick={onWarn}>변경</ChangeBtn>
              </Item>
            </div>
          </Block>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
