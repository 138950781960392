import React from "react";
import styled from "styled-components";

interface PopupProps {
  children: React.ReactNode;
  visible: boolean;
}

const ModalBg = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  cursor: default;
`;
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  cursor: default;
`;

const Popup = ({ children, visible }: PopupProps) => {
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      {visible && (
        <div onClick={handleClick}>
          <ModalBg />
          <ModalContainer>{children}</ModalContainer>
        </div>
      )}
    </>
  );
};

export default Popup;
