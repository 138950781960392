import instance from "../../../../API/MainAPI";
import { ApiResponse, PageableData } from "../../../../API/type";

interface Management {
  appStoreLink: string;
  compulsion: false;
  id: number;
  message: string;
  playStoreLink: string;
  status: boolean;
  version: string;
}

export default function AppStatsAPI(page: number, pageSize: number) {
  return instance.get<PageableData<Management>>("/v3/admins/app/managements", {
    params: {
      pageSize: pageSize,
      pageNumber: page - 1,
    },
  });
}
