import instance from "../../../../../API/MainAPI";

export default function GameExcelAPI(
  type: any,
  search: any,
  surveyCategory: any
) {
  return instance.get("/v3/admins/testers/managements/download", {
    params: {
      [type]: search,
      "survey-category": surveyCategory,
    },
    responseType: "arraybuffer",
  });
}
