import { PageableData } from "API/type";
import instance from "../../../../../API/MainAPI";
import { BountyHuntPhoto, BountyStatus } from "../../types";

interface BountyHunterPhotoListParams {
  bountyId: number;
  userId?: number;
  status?: BountyStatus;
  pageNumber: number;
  pageSize: number;
}

export default function BountyHunterPhotoList({
  bountyId,
  userId,
  status,
  pageNumber,
  pageSize,
}: BountyHunterPhotoListParams) {
  return instance.get<PageableData<BountyHuntPhoto>>(
    "/v3/admins/bountyHunters/managements/photo",
    {
      params: {
        pageNumber,
        pageSize,
        status,
        bounty: bountyId,
        user: userId,
      },
    }
  );
}
