import instance from "../../../../../API/MainAPI";

export default function UserRegisterPageAPI(id: number, page: number) {
  return instance.get(`/v3/admins/testers/managements/registrations/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { pageNumber: page - 1 },
  });
}
