import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";

import GifticonDetailAPI from "./Data/GitficonDetailAPI";
import GifticonModificationAPI from "./Data/GifticonModificationAPI";
import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Box = styled.div`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  margin: 5px 10px 5px 0;
  width: 50%;
  padding: 10px;
  height: auto;
  line-height: normal;
`;

const BoxTitle = styled.h3`
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  justify-content: center;
  font-size: 20px;
  display: flex;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  align-items: center;
`;

const Img = styled.img`
  width: 40%;
  margin: 0 1rem 0 1rem;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const Elements = styled.div`
  display: flex;
  width: 100%;
`;

const ItemInfo = styled.div`
  display: flex;
  font-size: 15px;
  margin-left: 1rem;
  align-items: center;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 35vw;
  resize: vertical;
  justify-content: center;
`;
const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const TokenInput = styled.input`
  width: 30vw;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const NumInput = styled.input`
  width: 30vw;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function GifticonDetail() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;

  const formData = new FormData();

  const { data: gifticonDetail, isSuccess } = useQuery(
    ["gifticonDetail", id],
    () => GifticonDetailAPI(id)
  );

  const [modalEdit, setModalEdit] = useState(false);

  function onEditModal() {
    setModalEdit(true);
  }
  function closeEdit() {
    setModalEdit(false);
  }

  async function CallEditAPI() {
    if (!isSuccess) {
      return;
    }
    const templateTokenValue = getValues("templateToken");
    const descriptionValue = getValues("description");
    const categoryValue = getValues("category");
    const expValue = getValues("exp");
    const visibleValue = getValues("visible");

    const modifiedData = {
      templateToken:
        templateTokenValue !== undefined
          ? templateTokenValue
          : gifticonDetail?.data.templateToken,
      description:
        descriptionValue !== undefined
          ? descriptionValue
          : gifticonDetail?.data.description,
      category:
        categoryValue !== undefined
          ? categoryValue
          : gifticonDetail?.data.category,
      exp: expValue !== undefined ? expValue : gifticonDetail?.data.exp,

      visible:
        visibleValue !== undefined
          ? visibleValue
          : gifticonDetail?.data.visible,
    };



    formData.append("category", modifiedData.category);
    formData.append("description", modifiedData.description);
    formData.append("exp", modifiedData.exp);
    formData.append("visible", modifiedData.visible);
    formData.append("templateToken", modifiedData.templateToken);

    await gifticonEdit.mutateAsync(formData);
  }

  const gifticonEdit = useMutation(
    (formData: FormData) => GifticonModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("등록이 완료되었습니다.");
        queryClient.invalidateQueries("gifticonDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error" + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await GifticonDetailAPI(id);

      if (response) {
        setValue("templateToken", response.data.templateToken);
        setValue("description", response.data.description);
        setValue("category", response.data.category);
        setValue("exp", response.data.exp);
        setValue("visible", response.data.visible);
      }
    };

    fetchData();
  }, []);

  const visibleValue = watch("visible");

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  function onEdit() {
    const expValue = getValues("exp");

    if (expValue < 0 || expValue > 100000) {
      alert("포인트는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
    } else {
      onEditModal();
    }
  }

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      <Inner>
        <Header>
          <Title>기프티콘 상세</Title>
        </Header>
        <Contents>
          <div style={{ display: "flex" }}>
            <Box>
              <BoxTitle>상품정보</BoxTitle>
              <Section>
                {<Img src={gifticonDetail?.data.bannerUrl} alt="" />}
                <Item>
                  <Elements>
                    <ItemTitle>제품명</ItemTitle>
                    <ItemInfo>{gifticonDetail?.data.title || "-"}</ItemInfo>
                  </Elements>
                  <Elements>
                    <ItemTitle>부가 설명</ItemTitle>
                    <ItemInfo>{gifticonDetail?.data.subtitle || "-"}</ItemInfo>
                  </Elements>
                  <Elements>
                    <ItemTitle>가격</ItemTitle>
                    <ItemInfo>{gifticonDetail?.data.price || "-"}원</ItemInfo>
                  </Elements>
                </Item>
              </Section>
              <Item>
                <ItemTitle>상세 설명</ItemTitle>
                <div>
                  <TextArea
                    rows={10}
                    maxLength={500}
                    className="input"
                    disabled
                    defaultValue={gifticonDetail?.data.description}
                  />
                </div>
              </Item>
            </Box>
            <div>
              <BoxTitle>수정 사항</BoxTitle>
              <Item>
                <ItemTitle>템플릿 토큰</ItemTitle>
                <TokenInput {...register("templateToken")} />
              </Item>
              <Item>
                <ItemTitle>
                  경험치
                  <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                </ItemTitle>
                <NumInput
                  type="number"
                  min="0"
                  defaultValue={gifticonDetail?.data.exp}
                  {...register("exp")}
                />
              </Item>
              <ItemTitle>분류</ItemTitle>
              <div className="w-50 mt-1">
                <Select {...register("category")} className="input">
                  <option value="GAME_GIFT_CARD">게임 기프트 카드</option>
                  <option value="CAFE_BAKERY">카페|베이커리</option>
                  <option value="CONVENIENCE_STORE">편의점</option>
                  <option value="CHICKEN_PIZZA_BURGER">치킨|피자|버거</option>
                  <option value="EAT_OUT">외식</option>
                </Select>
              </div>
              <Item>
                <ItemTitle>
                  상세 설명
                  <ItemSpan>최대 500자</ItemSpan>
                </ItemTitle>
                <div>
                  <TextArea
                    rows={10}
                    maxLength={500}
                    className="input"
                    defaultValue={gifticonDetail?.data.description}
                    {...register("description")}
                  />
                </div>
              </Item>
              <Item>
                <ItemTitle>어플 표시</ItemTitle>
                <Label className="radio">
                  <input
                    type="radio"
                    value="true"
                    checked={visibleValue === true}
                    {...register("visible")}
                    onChange={handleRadioChange}
                  />
                  보이기
                </Label>
                <Label className="radio">
                  <input
                    type="radio"
                    value="false"
                    checked={visibleValue === false}
                    {...register("visible")}
                    onChange={handleRadioChange}
                  />
                  숨기기
                </Label>
              </Item>
            </div>
          </div>
          <ButtonDiv>
            <EditButton onClick={onEdit}>수정하기</EditButton>
          </ButtonDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
