import instance from "../../../../../API/MainAPI";

export default function UserRegisterCreationAPI(body: any) {
  return instance.post("/v3/admins/testers/managements/registrations", body, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
