interface DateFormatOptions {
  year: "numeric";
  month: "long";
  day: "numeric";
  hour: "numeric";
  minute: "numeric";
  second: "numeric";
}

export default function formatDate(date: any) {
  if (date) {
    const options: DateFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(date).toLocaleDateString("ko", options);
  } else return "-";
}
