import React, { useEffect } from "react";
import { useQuery } from "react-query";

import Pagination from "react-js-pagination";
import styled from "styled-components";
import AnnouncementListAPI from "./Data/AnnouncementListAPI";
import date from "../../../common/function/date";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  flex: 4;
  margin-inline: 20px;
`;
const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
`;
const Contents = styled.div`
  margin-top: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function AnnouncementList() {
  const pageNum = sessionStorage.getItem("announcementPage") || "1";

  const { data: annoucement, refetch } = useQuery(
    ["userStats", pageNum],
    () => AnnouncementListAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("announcementPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {}, []);

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>공지글</Title>
          <Link to={"add"}>
            {" "}
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>
        <Contents>
          <Table>
            <Thead>
              <Tr>
                <th></th>
                <Th>제목</Th>
                <Th>작성일</Th>
                <th></th>
              </Tr>
            </Thead>
            <tbody>
              {annoucement?.data?.page?.content.map((announce: any) => (
                <Tr key={announce.id}>
                  <Td></Td>
                  <Td>{announce.title}</Td>
                  <Td>{date(announce.createdAt)}</Td>
                  <Td>
                    <Link
                      to={"/announcements/" + announce.id}
                      state={{
                        id: announce.id,
                      }}
                    >
                      <DetailButton>자세히</DetailButton>
                    </Link>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                annoucement?.data?.page?.pageable.totalElements || 0
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
