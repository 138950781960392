import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";

import { clearCheering } from "../../../common/clearStorage";
import { useQuery } from "react-query";
import styled from "styled-components";
import formatDate from "../../../common/function/formatDate";
import { AxiosResponse } from "axios";
import CheeringTesterPageAPI from "./Data/CheeringTesterSearchAPI";
import { CheeringTesterActivityAPI } from "./Data/CheeringTesterActivityAPI";
import CheeringTesterSearchAPI from "./Data/CheeringTesterSearchAPI";
import CheeringApprovalAPI from "./Data/CheeringApprovalAPI";
import CheeringRejectAPI from "./Data/CheeringRejectAPI";
import ImagePopup from "./Popup/ImagePopup";
import TestPopup from "./Popup/TestPopup";
import UserPopup from "./Popup/UserPopup";

import RejectModal from "./Modal/RejectModal";
import ApproveModal from "../../../common/Modal/ApproveModal";
import ConfirmedPopup from "./Popup/ConfirmedPopup";
import CheeringAllCheck from "./CheeringAllCheck";
import CheeringOnlyCheck from "./CheeringOnlyCheck";

interface Filter {
  userId: number | null;
  gameId: number | null;
  status: string | null;
}

interface Item {
  activityId: number;
  testerId: number;
  nickname: string;
  game: string;
  snapshotUrl: string;
  playTime: number;
  submittedAt: string;
  category: string;
  status: string;
  confirmed: boolean;
}

const Wrapper = styled.div`
  flex: 4;
  overflow-x: hidden;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Button = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const Table = styled.table`
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  caption-side: bottom;
  text-indent: initial;
  border-spacing: 2px;
`;

const Tr = styled.tr`
  background-color: #fff;
  border: 1px solid #fff;
  color: #333742;
  text-align: center;
`;

const Th = styled.th`
  display: table-cell;
  font-weight: bold;
`;

const ThTxt = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  cursor: pointer;
`;

const SelectAllBox = styled.input`
  margin: 0;
`;

const ThP = styled.p`
  margin-left: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;

const ThButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.5rem;
  border-radius: 0;
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col = styled.div`
  flex: 1 0;
`;

const Tbody = styled.tbody`
  text-align: center;
  border-spacing: 2px;
`;

const TrYellow = styled.tr`
  background-color: #ffc200;
  border: 10px solid #fff;
  height: 60px;
`;
const TrGray = styled.tr`
  background-color: #dddddd;
  border: 10px solid #fff;
  height: 60px;
`;

const SnapshotButton = styled.button`
  cursor: pointer;
  background: none;
  width: 50px;
  padding: 0.25rem;
  border-radius: 5px;
`;

const ButtonDiv = styled.div`
  flex: 1 0;
  display: flex;
  margin-top: 1.5rem;
  margin-right: 0.5rem;
  justify-content: flex-end;
`;

const ConfirmButton = styled.button`
  background-color: #ffc200;
  border: none;
  width: 5vw;
  height: 5vh;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  color: #333742;
`;

const PageBottom = styled.div`
  margin-top: 1rem;
  justify-content: center;
  display: flex;
`;
const Page = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  display: flex;
`;

const PageBtn = styled.button`
  border: 2px solid #ffc200;
  width: 50px;
  border-radius: 5px;
  color: #333742;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
  cursor: pointer;
  background: none;
  &:hover {
    background-color: #ffc200;
    color: #333742;
  }
`;

const NoneData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function CheeringTester() {
  const [pageNum, setPageNum] = useState(1);
  const [cheerList, setCheerList] = useState<any>([]);
  //체크박스 상태
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState<boolean>(false);
  const [oneCheckBoxChecked, setOneCheckBoxChecked] = useState<boolean[]>([]);
  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = { userId: null, gameId: null, status: null };
    return savedFilter;
  });

  const [popupState, setPopupState] = useState({
    isOpenPopupTest: false,
    isOpenPopupUser: false,
    isOpenPopupImage: false,
    isOpenPopupConfirmed: false,
    isOpenApprovePopup: false,
    isOpenRejectPopup: false,
  });

  let length: number = 0;
  const { data, refetch } = useQuery(
    ["cheeringPage", pageNum],
    async () => {
      return await CheeringTesterActivityAPI(Number(pageNum), filter);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,

      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        console.log(res);
        length = res?.data.page.content.length;
        const checkbox_state_arr = new Array(length).fill(false);
        setOneCheckBoxChecked(checkbox_state_arr);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setCheerList(data);
    }
    // console.log(data);
  }, [data]);
  useEffect(() => {
    setPageNum(1); //필터링할 때 마다 페이지 1로 초기화
  }, [filter]);

  useEffect(() => {
    refetch();
  }, [filter, pageNum]);
  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
    refetch();
  };

  //전체 선택 체크박스 핸들러
  const handleAllCheckBox = () => {
    const newAllCheckBoxChecked = !allCheckBoxChecked;
    setAllCheckBoxChecked(newAllCheckBoxChecked);
    // console.log(allCheckBoxChecked);
    const checkHandlerArray = new Array(oneCheckBoxChecked.length).fill(
      newAllCheckBoxChecked
    );
    setOneCheckBoxChecked(checkHandlerArray);
  };

  //각 항목 체크박스 핸들러
  const handleOneCheckBox = (index: number) => {
    oneCheckBoxChecked[index] = !oneCheckBoxChecked[index];
    setOneCheckBoxChecked([...oneCheckBoxChecked]);
  };

  //체크박스 선택된 데이터
  const selectedArray = useMemo(() => {
    return (cheerList?.data?.page.content as Item[] | undefined)?.filter(
      (item, idx) => oneCheckBoxChecked[idx]
    );
  }, [oneCheckBoxChecked, cheerList]);
  const activityIdinArray = selectedArray?.map((item) => item.activityId);

  //첨부사진 관련 state
  const [snapShot, setSnapShot] = useState<String>("");
  const [completeAt, setCompleteAt] = useState<string>("");

  const onApproval = async (activityIds: number, count: number) => {
    const body = {
      activityIds: activityIds,
      count: count,
    };
    await CheeringApprovalAPI(body)
      .then(() => {
        setPopupState({
          ...popupState,
          isOpenApprovePopup: false,
        });
        window.location.reload();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  const onRejection = async (
    activityIds: number,
    count: number,
    reason: string
  ) => {
    const body = {
      activityIds: activityIds,
      count: count,
      reason: reason,
    };
    await CheeringRejectAPI(body)
      .then(() => {
        setPopupState({
          ...popupState,
          isOpenRejectPopup: false,
        });
        window.location.reload();
      })
      .catch(() => {
        alert("다시 시도하세요.");
        window.location.reload();
      });
  };

  return (
    <Wrapper>
      {popupState.isOpenPopupTest && (
        <TestPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupTest: false,
            })
          }
          setSearch={(newGame: number) =>
            setFilter((prevState) => ({
              ...prevState,
              gameId: newGame,
            }))
          }
        />
      )}
      {popupState.isOpenPopupImage && (
        <ImagePopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupImage: false,
            })
          }
          phaseCheeringSnapshotUrl={snapShot}
          phaseCheeringCompletedAt={completeAt}
        />
      )}
      {popupState.isOpenPopupUser && (
        <UserPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupUser: false,
            })
          }
          setSearch={(newUser: number) =>
            setFilter((prevState) => ({
              ...prevState,
              userId: newUser,
            }))
          }
        />
      )}
      {popupState.isOpenPopupConfirmed && (
        <ConfirmedPopup
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenPopupConfirmed: false,
            })
          }
          setSearch={(newStatus: string) =>
            setFilter((prevState) => ({
              ...prevState,
              status: newStatus,
            }))
          }
        />
      )}
      {popupState.isOpenApprovePopup && (
        <ApproveModal
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenApprovePopup: false,
            })
          }
          callAPI={onApproval}
          selectArr={activityIdinArray}
        />
      )}
      {popupState.isOpenRejectPopup && (
        <RejectModal
          onClose={() =>
            setPopupState({
              ...popupState,
              isOpenRejectPopup: false,
            })
          }
          callAPI={onRejection}
          selectArr={activityIdinArray}
        />
      )}
      <Inner>
        <Header>
          <Title>게임 테스터 응원하기</Title>

          <Button
            onClick={() => {
              setFilter({
                userId: null,
                gameId: null,
                status: null,
              });
            }}
          >
            전체 목록 보기
          </Button>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th>
                  <ThTxt>
                    {/*전체 선택 체크박스 컴포넌트 */}
                    <CheeringAllCheck
                      checked={allCheckBoxChecked}
                      onChange={handleAllCheckBox}
                    />
                    <ThP>전체 선택</ThP>
                  </ThTxt>
                </Th>
                <Th>제출 일시</Th>
                <Th>
                  <ThTxt
                    onClick={() => {
                      setPopupState({
                        ...popupState,
                        isOpenPopupTest: true,
                      });
                    }}
                  >
                    <div>게임 타이틀</div>
                    <ThButton>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </ThButton>
                  </ThTxt>
                </Th>
                <Th>
                  <ThTxt
                    onClick={() => {
                      setPopupState({
                        ...popupState,
                        isOpenPopupUser: true,
                      });
                    }}
                  >
                    <div>유저</div>
                    <ThButton>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </ThButton>
                  </ThTxt>
                </Th>
                <Th>첨부사진</Th>

                <Th>테스터 카테고리</Th>
                <Th>
                  <ThTxt
                    onClick={() => {
                      setPopupState({
                        ...popupState,
                        isOpenPopupConfirmed: true,
                      });
                    }}
                  >
                    <div>제출상태</div>
                    <ThButton>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </ThButton>
                  </ThTxt>
                </Th>
                <Th>설문 인증상태</Th>
              </Tr>
            </thead>
            <Tbody>
              {cheerList &&
                cheerList?.data?.page?.content.map(
                  (tester: any, index: number) => {
                    return tester.confirmed === false ? (
                      <TrYellow key={tester.activityId}>
                        <td>
                          <CheeringOnlyCheck
                            index={index}
                            checked={oneCheckBoxChecked}
                            onChange={() => handleOneCheckBox(index)}
                            disabled={tester.confirmed}
                          />
                        </td>
                        <td>
                          {tester.phaseCheeringCompletedAt &&
                            new Date(
                              tester.phaseCheeringCompletedAt
                            ).toLocaleString()}
                        </td>
                        <td>{tester.game}</td>

                        <td>{tester.nickname}</td>
                        <td
                          onClick={() => {
                            setSnapShot(tester.phaseCheeringSnapshotUrl);
                            setCompleteAt(tester.phaseCheeringCompletedAt);
                            setPopupState({
                              ...popupState,
                              isOpenPopupImage: true,
                            });
                          }}
                        >
                          <SnapshotButton>
                            <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                          </SnapshotButton>
                        </td>

                        <td>
                          {tester.category === "PHASE_NONE"
                            ? "테스터 등록 가능"
                            : tester.category === "PHASE_0"
                            ? "테스터 등록"
                            : tester.category === "PHASE_1"
                            ? "1단계 완료"
                            : tester.category === "PHASE_2_TEMPORARY"
                            ? "2단계 진행중"
                            : tester.category === "PHASE_2"
                            ? "2단계 완료"
                            : tester.category === "PHASE_CHEERING"
                            ? "응원하기"
                            : tester.category === "PHASE_REVIEW"
                            ? "리뷰글"
                            : tester.category === "PHASE_COMPLETION"
                            ? "모든 테스트 완료"
                            : "코드 오류"}
                        </td>
                        <td>
                          {tester.status === "PENDING"
                            ? "대기"
                            : tester.status === "REJECTED"
                            ? "거절"
                            : tester.status === "APPROVED"
                            ? "승인"
                            : "-"}
                        </td>
                        <td>{tester.confirmed === false ? "대기" : "완료"}</td>
                      </TrYellow>
                    ) : (
                      <TrGray key={tester.activityId}>
                        <td>
                          <CheeringOnlyCheck
                            index={index}
                            checked={oneCheckBoxChecked}
                            onChange={() => handleOneCheckBox(index)}
                            disabled={tester.confirmed}
                          />
                        </td>
                        <td>
                          {tester.phaseCheeringCompletedAt &&
                            new Date(
                              tester.phaseCheeringCompletedAt
                            ).toLocaleString()}
                        </td>
                        <td>{tester.game}</td>

                        <td>{tester.nickname}</td>
                        <td
                          onClick={() => {
                            setSnapShot(tester.phaseCheeringSnapshotUrl);
                            setCompleteAt(tester.phaseCheeringCompletedAt);
                            setPopupState({
                              ...popupState,
                              isOpenPopupImage: true,
                            });
                          }}
                        >
                          <SnapshotButton>
                            <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                          </SnapshotButton>
                        </td>

                        <td>
                          {tester.category === "PHASE_NONE"
                            ? "테스터 등록 가능"
                            : tester.category === "PHASE_0"
                            ? "테스터 등록"
                            : tester.category === "PHASE_1"
                            ? "1단계 완료"
                            : tester.category === "PHASE_2_TEMPORARY"
                            ? "2단계 진행중"
                            : tester.category === "PHASE_2"
                            ? "2단계 완료"
                            : tester.category === "PHASE_CHEERING"
                            ? "응원하기"
                            : tester.category === "PHASE_REVIEW"
                            ? "리뷰글"
                            : tester.category === "PHASE_COMPLETION"
                            ? "모든 테스트 완료"
                            : "코드 오류"}
                        </td>
                        <td>
                          {tester.status === "PENDING"
                            ? "대기"
                            : tester.status === "REJECTED"
                            ? "거절"
                            : tester.status === "APPROVED"
                            ? "승인"
                            : "-"}
                        </td>
                        <td>{tester.confirmed === false ? "대기" : "완료"}</td>
                      </TrGray>
                    );
                  }
                )}
            </Tbody>
          </Table>
          <BottomDiv>
            <Col />
            <Col>
              <PaginationWrapper>
                <Pagination
                  activePage={Number(pageNum)}
                  itemsCountPerPage={10}
                  totalItemsCount={
                    cheerList?.data?.page?.pageable.totalElements || 1
                  }
                  pageRangeDisplayed={5}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                />
              </PaginationWrapper>

              {cheerList?.data?.page?.count === 0 && (
                <NoneData>검색 결과 없음</NoneData>
              )}
            </Col>
            <Col>
              <ButtonDiv>
                <ConfirmButton
                  type="button"
                  className="okBtn-s"
                  onClick={() => {
                    setPopupState({
                      ...popupState,
                      isOpenApprovePopup: true,
                    });
                  }}
                >
                  인증하기
                </ConfirmButton>
                <ConfirmButton
                  style={{ marginLeft: "1rem" }}
                  type="button"
                  className="okBtn-s ms-3"
                  onClick={() => {
                    setPopupState({
                      ...popupState,
                      isOpenRejectPopup: true,
                    });
                  }}
                >
                  거절하기
                </ConfirmButton>
              </ButtonDiv>
            </Col>
          </BottomDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
