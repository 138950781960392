import instance from "../../../../../API/MainAPI";

export default function CouponCreationAPI(body: any) {
  return instance.post("/v1/admins/coupons/managements", body, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
