import instance from "../../../../../API/MainAPI";

export default function DailyQuestListAPI(page: number) {
  return instance.get("/v3/admins/dailyQuests/managements", {
    params: {
      pageSize: 10,
      pageNumber: page - 1,
    },
  });
}
