import instance from "API/MainAPI";

export default function approveDayMission(dayMissionActivityIds: number[]) {
  return instance.patch<{
    success: boolean;
  }>("/v3/admins/dayMissions/activities/managements/approval", {
    dayMissionActivityIds,
    count: dayMissionActivityIds.length,
  });
}
