export const keys = <T extends Record<PropertyKey, unknown>>(
  o: T
): (keyof T)[] => Object.keys(o);

// 숫자를 주어진 자릿수만큼 0으로 채워주는 함수. (ex. `zeropad(1, 2) === '01'`)
export const zeropad = (num: number, pad: number) =>
  num.toString().padStart(pad, '0');

// 날짜 포맷팅 함수(ex. `YYYY-MM-DD HH:mm:ss`)
export const formatDate = (date: Date) => {
  // format `Date` to `YYYY-MM-DD HH:mm:ss` if year is not current year else `MM-DD HH:mm:ss`
  const today = new Date();

  const year = zeropad(date.getFullYear(), 2);
  const month = zeropad(date.getMonth() + 1, 2);
  const day = zeropad(date.getDate(), 2);

  const hour = zeropad(date.getHours(), 2);
  const minute = zeropad(date.getMinutes(), 2);

  if (today.getFullYear() === date.getFullYear()) {
      return `${month}.${day}. ${hour}:${minute}`;
  }

  return `${year}.${month}.${day}. ${hour}:${minute}`;
};

// 타임스탬프 포맷팅 함수(ex. `YYYY-MM-DD HH:mm:ss`)
export const formatTimestamp = (date: Date) => {
  const year = zeropad(date.getFullYear(), 2);
  const month = zeropad(date.getMonth() + 1, 2);
  const day = zeropad(date.getDate(), 2);

  const hour = zeropad(date.getHours(), 2);
  const minute = zeropad(date.getMinutes(), 2);
  const second = zeropad(date.getSeconds(), 2);

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};