import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import DonationCreationAPI from "./Data/DonationCreation";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const ClickedTitle = styled.h2`
  font-size: 30px;
  font-weight: 700;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 28px;
  color: gray;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: center;
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TxtInput = styled.input`
  width: 30%;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;
const Select = styled.select`
  border: 1px solid #a9a9a9;
  font-size: 16px;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const Label = styled.label`
  margin-right: 3rem;
  font-size: 16px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const NumInput = styled.input`
  width: 30vw;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const SubmitButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function DonationAdd() {
  const { register, setValue, getValues } = useForm();
  const navigate = useNavigate();

  const formData = new FormData();

  const [img, setImg] = useState<File | undefined>();

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("image", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallAPI() {
    const categoryValue = getValues("category");
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const descriptionValue = getValues("description");
    const expValue = getValues("exp");
    const priceValue = getValues("price");
    const visibleValue = getValues("visible");

    const blob = new Blob(
      [
        JSON.stringify({
          category: categoryValue,
          title: titleValue,
          subtitle: subtitleValue,
          description: descriptionValue,
          exp: expValue,
          price: priceValue,
          visible: visibleValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", blob);

    if (img) {
      formData.append("image", img);
    }

    await donateAddMutation.mutateAsync(formData);
  }

  const donateAddMutation = useMutation(DonationCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("후원 상품 등록이 완료되었습니다.");
      navigate("/gifticons");
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const categoryValue = getValues("category");
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const descriptionValue = getValues("description");
    const expValue = getValues("exp");
    const priceValue = getValues("price");
    const visibleValue = getValues("visible");

    if (expValue < 0 || expValue > 100000) {
      alert("포인트는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
    } else if (
      categoryValue &&
      titleValue &&
      subtitleValue &&
      descriptionValue &&
      priceValue &&
      visibleValue
    ) {
      setModal(true);
    } else {
      alert("모든 항목을 입력하세요");
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <Inner>
        <Header>
          <Link to="/gifticons/add" style={{ textDecoration: "none" }}>
            <ClickedTitle>기프티콘 추가</ClickedTitle>
          </Link>

          <Title>/후원 상품 추가</Title>
        </Header>
        <Contents>
          <div>{img && <Img src={URL.createObjectURL(img)} alt="" />}</div>
          <div>
            <ImgInput type="file" accept=".jpg, .png" onChange={Image} />
          </div>
          <Item>
            <ItemTitle>후원명</ItemTitle>
            <TxtInput {...register("title")} />
          </Item>

          <Item>
            <ItemTitle>부가 설명</ItemTitle>
            <div>
              <TxtInput {...register("subtitle")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>상세 설명</ItemTitle>
            <div>
              <TextArea rows={8} maxLength={500} {...register("description")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>분류</ItemTitle>

            <Select {...register("category")}>
              <option value="DONATION">후원</option>
            </Select>
          </Item>
          <Item>
            <ItemTitle>
              경험치
              <ItemSpan>*숫자만 입력 가능 / 필수 아님</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("exp")} />
          </Item>

          <Item>
            <ItemTitle>
              후원 가격
              <ItemSpan>*숫자만 입력 가능</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("price")} />
          </Item>

          <Item>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("visible")} />
              보이기
            </Label>
            <Label>
              <input type="radio" value="false" {...register("visible")} />
              숨기기
            </Label>
          </Item>
          <ButtonDiv>
            <SubmitButton onClick={onAdd}> 등록하기 </SubmitButton>
          </ButtonDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
