import instance from "API/MainAPI";

export default function rejectDayMission({
  dayMissionActivityIds,
  reason,
}: {
  dayMissionActivityIds: number[];
  reason: string;
}) {
  return instance.patch<{
    success: boolean;
  }>("/v3/admins/dayMissions/activities/managements/rejection", {
    dayMissionActivityIds,
    count: dayMissionActivityIds.length,
    reason,
  });
}
