import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";

import { useQuery } from "react-query";
import styled from "styled-components";
import formatDate from "../../../common/function/formatDate";

import { Link, useLocation } from "react-router-dom";
import EventRegisterPageAPI from "./Data/EventRegisterPageAPI";
import { clearUserRegister } from "../../../common/clearStorage";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Count = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-left: 30px;
`;

const Button = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const Table = styled.table`
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  caption-side: bottom;
  text-indent: initial;
  border-spacing: 2px;
`;

const Tr = styled.tr`
  background-color: #fff;
  border: 1px solid #fff;
  color: #333742;
  text-align: center;
`;

const Th = styled.th`
  display: table-cell;
  font-weight: bold;
`;

const ThTxt = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Col = styled.div`
  flex: 1 0;
`;

const Tbody = styled.tbody`
  text-align: center;
  border-spacing: 2px;
`;

const TrYellow = styled.tr`
  background-color: #ffc200;
  border: 10px solid #fff;
  height: 60px;
`;

const NoneData = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function EventRegisterPage() {
  const pageNum = sessionStorage.getItem("userRegisterPage") || 1;
  const location = useLocation();
  const id = location.state.id;

  const { data: eventsRegister, refetch } = useQuery(
    ["eventsRegister", pageNum],
    async () => await EventRegisterPageAPI(id)
  );

  useEffect(() => {
    clearUserRegister();
  }, []);

  let count = 0;

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>등록 유저 목록</Title>
          <Count>총인원: {eventsRegister?.data.count}</Count>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th />
                <Th>No.</Th>

                <Th>
                  <ThTxt>유저</ThTxt>
                </Th>

                <Th>핸드폰</Th>

                <Th />
              </Tr>
            </thead>
            <Tbody>
              {eventsRegister?.data.accounts.map((tester: any) => {
                count = count + 1;
                return (
                  <TrYellow key={tester.id}>
                    {" "}
                    <td />
                    <td>{count}</td>
                    <td>{tester.nickname}</td>
                    <td>{tester.phone}</td>
                    <td />
                  </TrYellow>
                );
              })}
            </Tbody>
          </Table>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
