import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BounceRateSurveyCreationAPIAPI from "./Data/BounceRateSurveyCreation";

import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TxtInput = styled.input`
  width: 30%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  margin-left: 10px;
  padding: 5px;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AppendButton = styled.button`
  margin-left: 3rem;
  background-color: gray;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;

  width: 60px;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function BounceRateSurveyAdd() {
  const { register, control, getValues, setValue, watch } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "choices",
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const formData = new FormData();

  const watchCategory = watch("category");

  async function CallAPI() {
    const contentValue = getValues("content");
    const visibleValue = getValues("visible");
    const allowedChoicesValue =
      watchCategory !== "MULTIPLE_CHOICE" ? 0 : getValues("allowedChoices");
    const minAnswerLengthValue =
      watchCategory !== "SUBJECTIVE" ? 0 : getValues("minAnswerLength");
    const categoryValue = getValues("category");
    const choicesValue = getValues("choices");
    const inputArray: { content: string; visible: boolean }[] = choicesValue;

    for (let i = 0; i < inputArray.length; i++) {
      formData.append("choices[" + i + "].content", inputArray[i].content);
      // @ts-ignore
      formData.append("choices[" + i + "].visible", inputArray[i].visible);
      /**
      const contentBlob = new Blob(
        [
          JSON.stringify({
            content: inputArray[i].content,
          }),
        ],
        {
          type: "application/json",
        }
      );
      const visibleBlob = new Blob(
        [
          JSON.stringify({
            visible: inputArray[i].visible,
          }),
        ],
        {
          type: "application/json",
        }
      );
      formData.append(`choices`, contentBlob);
      formData.append(`choices`, visibleBlob);
       **/
    }

    formData.append("content", contentValue);
    formData.append("visible", visibleValue);
    formData.append("allowedChoices", allowedChoicesValue);
    formData.append("minAnswerLength", minAnswerLengthValue);
    formData.append("category", categoryValue);
    formData.append("surveyCategory", "CBT_BREAKAWAY");

    await adsAddMutation.mutateAsync(formData);
  }

  const adsAddMutation = useMutation(BounceRateSurveyCreationAPIAPI, {
    onSuccess: () => {
      setModal(false);

      alert("설문 등록이 완료되었습니다.");
      queryClient.invalidateQueries("surveys");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const contentValue = getValues("content");
    const visibleValue = getValues("visible");
    const categoryValue = getValues("category");
    const choicesValue = getValues("choices");
    const inputArr: { content: string; visible: boolean }[] = choicesValue;
    const allowedChoicesValue =
      watchCategory === "MULTIPLE_CHOICE" ? getValues("allowedChoices") : 0;
    const allFieldsFilled =
      contentValue && visibleValue && categoryValue && choicesValue;
    if (
      (categoryValue == "SUBJECTIVE" ||
        categoryValue == "PHOTO_AUTHENTICATION") &&
      inputArr.length != 0
    ) {
      alert(
        "카테고리가 주관식이나 사진 첨부일때는 선택지를 등록하시면 안됩니다."
      );
    } else if (allFieldsFilled) {
      setModal(true);
    } else {
      alert("내용, 숨김여부, 선택지 허용 갯수, 분류, 선택지 입력해주세요.");
    }
  }

  useEffect(() => {
    setValue("category", "MULTIPLE_CHOICE");
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <Inner>
        <Header>
          <Title>설문 추가</Title>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>설문 내용</ItemTitle>
            <div>
              <TitleInput type="text" {...register("content")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>설문 숨김여부</ItemTitle>
            <Label>
              <input type="radio" value="true" {...register("visible")} />
              보이기
            </Label>
            <Label>
              <input type="radio" value="false" {...register("visible")} />
              숨기기
            </Label>
          </Item>

          <Item>
            <ItemTitle>카테고리</ItemTitle>
            <Select {...register("category")}>
              <option value="MULTIPLE_CHOICE">객관식</option>
              <option value="SUBJECTIVE">주관식</option>
              <option value="PHOTO_AUTHENTICATION">사진 첨부</option>
            </Select>
          </Item>
          {/* {watchCategory === "MULTIPLE_CHOICE" && ( */}
          <>
            {watchCategory === "MULTIPLE_CHOICE" && (
              <>
                <Item>
                  <ItemTitle>
                    다중 투표 허용 갯수
                    <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                  </ItemTitle>
                  <TxtInput
                    style={{
                      marginLeft: "0",
                    }}
                    type="number"
                    min="1"
                    {...register("allowedChoices")}
                  />
                </Item>
                <Item style={{ display: "flex", alignItems: "center" }}>
                  <ItemTitle>선택지</ItemTitle>
                  <AppendButton
                    type="button"
                    onClick={() => {
                      append({
                        visible: false,
                      });
                    }}
                  >
                    등록
                  </AppendButton>
                </Item>

                <div>
                  {fields.map((item, index) => {
                    return (
                      <div key={item.id} style={{ marginBottom: "1rem" }}>
                        선택지 #{`${index + 1}`}
                        <TxtInput
                          {...register(`choices.${index}.content`, {
                            required: true,
                          })}
                        />
                        <>
                          <input
                            type="radio"
                            value="true"
                            {...register(`choices.${index}.visible`)}
                          />
                          보이기
                        </>
                        <>
                          <input
                            type="radio"
                            value="false"
                            {...register(`choices.${index}.visible`)}
                          />
                          숨기기
                        </>
                        <DeleteButton
                          type="button"
                          onClick={() => remove(index)}
                        >
                          Delete
                        </DeleteButton>
                        <div />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>

          {watchCategory === "SUBJECTIVE" && (
            <Item>
              <ItemTitle>
                최소 답변 길이
                <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
              </ItemTitle>
              <TxtInput
                style={{
                  marginLeft: "0",
                }}
                type="number"
                min="0"
                {...register("minAnswerLength")}
              />
            </Item>
          )}
        </Contents>
        <ButtonDiv>
          <AddButton onClick={onAdd}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
