import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import GetDayMissionGameDetailAPI from "./Data/GetDayMissionGameDetailAPI";
import DayMissionGameHeader from "../shared/DayMissionGameHeader";
import date from "components/common/function/date";
import { DayMissionGameDetail } from "../types";

const Wrapper = styled.div`
  flex: 4;
  overflow-x: auto;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
`;
const ImageContainer = styled.div`
  display: flex;
  gap: 46px;
`;

const Banner = styled.img`
  width: 368px;
  object-fit: contain;
`;
const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
`;
const Img = styled.img`
  width: 208px;
  object-fit: contain;
  object-position: center bottom;
`;
const Details = styled.div`
  border: 2px solid #e6e6e6;
  border-radius: 22px;
  padding: 16px 64px;
  margin-top: 42px;
`;
const FieldName = styled.td`
  padding: 15px;
  color: #9d9d9d;
  font-size: 22px;
  font-weight: 600;
  min-width: 155px;
  padding-right: 22px;
`;
const FieldValue = styled.td`
  font-size: 22px;
  font-weight: 600;
`;
const DayMissionContainer = styled.div`
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 60px;
`;
const DayMission = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex: 0 0 auto;
`;
const DayMissionImage = styled.div<{ $isHorizontal?: boolean }>`
  border: 3px dashed #d9d9d9;
  padding: 20px 12px;
  border-radius: 10px;
  img {
    ${(props) => (!props.$isHorizontal ? "width: 180px" : "height: 180px")};
    object-fit: contain;
  }
`;
const DayMissionContent = styled.div`
  border: 2px solid rgba(230, 230, 230, 0.43);
  border-radius: 10px;
  padding: 20px 30px;
`;
const Day = styled.div`
  font-size: 22px;
  font-weight: 600;
`;
const Name = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
`;
const Value = styled.div`
  margin-top: 6px;
  font-size: 20px;
  font-weight: 500;
  color: #666;
`;
const Button = styled(Link)`
  margin-left: auto;
  display: block;

  width: fit-content;
  padding: 13px 34px;
  color: white;
  background: #2282e9;
  outline: 0;
  border: 0;
  border-radius: 13px;
  font-size: 24px;
  text-decoration: none;
  margin-top: 80px;
  &:first-child {
    margin-bottom: 130px;
  }
`;

type Field = {
  accessor: keyof DayMissionGameDetail | "platform";
  name: string;
};

const fields: Field[] = [
  {
    name: "게임이름",
    accessor: "title",
  },
  {
    name: "게임사",
    accessor: "company",
  },
  {
    name: "플랫폼",
    accessor: "platform",
  },
  {
    name: "유튜브 링크",
    accessor: "youtubeLink",
  },
  {
    name: "장르",
    accessor: "genres",
  },
  {
    name: "시작일",
    accessor: "startedAt",
  },
  {
    name: "종료일",
    accessor: "expiredAt",
  },
  {
    name: "게임 설명(썸네일)",
    accessor: "subtitle",
  },
  {
    name: "게임 설명",
    accessor: "description",
  },
];
const genres = [
  { value: "ROGUE_LITE_GAME", label: "로그라이트" },
  { value: "SHOOTING_GAME", label: "슈팅 게임" },
  { value: "RTS_GAME", label: "RTS(실시간 전략게임)" },
  { value: "AOS_GAME", label: "AOS" },
  { value: "MANAGEMENT_GAME", label: "경영(타이쿤)" },
  { value: "DEFENSE_GAME", label: "디펜스" },
  { value: "IDLE_TYPE_GAME", label: "방치형" },
  { value: "ARCADE_GAME", label: "아케이드" },
  { value: "PUZZLE_GAME", label: "퍼즐 게임" },
  { value: "TURN_BASE_GAME", label: "턴제" },
  { value: "SIMULATION_GAME", label: "시뮬레이션" },
  { value: "MERGE_GAME", label: "머지(Merge - 합성)" },
  { value: "ADVENTURE_GAME", label: "어드벤처 게임" },
  { value: "SANDBOX_GAME", label: "샌드박스" },
  { value: "RACING_GAME", label: "레이싱 게임" },
  { value: "STRATEGY_GAME", label: "전술" },
  { value: "ACTION_GAME", label: "액션 게임" },
  { value: "SPORTS_GAME", label: "스포츠 게임" },
  { value: "RPG_GAME", label: "RPG" },
  { value: "TRPG_GAME", label: "TRPG" },
  {
    value: "FARMING_GAME",
    label: "농사",
  },
  {
    value: "EVENT_GAME",
    label: "이벤트",
  },
];

const DayMissionGameDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const daymissionGameId = +(params.daymissionId || "1") || 1;

  const { data: daymissionData } = useQuery(
    ["daymissiongame", daymissionGameId],
    () => GetDayMissionGameDetailAPI(daymissionGameId),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );

  const dayMissionGame = daymissionData?.data;
  return (
    <Wrapper>
      {dayMissionGame && <DayMissionGameHeader item={dayMissionGame} />}
      <Inner>
        {dayMissionGame && (
          <Contents>
            <Title>게임 상세 내용</Title>
            <ImageContainer>
              <Banner src={dayMissionGame.bannerUrl} />
              <Images>
                {dayMissionGame.imageUrls.map((url) => (
                  <Img src={url} key={url} />
                ))}
              </Images>
            </ImageContainer>
            <Details>
              {fields.map(({ name, accessor }) => {
                let value: string;
                if (accessor === "genres") {
                  value = dayMissionGame["genres"]
                    .map(
                      (g) =>
                        genres.find(({ value, label }) => value === g)?.label
                    )
                    .join(" ");
                } else if (
                  accessor === "expiredAt" ||
                  accessor === "startedAt"
                ) {
                  value = date(new Date(dayMissionGame[accessor]!));
                } else if (accessor !== "platform")
                  value = dayMissionGame[accessor] as string;
                else {
                  value = dayMissionGame.appStoreLink
                    ? "iOS "
                    : "" + dayMissionGame.playStoreLink
                    ? "안드로이드"
                    : "";
                }
                return (
                  <tr key={name}>
                    <FieldName>{name}</FieldName>
                    <FieldValue>{value}</FieldValue>
                  </tr>
                );
              })}
            </Details>
            <Button to={`/retension/daymissions/${daymissionGameId}/edit`}>
              수정
            </Button>
            <Title>데이미션 인증 내용</Title>
            <DayMissionContainer>
              {dayMissionGame.dayMissions.map((dayMission) => (
                <DayMission key={dayMission.id}>
                  <DayMissionImage
                    $isHorizontal={dayMission.missionImageHorizontal}
                  >
                    <img src={dayMission.missionImageUrl} />
                  </DayMissionImage>
                  <DayMissionContent>
                    <Day>Day {dayMission.dayNumber}</Day>
                    <Name>
                      {dayMission.rewardCategory === "POINT"
                        ? "포인트"
                        : "플리더스"}
                    </Name>
                    <Value>
                      {dayMission.rewards}
                      {dayMission.rewardCategory === "POINT" ? "P" : "PS"}{" "}
                    </Value>
                    <Name>경험치</Name>
                    <Value>{dayMission.exp}EXP</Value>
                  </DayMissionContent>
                </DayMission>
              ))}
            </DayMissionContainer>
            <Button
              to={`/retension/daymissions/${daymissionGameId}/edit-daymission`}
            >
              수정
            </Button>
          </Contents>
        )}
      </Inner>
    </Wrapper>
  );
};

export default DayMissionGameDetailPage;
