import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { CreateDayMissionGameDto, CreateDayMissionGameFileDto } from "../types";

export interface DayMissionGameRegisterForm
  extends CreateDayMissionGameDto,
    CreateDayMissionGameFileDto {}

export type HandleSubmitFunc = (
  data: DayMissionGameRegisterForm
) => Promise<unknown> | unknown;

const useDayMissionGameRegisterForm = (
  handleSubmitProp: HandleSubmitFunc = () => {}
) => {
  const {
    control,
    register,
    handleSubmit: onSubmit,
    ...props
  } = useForm<DayMissionGameRegisterForm>({
    defaultValues: {
      genres: [],
      dayMissionRecords: [
        {
          dayNumber: 1,
        },
      ],
    },
  });

  const _handleSubmit: SubmitHandler<DayMissionGameRegisterForm> = async (
    data
  ) => {
    await handleSubmitProp(data);
  };
  const _handleError: SubmitErrorHandler<DayMissionGameRegisterForm> = (
    data
  ) => {
    window.alert("채우지 않은 필수 항목이 있습니다.");
  };
  const handleSubmit = onSubmit(_handleSubmit, _handleError);
  return {
    handleSubmit,
    inputs: {
      title: register("title"),
      subtitle: register("subtitle"),
      company: register("company", { required: true }),
      thumbnail: register("thumbnail", { required: true }),
      appStoreLink: register("appStoreLink"),
      playStoreLink: register("playStoreLink"),
      youtubeLink: register("youtubeLink"),
      startedAt: register("startedAt", { required: true }),
      expiredAt: register("expiredAt", { required: true }),
      genres: register("genres"),
      description: register("description"),
      visible: register("visible"),
      gameImages: register("gameImages", { required: true }),
    },
    control,
    register,
    ...props,
  };
};

export default useDayMissionGameRegisterForm;
