import instance from "../../../../../API/MainAPI";

export default function NoticePage(page: string) {
  return instance.get("/v1/admins/notices/managements", {
    params: {
      pageSize: 10,
      pageNumber: Number(page) - 1,
    },
  });
}
