import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import { useQuery } from "react-query";

import { useEffect } from "react";
import { cleargifticon } from "../../../common/clearStorage";
import index from "../../../common/function";
import GifticonPageAPI from "./Data/GiftcionListAPI";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const Td = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function GifticonList() {
  const pageNum = sessionStorage.getItem("gifticonPage") || "1";

  const { data: gifticonData, refetch } = useQuery(
    ["gifticon", pageNum],
    () => GifticonPageAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("gifticonPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    cleargifticon();
  }, []);

  let count = 0;

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>기프티콘 목록</Title>

          <Link to="/gifticons/add">
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th>No.</Th>
                <Th>제품명</Th>
                <Th>분류</Th>
                <Th>가격</Th>
                <Th>공개여부</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {gifticonData?.data.page.content &&
                gifticonData?.data.page.content.map((gifticon: any) => {
                  count = count + 1;
                  return (
                    <Tr key={gifticon.id}>
                      <Td>
                        {index(
                          gifticonData?.data?.page?.pageable.totalElements,
                          gifticonData?.data?.page?.pageable.pageNumber + 1,
                          gifticonData?.data?.page?.pageable.pageSize,
                          count
                        )}
                      </Td>
                      <Td>{gifticon.title}</Td>
                      <Td>
                        {gifticon.category === "GAME_GIFT_CARD"
                          ? "게임 기프트 카드"
                          : gifticon.category === "CAFE_BAKERY"
                          ? "카페|베이커리"
                          : gifticon.category === "CONVENIENCE_STORE"
                          ? "편의점"
                          : gifticon.category === "CHICKEN_PIZZA_BURGER"
                          ? "치킨|피자|버거"
                          : gifticon.category === "EAT_OUT"
                          ? "외식"
                          : gifticon.category === "DONATION"
                          ? "후원"
                          : "기타"}
                      </Td>
                      <Td>{gifticon.price}</Td>
                      <Td>{gifticon.visible === true ? "보임" : "숨김"}</Td>
                      <Td>
                        {gifticon.category === "DONATION" ? (
                          <Link
                            to={"/gifticons/donation/" + gifticon.id}
                            className="link"
                            state={{
                              id: gifticon.id,
                              visible: gifticon.visible,
                            }}
                          >
                            <DetailButton className="detailBtn">
                              자세히
                            </DetailButton>
                          </Link>
                        ) : (
                          <Link
                            to={"/gifticons/gifticon/" + gifticon.id}
                            className="link"
                            state={{
                              id: gifticon.id,
                              visible: gifticon.visible,
                            }}
                          >
                            <DetailButton className="detailBtn">
                              자세히
                            </DetailButton>
                          </Link>
                        )}
                      </Td>
                    </Tr> 
                  );
                })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                gifticonData?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
