import { PageableData } from "API/type";
import instance from "../../../../../API/MainAPI";
import { BountyHuntTime, BountyStatus } from "../../types";

interface BountyHunterTimeListParams {
  bountyId: number;
  userId?: number;
  status?: BountyStatus;
  pageNumber: number;
  pageSize: number;
}

export default function BountyHunterTimeList({
  bountyId,
  userId,
  status,
  pageNumber,
  pageSize,
}: BountyHunterTimeListParams) {
  return instance.get<PageableData<BountyHuntTime>>(
    "/v3/admins/bountyHunters/managements/time",
    {
      params: {
        pageNumber,
        pageSize,
        status,
        bounty: bountyId,
        user: userId,
      },
    }
  );
}
