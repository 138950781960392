import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import { useQuery } from "react-query";
import ElectionPageAPI from "./Data/ElectionListAPI";
import { useEffect, useRef, useState } from "react";
import index from "../../../common/function";
import { clearElection } from "../../../common/clearStorage";
import date from "../../../common/function/date";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const Td = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function ElectionList() {
  const pageNum = sessionStorage.getItem("electionPage") || "1";
  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };

  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const { data: electionData, refetch } = useQuery(
    ["election", pageNum],
    () => ElectionPageAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("electionPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearElection();
  }, []);

  let count = 0;

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>투표 목록</Title>

          <Link to="add">
            <AddBtn className="addBtn-min">등록</AddBtn>
          </Link>
        </Header>
        <Contents>
          <Table>
            <Thead>
              <Tr className="tableHeader">
                <Th className="col p-2">No.</Th>
                <Th>제목</Th>
                <Th>썸네일</Th>
                <Th className="col p-2">리워드</Th>
                <Th className="col p-2">다중 투표 허용 갯수</Th>
                <Th className="col p-2">시작일시</Th>
                <Th className="col p-2">만료일시</Th>
                <Th className="col p-2">공개여부</Th>
                <Th className="col p-2"></Th>
              </Tr>
            </Thead>
            <tbody>
              {electionData?.data.page.content &&
                electionData?.data.page.content.map((election: any) => {
                  count = count + 1;
                  return (
                    <Tr key={election.id}>
                      <Td>
                        {index(
                          electionData?.data?.page?.pageable.totalElements,
                          electionData?.data?.page?.pageable.pageNumber + 1,
                          electionData?.data?.page?.pageable.pageSize,
                          count
                        )}
                      </Td>
                      <Td>{election.title}</Td>
                      <Td>
                        <img
                          src={election.thumbnailUrl}
                          width={"100px"}
                          alt=""
                        />
                      </Td>
                      <Td>{election.rewards}</Td>
                      <Td>{election.allowedCandidates}</Td>
                      <Td>{date(election.startedAt)}</Td>
                      <Td>{date(election.expiredAt)}</Td>
                      <Td>{election.visible === true ? "보임" : "숨김"}</Td>
                      <Td>
                        <Link
                          to={"/elections/" + election.id}
                          state={{ id: election.id }}
                        >
                          <DetailButton className="detailBtn">
                            자세히
                          </DetailButton>
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                electionData?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
