import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import { useQuery } from "react-query";
import OrderPageAPI from "./Data/orderPageAPI";
import { useEffect } from "react";
import { clearOrder } from "../../../common/clearStorage";
import index from "../../../common/function";
import formatDate from "../../../common/function/formatDate";
const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
  .p-2 {
    padding: 2rem;
  }
`;

const Td = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  padding: 1rem;
  .px-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .p-2 {
    padding: 2rem;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;
const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function OrderList() {
  const pageNum = sessionStorage.getItem("orderPage") || "1";

  const { data: orderData, refetch } = useQuery(
    ["order", pageNum],
    () => OrderPageAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("orderPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearOrder();
  }, []);

  let count = 0;
  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>기프티콘 구매 내역</Title>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr className="tableHeader">
                <Th>No.</Th>
                <Th>닉네임</Th>
                <Th>발송 여부 (발송 일시)</Th>
                <Th>주문 일시</Th>
                <Th>제품</Th>
                <Th>분류</Th>
                <Th>가격</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {orderData?.data.page.content &&
                orderData?.data.page.content.map((order: any) => {
                  count = count + 1;
                  return (
                    <Tr className="tableH-50" key={order.id}>
                      <Td>
                        {index(
                          orderData?.data?.page?.pageable.totalElements,
                          orderData?.data?.page?.pageable.pageNumber + 1,
                          orderData?.data?.page?.pageable.pageSize,
                          count
                        )}
                      </Td>
                      <Td>{order.consumer.nickname}</Td>
                      <Td>
                        {order.exchanged
                          ? formatDate(order.exchangedAt)
                          : "미발송"}
                      </Td>
                      <Td>{formatDate(order.orderedAt)}</Td>
                      <Td>{order.product.name}</Td>
                      <Td>
                        {order.product.category === "MOBILE_COUPON"
                          ? "모바일 상품 교환 쿠폰"
                          : order.product.category === "GOOGLE_PLAY"
                          ? "구글플레이 기프트 쿠폰"
                          : order.product.category === "EXP"
                          ? "경험치"
                          : order.product.category === "GAME_GIFT_CARD"
                          ? "게임 기프트 카드"
                          : order.product.category === "CAFE_BAKERY"
                          ? "카페,베이커리"
                          : order.product.category === "CONVENIENCE_STORE"
                          ? "편의점"
                          : order.product.category === "CHICKEN_PIZZA_BURGER"
                          ? "치킨,피자,버거"
                          : order.product.category === "EAT_OUT"
                          ? "외식"
                          : order.product.category === "DONATION"
                          ? "후원"
                          : "코드 오류"}
                      </Td>
                      <Td>{order.product.price}</Td>
                      <Td>
                        <Link
                          to={"/orders/" + order.id}
                          className="link"
                          state={{ id: order.id }}
                        >
                          <DetailButton>자세히</DetailButton>
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                orderData?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
