import instance from "../../../../../API/MainAPI";

export default function UserNickAPI(id: any, body: any) {
  return instance.patch(`/v1/admins/users/managements/${id}/nickname`, body, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
