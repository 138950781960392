import instance from "../../../../../API/MainAPI";

export default function ReviewAwardsPageAPI(page: string) {
  return instance.get("/v3/reviews/awards", {
    params: {
      pageSize: 10,
      pageNumber: Number(page) - 1,
    },
  });
}
