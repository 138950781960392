import instance from "../../../../../API/MainAPI";
import { PageableData } from "../../../../../API/type";

interface Activity {
  activityId: number;
  testerId: number;
  nickname: string;
  game: string;
  snapshotUrl: string;
  playTime: number;
  submittedAt: string;
}

export function TesterActivityAPI(page: number, filter: any) {
  return instance.get<PageableData<Activity>>(
    "/v3/admins/testers/managements/activities/surveys",
    {
      params: {
        game: filter.gameId,
        user: filter.userId,
        status: filter.status == null ? null : filter.status,
        pageNumber: page - 1,
        phase: "PHASE_2",
      },
    }
  );
}
