import instance from "../../../../../API/MainAPI";

export default function EventModificationAPI(id: any, formData: any) {
  return instance.post(`/v3/admins/events/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data; boundary=Paw",
      Accept: "application/json",
    },
  });
}
