import { ModalType } from "components/common/Modal/ModalsContext";
import { BountyHuntPhoto, DayMissionActivity } from "../../types";
import useModal from "components/common/Modal/useModal";
import ModalCard from "components/common/Modal/ModalCard";
import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "assets/images/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/images/ArrowRight.svg";
import { useEffect, useState } from "react";

type Props = {
  index: number;
  modal: ModalType;
  items: (BountyHuntPhoto | DayMissionActivity)[];
};

const ModalBody = styled(ModalCard.Body)`
  min-width: 715px;
`;

const ImageContainer = styled.div`
  width: 100%;
  min-height: 356px;
  object-fit: contain;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const ImageInner = styled.div`
  img {
    max-height: 768px;
    max-width: 768px;
  }
`;
const ArrowButton = styled.div`
  cursor: pointer;
`;

const isBountyHuntPhoto = (
  hunt: BountyHuntPhoto | DayMissionActivity
): hunt is BountyHuntPhoto => "missionUrl" in hunt;

const PhotoPreviewModal = ({ modal, items, index }: Props) => {
  if (items.length < 1) return null;
  const [idx, setIdx] = useState<number>(0);

  useEffect(() => {
    setIdx(index);
  }, [index]);

  const selectedItem = items[idx];
  return (
    <ModalCard modal={modal} hasCloseButton>
      <ModalBody>
        <ImageContainer>
          <ArrowButton onClick={() => setIdx((idx) => idx - (idx > 0 ? 1 : 0))}>
            <ArrowLeft />
          </ArrowButton>
          <ImageInner>
            <img
              src={
                isBountyHuntPhoto(selectedItem)
                  ? selectedItem.missionUrl
                  : selectedItem.imageUrl
              }
            />
          </ImageInner>
          <ArrowButton
            onClick={() =>
              setIdx((idx) => idx + (idx + 1 < items.length ? 1 : 0))
            }
          >
            <ArrowRight />
          </ArrowButton>
        </ImageContainer>
      </ModalBody>
    </ModalCard>
  );
};

export default PhotoPreviewModal;
