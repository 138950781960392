import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { EditDayMissionsDto } from "../types";

export interface DayMissionEditForm extends EditDayMissionsDto {}

export type HandleSubmitFunc = (
  data: DayMissionEditForm
) => Promise<unknown> | unknown;

const useDayMissionEditForm = (
  handleSubmitProp: HandleSubmitFunc = () => {}
) => {
  const {
    control,
    register,
    handleSubmit: onSubmit,
    ...props
  } = useForm<DayMissionEditForm>({
    defaultValues: {
      dayMissions: [
        {
          dayNumber: 1,
        },
      ],
    },
  });

  const _handleSubmit: SubmitHandler<DayMissionEditForm> = async (data) => {
    await handleSubmitProp(data);
  };
  const _handleError: SubmitErrorHandler<DayMissionEditForm> = (data) => {
    console.log(data);
    window.alert("채우지 않은 필수 항목이 있습니다.");
  };
  const handleSubmit = onSubmit(_handleSubmit, _handleError);
  return {
    handleSubmit,
    control,
    register,
    ...props,
  };
};

export default useDayMissionEditForm;
