import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";

import GameNewsDeleteAPI from "./Data/GameNewsDeleteAPI";
import GameNewsDetailAPI from "./Data/GameNewsDetailAPI";
import GameNewsModiAPI from "./Data/GameNewsModiAPI";
import DeleteModal from "./Modal/DeleteModal";
import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ShowThumb = styled.img`
  width: 400px;
  margin-bottom: 10px;
`;

const PrevDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 30%;
  height: 100%;
`;

const ShowImg = styled.img`
  width: 40%;
  height: 10%;
  margin-right: 10px;
  padding: 10px;
`;

const ClickedImg = styled.img`
  width: 40%;
  height: 10%;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`;

const ThumbnailInput = styled.input`
  margin-top: 0.5rem;
  font-size: 16px;
`;

const ImgForm = styled.div`
  width: 40%;
  height: 200px;
  border: dotted;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CircleInput = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-size: 40px;
  border: 2px solid;
  padding: 2px;
  cursor: pointer;
  align-items: center;
`;

const ImgInput = styled.input`
  font-size: 16px;
  display: none;
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;
const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;
const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function GameNewsEdit() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [images, setImages] = useState<File[]>([]);
  const [originImages, setOriginImages] = useState<string[]>([]);

  const [home, setHome] = useState<File | undefined>();

  const [showImages, setShowImages] = useState<string[]>([]);
  const formData = new FormData();
  const showLink = watch("category");

  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };

  const { data: gameNewsDetail } = useQuery(["gameNewsDetail", id], () =>
    GameNewsDetailAPI(id)
  );

  //modal
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  function onDelete() {
    setModalDelete(true);
  }
  function closeDelete() {
    setModalDelete(false);
  }
  function onEdit() {
    const redirectLinkValue = getValues("redirectLink");
    if (showLink === "EXTERNALS") {
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else {
        setModalEdit(true);
      }
    } else {
      setValue("redirectLink", "");
      setModalEdit(true);
    }
  }
  function closeEdit() {
    setModalEdit(false);
  }

  const HomeBanner = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeHomeToBase64(selectedImage);
    }
  };

  function encodeHomeToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setHome(file);
    };
  }
  const { mutate: deleteAds, isLoading: deleteLoading } = useMutation(
    async () => await GameNewsDeleteAPI(id),
    {
      onMutate: async () => {
        // Remove the query from cache before making the delete request
        queryClient.removeQueries(["gameNewsDetail", id]);
      },
      onSuccess: () => {
        closeDelete();
      },
      onError: () => {
        alert("실패했습니다. 다시 시도하세요.");
        closeDelete();
      },
    }
  );

  const handleDelete = async () => {
    await deleteAds();
    await alert("게임뉴스가 삭제되었습니다.");
    await navigate(-1); // Move navigation here
  };

  async function CallEditAPI() {
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const contentValue = getValues("content");
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");

    const modifiedData = {
      title: titleValue !== undefined ? titleValue : gameNewsDetail?.data.title,
      subtitle:
        subtitleValue !== undefined
          ? subtitleValue
          : gameNewsDetail?.data.subtitle,
      content:
        contentValue !== undefined ? contentValue : gameNewsDetail?.data.cotent,
      redirectLink:
        redirectLinkValue !== undefined
          ? redirectLinkValue
          : gameNewsDetail?.data.redirectLink,
      category:
        categoryValue !== undefined
          ? categoryValue
          : gameNewsDetail?.data.category,
    };

    const dataBlob = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("data", dataBlob);

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    if (home) {
      formData.append("home", home);
    }
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    await gameNewsEdit.mutateAsync(formData);
  }

  const gameNewsEdit = useMutation(
    (formData: FormData) => GameNewsModiAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("게임뉴스 수정이 완료되었습니다.");
        queryClient.invalidateQueries("gameNewsDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GameNewsDetailAPI(id);

    if (response) {
      setValue("title", response.data.title);
      setValue("subtitle", response.data.subtitle);
      setValue("content", response.data.content);
      setValue("redirectLink", response.data.redirectLink);
      setValue("category", response.data.category);
      setOriginImages(response.data.imageUrls);
    }
  };

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images!.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));
        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          CallAPI={handleDelete}
          isLoding={deleteLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>게임 뉴스 #{id}</Title>
        </Header>
        <Contents>
          <FlexItem>
            <Item>
              <Item>
                <ItemTitle>플리더스 뉴스 목록 썸네일</ItemTitle>
                {thumbnail ? (
                  <ShowThumb src={URL.createObjectURL(thumbnail)} alt="" />
                ) : (
                  <ShowThumb src={gameNewsDetail?.data.thumbnailUrl} alt="" />
                )}
              </Item>
              <Item>
                <ThumbnailInput
                  type="file"
                  accept=".jpg, .png"
                  className="mt-3"
                  name="chooseFile"
                  onChange={Thumbnail}
                />
              </Item>
            </Item>
            <Item style={{ marginLeft: "20%" }}>
              {" "}
              <Item>
                <ItemTitle>메인 페이지 기사 사진</ItemTitle>
                {home ? (
                  <ShowThumb src={URL.createObjectURL(home)} alt="" />
                ) : (
                  <ShowThumb
                    src={gameNewsDetail?.data.homeThumbnailUrl}
                    alt=""
                  />
                )}
              </Item>
              <Item>
                <ThumbnailInput
                  type="file"
                  accept=".jpg, .png"
                  onChange={HomeBanner}
                />
              </Item>
            </Item>
          </FlexItem>

          <Item>
            <ItemTitle>기존 이미지</ItemTitle>
          </Item>
          <Item>
            <PrevDiv>
              {originImages &&
                originImages.map((url, idx) => (
                  <ShowImg key={idx} src={url} alt="" />
                ))}
            </PrevDiv>
          </Item>
          <Item>
            <ItemTitle>
              플리더스 게임뉴스 기사 이미지 파일
              <ItemSpan>*수정 시 사진 재등록 필수(최대 5개까지)</ItemSpan>
            </ItemTitle>
          </Item>

          <Item>
            <ImgForm>
              <div>
                <CircleInput onClick={handleClick}>
                  <div style={{ marginBottom: "0.5rem" }}>+</div>
                </CircleInput>
                <ImgInput
                  ref={fileRef}
                  type="file"
                  multiple
                  accept=".jpg, .png"
                  onChange={Image}
                />
              </div>
              <ItemSpan>이미지 선택 시 삭제</ItemSpan>
            </ImgForm>
          </Item>
          <Item>
            <PrevDiv>
              {showImages &&
                showImages.map((url, idx) => (
                  <ClickedImg
                    key={idx}
                    src={url}
                    alt=""
                    onClick={() => imageDelete(idx, url)}
                  />
                ))}
            </PrevDiv>
          </Item>

          <Item>
            <ItemTitle>뉴스 제목</ItemTitle>
            <div>
              <TitleInput
                type="text"
                defaultValue={gameNewsDetail?.data.title}
                {...register("title")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>뉴스 부제목</ItemTitle>
            <div>
              <TitleInput
                type="text"
                defaultValue={gameNewsDetail?.data.subtitle}
                {...register("subtitle")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              뉴스 내용
              <ItemSpan>*최대 3000자</ItemSpan>
            </ItemTitle>
            <div>
              <TextArea
                rows={10}
                maxLength={3000}
                defaultValue={gameNewsDetail?.data.content}
                {...register("content")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>카테고리</ItemTitle>
            <div className="w-25 mt-1">
              <Select {...register("category")}>
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </Select>
              {showLink === "EXTERNALS" && (
                <Item>
                  <ItemTitle>연결 링크</ItemTitle>
                  <LinkInput
                    type="text"
                    defaultValue={gameNewsDetail?.data.redirectLink}
                    {...register("redirectLink")}
                  />
                </Item>
              )}
            </div>
          </Item>
        </Contents>
        <ButtonDiv>
          <DeleteButton onClick={onDelete}>삭제하기</DeleteButton>
          <EditButton onClick={onEdit}>수정하기</EditButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
