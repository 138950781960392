import instance from "../../../../../API/MainAPI";

export default function UserNotListAPI(page: any, id: number) {
  return instance.get(`/v1/admins/notifications/managements/${id}`, {
    params: {
      pageSize: 10,
      pageNumber: page - 1,
    },
  });
}
