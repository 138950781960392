import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ModalType } from "components/common/Modal/ModalsContext";
import useModal from "components/common/Modal/useModal";

const ModalBg = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 6;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 250px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 580px;
  min-height: 400px;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const ReasonTxt = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ReasonInput = styled.input`
  width: 70%;
  height: 1rem;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-left: 1rem;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 50px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
`;

const CancelBtn = styled.button`
  width: 50px;
  padding: 5px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

const ReasonList = styled.div`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReasonItem = styled.div`
  margin-bottom: 3px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

interface RejectModalProps {
  modal: ModalType;
  isLoading: boolean;
  handleReject: (reason: string) => void;
}

function RejectModal({ modal, isLoading, handleReject }: RejectModalProps) {
  const { closeModal } = useModal();
  const close = () => closeModal(modal.id);
  const [rejectionReason, setRejectionReason] = useState("");
  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>인증을 거절하시겠습니까?</ContentsTxt>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.8rem",
            marginLeft: "1rem",
          }}
        >
          {" "}
          <ReasonTxt>거절 사유</ReasonTxt>
          <ReasonInput onChange={(e) => setRejectionReason(e.target.value)} />
        </div>

        <DivBtn>
          <CancelBtn className="cancelBtn" onClick={close}>
            취소
          </CancelBtn>
          <OkBtn
            disabled={isLoading}
            onClick={() => handleReject(rejectionReason)}
          >
            확인
          </OkBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
}

export default RejectModal;
