import styled from "styled-components";
import TableRow from "./TableRow";
import { Column } from "./types";
import {
  BountyHunt,
  BountyHuntFilter,
  BountyHuntPhoto,
  SetBountyHuntFilter,
} from "../../types";
import TableHeader from "./TableHeader";
import { useCallback, useEffect, useMemo, useState } from "react";

type TableType = "all" | "photo" | "time";
interface CertificationTableProps {
  items: Array<BountyHunt>;
  checkedItems: Set<number | string>; // 선택된 아이템 ID의 집합
  toggleItem: (item: BountyHunt, checked: boolean) => void; // 아이템 선택 토글 함수
  toggleItems: (
    filter: (item: BountyHunt) => boolean,
    checked: boolean
  ) => void; // 모든 아이템 선택 토글 함수
  type?: TableType;
  openPreviewModal?: (items: BountyHuntPhoto[], idx: number) => void;
  setFilter: SetBountyHuntFilter;
}

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 6px;
`;

const Spacer = styled.tr`
  height: 8px;
`;

const columns: Array<Column & { type: TableType }> = [
  {
    header: "전체선택",
    accessor: "checked",
    type: "photo",
  },
  {
    header: "닉네임",
    accessor: "nickname",
    type: "all",
  },
  {
    header: "플랫폼",
    accessor: "platform",
    type: "all",
  },
  {
    header: "플레이 타임",
    accessor: "playTime",
    type: "time",
  },
  {
    header: "인증사진",
    accessor: "missionUrl",
    type: "photo",
  },
  {
    header: "인증상태",
    accessor: "bountyStatus",
    type: "all",
  },
  {
    header: "참여날짜",
    accessor: "registeredAt",
    type: "all",
  },
  {
    header: "인증날짜",
    accessor: "confirmedAt",
    type: "all",
  },
  {
    header: "반려사유",
    accessor: "rejectionReason",
    type: "photo",
  },
];
const huntFilter = (item: BountyHunt) => item.bountyStatus === "SUBMITTED";

const CertificationTable = ({
  items,
  checkedItems,
  toggleItem,
  toggleItems,
  type = "photo",
  openPreviewModal,
  setFilter,
}: CertificationTableProps) => {
  const columnsFiltered = columns.filter(
    (column) => column.type === "all" || column.type === type
  );
  const itemFiltered = useMemo(() => items.filter(huntFilter), [items]);
  const [checkbox, setCheckbox] = useState<{
    checked: boolean;
    indeterminate: boolean;
  }>({ checked: false, indeterminate: false });

  const handleToggleAllitems = useCallback(() => {
    if (checkbox.checked || checkbox.indeterminate) {
      toggleItems(huntFilter, false);
      setCheckbox({ checked: false, indeterminate: false });
    } else {
      toggleItems(huntFilter, true);
      setCheckbox({ checked: true, indeterminate: false });
    }
  }, [checkbox, toggleItems]);

  useEffect(() => {
    if (checkedItems.size === 0 || !items) {
      setCheckbox({ checked: false, indeterminate: false });
    } else if (checkedItems.size === itemFiltered.length) {
      setCheckbox({ checked: true, indeterminate: false });
    } else {
      setCheckbox({ checked: false, indeterminate: true });
    }
  }, [checkedItems, items]);
  return (
    <Table>
      <thead>
        <TableHeader
          type={type}
          columns={columnsFiltered}
          {...checkbox}
          toggleAllItems={handleToggleAllitems}
          setFilter={setFilter}
        ></TableHeader>
      </thead>
      <tbody>
        <Spacer />
        {items.map((item, index) => (
          <TableRow
            item={item}
            columns={columnsFiltered}
            key={item.bountyHunterId}
            checked={checkedItems.has(item.bountyHunterId)}
            onCheckedChange={(checked) => toggleItem(item, checked)}
            openPreviewModal={() =>
              type === "photo" &&
              openPreviewModal?.(items as BountyHuntPhoto[], index)
            }
          />
        ))}
      </tbody>
    </Table>
  );
};

export default CertificationTable;
