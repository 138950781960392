import styled from "styled-components";
import Checkbox from "../Checkbox";
import { Column } from "./types";
import useModal from "components/common/Modal/useModal";
import UserModal from "../modals/UserModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { BountyHuntFilter, SetBountyHuntFilter } from "../../types";
import StatusModal from "../modals/StatusModal";
import usePopup from "../usePopup";
import Popup from "../Popup";

type Props = {
  checked: boolean;
  indeterminate: boolean;
  toggleAllItems: () => void;
  columns: Column[];
  setFilter: SetBountyHuntFilter;
  type: "all" | "photo" | "time";
};

const TableRow = styled.tr`
  background: #333742;
  & th {
    font-size: 20px;
    font-weight: 700;
    color: white;
    padding: 15px 30px 17px;
  }
`;
const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
`;

const TableHeader = ({
  columns,
  checked,
  indeterminate,
  toggleAllItems,
  setFilter,
  type,
}: Props) => {
  const {
    visible: userModalVisible,
    open: openUserModal,
    close: closeUserModal,
  } = usePopup();
  const {
    visible: statusModalVisible,
    open: openStatusModal,
    close: closeStatusModal,
  } = usePopup();
  const renderHeader = (column: Column) => {
    switch (column.accessor) {
      case "checked":
        return (
          <Checkbox
            aria-label="Select all projects"
            checked={checked}
            indeterminate={indeterminate}
            onChange={toggleAllItems}
          >
            전체선택
          </Checkbox>
        );
      case "nickname":
        return (
          <ToggleButton onClick={openUserModal}>
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} />
            <Popup visible={userModalVisible}>
              <UserModal
                close={closeUserModal}
                setUser={(user) => setFilter((filter) => ({ ...filter, user }))}
              />
            </Popup>
          </ToggleButton>
        );
      case "bountyStatus":
        return (
          <ToggleButton onClick={openStatusModal}>
            {column.header}
            <FontAwesomeIcon icon={faCaretDown} />
            <Popup visible={statusModalVisible}>
              <StatusModal
                type={type}
                close={closeStatusModal}
                setStatus={(status) =>
                  setFilter((filter) => ({ ...filter, status }))
                }
              />
            </Popup>
          </ToggleButton>
        );
      default:
        return column.header;
    }
  };
  return (
    <TableRow>
      {columns.map((column) => (
        <th key={column.accessor}>{renderHeader(column)}</th>
      ))}
    </TableRow>
  );
};

export default TableHeader;
