import styled from "styled-components";
import { Column, DayMissionColumn } from "../types";
import {
  BountyHunt,
  BountyHuntFilter,
  BountyHuntPhoto,
  DayMissionActivity,
  DayMissionGame,
  DayMissionGameDetail,
  SetBountyHuntFilter,
} from "../../../types";
import TableHeader from "./TableHeader";
import { useCallback, useEffect, useMemo, useState } from "react";
import TableRow from "./TableRow";

interface CertificationTableProps {
  dmg?: DayMissionGameDetail;
  items: Array<DayMissionActivity>;
  checkedItems: Set<number | string>; // 선택된 아이템 ID의 집합
  toggleItem: (item: DayMissionActivity, checked: boolean) => void; // 아이템 선택 토글 함수
  toggleItems: (
    filter: (item: DayMissionActivity) => boolean,
    checked: boolean
  ) => void; // 모든 아이템 선택 토글 함수
  openPreviewModal?: (items: DayMissionActivity[], idx: number) => void;
  setFilter: SetBountyHuntFilter;
}

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 6px;
`;

const Spacer = styled.tr`
  height: 8px;
`;

const columns: Array<DayMissionColumn> = [
  {
    header: "전체선택",
    accessor: "checked",
  },
  {
    header: "닉네임",
    accessor: "nickname",
  },
  {
    header: "플랫폼",
    accessor: "platform",
  },
  {
    header: "DAY",
    accessor: "dayNumber",
  },
  {
    header: "인증사진",
    accessor: "imageUrl",
  },
  {
    header: "인증상태",
    accessor: "status",
  },
  {
    header: "참여날짜",
    accessor: "submittedAt",
  },
  {
    header: "인증날짜",
    accessor: "confirmedAt",
  },
  {
    header: "반려사유",
    accessor: "rejectionReason",
  },
];
const itemFilter = (item: DayMissionActivity) => item.status === "SUBMITTED";

const CertificationTable = ({
  dmg,
  items,
  checkedItems,
  toggleItem,
  toggleItems,
  openPreviewModal,
  setFilter,
}: CertificationTableProps) => {
  const itemFiltered = useMemo(() => items.filter(itemFilter), [items]);
  const [checkbox, setCheckbox] = useState<{
    checked: boolean;
    indeterminate: boolean;
  }>({ checked: false, indeterminate: false });

  const handleToggleAllitems = useCallback(() => {
    if (checkbox.checked || checkbox.indeterminate) {
      toggleItems(itemFilter, false);
      setCheckbox({ checked: false, indeterminate: false });
    } else {
      toggleItems(itemFilter, true);
      setCheckbox({ checked: true, indeterminate: false });
    }
  }, [checkbox, toggleItems]);

  useEffect(() => {
    if (checkedItems.size === 0 || !items) {
      setCheckbox({ checked: false, indeterminate: false });
    } else if (checkedItems.size === itemFiltered.length) {
      setCheckbox({ checked: true, indeterminate: false });
    } else {
      setCheckbox({ checked: false, indeterminate: true });
    }
  }, [checkedItems, items]);
  return (
    <Table>
      <thead>
        <TableHeader
          dmg={dmg}
          columns={columns}
          {...checkbox}
          toggleAllItems={handleToggleAllitems}
          setFilter={setFilter}
        ></TableHeader>
      </thead>
      <tbody>
        <Spacer />
        {items.map((item, index) => (
          <TableRow
            item={item}
            columns={columns}
            key={item.id}
            checked={checkedItems.has(item.id)}
            onCheckedChange={(checked) => toggleItem(item, checked)}
            openPreviewModal={() => openPreviewModal?.(items, index)}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default CertificationTable;
