import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function EditModal(props: any) {
  const [isLoading, setIsLoading] = useState(false);

  function onClick() {
    setIsLoading(true);
    props.CallAPI().then(() => {
      props.setIsLoading();
      setIsLoading(false);
    });
  }

  return (
    <div className="modal__background">
      <div className="modal__box d-block">
        <div className="d-flex justify-content-center mt-4 mb-2 f-20 fw-bold">
          후원상품을 수정하시겠습니까?
        </div>
        <div
          className={
            isLoading
              ? "d-flex justify-content-center index fa-xl"
              : "d-flex justify-content-center index fa-xl invisible"
          }
        >
          {<FontAwesomeIcon icon={faSpinner} className="fa-spin " />}
        </div>
        <div className="divBtn">
          <button className="cancelBtn" onClick={() => props.close()}>
            취소
          </button>
          <button
            className={isLoading ? "ok-disable ms-2" : "okBtn ms-2"}
            disabled={isLoading}
            onClick={onClick}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
