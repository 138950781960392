import { useState } from "react";

const usePopup = () => {
  const [visible, setVisible] = useState(false);

  const open = () => setVisible(true);
  const close = () => {
    setVisible(false);
  };

  return { visible, open, close };
};

export default usePopup;
