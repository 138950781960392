import instance from "../../../../../API/MainAPI";

export default function GameNewsCreationAPI(formData: FormData) {
  return instance.post("/v3/admins/news", formData, {
    headers: {
      "Content-Type": "multipart/form-data; boundary=Paw",
      Accept: "application/json",
    },
  });
}
