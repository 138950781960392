import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { clearNotice } from "../../../common/clearStorage";

import index from "../../../common/function";
import NoticePage from "./Data/noticeAPI";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border: 2px solid #333742;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Tr = styled.tr`
  width: 100%;
`;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const OverflowDiv = styled.div`
  font-size: 15px;
  text-align: center;
  overflow: hidden;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  width: autopx;
  table-layout: fixed;
  word-break: break-all;
  height: auto;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function NoticeList() {
  const pageNum = sessionStorage.getItem("noticePage") || "1";

  const { data: notice, refetch } = useQuery(
    ["notice", pageNum],
    async () => await NoticePage(pageNum)
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("noticePage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearNotice();
  }, []);

  let count = 0;
  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>팝업창</Title>
          <Link to="add">
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>

        <Contents>
          <Table>
            <thead>
              <Tr>
                <Th>No.</Th>
                <Th>주제</Th>
                <Th>연결 링크</Th>
                <Th>배너 이미지</Th>
                <Th>공개 여부</Th>
                <Th>자세히</Th>
              </Tr>
            </thead>
            <tbody>
              {notice?.data?.page?.content.map((noti: any) => {
                count = count + 1;
                return (
                  <Tr key={noti.id}>
                    <Td>
                      {index(
                        notice?.data?.page?.pageable.totalElements,
                        notice?.data?.page?.pageable.pageNumber + 1,
                        notice?.data?.page?.pageable.pageSize,
                        count
                      )}
                    </Td>
                    <Td>
                      <OverflowDiv>{noti.subject}</OverflowDiv>
                    </Td>

                    <Td>
                      <OverflowDiv>{noti.redirectLink} </OverflowDiv>
                    </Td>

                    <Td>
                      <img src={noti.bannerUrl} width={"200px"} alt="" />
                    </Td>
                    <Td>{noti.visible === true ? "보임" : "숨김"}</Td>
                    <Td>
                      <Link
                        to={"/notices/" + noti.id}
                        state={{
                          id: noti.id,
                          visible: noti.visible,
                        }}
                      >
                        <DetailButton>자세히</DetailButton>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={notice?.data?.page?.pageable.totalElements || 1}
              pageRangeDisplayed={5}
              prevPageText={"<"}
              nextPageText={">"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
