import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import AddModal from "../../../common/Modal/AddModal";

import GameNewsCreationAPI from "./Data/GameNewsAddAPI";
import GameNewsNotiModal from "../../Announcement/AnnouncementAdd/modal/AnnouncementNotiModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: center;
  justify-content: center;
`;

const ShowThumb = styled.img`
  width: 400px;
  margin-bottom: 10px;
`;

const PrevDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 30%;
  height: 100%;
`;

const ShowImg = styled.img`
  width: 40%;
  height: 10%;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
`;

const ThumbnailInput = styled.input`
  margin-top: 0.5rem;
  font-size: 16px;
`;

const ImgForm = styled.div`
  width: 40%;
  height: 200px;
  border: dotted;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CircleInput = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-size: 40px;
  border: 2px solid;
  padding: 2px;
  cursor: pointer;
  align-items: center;
`;

const ImgInput = styled.input`
  font-size: 16px;
  display: none;
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function GameNewsAdd() {
  const { register, setValue, getValues, watch } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    fileRef?.current?.click();
  };

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [home, setHome] = useState<File | undefined>();
  const [images, setImages] = useState<File[]>([]);
  const [showImages, setShowImages] = useState<string[]>([]);

  const formData = new FormData();
  const showLink = watch("category");

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeThumbToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  const HomeBanner = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeHomeToBase64(selectedImage);
    }
  };

  function encodeHomeToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setHome(file);
    };
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageLength = images.length + 1;
    if (imageLength <= 5) {
      if (e.target.files && e.target.files.length <= 5) {
        const targetFiles = (e.target as HTMLInputElement).files as FileList;
        const targetFilesArray = Array.from(targetFiles);
        const selectedFiles: string[] = targetFilesArray.map((file) => {
          return URL.createObjectURL(file);
        });

        setImages((prev) => prev.concat(targetFilesArray));

        setShowImages((prev) => prev?.concat(selectedFiles));
      } else {
        alert("5장 이상 넣으셨습니다. 5장 이하로 넣어주세요");
      }
    } else {
      alert("이미지가 5장 초과하였습니다.");
    }
  };

  const imageDelete = (idx: number, url: string) => {
    setShowImages(showImages!.filter((e) => e !== url));

    setImages([
      ...images.slice(0, idx),
      ...images.slice(idx + 1, images.length),
    ]);
  };

  async function CallAPI() {
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const contentValue = getValues("content");
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");

    const dataBlob = new Blob(
      [
        JSON.stringify({
          title: titleValue,
          subtitle: subtitleValue,
          content: contentValue,
          redirectLink: redirectLinkValue,
          category: categoryValue,
        }),
      ],
      {
        type: "application/json",
      }
    );

    formData.append("data", dataBlob);
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    if (home) {
      formData.append("home", home);
    }

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    await gameNewsAddMutation.mutateAsync(formData);
  }

  const gameNewsAddMutation = useMutation(GameNewsCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("게임뉴스 등록이 완료되었습니다.");
      queryClient.invalidateQueries("notices");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const contentValue = getValues("content");
    const redirectLinkValue = getValues("redirectLink");
    const categoryValue = getValues("category");

    const requiredFields = [
      titleValue,
      subtitleValue,
      contentValue,
      categoryValue,
      home,
      thumbnail,
    ];

    const allFieldsFilled = requiredFields.every((field) => field);

    if (showLink === "EXTERNALS") {
      // 외부 링크이며 링크가 없는 경우
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else if (allFieldsFilled) {
        setModal(true);
      }
    } else if (allFieldsFilled) {
      setValue("redirectLink", "");
      setModal(true);
    } else {
      alert(
        "썸네일, 뉴스제목, 뉴스부제목, 뉴스 내용, 카테고리, 연결링크는 필수입니다."
      );
    }
  }

  useEffect(() => {
    clearAll();
  }, []);

  return (
    <Wrapper>
      {modal && (
        <AddModal close={closeAdd} CallAPI={CallAPI} name={"게임뉴스"} />
      )}
      <Inner>
        <Header>
          <Title>플리더스 뉴스 추가</Title>
        </Header>
        <Contents>
          <FlexItem>
            <Item>
              <ItemTitle>플리더스 뉴스 목록 썸네일</ItemTitle>
              {thumbnail && (
                <ShowThumb src={URL.createObjectURL(thumbnail)} alt="" />
              )}
              <Item>
                <ThumbnailInput
                  type="file"
                  accept=".jpg, .png"
                  onChange={Thumbnail}
                />
              </Item>
            </Item>
            <Item style={{ marginLeft: "10%" }}>
              <Item>
                <ItemTitle>메인 페이지 기사 사진</ItemTitle>
                {home && (
                  <div>
                    <ShowThumb src={URL.createObjectURL(home)} alt="" />
                  </div>
                )}
              </Item>
              <Item>
                <ThumbnailInput
                  type="file"
                  accept=".jpg, .png"
                  onChange={HomeBanner}
                />
              </Item>
            </Item>
          </FlexItem>
          <Item>
            <ItemTitle>
              플리더스 게임뉴스 기사 이미지 파일
              <ItemSpan>*필수 아님(최대 5개까지 선택 가능)</ItemSpan>
            </ItemTitle>
          </Item>
          <Item>
            <ImgForm>
              <div>
                <CircleInput onClick={handleClick}>
                  <div style={{ marginBottom: "0.5rem" }}>+</div>
                </CircleInput>
                <ImgInput
                  ref={fileRef}
                  type="file"
                  multiple
                  accept=".jpg, .png"
                  onChange={Image}
                />
              </div>
              <ItemSpan>이미지 선택 시 삭제</ItemSpan>
            </ImgForm>
          </Item>
          <Item>
            <PrevDiv>
              {showImages?.map((url, idx) => (
                <ShowImg
                  key={url}
                  src={url}
                  onClick={() => imageDelete(idx, url)}
                />
              ))}
            </PrevDiv>
          </Item>
          <Item>
            <ItemTitle>뉴스 제목</ItemTitle>
            <div>
              <TitleInput type="text" {...register("title")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>뉴스 부제목</ItemTitle>
            <div>
              <TitleInput type="text" {...register("subtitle")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>
              뉴스 내용
              <ItemSpan>*최대 3000자</ItemSpan>
            </ItemTitle>
            <div>
              <TextArea rows={10} maxLength={3000} {...register("content")} />
            </div>
            <Item>
              <ItemTitle>카테고리</ItemTitle>

              <Select {...register("category")}>
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </Select>
              {showLink === "EXTERNALS" && (
                <Item>
                  <ItemTitle>연결 링크</ItemTitle>
                  <LinkInput type="text" {...register("redirectLink")} />
                </Item>
              )}
            </Item>
          </Item>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={onAdd}>등록하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
