import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import BannerListAPI from "./Data/bannerListAPI";
import { clearAds } from "../../../common/clearStorage";
import { useEffect } from "react";
import styled from "styled-components";
import { bannerCategoryText } from "../../../common/function/bannerCategory";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .p-1 {
    width: 30%;
  }
  .w-25 {
    width: 25%;
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function BannerList() {
  const pageNum = sessionStorage.getItem("adsPage") || "1";
  const { data: ads } = useQuery(
    ["ads", pageNum],
    async () => await BannerListAPI()
  );
  let count = ads?.data.count + 1;

  useEffect(() => {
    clearAds();
  }, []);
  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>배너 목록</Title>
          <Link to="/ads/add">
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr className="tableHeader">
                <Th>No.</Th>
                <Th>배너명</Th>
                <Th>공개여부</Th>
                <Th>카테고리</Th>
                <Th />
              </Tr>
            </thead>
            <tbody>
              {ads?.data?.contents.map((ad: any) => {
                count = count - 1;
                return (
                  <Tr className="tableH-50" key={ad.id}>
                    <Td>{count}</Td>
                    <Td>{ad.title}</Td>
                    <Td>{ad.visible === true ? "보임" : "숨김"}</Td>
                    <Td>{bannerCategoryText(ad.category)}</Td>
                    <Td>
                      <Link
                        to={"/ads/" + ad.id}
                        className="link"
                        state={{
                          id: ad.id,
                          visible: ad.visible,
                        }}
                      >
                        <DetailButton className="detailBtn">
                          자세히
                        </DetailButton>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
