import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 380px;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 50px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
`;

const CancelBtn = styled.button`
  width: 50px;
  padding: 5px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

function LogoutModal(props: any) {
  const navigate = useNavigate();

  async function Logout() {
    sessionStorage.clear();
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.JAVASCRIPT_KEY);
    }
    window.Kakao.Auth.logout();
    localStorage.clear();

    navigate("/login");
  }

  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>로그아웃 하시겠습니까?</ContentsTxt>
        <DivBtn>
          <CancelBtn onClick={() => props.onClose()}>취소</CancelBtn>
          <OkBtn onClick={() => Logout()}>확인</OkBtn>
        </DivBtn>
      </ModalBox>
    </ModalBg>
  );
}

export default LogoutModal;
