import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { clearUser } from "../../../common/clearStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import index from "../../../common/function";
import { useEffect, useState } from "react";
import UserPage from "./Data/userPageAPI";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import UserSearch from "./Data/userSearchAPI";
import tier from "../../../common/function/tier";
import styled from "styled-components";
import { TransformStreamDefaultController } from "node:stream/web";
import date from "../../../common/function/date";
import UserDownload from "../../../common/Modal/DownloadList/UserDonwload";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const DownloadButton = styled.button`
  cursor: pointer;
  margin-left: 5rem;
  border: 1px solid #dee2e6;
  font-size: 15px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SearchStyle = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: end;
  font-size: large;
`;

const SerachInput = styled.input`
  margin: 0 1rem;
  border-radius: 10px;
  border: 1px solid;
  width: 300px; /* 크기 조절 */
  height: 40px; /* 높이 추가 */
  font-size: large;
`;

const SerachIcon = styled.div`
  height: auto;
`;

const SearchButton = styled.button`
  display: flex;
  justify-content: start;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: large;
`;

const DetailButton = styled.button`
  width: 50px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  height: 50px;
  text-align: center;
`;
const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .p-1 {
    width: 30%;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function UserList() {
  const pageNum = sessionStorage.getItem("userLists") || "1";

  const {
    data: userList,
    isLoading,
    refetch,
  } = useQuery(["userList", pageNum], () => UserPage(Number(pageNum), 10), {
    keepPreviousData: true,
  });

  const [downloadModal, setDownloadModal] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [showPage, setShowPage] = useState(true);

  const { handleSubmit, register, setValue, getValues } = useForm();

  function onModal() {
    setDownloadModal(true);
  }

  function closeModal() {
    setDownloadModal(false);
  }

  const onSubmit = async (data: any) => {
    if (!data.search) refetch();
    else {
      try {
        const response = await UserSearch(data.search);

        const newSearchResults = response.data.contents;
        setValue("search", "");

        setSearchResults(newSearchResults);
        setShowPage(false);

        refetch(newSearchResults);
      } catch (e) {
        alert("다시 시도하세요");
        window.location.reload();
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("userLists", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearUser();
  }, []);
  let count = 0;

  return (
    <Wrapper>
      {downloadModal && <UserDownload close={closeModal} />}
      <Inner>
        <Header>
          {" "}
          <Title>유저 목록</Title>
          <DownloadButton onClick={onModal}>엑셀 다운로드</DownloadButton>
        </Header>
        <Contents>
          <Search>
            <SearchStyle>검색</SearchStyle>
            <SerachInput
              type="text"
              placeholder="닉네임이나 전화번호를 입력하세요"
              {...register("search")}
              onKeyDown={handleKeyDown}
            />
            <SerachIcon>
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} className="col fa-spin" />
              ) : (
                <SearchButton onClick={handleSubmit(onSubmit)}>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchButton>
              )}
            </SerachIcon>
          </Search>
          <Table>
            <Thead>
              <Tr className="tableHeader">
                <Th>No.</Th>
                <Th>닉네임</Th>
                <Th>포인트</Th>
                <Th>플리스타</Th>
                <Th>경험치</Th>
                <Th>티어</Th>
                <Th>회원 가입 일시</Th>
                <Th>푸시 알림 허용 여부</Th>
                <Th>상세정보</Th>
                <Th>출석현황</Th>
              </Tr>
            </Thead>
            <tbody>
              {(searchResults.length > 0
                ? searchResults
                : userList?.data.page.content || []
              ).map((user: any) => {
                count = count + 1;
                return (
                  <Tr key={user.id}>
                    <Td>
                      {searchResults.length > 0
                        ? count
                        : index(
                            userList?.data?.page?.pageable.totalElements,
                            userList?.data?.page?.pageable.pageNumber + 1,
                            userList?.data?.page?.pageable.pageSize,
                            count
                          )}
                    </Td>
                    <Td>{user.nickname}</Td>
                    <Td>{user.points ? user.points : "0"}</Td>
                    <Td>{user.plistar}</Td>
                    <Td>{user.exp}</Td>
                    <Td>{tier(user.tier)}</Td>
                    <Td>{date(user.signedUpAt)}</Td>
                    <Td>{user.allowedNotifications ? "허용" : "거절"}</Td>
                    <Td>
                      <Link
                        to={"/users/" + user.id}
                        className="link"
                        state={{ id: user.id }}
                      >
                        <DetailButton>자세히</DetailButton>
                      </Link>
                    </Td>
                    <Td>
                      <Link
                        to={"/users/attendance/" + user.id}
                        className="link"
                        state={{ id: user.id, nickname: user.nickname }}
                      >
                        <DetailButton>보기</DetailButton>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </Contents>{" "}
        {showPage && (
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={
                userList?.data?.page?.pageable.totalElements || 1
              }
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        )}
      </Inner>
    </Wrapper>
  );
}
