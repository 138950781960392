import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";

import GifticonDetailAPI from "../GifticonDetail/Data/GitficonDetailAPI";
import DonationModificationAPI from "./Data/DonationModificationAPI";
import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;
const Select = styled.select`
  font-size: 16px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const NumInput = styled.input`
  width: 30vw;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function DonationDetail() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const visibleValue = watch("visible");

  const formData = new FormData();

  const [img, setImg] = useState<File | undefined>();

  const { data: donationDetail } = useQuery(["donationDetail", id], () =>
    GifticonDetailAPI(id)
  );

  const [modalEdit, setModalEdit] = useState(false);

  function onEditModal() {
    setModalEdit(true);
  }
  function closeEdit() {
    setModalEdit(false);
  }

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("image", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImg(file);
    };
  }

  async function CallEditAPI() {
    const categoryValue = getValues("category");
    const titleValue = getValues("title");
    const subtitleValue = getValues("subtitle");
    const descriptionValue = getValues("description");
    const expValue = getValues("exp");
    const priceValue = getValues("price");
    const visibleValue = getValues("visible");

    const modifiedData = {
      category:
        categoryValue !== undefined
          ? categoryValue
          : donationDetail?.data.category,
      title: titleValue !== undefined ? titleValue : donationDetail?.data.title,

      subtitle:
        subtitleValue !== undefined
          ? subtitleValue
          : donationDetail?.data.subtitle,

      description:
        descriptionValue !== undefined
          ? descriptionValue
          : donationDetail?.data.description,

      exp: expValue !== null ? expValue : 0,

      price: priceValue !== undefined ? priceValue : donationDetail?.data.price,

      visible:
        visibleValue !== undefined
          ? visibleValue
          : donationDetail?.data.visible,
    };

    const data = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("data", data);

    if (img) {
      formData.append("image", img);
    }

    await donationEdit.mutateAsync(formData);
  }

  const donationEdit = useMutation(
    (formData: FormData) => DonationModificationAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("등록이 완료되었습니다.");
        queryClient.invalidateQueries("donationDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error" + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await GifticonDetailAPI(id);

      if (response) {
        setValue("title", response.data.title);
        setValue("subtitle", response.data.subtitle);
        setValue("description", response.data.description);
        setValue("category", response.data.category);
        setValue("exp", response.data.exp);
        setValue("price", response.data.price);
        setValue("visible", response.data.visible);
      }
    };

    fetchData();
  }, []);

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  function onEdit() {
    const expValue = getValues("exp");

    if (expValue < 0 || expValue > 100000) {
      alert("포인트는 음수값이나 100000 이상의 수를 입력할 수 없습니다.");
    } else {
      onEditModal();
    }
  }

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      <Inner>
        <Header>
          <Title>후원 상품 상세</Title>
        </Header>
        <Contents>
          <Item>
            <div>
              {" "}
              {img ? (
                <Img src={URL.createObjectURL(img)} alt="" />
              ) : (
                <Img src={donationDetail?.data.bannerUrl} alt="" />
              )}
            </div>{" "}
            <div>
              <ImgInput
                type="file"
                accept=".jpg, .png"
                className="mt-3"
                name="chooseFile"
                onChange={Image}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>후원명</ItemTitle>
            <div>
              <TitleInput
                type="text"
                defaultValue={donationDetail?.data.title}
                {...register("title")}
              />
            </div>
          </Item>

          <Item>
            <ItemTitle>부가설명</ItemTitle>
            <div>
              <TitleInput
                type="text"
                defaultValue={donationDetail?.data.subtitle}
                {...register("subtitle")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>상세 설명</ItemTitle>
            <div>
              <TextArea
                rows={8}
                maxLength={500}
                defaultValue={donationDetail?.data.description}
                {...register("description")}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>분류</ItemTitle>

            <Select {...register("category")}>
              <option value="DONATION">후원</option>
            </Select>
          </Item>
          <Item>
            <ItemTitle>
              경험치
              <ItemSpan>*숫자만 입력 가능 / 필수 아님</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("exp")} />
          </Item>

          <Item>
            <ItemTitle>
              후원 가격
              <ItemSpan>*숫자만 입력 가능</ItemSpan>
            </ItemTitle>
            <NumInput type="number" min="0" {...register("price")} />
          </Item>

          <Item>
            <ItemTitle>어플 표시</ItemTitle>
            <Label>
              <input
                type="radio"
                value="true"
                checked={visibleValue === true}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              보이기
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                checked={visibleValue === false}
                {...register("visible")}
                onChange={handleRadioChange}
              />
              숨기기
            </Label>
          </Item>
          <ButtonDiv>
            <EditButton onClick={onEdit}>수정하기</EditButton>
          </ButtonDiv>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
