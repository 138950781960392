import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import pictureSrc from "assets/images/picture.png";

interface ImageFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  previewSrc?: string;
}

const ImageFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ImageFieldLabel = styled.label`
  font-weight: 500;
  font-size: 22px;
`;
const ImageFieldControl = styled.div`
  cursor: pointer;
  position: relative;
  border: 3px dashed #d9d9d9;
  width: 438px;
  height: 438px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.5);
  input {
    display: none;
  }
  &:hover {
    background-color: rgba(227, 227, 227, 0.3);
  }
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
`;
const UploadButton = styled.div`
  border: 1px solid #dee2e6;
  padding: 6px 8px 4px;
  color: #5f5f5f;
  background-color: #f0f0f0;
  font-size: 20px;
  font-weight: 500;
  border-radius: 7px;
  cursor: pointer;
`;
const Comment = styled.div`
  font-size: 16px;
  margin-top: 15px;
`;
const Preview = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
  z-index: -1;
`;
const PictureIconImg = styled.img`
  width: 48px;
  margin-left: 7px;
  margin-bottom: 20px;
`;

const ImageField = forwardRef<HTMLInputElement, ImageFieldProps>(
  ({ label, onChange, disabled, previewSrc, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => inputRef.current
    );

    const [image, setImage] = useState<File>();
    const [preview, setPreview] = useState<string>();

    const handleClick = () => {
      inputRef.current?.click();
    };

    useEffect(() => {
      setPreview(previewSrc);
    }, [previewSrc]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      console.log(inputRef.current?.files);
      if (!inputRef.current?.files || !inputRef.current.files[0]) {
        setPreview(undefined);
        setImage(undefined);
        return;
      }

      const file = inputRef.current.files[0];

      setImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      onChange?.(e);
    };
    return (
      <ImageFieldContainer>
        <ImageFieldLabel>{label}</ImageFieldLabel>
        <ImageFieldControl onClick={handleClick} aria-disabled={disabled}>
          {preview && <Preview src={preview} />}
          <PictureIconImg src={pictureSrc} />
          <UploadButton>파일 선택</UploadButton>
          <input
            type="file"
            ref={inputRef}
            accept="image/*"
            onChange={handleChange}
            disabled={disabled}
            {...props}
          />
          {!image && <Comment>*선택된 파일 없음</Comment>}
        </ImageFieldControl>
      </ImageFieldContainer>
    );
  }
);

export default ImageField;
