import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import ReviewAwardDeleteAPI from "./Data/ReviewAwardDeleteAPI";
import ReviewAwardDetailAPI from "./Data/ReviewAwardDetailAPI";
import ReviewAwardEditAPI from "./Data/ReviewAwardEditAPI";
import DeleteModal from "./Modal/DeleteModal";
import EditModal from "./Modal/EditModal";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const Img = styled.img`
  width: 30%;
  margin-bottom: 10px;
`;

const ImgInput = styled.input`
  margin-top: 1rem;
  font-size: 16px;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TextArea = styled.textarea`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  width: 75%;
  resize: vertical;
`;
const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const LinkInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;
const EditButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function ReviewAwardEdit() {
  const { register, setValue, getValues, watch } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const [image, setImage] = useState<File | undefined>();
  const formData = new FormData();
  const showLink = watch("redirectionCategory");

  const { data: rewardAwardDetail } = useQuery(["rewardAwardDetail", id], () =>
    ReviewAwardDetailAPI(id)
  );

  //modal
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  function onDelete() {
    setModalDelete(true);
  }
  function closeDelete() {
    setModalDelete(false);
  }
  function onEdit() {
    const redirectLinkValue = getValues("redirectLink");
    if (showLink === "EXTERNALS") {
      if (!redirectLinkValue) {
        alert("외부링크를 입력해주세요.");
      } else {
        setModalEdit(true);
      }
    } else {
      setValue("redirectLink", "");
      setModalEdit(true);
    }
  }

  function closeEdit() {
    setModalEdit(false);
  }
  const { mutate: deleteReviewAward, isLoading: deleteLoading } = useMutation(
    async () => await ReviewAwardDeleteAPI(id),
    {
      onMutate: async () => {
        // Remove the query from cache before making the delete request
        queryClient.removeQueries(["rewardAwardDetail", id]);
      },
      onSuccess: () => {
        closeDelete();
      },
      onError: () => {
        alert("실패했습니다. 다시 시도하세요.");
        closeDelete();
      },
    }
  );

  const handleDelete = async () => {
    await deleteReviewAward();
    await alert("리뷰어워드가 삭제되었습니다.");
    await navigate(-1); // Move navigation here
  };

  async function CallEditAPI() {
    const titleValue = getValues("title");

    const contentValue = getValues("content");

    const redirectLinkValue = getValues("redirectLink");
    const redirectionCategoryValue = getValues("redirectionCategory");

    const modifiedData = {
      title:
        titleValue !== undefined ? titleValue : rewardAwardDetail?.data.title,
      content:
        contentValue !== undefined
          ? contentValue
          : rewardAwardDetail?.data.content,
      redirectLink:
        redirectLinkValue !== undefined
          ? redirectLinkValue
          : rewardAwardDetail?.data.redirectLink,

      redirectionCategory:
        redirectionCategoryValue !== undefined
          ? redirectionCategoryValue
          : rewardAwardDetail?.data.redirectionCategory,
    };

    const blob = new Blob([JSON.stringify(modifiedData)], {
      type: "application/json",
    });

    formData.append("data", blob);
    if (image) {
      formData.append("image", image);
    }

    await ReviewAwardEdit.mutateAsync(formData);
  }

  const ReviewAwardEdit = useMutation(
    (formData: FormData) => ReviewAwardEditAPI(id, formData),
    {
      onSuccess: () => {
        closeEdit();

        alert("리뷰어워드 수정이 완료되었습니다.");
        queryClient.invalidateQueries("rewardAwardDetail");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);

        closeEdit();
      },
    }
  );

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await ReviewAwardDetailAPI(id);
      if (response) {
        setValue("title", response.data.title);
        setValue("content", response.data.content);
        setValue("redirectLink", response.data.redirectLink);
        setValue("redirectionCategory", response.data.redirectionCategory);
      }
    };

    fetchData();
  }, []);

  const Image = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeFileToBase64(selectedImage);
    }
  };

  function encodeFileToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(file);
    };
  }

  return (
    <Wrapper>
      {modalEdit && <EditModal close={closeEdit} CallAPI={CallEditAPI} />}
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          CallAPI={handleDelete}
          isLoding={deleteLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>리뷰어워드 #{id}</Title>
        </Header>
        <Contents>
          <Item>
            <div>
              {image ? (
                <Img src={URL.createObjectURL(image)} alt="" />
              ) : (
                <Img src={rewardAwardDetail?.data.imageUrl} alt="" />
              )}
            </div>
            <div>
              <ImgInput
                type="file"
                accept=".jpg, .png"
                className="mt-3"
                name="chooseFile"
                onChange={Image}
              />
            </div>
          </Item>
          <Item>
            <ItemTitle>리뷰어워드 제목</ItemTitle>
            <div>
              <TitleInput
                type="text"
                defaultValue={rewardAwardDetail?.data.title}
                {...register("title")}
              />
            </div>
          </Item>

          <Item>
            <ItemTitle>리뷰어워드 내용</ItemTitle>
            <div>
              <TextArea
                defaultValue={rewardAwardDetail?.data.content}
                {...register("content")}
              />
            </div>
          </Item>

          <Item>
            <ItemTitle>연결 링크 카테고리</ItemTitle>
            <div className="w-25 mt-1">
              <Select
                // value={rewardAwardDetail?.data.category}
                {...register("redirectionCategory")}
              >
                <option value="ANNOUNCEMENT">공지글</option>
                <option value="GAME_NEWS">게임뉴스</option>
                <option value="TESTS">테스트 게임</option>
                <option value="ADVANCE_RESERVATIONS">사전예약 게임</option>
                <option value="RELEASE">출시게임</option>
                <option value="QUESTS">퀘스트</option>
                <option value="CONTENTS">콘텐츠</option>
                <option value="MY_PAGES">마이페이지</option>
                <option value="SETTINGS">설정</option>
                <option value="CALENDAR">캘린더</option>
                <option value="EXTERNALS">외부링크</option>
                <option value="NONE">리다이렉션 없음</option>
              </Select>
            </div>
          </Item>
          {showLink === "EXTERNALS" && (
            <Item>
              <ItemTitle>
                연결 링크
                <ItemSpan>*필수 아님</ItemSpan>
              </ItemTitle>
              <LinkInput
                type="text"
                defaultValue={rewardAwardDetail?.data.redirectLink}
                {...register("redirectLink")}
              />
            </Item>
          )}
        </Contents>
        <ButtonDiv>
          <DeleteButton onClick={onDelete}>삭제하기</DeleteButton>
          <EditButton onClick={onEdit}>수정하기</EditButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
