import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalType } from "components/common/Modal/ModalsContext";
import useModal from "components/common/Modal/useModal";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface OwnProps {
  url: any;
  modal: ModalType;
  isLoading: boolean;
}

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 380px;
`;

const HeaderName = styled.h3`
  display: flex;
  font-size: 14px;
  margin: 0.5rem 0 0.5rem 1rem;
  font-weight: bold;
`;

const Line = styled.hr`
  margin: 0;
  opacity: 0.25;
`;

const ContentsTxt = styled.div`
  font-weight: 700;
  margin-left: 1.5rem;

  display: flex;
  font-size: 16px;
  box-sizing: border-box;
`;

const ModalBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 120px;
  height: 40px;
  border: none;
  padding: 5px;
  background-color: lightgrey;
  color: black;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  &:disabled {
    cursor: progress;
  }
`;

const DownloadModal: React.FC<OwnProps> = ({ url, modal, isLoading }) => {
  const { closeModal } = useModal();
  const close = () => closeModal(modal.id);
  const download = () => {
    console.log(url);
    const objUrl = window.URL.createObjectURL(
      new Blob([url], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    const link = document.createElement("a");
    link.href = objUrl;
    link.setAttribute("download", `UserList.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  function onClick() {
    download();
    close();
  }

  return (
    <ModalBg>
      <ModalBox>
        <HeaderName>현상금 참여미션 내역 다운로드</HeaderName>
        <Line />
        <ContentsTxt style={{ marginTop: "1.5rem" }}>
          엑셀 파일을 다운로드 하시겠습니까?
        </ContentsTxt>

        <ModalBtn>
          <Button style={{ marginRight: "1rem" }} onClick={close}>
            아니오
          </Button>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={onClick}
            disabled={isLoading}
          >
            {isLoading ? <FontAwesomeIcon icon={faSpinner} /> : "네"}
          </Button>
        </ModalBtn>
      </ModalBox>
    </ModalBg>
  );
};

export default DownloadModal;
