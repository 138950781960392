interface DateFormatOptions {
  year: "numeric";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
}

export default function date(date: Date) {
  if (date) {
    const options: DateFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("ko", options);
  } else return "-";
}
