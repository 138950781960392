import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { useFieldArray, useForm } from "react-hook-form";

import styled from "styled-components";

import SurveyModificationAPI from "../Data/SurveyModificationAPI";
import { useNavigate } from "react-router-dom";

import EditModal from "../../../../common/Modal/EditModal";
import SurveyChoiceModiAPI from "../Data/SurveyChoiceModiAPI";
import AddModal from "../../../../common/Modal/AddModal";
import SurveyChoiceCreationAPI from "../Data/SurveyChoiceCreationAPI";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
`;

const AlertTest = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  margin: auto;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-top: 1rem;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  word-break: break-word;
  z-index: 2;
  top: 5%;
  left: 5%;
  width: 60%;
  overflow-y: scroll;
`;

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
`;

const Contents = styled.div`
  padding: 10px 40px;
`;

const Cursor = styled.div`
  cursor: pointer;
  display: flex;
`;

const Txt = styled.div`
  font-weight: 700;
  margin-right: 0.5rem;
`;

const Btn = styled.div`
  padding-left: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  text-transform: none;
`;

const TxtTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  width: 20%;
  cursor: auto;
  margin-bottom: 0.5rem;
  width: 80%;
`;

const TxtInput = styled.input`
  width: 60%;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin: 5px;
  height: auto;
  line-height: normal;
  padding: 5px;
  color: #000;
  margin: 0;
  font-size: 16px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
  margin-right: 1rem;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

function ChoicePopup(props: any) {
  const queryClient = useQueryClient();

  const { register, getValues, setValue, watch, control } = useForm({});
  const { fields } = useFieldArray({
    control,
    name: "choices",
  });

  const formData = new FormData();
  const questionId = props.questionId;
  const navigate = useNavigate();
  const [onAddModal, setOnAddModal] = useState(false);
  const [onEditModal, setOnEditModal] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [choiceId, setChoiceId] = useState<any>(0);

  const openEditModal = () => {
    setOnEditModal(true);
  };

  function closeEditModal() {
    setOnEditModal(false);
  }

  const openAddModal = () => {
    setOnAddModal(true);
  };

  function closeAddModal() {
    setOnAddModal(false);
  }

  const choicesValue = getValues("choices");
  const [visibleList, setVisibleList] = useState<boolean[]>([]);

  async function CallEditAPI() {
    const idx = index;

    const contentValue = getValues(`choices.${idx}.content`);
    const visibleValue = getValues(`choices.${idx}.visible`);

    const modifiedData = {
      questionId: questionId,
      content: contentValue,
      visible: visibleValue,
    };

    formData.append("content", modifiedData.content);
    formData.append("questionId", modifiedData.questionId);
    formData.append("visible", modifiedData.visible);

    await choiceEdit.mutateAsync(formData);
  }

  const choiceEdit = useMutation(
    (formData: FormData) => SurveyChoiceModiAPI(choiceId, formData),
    {
      onSuccess: () => {
        closeEditModal();

        alert("설문 질문 수정이 완료되었습니다.");
        queryClient.invalidateQueries("notices");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);
        closeEditModal();
      },
    }
  );

  async function CallAddAPI() {
    const contentValue = watch(`newContent`);
    const visibleValue = getValues(`newVisible`);

    const modifiedData = {
      questionId: questionId,
      content: contentValue,
      visible: visibleValue,
    };

    formData.append("questionId", modifiedData.questionId);
    formData.append("content", modifiedData.content);

    formData.append("visible", modifiedData.visible);

    await choiceAdd.mutateAsync(formData);
  }

  const choiceAdd = useMutation(
    (formData: FormData) => SurveyChoiceCreationAPI(formData),
    {
      onSuccess: () => {
        closeAddModal();

        alert("설문 질문 추가가 완료되었습니다.");
        queryClient.invalidateQueries("notices");
        navigate(-1);
      },
      onError: (error) => {
        alert("Error: " + error);
        closeAddModal();
      },
    }
  );

  useEffect(() => {
    setValue("choices", props.data);
  }, []);

  useEffect(() => {
    // choicesValue가 변경될 때마다 visibleList를 업데이트
    const newVisibleList = choicesValue?.map((item: any) => item.visible) || [];
    setVisibleList(newVisibleList);
  }, [choicesValue]);

  const handleRadioChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value === "true";
      const updatedVisibleList = [...visibleList];
      updatedVisibleList[index] = newValue;
      setVisibleList(updatedVisibleList);
    };

  return (
    <div>
      <Wrapper>
        {onEditModal && (
          <EditModal
            close={closeEditModal}
            CallAPI={CallEditAPI}
            name={"선택지"}
          />
        )}
        {onAddModal && (
          <AddModal
            close={closeAddModal}
            CallAPI={CallAddAPI}
            name={"선택지"}
          />
        )}
        <AlertTest>
          <Header>
            <Cursor onClick={props.onClose}>
              <Txt>설문선택지 수정</Txt>
              <Btn>
                <FontAwesomeIcon icon={faXmark} />
              </Btn>
            </Cursor>
            <AddButton
              style={{ width: "7%" }}
              type="button"
              onClick={() => {
                setOnAdd(true);
              }}
            >
              추가
            </AddButton>
          </Header>

          <Contents>
            <div>
              {fields.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{ margin: "1rem", justifyContent: "space-between" }}
                  >
                    <TxtTitle>선택지#{`${index + 1}`}</TxtTitle>
                    <TxtInput
                      {...register(`choices[${index}].content`, {
                        required: true,
                      })}
                    />
                    <>
                      <input
                        {...register(`choices[${index}].visible`)}
                        type="radio"
                        value="true"
                        checked={visibleList[index] === true}
                        onChange={handleRadioChange(index)}
                      />
                      보이기
                    </>
                    <>
                      <input
                        {...register(`choices[${index}].visible`)}
                        type="radio"
                        value="false"
                        checked={visibleList[index] === false}
                        onChange={handleRadioChange(index)}
                      />
                      숨기기
                    </>
                    <AddButton
                      style={{ width: "7%" }}
                      type="button"
                      onClick={() => {
                        openEditModal();
                        setChoiceId(getValues(`choices[${index}].id`));
                        setIndex(index);
                      }}
                    >
                      수정
                    </AddButton>
                    <div />
                  </div>
                );
              })}
            </div>
            {onAdd && (
              <div style={{ margin: "1rem", justifyContent: "space-between" }}>
                <TxtTitle>선택지#{`${props.data.length + 1}`}</TxtTitle>
                <TxtInput
                  {...register(`newContent`, {
                    required: true,
                  })}
                />
                <>
                  <input
                    {...register(`newVisible`)}
                    type="radio"
                    value="true"
                  />
                  보이기
                </>
                <>
                  <input
                    {...register(`newVisible`)}
                    type="radio"
                    value="false"
                  />
                  숨기기
                </>
                <AddButton
                  style={{ width: "7%" }}
                  type="button"
                  onClick={() => {
                    openAddModal();
                  }}
                >
                  등록
                </AddButton>
                <AddButton
                  style={{ width: "7%" }}
                  type="button"
                  onClick={() => {
                    setOnAdd(false);
                  }}
                >
                  취소
                </AddButton>
              </div>
            )}
          </Contents>
        </AlertTest>
      </Wrapper>
    </div>
  );
}

export default ChoicePopup;
