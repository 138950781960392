import instance from "../../../../../API/MainAPI";

export default function WordStatementAPI(
  page: number,
  pageSize: number,
  id: number
) {
  return instance.get(`/v3/admins/attendances/statements/words/${id}`, {
    params: {
      pageNumber: Number(Number(page) - 1),
      pageSize: pageSize,
    },
  });
}
