import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

const Tab = styled.div`
  display: flex;
  background-color: #fffaf0;
  width: 100%;
  margin: 0 auto;
`;

const SelectedTab = styled.li`
  background-color: #ffc200;
  color: #fff;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  padding: 5px 0;
  text-align: center;
  list-style: none;
  margin: 0;
  width: 100%;
`;

const UnselectedTab = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  padding: 5px 0;
  text-align: center;
  margin: 0;
  width: 100%;
`;

function TestManage() {
  const location = useLocation();
  const url = location.pathname;

  return (
    <div
      style={{
        flex: "4",
        zIndex: "1",
        position: "relative",
      }}
    >
      <div>
        {url === "/test/phaseOne" ? (
          <Tab>
            <Link
              to="/test/phaseOne"
              style={{ textDecoration: "none", width: "100%" }}
            >
              <SelectedTab>게임 테스터 1단계</SelectedTab>
            </Link>
            <Link
              to="/test/phaseTwo"
              style={{ textDecoration: "none", color: "black", width: "100%" }}
            >
              <UnselectedTab>게임 테스터 2단계</UnselectedTab>
            </Link>
          </Tab>
        ) : (
          <Tab>
            <Link
              to="/test/phaseOne"
              style={{ textDecoration: "none", width: "50%", color: "black" }}
            >
              <UnselectedTab>게임 테스터 1단계</UnselectedTab>
            </Link>
            <Link
              to="/test/phaseTwo"
              style={{ textDecoration: "none", width: "50%" }}
            >
              <SelectedTab>게임 테스터 2단계</SelectedTab>
            </Link>
          </Tab>
        )}
      </div>
      <Outlet />
    </div>
  );
}

export default TestManage;
