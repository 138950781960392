import { useQuery } from "react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BountyListAPI from "./Data/BountyListAPI";
import BountyGroup from "./BountyGroup";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import { BOUNTY_FILTER_STATUS, BountyFilterStatus } from "../constants";
import { useState } from "react";

const PAGE_SIZE = 10;

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;
const RegisterButton = styled(Link)`
  border: 1px solid #dee2e6;
  padding: 9px 13.5px;
  color: #4e4e4e;
  background-color: #f0f0f0;
  font-size: 21px;
  font-weight: 600;
  border-radius: 10px;
  margin-left: auto;
  cursor: pointer;
  text-decoration: none;
`;
const FilterSelect = styled.div`
  position: relative;
  width: 123px;
  height: 42px;
  border-radius: 11px;
  border: 2px solid #454545;
  display: flex;
  align-items: center;
  margin-left: 27px;
  select {
    box-sizing: border-box;
    background: transparent;
    border: 0 none;
    outline: 0 none;
    margin: 0 14px;
    padding: 0 20px;
    position: relative;
    z-index: 3; // select가 위로 올라와야 함
    font-size: 16px;
    font-weight: 700;
    height: 100%;
  }
  option {
    background: lightcoral;
    color: #fff;
    margin-left: 6px;
    font-size: 16px;
  }
`;

export default function BountyList() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = +(searchParams.get("page") || "1") || 1;

  const [filter, setFilter] = useState<BOUNTY_FILTER_STATUS>(
    BountyFilterStatus.All
  );

  const { data } = useQuery(
    ["bountylist", page, filter],
    () => BountyListAPI(page, PAGE_SIZE, filter),
    {
      keepPreviousData: true,
    }
  );

  return (
    <Wrapper>
      <Inner>
        <Header>
          {" "}
          <Title>현상금 관리</Title>
          <FilterSelect>
            <select
              onChange={(e) =>
                setFilter(+e.target.value as BOUNTY_FILTER_STATUS)
              }
              value={filter}
            >
              <option value={BountyFilterStatus.All}>전체</option>
              <option value={BountyFilterStatus.OnGoing}>진행중</option>
              <option value={BountyFilterStatus.Ended}>종료</option>
            </select>
          </FilterSelect>
          <RegisterButton to="register">현상금 등록</RegisterButton>
        </Header>
        <Contents>
          {data && <BountyGroup items={data.data.page.content} />}
        </Contents>
        <PaginationWrapper>
          <Pagination
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={data?.data.page.pageable.totalElements || 1}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            onChange={(pageNum) => navigate(`.?page=${pageNum}`)}
          />
        </PaginationWrapper>
      </Inner>
    </Wrapper>
  );
}
