import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import useDayMissionGameRegisterForm, {
  HandleSubmitFunc,
} from "./useDayMissionGameRegisterForm";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import Form from "../shared/Form";
import { useNavigate } from "react-router-dom";
import { createDayMissionGame } from "../api";
import MultiImageField from "../shared/MultiImageField";
import DayMissionRegister from "./DayMissionRegister";

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px 40px 20px 20px;
  align-items: center;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const ImageContainer = styled.div``;
const FormInner = styled.div`
  display: grid;
  grid-template-columns: 438px 1fr;
  gap: 60px 30px;
`;
const Button = styled.button`
  margin-left: auto;
  width: fit-content;
  grid-column: 2;
  padding: 13px 34px;
  color: white;
  background: #2282e9;
  outline: 0;
  border: 0;
  border-radius: 13px;
  font-size: 24px;
`;

const platformOptions = [
  { value: "android", label: "Android 다운 링크" },
  { value: "ios", label: "IOS 다운 링크" },
  { value: "all", label: "2개 플랫폼 모두" },
];
const linkOptions = [
  { value: true, label: "있음" },
  { value: false, label: "없음" },
];

const visibleOptions = [
  { label: "보이기", value: true },
  { label: "숨기기", value: false },
];

export default function DayMissionGameRegister() {
  const navigate = useNavigate();
  const [platform, setPlatform] = useState("");
  const [ylinkExist, setYlinkExist] = useState(true);
  const { mutate } = useMutation(createDayMissionGame, {
    onSuccess() {
      window.alert("데이미션이 등록되었습니다.");
      navigate("/retension/daymissions");
    },
  });

  const _handleSubmit: HandleSubmitFunc = (forms) => {
    const { gameImages, thumbnail, ...data } = forms;
    console.log(forms);
    mutate({
      data,
      fileData: {
        gameImages,
        thumbnail,
      },
    });
  };

  const { handleSubmit, inputs, control, register } =
    useDayMissionGameRegisterForm(_handleSubmit);

  return (
    <Wrapper>
      <Inner>
        <Header>
          {" "}
          <Title>데이미션 게임 등록</Title>
        </Header>
        <Contents>
          <form onSubmit={handleSubmit}>
            <FormInner>
              <ImageContainer>
                <Form.ImageField label="썸네일" {...inputs.thumbnail} />
                <MultiImageField
                  label="게임 이미지"
                  placeholder="*최대 5장까지 추가 가능합니다."
                  {...inputs.gameImages}
                />
              </ImageContainer>
              <Form.FormGroup>
                <Form.TextField label="게임 이름" {...inputs.title} />
                <Form.TextField label="게임사" {...inputs.company} />
                <Form.Select
                  containerStyle={{ gridColumn: "span 2" }}
                  label="미션 인증 방법 선택"
                  placeholder="플랫폼을 선택해주세요."
                  options={platformOptions}
                  value={platform}
                  onChange={({ value }) => setPlatform(value)}
                />
                <Form.TextField
                  label="Android 다운 링크"
                  {...inputs.playStoreLink}
                  disabled={platform === "ios"}
                />
                <Form.TextField
                  label="IOS 다운 링크"
                  {...inputs.appStoreLink}
                  disabled={platform === "android"}
                />
                <Form.RadioGroup
                  containerStyle={{ gridColumn: "span 2" }}
                  label="유튜브링크"
                  options={linkOptions}
                  value={ylinkExist}
                  onValueChange={setYlinkExist}
                >
                  <Form.TextField
                    {...inputs.youtubeLink}
                    disabled={!ylinkExist}
                  />
                </Form.RadioGroup>
                <Form.TextField
                  label="시작일"
                  type="datetime-local"
                  {...inputs.startedAt}
                />
                <Form.TextField
                  label="종료일"
                  type="datetime-local"
                  {...inputs.expiredAt}
                />
                <Controller
                  control={control}
                  name="genres"
                  render={({ field: { value, onChange, ref } }) => (
                    <Form.MultiSelect
                      label="장르"
                      placeholder="장르를 선택하세요."
                      onChange={onChange}
                      ref={ref}
                      value={value}
                    />
                  )}
                />
                <Form.TextArea
                  containerStyle={{ gridColumn: "span 2" }}
                  label="게임 설명 (썸네일)"
                  placeholder="최대 20자"
                  {...inputs.subtitle}
                />
                <Form.TextArea
                  containerStyle={{ gridColumn: "span 2" }}
                  label="게임 상세 설명"
                  placeholder="최대 2000자"
                  {...inputs.description}
                />
                <Form.RadioGroup
                  label="어플표시"
                  options={visibleOptions}
                  {...inputs.visible}
                />
              </Form.FormGroup>
              <DayMissionRegister control={control} register={register} />
              <Form.FormGroup containerStyle={{ gridColumn: 2 }}>
                <Button type="submit">등록</Button>
              </Form.FormGroup>
            </FormInner>
          </form>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
