// v3/admins/users/supporters/advantage

import instance from "API/MainAPI";

export default function SupporterSetting(
  advantage: number | undefined,
  gameCategory: string
) {
  return (
    instance.patch("/v3/admins/users/supporters/advantage", {
      advantage: advantage !== undefined ? advantage - 100 : 0,
      gameCategory: gameCategory,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
