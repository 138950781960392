import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useMutation } from "react-query";
import PushAllAPI from "../../../Push/PushSend/Data/PushAllAPI";

const ModalBg = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const ModalBox = styled.div`
  z-index: 4;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 380px;
`;

const ContentsTxt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
`;

const DivBtn = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const OkBtn = styled.button`
  width: 50px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 0.5rem;
`;

const CancelBtn = styled.button`
  width: 50px;
  padding: 5px;
  background-color: white;
  color: teal;
  border: 1px solid teal;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;
function AddAnnounceModal(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  function onClick() {
    setIsLoading(true);
    CallAPI().then(() => {
      props.CallAPI().then(() => {
        setIsLoading(false);
      });
    });
  }

  async function CallAPI() {
    const requestDataWithoutIds = {
      title: props.title,
      message: props.message,
      category: "TOPIC_NEW",
    };

    await PushAllMutation.mutateAsync(requestDataWithoutIds);
  }

  const PushAllMutation = useMutation(
    (requestData: any) => PushAllAPI(requestData),
    {
      onSuccess: () => {
        alert("전체 푸시를 전송했습니다.");
      },
      onError: () => {
        alert("푸시 전송을 실패했습니다. 다시 시도하세요.");
      },
    }
  );

  return (
    <ModalBg>
      <ModalBox>
        <ContentsTxt>{props.name}을/를 등록하시겠습니까?</ContentsTxt>
        {!isLoading && (
          <DivBtn>
            <CancelBtn onClick={() => props.close()}>취소</CancelBtn>
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} />
            ) : (
              <OkBtn disabled={isLoading} onClick={onClick}>
                확인
              </OkBtn>
            )}
          </DivBtn>
        )}
      </ModalBox>
    </ModalBg>
  );
}

export default AddAnnounceModal;
