import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { clearAll } from "../../../common/clearStorage";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useFieldArray, useForm } from "react-hook-form";

import EditModal from "../../../common/Modal/EditModal";
import SurveyDetailAPI from "./Data/SurveyDetailAPI";
import SurveyModificationAPI from "./Data/SurveyModificationAPI";
import ChoicePopup from "./Popup/ChoicePopup";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const TxtInput = styled.input`
  width: 30%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  margin-left: 10px;
  padding: 5px;
`;

const Select = styled.select`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;

  height: auto;
  line-height: normal;
  padding: 5px;
  text-transform: none;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

const AppendButton = styled.button`
  margin-left: 3rem;
  background-color: gray;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;

  width: 60px;
`;

const AddButton = styled.button`
  margin-left: 1rem;
  background-color: teal;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

export default function SurveyDetail() {
  const { register, control, getValues, setValue, watch } = useForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const id = location.state.id;

  const [modal, setModal] = useState(false);
  const [choiceModal, setChoiceModal] = useState(false);

  const handleVisibleChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  function closeAdd() {
    setModal(false);
  }

  function closeChoice() {
    setChoiceModal(false);
  }
  const formData = new FormData();

  const watchCategory = watch("category");
  const visibleValue = watch("visible");

  async function CallAPI() {
    const contentValue = getValues("content");
    const visibleValue = getValues("visible");
    const allowedChoicesValue =
      watchCategory !== "MULTIPLE_CHOICE" ? 0 : getValues("allowedChoices");
    const minAnswerLengthValue =
      watchCategory !== "SUBJECTIVE" ? 0 : getValues("minAnswerLength");

    formData.append("content", contentValue);
    formData.append("visible", visibleValue);
    formData.append("allowedChoices", allowedChoicesValue);
    formData.append("minAnswerLength", minAnswerLengthValue);

    await surveysAddMutation.mutateAsync(formData);
  }

  const surveysAddMutation = useMutation(
    (formData: FormData) => SurveyModificationAPI(id, formData),
    {
      onSuccess: () => {
        setModal(false);

        alert("설문 등록이 완료되었습니다.");
        queryClient.invalidateQueries("surveys");
        navigate(-1);
      },
      onError: () => {
        setModal(false);
      },
    }
  );

  function onAdd() {
    setModal(true);
  }

  useEffect(() => {
    setValue("category", "MULTIPLE_CHOICE");
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await SurveyDetailAPI(id);
    if (response) {
      setValue("content", response.data.content);
      setValue("allowedChoices", response.data.allowedChoices);
      setValue("category", response.data.category);
      setValue("visible", response.data.visible);
      setValue("choices", response.data.choices);
      setValue("minAnswerLength", response.data.minAnswerLength);
    }
  };
  return (
    <Wrapper>
      {modal && <EditModal close={closeAdd} CallAPI={CallAPI} name={"설문"} />}
      {choiceModal && (
        <ChoicePopup
          onClose={closeChoice}
          data={getValues("choices")}
          questionId={id}
          name={"설문"}
        />
      )}
      <Inner>
        <Header>
          <Title>설문 수정</Title>
        </Header>
        <Contents>
          <Item>
            <ItemTitle>설문 내용</ItemTitle>
            <div>
              <TitleInput type="text" {...register("content")} />
            </div>
          </Item>
          <Item>
            <ItemTitle>설문 숨김여부</ItemTitle>
            <Label>
              <input
                type="radio"
                value="true"
                checked={visibleValue === true}
                {...register("visible")}
                onChange={handleVisibleChange}
              />
              보이기
            </Label>
            <Label>
              <input
                type="radio"
                value="false"
                checked={visibleValue === false}
                {...register("visible")}
                onChange={handleVisibleChange}
              />
              숨기기
            </Label>
          </Item>

          <>
            {watchCategory === "MULTIPLE_CHOICE" && (
              <>
                {" "}
                <Item>
                  <ItemTitle>
                    다중 투표 허용 갯수
                    <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                  </ItemTitle>
                  <TxtInput
                    style={{
                      marginLeft: "0",
                    }}
                    type="number"
                    min="1"
                    {...register("allowedChoices")}
                  />
                </Item>
                <Item style={{ display: "flex", alignItems: "center" }}>
                  <ItemTitle>선택지</ItemTitle>
                  <AppendButton
                    type="button"
                    onClick={() => {
                      setChoiceModal(true);
                    }}
                  >
                    수정하기
                  </AppendButton>
                </Item>
              </>
            )}
            {watchCategory === "SUBJECTIVE" && (
              <Item>
                <ItemTitle>
                  최소 답변 길이
                  <ItemSpan>*숫자만 입력 가능합니다</ItemSpan>
                </ItemTitle>
                <TxtInput
                  style={{
                    marginLeft: "0",
                  }}
                  type="number"
                  min="0"
                  {...register("minAnswerLength")}
                />
              </Item>
            )}
          </>
        </Contents>
        <ButtonDiv>
          <AddButton onClick={onAdd}>수정하기</AddButton>
        </ButtonDiv>
      </Inner>
    </Wrapper>
  );
}
