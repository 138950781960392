import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { clearAds } from "../../../common/clearStorage";
import { useEffect, useState } from "react";
import styled from "styled-components";
import EventPageAPI from "./Data/EventPageAPI";
import Pagination from "react-js-pagination";
import date from "../../../common/function/date";
import EventOngoingPageAPI from "./Data/EventOngoingPageAPI";

const Wrapper = styled.div`
  flex: 4;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ToggleBtn = styled.button<{ $toggle: boolean }>`
  width: 65px;
  height: 30px;
  margin-left: 60px;
  border-radius: 30px;
  border: none;
  cursor: pointer;

  background-color: ${(props) => (!props.$toggle ? "none" : "#ffc200")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;
const Circle = styled.div<{ $toggle: boolean }>`
  background-color: white;
  width: 19px;
  height: 19px;
  border-radius: 50px;
  position: absolute;
  left: 5%;
  transition: all 0.5s ease-in-out;
  ${(props) =>
    props.$toggle &&
    ` transform: translate(40px, 0);
      transition: all 0.5s ease-in-out;
    `}
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Tr = styled.tr``;

const Th = styled.th`
  background-color: #333742;
  color: white;
  padding: 1rem;
`;

const Td = styled.td`
  font-size: 15;
  padding: 1rem;
  .p-1 {
    width: 30%;
  }
  .w-25 {
    width: 25%;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const DetailButton = styled.button`
  width: 60px;
  border: none;
  padding: 4px;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: lightgray;
`;

export default function EventPage() {
  const pageNum = sessionStorage.getItem("eventsPage") || "1";
  const { data: events, refetch } = useQuery(["events", pageNum], async () =>
    toggle
      ? await EventPageAPI(Number(pageNum))
      : await EventOngoingPageAPI(Number(pageNum))
  );
  let count = events?.data.count + 1;

  const [toggle, setToggle] = useState(false);
  const clickedToggle = () => {
    setToggle((prev) => !prev);
  };

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("appStatsPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearAds();
  }, []);

  useEffect(() => {
    refetch();
  }, [toggle]);
  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title style={{ display: "flex" }}>
            {!toggle ? "진행 중인 이벤트 목록" : "모든 이벤트 목록"}

            <ToggleBtn onClick={clickedToggle} $toggle={toggle}>
              <Circle $toggle={toggle} />
            </ToggleBtn>
          </Title>

          <Link to="/events/add">
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>
        <Contents>
          <Table>
            <thead>
              <Tr className="tableHeader">
                <Th>No.</Th>
                <Th>이벤트 제목</Th>
                <Th>이벤트 부제목</Th>
                <Th>썸네일</Th>
                <Th>만료일시</Th>
                <Th>참여자 목록</Th>
                <Th />
              </Tr>
            </thead>
            <tbody>
              {events?.data?.page.content.map((event: any) => {
                count = count - 1;
                return (
                  <Tr className="tableH-50" key={event.id}>
                    <Td>{count}</Td>
                    <Td>{event.title}</Td>
                    <Td>{event.subtitle}</Td>
                    <Td>
                      {<img src={event.thumbnailUrl} width={"200px"} alt="" />}
                    </Td>
                    <Td>{date(event.expiredAt)}</Td>
                    <Td>
                      <Link
                        to={"/events/register/" + event.id}
                        className="link"
                        state={{
                          id: event.id,
                        }}
                      >
                        <DetailButton className="detailBtn">
                          목록보기
                        </DetailButton>
                      </Link>
                    </Td>{" "}
                    <Td>
                      <Link
                        to={"/events/" + event.id}
                        className="link"
                        state={{
                          id: event.id,
                          thumbnailUrl: event.thumbnailUrl,
                        }}
                      >
                        <DetailButton className="detailBtn">
                          자세히
                        </DetailButton>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={events?.data?.page?.pageable.totalElements || 0}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
