import React, { useEffect } from "react";

import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import { Category } from "../../../common/function/category";
import UserNotListAPI from "../UserList/Data/userNotListAPI";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 40px;
  align-items: center;
  line-height: normal;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid #000;
`;

const HeadTr = styled.tr`
  background-color: #333742;
  border: 1px solid #333742;
  color: #fff;
`;

const Th = styled.th`
  padding: 0.5rem;
  border: 0 solid;
  border-collapse: collapse;
  text-align: -webkit-match-parent;
`;

const BodyTr = styled.tr`
  border: solid #333742;
  border-width: 0 0 1px;
  height: 50px;
`;

const BodyTd = styled.td`
  text-align: center;
  padding: 0.5rem;
`;

const MessageDiv = styled.div`
  text-align: center;
  margin-bottom: 0.25rem;
  padding-bottom: 15px;
  padding-top: 15px;
  text-overflow: ellipsis;
  width: 400px;
  font-size: 14px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function UserAttendanceStatement(id: any) {
  const pageNum = sessionStorage.getItem("userStatementList") || "1";

  const { data: userStatementList, refetch } = useQuery(
    ["userStatementPage", pageNum],
    () => UserNotListAPI(pageNum, id.id),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("userStatementList", pageNumber.toString());
    refetch();
  };
  useEffect(() => {
    clearAll();
  }, []);
  let count = 0;
  return (
    <Wrapper>
      <Inner>
        <Contents>
          {userStatementList?.data.page.cotent ? (
            <div>
              <Table>
                <thead>
                  <HeadTr className="tableHeader">
                    <Th className="col p-2">미션 단어 내용</Th>
                    <Th className="col p-2">미션 단어 완성 일시</Th>
                  </HeadTr>
                </thead>
                <tbody>
                  {userStatementList?.data.page.content &&
                    userStatementList?.data.page.content.map(
                      (wordList: any) => {
                        count = count + 1;
                        return (
                          <BodyTr className="notificationTable" key={count}>
                            <BodyTd>{wordList.content}</BodyTd>
                            <BodyTd>{wordList.exchangedAt}</BodyTd>
                          </BodyTr>
                        );
                      }
                    )}
                </tbody>
              </Table>
              <PaginationWrapper>
                {" "}
                <Pagination
                  activePage={Number(pageNum)}
                  itemsCountPerPage={10}
                  totalItemsCount={
                    userStatementList?.data?.page?.pageable?.totalElements || 1
                  }
                  pageRangeDisplayed={5}
                  prevPageText={"‹"}
                  nextPageText={"›"}
                  onChange={handlePageChange}
                />
              </PaginationWrapper>
            </div>
          ) : (
            "완성 단어가 없습니다"
          )}
        </Contents>
      </Inner>
    </Wrapper>
  );
}
