import React from "react";

interface checkProps {
  index: number;
  checked: boolean[];
  onChange(): void;
  disabled: boolean;
}
const TestManageTwoOnlyCheck: React.FC<checkProps> = ({
  index,
  checked,
  onChange,
  disabled,
}) => {
  //   console.log(checked);
  return (
    <input
      type="checkbox"
      key={index}
      checked={checked[index]}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default TestManageTwoOnlyCheck;
