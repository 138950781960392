import instance from "API/MainAPI";
import { PageableData } from "API/type";
import { LIST_FILTER_STATUS, ListFilterStatus, PAGE_SIZE } from "./constants";
import {
  CreateDayMissionGameDto,
  CreateDayMissionGameFileDto,
  DayMission,
  DayMissionDto,
  DayMissionGame,
  DayMissionGameDetail,
  EditDayMissionGameDto,
  EditDayMissionGameFileDto,
  EditDayMissionsDto,
} from "./types";

interface GetDayMissionsParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: LIST_FILTER_STATUS;
}
export function getDayMissionGames({
  pageNumber = 0,
  pageSize = PAGE_SIZE,
  filter,
}: GetDayMissionsParams) {
  return instance.get<PageableData<DayMissionGame>>(
    "/v3/admins/dayMissionGames/managements",
    {
      params: {
        pageSize,
        pageNumber: pageNumber - 1,
        ongoing:
          filter === ListFilterStatus.All
            ? undefined
            : filter === ListFilterStatus.Ended
            ? false
            : true,
      },
    }
  );
}

export function getDayMissionGameDetail(id: number) {
  return instance.get<DayMissionGameDetail>(
    `/v3/admins/dayMissionGames/managements/${id}`
  );
}

interface CreateDayMissionGameParams {
  data: CreateDayMissionGameDto;
  fileData: CreateDayMissionGameFileDto;
}

export function createDayMissionGame({
  data,
  fileData,
}: CreateDayMissionGameParams) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );

  appendFile(formData, "thumbnail", fileData.thumbnail, true);
  appendFile(formData, "gameImages", fileData.gameImages);
  appendFile(
    formData,
    "missionImages",
    data.dayMissionRecords.map((record) => (record.missionImage as FileList)[0])
  );

  return instance.post<DayMissionGame>(
    "/v3/admins/dayMissionGames/managements",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=Paw",
        Accept: "application/json",
      },
    }
  );
}

interface EditDayMissionGameParams {
  data: EditDayMissionGameDto;
  fileData: EditDayMissionGameFileDto;
  id: number;
}
export function editDayMissionGame({
  data,
  fileData,
  id,
}: EditDayMissionGameParams) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );

  appendFile(formData, "thumbnail", fileData.thumbnail, true);
  appendFile(formData, "gameImages", fileData.gameImages);

  return instance.post<DayMissionGame>(
    `/v3/admins/dayMissionGames/managements/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=Paw",
        Accept: "application/json",
      },
    }
  );
}

interface EditDayMissionsParams {
  data: EditDayMissionsDto;
  gameId: number;
}
export async function editDayMissions({ data, gameId }: EditDayMissionsParams) {
  const promises = [];

  try {
    for (const dayMission of data.dayMissions) {
      const { id } = dayMission;
      if (id)
        promises.push(
          editDayMission({
            data: { ...dayMission, id },
            gameId,
          })
        );
      else {
        await createDayMission({ data: dayMission, gameId });
      }
    }
    const res = await Promise.all(promises);
    console.log(res);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

interface EditDayMissionParams {
  data: DayMissionDto & {
    id: number;
  };
  gameId: number;
}

export function editDayMission({ data, gameId }: EditDayMissionParams) {
  const formData = new FormData();
  const { id, ...rest } = data;
  formData.append(
    "data",
    new Blob([JSON.stringify(rest)], {
      type: "application/json",
    })
  );

  if (data.missionImage)
    appendFile(formData, "missionImage", data.missionImage);

  return instance.post<DayMission>(
    `/v3/admins/dayMissionGames/managements/${gameId}/dayMissions/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=Paw",
        Accept: "application/json",
      },
    }
  );
}
interface CreateDayMissionParams {
  data: DayMissionDto;
  gameId: number;
}

export function createDayMission({ data, gameId }: CreateDayMissionParams) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );

  appendFile(formData, "missionImage", data.missionImage);

  return instance.post<DayMission>(
    `/v3/admins/dayMissionGames/managements/${gameId}/dayMissions`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data; boundary=Paw",
        Accept: "application/json",
      },
    }
  );
}

function appendFile(
  formData: FormData,
  key: string,
  fileList?: FileList | File[],
  single: boolean = false
) {
  if (!fileList) return false;
  let i = 0;
  for (; i < fileList.length; i++) {
    formData.append(key, fileList[i]);
    if (single) return true;
  }
  return i > 0;
}
