import { useEffect, useState } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import AppStatsAPI from "../../Dashboard/Data/AppStatusAPI";
import { clearApps } from "../../../common/clearStorage";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "./Modal/DeleteModal";
import AppStatusDeleteAPI from "./Data/AppStatusDeleteAPI";

const Wrapper = styled.div`
  flex: 4;

  margin-inline: 20px;
`;
const Inner = styled.div`
  margin: 20px;
  justify-content: space-between;
`;
const Contents = styled.div`
  margin-top: 10px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const AddBtn = styled.button`
  width: 60px;
  border: none;
  padding: 3px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
const Table = styled.table`
  border: 2px solid #333742;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const Tr = styled.tr``;

const Th = styled.th`
  padding: 1rem;
  word-break: break-all;
`;

const Td = styled.td`
  font-size: 15px;
  padding: 1rem;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled.button`
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 60px;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function AppStatsList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modalDelete, setModalDelete] = useState(false);

  const [currentId, setCurrentId] = useState(0);

  const pageNum = sessionStorage.getItem("appStatsPage") || "1";

  const { data: detail, refetch } = useQuery(
    ["appStats", pageNum],
    () => AppStatsAPI(Number(pageNum), 10),
    {
      keepPreviousData: true,
    }
  );

  const { mutate: deleteAppStatus, isLoading: deleteLoading } = useMutation(
    async (id: number) => await AppStatusDeleteAPI(id),
    {
      onMutate: async (id) => {
        // Remove the query from cache before making the delete request
        queryClient.removeQueries(["appStats", id]);
      },
      onSuccess: () => {
        closeDelete();
      },
      onError: () => {
        alert("실패했습니다. 다시 시도하세요.");
        closeDelete();
      },
    }
  );

  const onDelete = (id: number) => {
    setCurrentId(id);
    setModalDelete(true);
  };

  function closeDelete() {
    setModalDelete(false);
  }

  const handleDelete = async () => {
    await deleteAppStatus(currentId);
    await alert("앱 버전 기록이 삭제되었습니다.");
  };

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("appStatsPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearApps();
  }, []);

  return (
    <Wrapper>
      {modalDelete && (
        <DeleteModal
          close={closeDelete}
          CallAPI={handleDelete}
          isLoding={deleteLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>앱 버전 기록</Title>
          <Link to="/appStats/add">
            <AddBtn>등록</AddBtn>
          </Link>
        </Header>

        <Contents>
          <Table>
            <Thead>
              <Tr>
                <Th>버전</Th>
                <Th style={{ width: "20%" }}>메세지</Th>
                <Th style={{ width: "10%" }}>앱스토어 링크</Th>
                <Th style={{ width: "10%" }}>구글스토어 링크</Th>
                <Th style={{ width: "10%" }}>접속</Th>
                <Th style={{ width: "20%" }}>강제 업데이트</Th>
                <Th>삭제</Th>
              </Tr>
            </Thead>
            <tbody>
              {detail?.data?.page?.content.map((stat: any) => (
                <Tr key={stat.id}>
                  <Td>{stat.version}</Td>
                  <Td style={{ width: "30%" }}>{stat.message}</Td>
                  <Td style={{ width: "10%", fontSize: "9px" }}>
                    {stat.appStoreLink}
                  </Td>
                  <Td style={{ width: "10%" }}>{stat.playStoreLink}</Td>
                  <Td>
                    <input type="checkbox" checked={stat.status} readOnly />
                  </Td>
                  <Td>
                    <input type="checkbox" checked={stat.compulsion} readOnly />
                  </Td>
                  <Td>
                    <DeleteButton onClick={() => onDelete(stat.id)}>
                      삭제
                    </DeleteButton>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
          <PaginationWrapper>
            <Pagination
              activePage={Number(pageNum)}
              itemsCountPerPage={10}
              totalItemsCount={detail?.data?.page?.pageable.totalElements || 0}
              pageRangeDisplayed={5}
              prevPageText={"‹"}
              nextPageText={"›"}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
