import Immigration from "./immigration";

declare global {
  interface Window {
    Kakao: any;
  }
}

export default function KakaoSDK() {
  const kakao = (window as any).Kakao;
  const value = process.env.REACT_APP_JAVASCRIPT_KEY;

  if (!kakao.isInitialized) {
    kakao.init(value);
    // console.log(window.Kakao.isInitialized());
  }

  kakao.Auth.loginForm({
    success: (authObj: any) => {
      Immigration(authObj.access_token);
    },
  });
}
