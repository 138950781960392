import { BountyStatus } from "./types";

export const BountyFilterStatus = {
  All: 0,
  OnGoing: 1,
  Ended: 2,
} as const;

export type BOUNTY_FILTER_STATUS =
  (typeof BountyFilterStatus)[keyof typeof BountyFilterStatus];

type LabelOfBountyStatus = { [K in BountyStatus]?: string | null };

export const labelOfBountyStatusPhoto: LabelOfBountyStatus = {
  REJECTED: "반려",
  APPROVED: "승인",
  SUBMITTED: "대기",
  REGISTERED: null,
  UNREGISTERED: null,
};
export const labelOfBountyStatusTime: LabelOfBountyStatus = {
  REJECTED: null,
  APPROVED: "완료",
  SUBMITTED: "진행중",
  REGISTERED: null,
  UNREGISTERED: null,
};

export const PAGE_SIZE = 10;

export const ListFilterStatus = {
  All: 0,
  OnGoing: 1,
  Ended: 2,
} as const;

export type LIST_FILTER_STATUS =
  (typeof ListFilterStatus)[keyof typeof ListFilterStatus];
