import instance from "API/MainAPI";

export default function BountyExcelAPI(bountyId: number) {
  return instance.get(
    `/v3/admins/bountyHunters/managements/download/${bountyId}`,
    {
      responseType: "arraybuffer",
    }
  );
}
