import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../../common/clearStorage";
import date from "../../../../common/function/date";
import { TestSurveyDetailAPI } from "./Data/TestSurveyDetailAPI";

const Wrapper = styled.div`
  flex: 4;
`;

const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const NickName = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-left: 1rem;
`;

const Contents = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  align-items: flex-end;
  /*justify-content: space-between;*/
`;

const ImageInput = styled.input`
  margin-left: 1rem;
  font-size: 16px;
`;

const CandidateForm = styled.div`
  width: 70%;
  border: 2px solid;
  border-radius: 10px;
  align-items: center;
  margin: 1rem;
  margin-left: 0;
  padding: 1rem;
`;

const ItemTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333742;
  display: flex;
`;

const ItemSpan = styled.span`
  margin-left: 0.5rem;
  color: gray;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  width: 50%;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const AppendButton = styled.button`
  margin-left: 3rem;
  background-color: gray;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 5px;

  width: 60px;
`;

const DeleteButton = styled.button`
  margin-left: 1rem;
  background-color: darkred;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 80px;
`;

const Label = styled.label`
  margin-right: 3rem;
`;

const DateInput = styled.input`
  font-size: 1rem;
  width: 25%;
  height: 1.5rem;
  margin-top: 5px;
  padding: 0 5px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
`;

const TxtInput = styled.input`
  width: 25%;
  font-size: 1rem;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const NumInput = styled.input`
  width: 30vw;
  font-size: 16px;
  height: 1.5rem;
  border-radius: 5px;
  border: 1px solid #a9a9a9;
  height: auto;
  line-height: normal;
  padding: 5px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: end;
`;

export default function TestSurveyDetail() {
  const { register, setValue, getValues, watch, control, reset } = useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const title = location.state.title;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "candidate",
  });

  const formData = new FormData();

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const [index, setIndex] = useState<number>(0);
  const [candidateId, setCandidateId] = useState<any>(0);
  const [image, setImage] = useState<File | undefined>();
  const [showImg, setShowImg] = useState<string[]>([]);
  const { data: testSurveyDetail } = useQuery(["testSurveyDetail", id], () =>
    TestSurveyDetailAPI(id)
  );

  //modal

  const [modalEdit, setModalEdit] = useState(false);

  const [candidateEdit, setCandidateEdit] = useState(false);

  function closeEdit() {
    setModalEdit(false);
  }

  function onCandidateEdit() {
    setCandidateEdit(true);
  }

  function closeCandidateEdit() {
    setCandidateEdit(false);
  }

  useEffect(() => {
    clearAll();

    const fetchData = async () => {
      const response = await TestSurveyDetailAPI(id);
      const { data } = response;

      reset(data);
    };
    fetchData();
  }, []);

  const Thumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      setValue("photo", selectedImage);
      encodeThumbToBase64(selectedImage);
    }
  };

  function encodeThumbToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setThumbnail(file);
    };
  }

  function encodeImgToBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(file);
      showImg[index] = URL.createObjectURL(file);
    };
  }

  const visibleValue = watch("visible");

  const handleRadioChange = (event: any) => {
    const newValue = event.target.value === "true";
    setValue("visible", newValue);
  };

  return (
    <Wrapper>
      <Inner>
        <Header>
          <Title>{title}</Title>
          <NickName>{location.state.nickname}</NickName>
        </Header>
        <Contents>
          <Item style={{ display: "flex", alignItems: "center" }}>
            <ItemTitle>객관식</ItemTitle>
          </Item>
          <CandidateForm>
            <div>
              {fields.map((item, index) => {
                const content = getValues(`multipleQuestions.${index}.content`);
                const allowedChoices = getValues(
                  `multipleQuestions.${index}.allowedChoices`
                );
                const choices = getValues(`multipleQuestions.${index}.choices`);
                return (
                  <div key={item.id} style={{ marginBottom: "1rem" }}>
                    <Item style={{ marginTop: "0.5rem" }}>
                      제목
                      <TxtInput
                        style={{ margin: "0 1rem" }}
                        {...register(`candidate.${index}.title`, {
                          required: true,
                        })}
                      />
                      내용
                      <TxtInput
                        style={{ margin: "0 1rem" }}
                        {...register(`candidate.${index}.body`, {
                          required: true,
                        })}
                      />
                    </Item>
                  </div>
                );
              })}
            </div>
          </CandidateForm>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
