import instance from "../../../../../API/MainAPI";


export default function GameSearchAPI(search: any) {
  return instance.get("/v3/games/search", {
    params: {
      query: search,
    },
  });
}
