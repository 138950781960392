import { useState } from "react";
import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import GetBountyDetailAPI from "../BountyDetail/Data/GetBountyDetailAPI";
import BountyHeader from "../shared/BountyHeader";
import CertificationTable from "../shared/CertificationTable/CertificationTable";
import useBountyHunterTimeDetails from "./useBountyHunterTimeDetail";
import useModal from "components/common/Modal/useModal";
import DownloadModal from "../shared/modals/DownloadModal";
import BountyExcelAPI from "./Data/BountyExcelAPI";

const PAGE_SIZE = 10;

const Wrapper = styled.div`
  flex: 4;
`;
const Inner = styled.div`
  margin: 20px;
  align-items: center;
`;
const Contents = styled.div`
  margin-top: 20px;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;
const ExcelDownloadButton = styled.button`
  outline: 0;
  border-radius: 7px;
  border: 1px solid #dee2e6;
  background: #f0f0f0;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 8px;
  color: #000;
  cursor: pointer;
  margin: 0 0 28px 14px;
`;

const BountyHunterTime = () => {
  const navigate = useNavigate();
  const params = useParams();
  const bountyId = +(params.bountyId || "1") || 1;

  const { data: bountyData } = useQuery(
    ["bounty", bountyId],
    () => GetBountyDetailAPI(bountyId),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );

  const {
    currentPage,
    items,
    checkedItems,
    toggleItems,
    toggleItem,
    setFilter,
  } = useBountyHunterTimeDetails(bountyId);

  const { data: excelData, isLoading } = useQuery(
    ["excel", "bounty", bountyId],
    () => BountyExcelAPI(bountyId),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );
  const { modal } = useModal();
  const openExcelModal = () =>
    modal((modal) => (
      <DownloadModal
        modal={modal}
        isLoading={isLoading}
        url={excelData?.data}
      />
    ));

  return (
    <Wrapper>
      {bountyData && bountyData.data && <BountyHeader item={bountyData.data} />}
      <Inner>
        <Contents>
          <ExcelDownloadButton onClick={openExcelModal}>
            엑셀 다운로드
          </ExcelDownloadButton>
          {items && (
            <CertificationTable
              type="time"
              items={items}
              checkedItems={checkedItems}
              toggleItems={toggleItems}
              toggleItem={toggleItem}
              setFilter={setFilter}
            />
          )}
        </Contents>
        <PaginationWrapper>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={1}
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            onChange={(pageNum) => navigate(`.?page=${pageNum}`)}
          />
        </PaginationWrapper>
        {/* {totalData?.page?.content.length == 0 && (
          <NoneData>검색 결과 없음</NoneData>
        )} */}
      </Inner>
    </Wrapper>
  );
};

export default BountyHunterTime;
