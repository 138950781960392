import instance from "../../../../../API/MainAPI";

export default function UserPage(page: number, pageSize: number) {
  return instance.get("/v1/admins/users/managements", {
    params: {
      pageNumber: Number(page - 1),
      pageSize: pageSize,
    },
  });
}
