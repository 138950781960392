import { useEffect } from "react";
import Pagination from "react-js-pagination";
import { useQuery } from "react-query";
import styled from "styled-components";
import { clearNotification } from "../../../common/clearStorage";
import index from "../../../common/function";
import { Category } from "../../../common/function/category";
import NotificationPage from "./Data/notificationAPI";

const Wrapper = styled.div`
  flex: 4;
  margin: 20px;
`;
const Contents = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Table = styled.table`
  border: 2px solid #333742;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
`;

const Thead = styled.thead`
  background-color: #333742;
  color: white;
`;

const TableContents = styled.tr`
  border: solid #333742;
  border-width: 0 0 1px;
`;

const TableHeader = styled.tr`
  background-color: #333742;
  border: 1px solid #333742;
  color: #fff;
`;

const Th = styled.th`
  border: 0 solid;
  border-color: inherit;
  padding: 1rem !important;
`;

const Td = styled.td`
  border-bottom: 1px solid #333742;
  font-size: 15;
  padding: 1rem;
  word-break: normal;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #ffc200;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #ffc200;
      }
    }
  }
`;

export default function NotificationList() {
  const pageNum = sessionStorage.getItem("notificationPage") || "1";

  const { data: notification, refetch } = useQuery(
    ["notification", pageNum],
    () => NotificationPage(pageNum),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("notificationPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearNotification();
  }, []);

  let count = 0;

  return (
    <Wrapper>
      <Header>
        <Title>알림 내역</Title>
      </Header>
      <Contents>
        <Table>
          <Thead>
            <TableHeader>
              <Th className="p-2">No.</Th>
              <Th className="p-2">분류</Th>
              <Th className="p-2">제목</Th>
              <Th className="p-2">메시지</Th>
              <Th className="p-2">유저</Th>
            </TableHeader>
          </Thead>
          <tbody>
            {notification?.data?.page?.content.map((noti: any) => {
              const category = Category(noti.category);
              count = count + 1;
              return (
                <TableContents key={noti.id}>
                  <Td>
                    {index(
                      notification?.data?.page?.pageable.totalElements,
                      notification?.data?.page?.pageable.pageNumber + 1,
                      notification?.data?.page?.pageable.pageSize,
                      count
                    )}
                  </Td>
                  <Td>{category}</Td>
                  <Td>{noti.title}</Td>
                  <Td>{noti.message}</Td>
                  <Td>{noti.user.nickname}</Td>
                </TableContents>
              );
            })}
          </tbody>
        </Table>
        <PaginationWrapper>
          <Pagination
            activePage={Number(pageNum)}
            itemsCountPerPage={10}
            totalItemsCount={
              notification?.data?.page?.pageable.totalElements || 1
            }
            pageRangeDisplayed={5}
            prevPageText={"‹"}
            nextPageText={"›"}
            onChange={handlePageChange}
          />
        </PaginationWrapper>
      </Contents>
    </Wrapper>
  );
}
