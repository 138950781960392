import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearAll } from "../../../common/clearStorage";
import OrderCompletionAPI from "./Data/PlistarOrderCompletionAPI";
import PlistarOrderDetailAPI from "./Data/PlistarOrderDetailAPI";
import OrderDetailAPI from "./Data/PlistarOrderDetailAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  flex: 4 1;
`;

const Inner = styled.div`
  margin: 20px 40px 20px 40px;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
`;

const Contents = styled.div`
  margin-top: 20px;
`;

const Box = styled.div`
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  margin-top: 5px;
  margin: 5px 0px 5px 0;
  width: 50%;
  padding: 5px;
  height: auto;
  line-height: normal;
`;

const BoxTitle = styled.h3`
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  justify-content: center;
  font-size: 20px;
  display: flex;
`;

const Item = styled.div`
  display: flex;
  margin: 20px 0;
  box-sizing: border-box;
  line-height: normal;
  align-items: center;
`;

const ItemTitle = styled.div`
  margin-right: 1.5rem;
  display: flex;
  font-size: 15px;
  font-weight: 600;
`;

const ItemSubTitle = styled.div`
  margin-right: 1rem;
  display: flex;
  font-size: 15px;
`;

const ItemInfo = styled.div`
  display: flex;
  font-size: 20px;
  margin-bottom: 0.25rem;
  line-height: normal;
`;

const Elements = styled.div`
  display: flex;
  width: 50%;
`;

const Img = styled.img`
  width: 80%;
  margin-bottom: 10px;
  margin-left: 3rem;
  margin-right: 3rem;
`;

const Space = styled.div`
  width: 10%;
`;
const BtnDiv = styled.div`
  margin-top: 0.5rem;
  justify-content: flex-end;
  display: flex;
`;
const CompletionBtn = styled.button`
  width: 100px;
  border: none;
  padding: 3px;
  background-color: #f6cc42;
  color: #333742;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
`;

const BarcodeInput = styled.input`
  width: 20vw;
  height: auto;
  padding: 10px;
  border: 1px solid darkgray;
  border-radius: 5px;
  margin: 10px 0;
  font-size: 15px;
`;

export default function PlistarOrderDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state.id;

  const { data: orderDetail } = useQuery(["orderDetail", id], () =>
    PlistarOrderDetailAPI(id)
  );

  useEffect(() => {
    clearAll();
  }, []);

  const [modal, setModal] = useState(false);
  function close() {
    setModal(false);
  }

  function onClick() {
    setModal(true);
  }

  async function CallAPI() {
    await BarcodeMutation.mutateAsync();
  }

  const BarcodeMutation = useMutation(() => OrderCompletionAPI(id), {
    onSuccess: () => {
      setModal(false);
      setIsLoading(false);
      alert("지급 완료 처리되었습니다.");
      navigate(-1);
    },
    onError: () => {
      setIsLoading(false);
      alert("지급 실패했습니다. 다시 시도하세요.");
      setModal(false);
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  function setLoading() {
    setIsLoading(false);
  }

  return (
    <Wrapper>
      {modal && (
        <Modal
          close={close}
          CallAPI={CallAPI}
          isLoading={isLoading}
          setIsLoading={setLoading}
        />
      )}
      <Inner>
        <Header>
          <Title>교환 세부 내역 #{id}</Title>
        </Header>
        <Contents>
          <div style={{ display: "flex" }}>
            <Box>
              <BoxTitle>주문 정보</BoxTitle>
              <Item>
                <Elements>
                  {orderDetail?.data.product && (
                    <Img src={orderDetail.data.imageUrl} alt="" />
                  )}
                </Elements>
                <Elements>
                  <div style={{ marginLeft: "1rem" }}>
                    <Item>
                      <ItemTitle>제품명</ItemTitle>
                      {orderDetail?.data.product && (
                        <ItemInfo
                          style={{
                            fontWeight: 600,
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          {orderDetail?.data.product}
                        </ItemInfo>
                      )}
                    </Item>{" "}
                    <Item>
                      <ItemTitle>플리스타 사이클</ItemTitle>
                      {orderDetail?.data && (
                        <ItemInfo>{orderDetail?.data.cycle}</ItemInfo>
                      )}
                    </Item>{" "}
                    <Item>
                      <ItemTitle>제품단계</ItemTitle>
                      {orderDetail?.data && (
                        <ItemInfo>
                          {orderDetail.data.phase === "PHASE_ONE"
                            ? "1단계"
                            : orderDetail.data.phase === "PHASE_TWO"
                            ? "2단계"
                            : orderDetail.data.phase === "PHASE_THREE"
                            ? "3단계"
                            : orderDetail.data.phase === "PHASE_FOUR"
                            ? "4단계"
                            : orderDetail.data.phase === "PHASE_FIVE"
                            ? "5단계"
                            : orderDetail.data.phase === "PHASE_SIX"
                            ? "6단계"
                            : orderDetail.data.phase === "PHASE_SEVEN"
                            ? "7단계"
                            : orderDetail.data.phase === "PHASE_EIGHT"
                            ? "8단계"
                            : orderDetail.data.phase === "PHASE_NINE"
                            ? "9단계"
                            : orderDetail.data.phase === "PHASE_TEN"
                            ? "10단계"
                            : "코드오류"}
                        </ItemInfo>
                      )}
                    </Item>
                    {orderDetail?.data.exp && (
                      <Item>
                        <ItemTitle>지급</ItemTitle>
                        <ItemInfo>{orderDetail?.data.exp}</ItemInfo>
                        <ItemSubTitle>exp</ItemSubTitle>
                      </Item>
                    )}
                  </div>
                </Elements>
              </Item>
              <div
                style={{ display: "flex", margin: "1.5rem 3rem 1.5rem 3rem" }}
              >
                <ItemTitle>주문 일시</ItemTitle>
                <ItemSubTitle>
                  {orderDetail?.data.orderedAt && (
                    <div style={{ marginRight: "1rem" }}>
                      {String(orderDetail?.data.orderedAt).substring(0, 10)}
                    </div>
                  )}
                  {orderDetail?.data.orderedAt && (
                    <div>
                      {String(orderDetail?.data.orderedAt).substring(11, 19)}
                    </div>
                  )}
                </ItemSubTitle>
              </div>

              <div
                style={{ display: "flex", margin: "1.5rem 3rem 1.5rem 3rem" }}
              >
                <ItemTitle>제품 발송 일시</ItemTitle>
                <ItemSubTitle>
                  {orderDetail?.data.exchanged &&
                    String(orderDetail?.data.exchangedAt).substring(0, 10)}
                  {orderDetail?.data.exchanged ? " (발송 완료)" : "미발송"}
                </ItemSubTitle>
              </div>
              {orderDetail?.data.product.description && (
                <div
                  style={{ display: "flex", margin: "1.5rem 3rem 1.5rem 3rem" }}
                >
                  <ItemTitle>상세 설명</ItemTitle>

                  <ItemSubTitle>
                    {orderDetail?.data.product.description}
                  </ItemSubTitle>
                </div>
              )}
              {orderDetail?.data.description && (
                <div
                  style={{ display: "flex", margin: "1.5rem 3rem 1.5rem 3rem" }}
                >
                  <ItemTitle>상세 설명</ItemTitle>

                  <ItemSubTitle style={{ width: "1000px" }}>
                    {orderDetail?.data.description}
                  </ItemSubTitle>
                </div>
              )}
            </Box>
            <Space />
            <div style={{ display: "flex-direction:column" }}>
              <Box style={{ width: "20vw" }}>
                <BoxTitle>구매자 정보</BoxTitle>
                <div style={{ marginLeft: "3rem" }}>
                  <Item>
                    <ItemTitle>구매자</ItemTitle>
                    {orderDetail?.data.consumer && (
                      <ItemSubTitle>
                        {orderDetail?.data.consumer.nickname}
                      </ItemSubTitle>
                    )}
                  </Item>
                  <Item>
                    <ItemTitle>전화번호</ItemTitle>
                    {orderDetail?.data.consumer && (
                      <ItemSubTitle>
                        {orderDetail?.data.consumer.phone}
                      </ItemSubTitle>
                    )}
                  </Item>
                  <Item>
                    <ItemTitle>레벨</ItemTitle>
                    {orderDetail?.data.consumer && (
                      <ItemSubTitle>
                        {String(orderDetail?.data.consumer.level).substring(5)}
                      </ItemSubTitle>
                    )}
                  </Item>
                  <Item>
                    <ItemTitle>티어</ItemTitle>
                    {orderDetail?.data.consumer && (
                      <ItemSubTitle>
                        {String(orderDetail?.data.consumer.tier).substring(5)}
                      </ItemSubTitle>
                    )}
                  </Item>
                  <Item>
                    <ItemTitle>플리스타</ItemTitle>
                    {orderDetail?.data && (
                      <ItemSubTitle>
                        {orderDetail?.data.consumer.plistar}
                      </ItemSubTitle>
                    )}
                  </Item>
                  <Item>
                    <ItemTitle>사이클</ItemTitle>
                    {orderDetail?.data && (
                      <ItemSubTitle>
                        {orderDetail?.data.consumer.cycle}
                      </ItemSubTitle>
                    )}
                  </Item>
                </div>
              </Box>
              {!orderDetail?.data.exchanged && (
                <div>
                  <ItemInfo
                    style={{
                      fontWeight: "bold",
                      marginBottom: "0.5rem",
                      marginTop: "3rem",
                    }}
                  >
                    지급 완료 처리
                  </ItemInfo>

                  <BtnDiv>
                    <CompletionBtn onClick={onClick}>
                      지급 완료하기
                    </CompletionBtn>
                  </BtnDiv>
                </div>
              )}
            </div>
          </div>
        </Contents>
      </Inner>
    </Wrapper>
  );
}
