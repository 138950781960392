import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BountyHunt,
  BountyHuntFilter,
  BountyStatus,
  DayMissionActivity,
  DayMissionFilter,
} from "../types";
import { useQuery } from "react-query";
import DayMissionList from "./Data/DayMissionList";

const ITEMS_PER_PAGE = 10;

const useDayMissionGameCertificateDetail = (dayMissionGameId: number) => {
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState<DayMissionFilter>({});
  // 현재 페이지 불러오기(예외 시 1)
  const currentPage = useMemo(() => {
    const _page = Number(searchParams.get("page") || "1");
    return Number.isNaN(_page) ? 1 : _page;
  }, [location.search]);

  const [checkedItems, setCheckedItems] = useState<Set<string | number>>(
    new Set()
  );

  // 아이템 목록 불러오기

  const { data: dayMissionData } = useQuery(
    [
      "daymission",
      dayMissionGameId,
      currentPage,
      filter.user,
      filter.dayNumber,
    ],
    () =>
      DayMissionList({
        dayMissionGameId,
        pageNumber: currentPage - 1,
        pageSize: ITEMS_PER_PAGE,
        userId: filter.user,
        dayNumber: filter.dayNumber,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );
  const items = dayMissionData?.data.page.content;

  // 아이템 선택/해제
  const toggleItem = useCallback(
    (item: DayMissionActivity, checked: boolean) => {
      const id = item.id;
      if (!checked) checkedItems.delete(id);
      else if (!checkedItems.has(id)) checkedItems.add(id);
      setCheckedItems(new Set(checkedItems));
    },
    [checkedItems]
  );

  // 모든 아이템 선택/해제
  const toggleItems = useCallback(
    (itemFilter: (item: DayMissionActivity) => boolean, checked: boolean) => {
      if (!items) return;
      if (checked)
        setCheckedItems(
          new Set(items.filter(itemFilter).map((item) => item.id))
        );
      else setCheckedItems(new Set());
    },
    [checkedItems, items]
  );

  return {
    currentPage,
    items,
    checkedItems,
    toggleItem,
    toggleItems,
    setFilter,
    pageable: dayMissionData?.data.page.pageable,
  };
};

export default useDayMissionGameCertificateDetail;
